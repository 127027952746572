import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { fadeRegularAnimation } from '../../../../../app-animations/app-animations';

@Component({
  selector: 'app-refresh-page',
  templateUrl: './refresh-page.component.html',
  animations: [fadeRegularAnimation()],
})
export class RefreshPageComponent {
  @Input() messageType: string = 'refreshPage'; // pageNotFound

  constructor(private router: Router) {}

  refreshPage(event) {
    if (event.target.classList.contains('action')) {
      this.router.navigate([this.router.url]);
      location.reload();
    }
  }
}
