<!-- REGULAR VIEW
  ============================================================== -->
<h3
  class="upload-button dd-menu {{ view }}"
  data-qa="upload-button-wrap"
  [keyboardActions]="{ dropdown: true }"
  [tabindex]="!active ? -1 : 0"
  [ngClass]="{ disabled: !active }"
  dropdown
>
  <label
    *ngIf="hasLocationData && !canUploadFolders"
    [for]="active ? 'uplFile' : ''"
    (click)="cleanInput()"
    [ngClass]="{ disabled: !active }"
    class="button full-size col-blue with-icon bold"
  >
    <span class="icon-wrap">
      <div class="upload-button-plus" *ngIf="view === 'regular-view'">+</div>
      <svg
        *ngIf="view === 'mobile-view'"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 35 35"
        style="enable-background: new 0 0 35 35"
        xml:space="preserve"
      >
        <g>
          <g>
            <g>
              <path
                style="fill: #ffffff"
                class="st0"
                d="M29.4,21.1c-0.8,0-1.5,0.8-1.5,1.6v5.9H7.1v-6.1c0-0.8-0.7-1.5-1.5-1.5s-1.5,0.7-1.5,1.5v6.1
                c0,2.5,0.5,3,3,3h20.8c2.5,0,3-0.5,3-3v-6.1C30.9,21.8,30.2,21.1,29.4,21.1z M11.6,13.3L16,9v13.7c0,1,0.5,1.5,1.5,1.5
                s1.5-0.5,1.5-1.5l0.2-13.5l4.2,4.1c0.3,0.3,0.8,0.5,1.3,0.5s0.9-0.2,1.3-0.5c0.7-0.7,0.7-1.8,0-2.5l-7.1-6.9
                c-0.1-0.1-0.2-0.2-0.3-0.2c0,0-0.1,0-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1s-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2,0s-0.2,0-0.3,0l0,0l0,0
                c-0.2,0-0.3,0-0.5,0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0.1-0.3,0.2-0.5,0.3l-7.1,7c-0.7,0.7-0.7,1.8,0,2.5C9.8,14,10.9,14,11.6,13.3
                z"
              />
            </g>
          </g>
        </g>
      </svg>
    </span>
    <ng-container *ngIf="view === 'regular-view'">
      {{ 'sidebar.uploadFiles' | translate }}
    </ng-container>
    <ng-container *ngIf="view === 'mobile-view'">
      {{ 'sidebar.upload' | translate }}
    </ng-container>
  </label>

  <div
    *ngIf="hasLocationData && canUploadFolders"
    (click)="cleanInput()"
    [ngClass]="{ disabled: !active }"
    class="button full-size col-blue with-icon bold dd-menu-head upload-button-main"
    data-qa="upload-button"
    dropdown-open
  >
    <span class="icon-wrap">
      <div class="upload-button-plus" *ngIf="view === 'regular-view'">+</div>
      <svg
        *ngIf="view === 'mobile-view'"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 35 35"
        style="enable-background: new 0 0 35 35"
        xml:space="preserve"
      >
        <g>
          <g>
            <g>
              <path
                style="fill: #ffffff"
                class="st0"
                d="M29.4,21.1c-0.8,0-1.5,0.8-1.5,1.6v5.9H7.1v-6.1c0-0.8-0.7-1.5-1.5-1.5s-1.5,0.7-1.5,1.5v6.1
                c0,2.5,0.5,3,3,3h20.8c2.5,0,3-0.5,3-3v-6.1C30.9,21.8,30.2,21.1,29.4,21.1z M11.6,13.3L16,9v13.7c0,1,0.5,1.5,1.5,1.5
                s1.5-0.5,1.5-1.5l0.2-13.5l4.2,4.1c0.3,0.3,0.8,0.5,1.3,0.5s0.9-0.2,1.3-0.5c0.7-0.7,0.7-1.8,0-2.5l-7.1-6.9
                c-0.1-0.1-0.2-0.2-0.3-0.2c0,0-0.1,0-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1s-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2,0s-0.2,0-0.3,0l0,0l0,0
                c-0.2,0-0.3,0-0.5,0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0.1-0.3,0.2-0.5,0.3l-7.1,7c-0.7,0.7-0.7,1.8,0,2.5C9.8,14,10.9,14,11.6,13.3
                z"
              />
            </g>
          </g>
        </g>
      </svg>
    </span>
    <ng-container *ngIf="view === 'regular-view'">
      {{ 'sidebar.new' | translate }}
    </ng-container>
    <ng-container *ngIf="view === 'mobile-view'">
      {{ 'sidebar.upload' | translate }}
    </ng-container>
  </div>

  <ul class="dd-menu-hidden upload-button-list">
    <li>
      <div class="dd-link" tabindex="-1" (click)="triggerUpload('uplFile')" data-qa="upload-file">
        <label>
          <span class="sub-menu-icon iconame-upload-file"></span>
          <span>{{ 'actionMenu.uploadFiles' | translate }}</span>
        </label>
      </div>
    </li>
    <li>
      <div class="dd-link" tabindex="-1" (click)="triggerUpload('uplFolder')" data-qa="upload-folder">
        <label>
          <span class="sub-menu-icon iconame-upload-folder"></span>
          <span>{{ 'actionMenu.uploadFolder' | translate }}</span>
        </label>
      </div>
    </li>
    <li class="separator"></li>
    <li>
      <div class="dd-link" tabindex="-1" (click)="createNewItem('doc')" data-qa="new-doc">
        <div>
          <span class="sub-menu-icon iconame-mi-documents"></span>
          <span>{{ 'sidebar.newDocument' | translate }}</span>
        </div>
      </div>
    </li>
    <li>
      <div class="dd-link" tabindex="-1" (click)="createNewItem('sheet')" data-qa="new-sheet">
        <div>
          <span class="sub-menu-icon iconame-mi-sheets"></span>
          <span>{{ 'sidebar.newSpreadsheet' | translate }}</span>
        </div>
      </div>
    </li>
    <li>
      <div class="dd-link" tabindex="-1" (click)="createNewItem('presentation')" data-qa="new-presentation">
        <div>
          <span class="sub-menu-icon iconame-mi-slides"></span>
          <span>{{ 'sidebar.newPresentation' | translate }}</span>
        </div>
      </div>
    </li>
    <li class="separator"></li>
    <li>
      <div class="dd-link" tabindex="-1" (click)="createNewFolder()" data-qa="new-folder">
        <div>
          <span class="sub-menu-icon iconame-new-folder"></span>
          <span>{{ 'actionMenu.newFolder' | translate }}</span>
        </div>
      </div>
    </li>
  </ul>

  <div *ngIf="!hasLocationData" class="skeleton-loader button full-size"></div>
</h3>
