<section class="modal-wrap share-modal">
  <span class="back-to-view" [ngClass]="{ switch: isMailView }" (click)="showMailView(false)">
    <span class="menu-icon iconame-back-arrow"></span>
  </span>
  <div class="modal-body cfix">
    <div class="view-box email-view cfix"
         [ngClass]="{ switch: isMailView }"
         *ngIf="!isMailView">
      <img class="sub-modal-icon" src="../../../../../assets/images/congratulations20.svg" alt="congratulations">
      <h5 class="sub-modal-title center" data-qa="im-title">
        {{ 'modal.inviteCongratulations' | translate }}
      </h5>
      <p class="sub-modal-text center" [innerHTML]="'modal.inviteCongratulationsText' | translate : { value: storage }"></p>
    </div>

    <div class="view-box email-view cfix" [ngClass]="{ switch: isMailView }">
      <h5 class="sub-modal-title" data-qa="im-title">
        {{ 'modal.inviteTitle' | translate }}
      </h5>

      <div
        class="entry-mails"
        [ngClass]="{ focused: mailBoxFocused, locked: emailsList.length === emailsLimit }"
        #emailEntryContainer
        [perfectScrollbar]
        [disabled]="mobileDevice || heightLimit"
      >
        <tag-input
          #tagInput
          [(ngModel)]="emailsList"
          [placeholder]="'modal.plusMail' | translate"
          [secondaryPlaceholder]="'modal.addEmails' | translate"
          [separatorKeyCodes]="[32, 186, 188]"
          [separatorKeys]="[32, 186, 188]"
          [addOnBlur]="true"
          [addOnPaste]="true"
          [editable]="true"
          [disable]="sendingMail"
          [inputId]="['emailsListInput']"
          [animationDuration]="{ enter: '0ms', leave: '0ms' }"
          (onFocus)="setMailBoxState(true)"
          (onBlur)="onBlurEmailInput(false, $event)"
          (onAdd)="onAdding($event)"
          (onRemove)="onRemove($event)"
          (onSelect)="onTagSelect($event)"
          (onTagEdited)="onTagEdited($event, emailsList)"
        >
        </tag-input>
      </div>

      <div class="error-box" *ngIf="emailsList.length >= emailLimit">
        <div class="form-error">
          {{"modal.emailLimit" | translate}}
        </div>
      </div>

      <form [formGroup]="sendEmailForm" class="single-item-form message-form">
        <div class="form-item">
          <textarea
            class="textarea-style input-style invite"
            #mailMessage
            id="mailMessage"
            formControlName="mailMessage"
            placeholder="{{ 'modal.enterMessageInviteLink' | translate }}"
          >
          </textarea>
        </div>
        <div
          class="text-limit"
          [ngClass]="{ error: emailFc.mailMessage.value.length > emailTextLimit }"
          *ngIf="emailFc.mailMessage.value && emailFc.mailMessage.value.length > 249"
        >
          {{ sendEmailForm.controls.mailMessage.value.length + '/' + emailTextLimit }}
        </div>
      </form>
    </div>
  </div>

  <footer class="modal-footer cfix" [ngClass]="{'congratulations': !isMailView}">
    <button
      *ngIf="!isMailView"
      class="button col-blue medium-size sub-modal-button"
      (click)="showMailView(true)"
    >
      {{ 'modal.invite' | translate }}
    </button>
    <button
      *ngIf="isMailView"
      class="button col-blue medium-size"
      [disabled]="
        !emailsList.length ||
        sendingMail ||
        invalidEmail ||
        !emailFc.mailMessage.value ||
        (emailFc.mailMessage.value && emailFc.mailMessage.value.length > emailTextLimit)
      "
      title="{{ invalidEmail ? ('modal.sendLinkButtonError' | translate) : '' }}"
      (click)="onSendInvite()"
    >
      <span *ngIf="!sendingMail">{{ 'modal.sendInviteEmail' | translate }}</span>
      <span *ngIf="sendingMail">{{ 'modal.sendingInviteLink' | translate }}</span>
    </button>
  </footer>
</section>
