import { Injectable } from '@angular/core';
import { ReportPageEventService } from './report-page-event.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

export interface IPageMapItem {
  name: string;
  pageNameClass: string;
  static: boolean;
  hidePageHeader?: boolean;
  customClass: string;
}
@Injectable({
  providedIn: 'root',
})
export class NavigationHandlerService {
  constructor(private reportPageEventService: ReportPageEventService, private router: Router) {
    this.currentUrl = this.router.url;
    router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
    });
  }

  private previousUrl: string = '/';
  private currentUrl: string = '/';

  currentPage: IPageMapItem = null;

  pagesMap: IPageMapItem[] = [
    { name: 'home', pageNameClass: 'public home', static: true, customClass: '' }, // home is always first item !!!
    { name: 'activate', pageNameClass: 'public activate', static: true, customClass: '' },
    { name: 'pricing', pageNameClass: 'public pricing', static: true, customClass: '' },
    { name: 'pricing-plans', pageNameClass: 'public pricing pricing-plans', static: true, customClass: '' },
    { name: 'cloud-storage-plans', pageNameClass: 'public pricing landing-page', static: true, customClass: '' },
    { name: 'checkout', pageNameClass: 'public checkout', static: true, customClass: '' },
    { name: 'purchase-success', pageNameClass: 'public purchase-success', static: true, customClass: '' },
    { name: 'order-error', pageNameClass: 'public order-error', static: true, customClass: '' },
    { name: 'updates', pageNameClass: 'public updates', static: true, customClass: '' },
    { name: 'privacy-policy', pageNameClass: 'public legal-pages', static: true, customClass: '' },
    { name: 'terms-of-use', pageNameClass: 'public legal-pages', static: true, customClass: '' },
    { name: 'survey', pageNameClass: 'survey', static: true, customClass: 'survey-page-wrap' },
    { name: 'my-files', pageNameClass: 'my-files', static: false, customClass: '' },
    { name: 'shared', pageNameClass: 'shared', static: false, customClass: 'has-extra-menu' },
    { name: 'recent-files', pageNameClass: 'recent-files', static: false, customClass: '' },
    { name: 'bin', pageNameClass: 'bin-page', static: false, customClass: '' },
    { name: 'backups', pageNameClass: 'backups-page', static: false, customClass: '' },
    { name: 'search', pageNameClass: 'search', static: false, customClass: '' },
    {
      name: 'file-version',
      pageNameClass: 'file-version',
      static: false,
      hidePageHeader: true,
      customClass: 'file-version-page',
    },
    { name: 'shared-folder', pageNameClass: 'shared-folder', static: false, customClass: '' },
    { name: 'sharelink', pageNameClass: 'shlinkpage', static: false, customClass: 'share-link-page' },
  ];

  getPageData(event: NavigationEnd): IPageMapItem {
    if (event.url.split('?')[0].split('#')[0] === '/') {
      this.currentPage = this.pagesMap[0];
    } else {
      const page = this.pagesMap.find((elem) => {
        return elem.name === event.urlAfterRedirects.match(/^\/([^\/?]+)/)[1];
      });
      this.currentPage = page ? page : null;
    }

    return this.currentPage;
  }

  getCurrentPageData(): IPageMapItem {
    return this.currentPage;
  }

  getPreviousUrl(): string {
    return this.previousUrl;
  }

  checkForPageError() {
    if (this.reportPageEventService.isPageError()) {
      this.reportPageEventService.setPageError(false);
      this.reportPageEventService.reportEvent('errorMessages.404error', 'error', '404error');
    }
  }
}
