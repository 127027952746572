import { Component, OnInit, Input, ViewChild, ElementRef, NgZone, AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ApplyActionService } from '../../../../services/apply-action.service';
import { fromEvent } from 'rxjs';
import { IRefsMap } from '../../../../models/common.int';

@Component({
  selector: 'app-rename-file',
  templateUrl: './rename-file.component.html',
})
export class RenameFileComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() filesData: any;
  @Input() menuSource: any;
  @Input() modal: any;

  @ViewChild('editName') editName: ElementRef;

  renameForm: FormGroup;
  fc: any;

  isFolder: boolean = false;
  lastDot: any;
  errorCode: boolean = false;
  loading: boolean = false;
  originalFileExtension: string = '';
  warningMessage: boolean = false;

  refs: IRefsMap = {};

  constructor(private formBuilder: FormBuilder, private applyActionService: ApplyActionService, private zone: NgZone) {}

  ngOnInit() {
    const fullFileName: string = this.filesData[0].name;
    let fileName: string = '';
    this.isFolder = this.filesData[0].dir ? true : false;

    if (this.isFolder) {
      fileName = fullFileName.slice(0, -1);
      this.lastDot = fileName.length;
    } else {
      fileName = fullFileName;
      this.lastDot = this.getLastDotPosition(fullFileName);
      this.originalFileExtension = this.getFileExtension(fullFileName);
    }

    this.renameForm = this.formBuilder.group({
      editName: [fileName, [Validators.required, Validators.maxLength(250), Validators.pattern('(?!\\s*(?:\\.\\s*)+$)[^\\\\:*?"<>|/]+')]],
    });

    this.renameForm.valueChanges.subscribe((value) => {
      // Handle empty space at the begining of the string
      const startWithSpace = value.editName.match(/^\s+/g);
      if (startWithSpace) {
        const trimmedValue = value.editName.replace(/^\s+/g, '');
        this.renameForm.patchValue({ editName: trimmedValue });
      }
    });

    this.fc = this.renameForm.controls;
  }

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      this.refs.keyDownEventRef = fromEvent(document, 'keydown').subscribe((event) => {
        this.handleKeyboardEventDown(event);
      });

      setTimeout(() => {
        this.editName.nativeElement.focus();
        this.editName.nativeElement.setSelectionRange(0, this.lastDot);

        this.refs.keyDownEnterRef = fromEvent(document.getElementById('renameForm'), 'keydown').subscribe((event: KeyboardEvent) => {
          if (event.keyCode === 13 && !this.fc.editName.errors) {
            this.submitForm();
          }
        });
      }, 100);
    });
  }

  ngOnDestroy() {
    // Clean refs
    for (const ref in this.refs) {
      if (this.refs[ref]) {
        this.refs[ref].unsubscribe();
      }
    }
  }

  handleKeyboardEventDown(event) {
    // Handle Ctrl+A
    if (event.ctrlKey && event.keyCode === 65) {
      event.preventDefault();
      this.zone.run(() => {
        this.editName.nativeElement.focus();
        this.editName.nativeElement.setSelectionRange(0, this.fc.editName.value.length);
      });
    }
  }

  getLastDotPosition(fileName) {
    return fileName.lastIndexOf('.') !== -1 ? '' + fileName.lastIndexOf('.') : false;
  }

  getFileExtension(fileName) {
    const lastDot = this.getLastDotPosition(fileName);
    const fExtension = lastDot ? fileName.substring(parseInt(lastDot), fileName.length) : '';
    return fExtension;
  }

  submitForm(): void {
    this.zone.run(() => {
      // Remove white space from the START and the END of the FileName
      let fileName = this.fc.editName.value.trim();

      if (this.isFolder) {
        fileName += '/';
      } else if (!this.warningMessage) {
        const newExtension = this.getFileExtension(fileName);
        if (this.originalFileExtension !== newExtension) {
          this.warningMessage = true;
          return;
        }
      }

      // Check if name has been changed
      if (this.filesData[0].name === fileName) {
        this.modal.close();
      } else {
        this.fc.editName.disable();
        this.applyActionService.renameAction(this.filesData, fileName, this.menuSource);

        this.loading = false;
        this.modal.close();
      }
    });
  }

  cancelAction() {
    if (this.warningMessage) {
      this.warningMessage = false;
    } else {
      this.modal.close();
    }
  }
}
