import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { fadeRegularAnimation } from '../../../../../app-animations/app-animations';

@Component({
  selector: 'app-loader-grid',
  templateUrl: './loader-grid.component.html',
  animations: [fadeRegularAnimation()],
})
export class LoaderGridComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() loaderTemplate: string = '';
  @Input() loaderMode: string = '';
  @Input() loadItems: number = 0;

  ldItems: any[] = [1];
  ldBlink: boolean = false;

  ldBlinkInterval: any;
  preparationTimeout: any;

  @ViewChild('ldWrap') ldWrap: ElementRef;
  @ViewChild('ldBody') ldBody: ElementRef;

  constructor(private elem: ElementRef, private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.prepareAnimation();
  }

  ngOnDestroy() {
    clearInterval(this.ldBlinkInterval);
    clearInterval(this.preparationTimeout);
  }

  prepareAnimation() {
    let items = 0;

    if (this.loaderTemplate === 'file-list' || this.loaderTemplate === 'file-list-shared') {
      if (this.loaderMode === 'grid-view') {
        const rowNumber = Math.ceil(
          this.ldBody.nativeElement.offsetHeight / this.elem.nativeElement.querySelectorAll('.ld-item')[0].offsetHeight
        );
        const colNumber = Math.ceil(
          this.ldBody.nativeElement.offsetWidth / this.elem.nativeElement.querySelectorAll('.ld-item')[0].offsetWidth
        );
        items = rowNumber * colNumber;
      } else {
        items = this.getNumberOfItems(this.ldBody.nativeElement);
      }
    }

    if (this.loaderTemplate.indexOf('modal') !== -1) {
      items = this.getNumberOfItems(this.ldWrap.nativeElement);
    }

    if (items) {
      this.ldItems.length = this.loadItems ? this.loadItems : items;
      this.startBlinkChanger();
      this.cdRef.detectChanges();
    } else {
      this.preparationTimeout = setTimeout(() => {
        this.prepareAnimation();
      }, 10);
    }
  }

  startBlinkChanger() {
    this.ldBlink = true;
    this.ldBlinkInterval = setInterval(() => {
      this.ldBlink = !this.ldBlink;
      this.cdRef.detectChanges();
    }, 900);
  }

  getNumberOfItems(container) {
    return Math.ceil(container.offsetHeight / this.elem.nativeElement.querySelectorAll('.ld-item')[0].offsetHeight);
  }
}
