import { Injectable, EventEmitter, Output } from '@angular/core';
import { FileExtensionPipe } from '../modules/_shared/pipes/file-extension.pipe';
import { OfficeSuiteSupportService } from './office-suite-support.service';
import { Subject } from 'rxjs';

@Injectable()
export class SelectFilesService {
  selectedFiles: any[] = [];

  lastSelectedFiles: any[] = []; // Store files from last selection action
  lastSelectionSet: any[] = []; // Store selection set from Shift selection action

  constructor(private officeSuiteSupportService: OfficeSuiteSupportService) {}

  @Output() filesSelection: EventEmitter<any> = new EventEmitter();
  private selectedSignalSubject = new Subject<any>();
  readonly selectedSignal$ = this.selectedSignalSubject.asObservable();

  selectFiles(data: any[]): void {
    this.selectedFiles = data;
  }

  addSelectedFile(item) {
    // Prevent adding duplicates
    const index = this.selectedFiles.indexOf(item);
    if (index === -1) {
      item.selected = true;
      this.selectedFiles.push(item);
      if (this.officeSuiteSupportService.isOfficeSupported(item)) {
        this.selectedSignalSubject.next(item);
      }
    }
  }

  getSelectedFiles() {
    return this.selectedFiles;
  }

  getSelectedFilesCount() {
    return this.selectedFiles.length;
  }

  removeSelectedFile(item) {
    if (!item || !item.key) {
      return;
    }

    let index = -1;
    for (let i = 0; i < this.selectedFiles.length; i++) {
      if (this.selectedFiles[i].key === item.key) {
        index = i;
        break;
      }
    }

    if (index !== -1) {
      this.selectedFiles[index].selected = false;
      this.selectedFiles.splice(index, 1);
    }
  }

  removeSelectedFiles(selectionComplete) {
    this.lastSelectedFiles = [];
    this.removeAllSelection();
    // Emit selection event when selection process end
    if (selectionComplete) {
      this.selectionComplete();
    }
  }

  removeAllSelection() {
    this.selectedFiles.forEach((item) => {
      item.selected = false;
    });

    this.lastSelectionSet = [];
    this.selectedFiles = [];
  }

  /* Get selection content */
  getSelectionContent() {
    let selectionContent = this.selectedFiles[0].dir ? 'folders' : 'files';
    let firstFileClassName = selectionContent === 'files' ? new FileExtensionPipe().transform(this.selectedFiles[0].name, 'noDot') : '';
    const sl = this.selectedFiles.length;

    if (sl > 1) {
      for (let i = 1; i < sl; i++) {
        if (!this.selectedFiles[i].dir && !firstFileClassName) {
          firstFileClassName = new FileExtensionPipe().transform(this.selectedFiles[i].name, 'noDot');
        }

        if (this.selectedFiles[i].dir !== this.selectedFiles[i - 1].dir) {
          selectionContent = 'mixed';
          break;
        }
      }
    }

    return { content: selectionContent, firstFileClass: 'iconame-ft-' + firstFileClassName };
  }

  /* Selection with preserving selection Set for Shift and Ctrl
     selection functionality
  */
  selectWithFileSet(file, fileIndex) {
    this.addSelectedFile(file);
    this.cleanSelectionSet();
    this.setLastSelectedFiles([{ file: file, index: fileIndex }]);
  }

  deselectWithFileSet(file) {
    this.removeSelectedFile(file);
    this.removeLastSelectedFile(file);
  }

  /* Handle Last Selected Files */
  setLastSelectedFiles(data: any[]): void {
    this.lastSelectedFiles = data;
  }

  getLastSelectedFiles() {
    return this.lastSelectedFiles;
  }

  removeLastSelectedFile(file) {
    this.lastSelectedFiles = this.lastSelectedFiles.filter((item) => {
      return item.file.key !== file.key;
    });
  }

  /* Handle Last Selection Set */
  discardLastSelectionSet() {
    if (this.lastSelectionSet.length) {
      this.lastSelectionSet.forEach((item) => {
        this.removeSelectedFile(item);
      });

      this.lastSelectionSet = [];
    }
  }

  addItemToSelectionSet(item) {
    this.lastSelectionSet.push(item);
  }

  cleanSelectionSet() {
    this.lastSelectionSet = [];
  }

  /* Event Emitters */
  selectionComplete() {
    this.filesSelection.emit(this.selectedFiles);
  }
}
