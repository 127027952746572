import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

import { Subject, BehaviorSubject } from 'rxjs';

export interface ILocationData {
  account: string;
  key: string | null;
  name: string | null;
  parent: null | ILocationData;
  root?: boolean;
  bin?: boolean;
  dir?: boolean;
  publiclyShared?: boolean;
  sharedRoot?: string;
  sharedRootKey?: string;
}

@Injectable()
export class LocationService {
  private getBreadcrumbsDataSubject = new Subject<ILocationData[]>();
  readonly getBreadcrumbsData = this.getBreadcrumbsDataSubject.asObservable();

  private locationFilesLoadedSubject = new Subject<boolean>();
  readonly locationFilesLoaded = this.locationFilesLoadedSubject.asObservable();

  private locationSharedFolderDataErrorSignalSubject = new Subject<any>();
  readonly locationSharedFolderDataErrorSignal = this.locationSharedFolderDataErrorSignalSubject.asObservable();

  private parentLocationSetSignalSubject = new BehaviorSubject<ILocationData>(null);
  readonly parentLocationSetSignal$ = this.parentLocationSetSignalSubject.asObservable();

  private showLocatingMessageSubject = new Subject<boolean>();
  readonly showLocatingMessage = this.showLocatingMessageSubject.asObservable();
  private showLocationErrorSubject = new Subject<boolean>();
  readonly showLocationError = this.showLocationErrorSubject.asObservable();

  constructor(private authService: AuthService) {}

  parentInit: boolean = false;
  parentKey: string | null = null;
  currentParent: ILocationData;
  breadcrumbsList: ILocationData[] = [];
  modalState: boolean = false;

  sharedRootMap: any = {};
  sharedPage: boolean = false;

  defaultRoot: ILocationData = {
    account: '',
    key: null,
    name: 'My Files/',
    parent: null,
    publiclyShared: false,
  };

  hasParentLocation() {
    return this.parentInit;
  }

  setParentKey(key: string | null) {
    this.parentKey = key;
  }

  getParentKey() {
    return this.parentKey;
  }

  getRootParent() {
    return { ...this.defaultRoot, account: this.authService.getUserAuth().accountId };
  }

  setLocationSharedFolderErrorData(err) {
    this.locationSharedFolderDataErrorSignalSubject.next(err);
    this.parentLocationSetSignalSubject.next({} as ILocationData);
    this.locationDataReady(true);
  }

  setCurrentParent(data: ILocationData) {
    // If get parent data from folder or breadcrumb click do not
    // make server call for set the breadcrumbs
    // Emit event when load first parent data
    this.parentLocationSetSignalSubject.next(data);
    this.parentInit = true;
    this.currentParent = {
      account: data.account,
      key: data.key,
      name: data.name,
      parent: data.parent,
      publiclyShared: data.publiclyShared,
    };

    // Handle Shared Root Key for Shared Folders
    if (data.sharedRoot) {
      this.currentParent.sharedRoot = data.sharedRoot;
      this.sharedRootMap[data.sharedRoot] = data.sharedRootKey;
      this.sharedPage = true;
    } else {
      this.sharedPage = false;
    }

    this.buildBreadcrumbs();
  }

  getCurrentParent() {
    return this.currentParent;
  }

  getUploadParent() {
    if (this.authService.getUserAuth().accountId !== this.currentParent.account) {
      return this.getRootParent();
    }
    return this.currentParent;
  }

  getBreadcrumbs() {
    return this.breadcrumbsList;
  }

  buildBreadcrumbs() {
    this.breadcrumbsList = [];
    this.getFolderLevels(this.currentParent);
    this.getBreadcrumbsDataSubject.next(this.breadcrumbsList);
  }

  getFolderLevels(obj) {
    const response: any = { dir: true, ...obj };
    this.breadcrumbsList.unshift(response);

    // For Backup Breadcrumbs discard root parent
    if (response.key === 'BA__CK__UPS') {
      return;
    }

    if (response['parent'] !== null) {
      if (this.sharedPage) {
        if (response.key === this.sharedRootMap[response.sharedRoot]) {
          return;
        } else {
          response.parent.sharedRoot = response.sharedRoot;
        }
      }
      this.getFolderLevels(response['parent']);
    }
  }

  cleanAfterItem(item) {
    let cleanIndex = null;
    this.breadcrumbsList.forEach((crumb, index) => {
      if (crumb.key === item.key) {
        cleanIndex = index;
      }
    });
    if (cleanIndex) {
      this.breadcrumbsList = this.breadcrumbsList.slice(0, cleanIndex + 1);
      this.getBreadcrumbsDataSubject.next(this.breadcrumbsList);
    }
  }

  // Handle file location messages
  toggleLocationMessage(isShowLocating: boolean) {
    this.showLocatingMessageSubject.next(isShowLocating);
  }

  setLocationError() {
    this.showLocationErrorSubject.next(true);
  }

  // TODO move logic to dedicated modal service
  setModalState(state) {
    this.modalState = state;
  }

  hasOpenModal() {
    return this.modalState;
  }

  locationDataReady(val: boolean) {
    // TODO Location files loaded is used from selected Items menu only
    // For future each page must init event, when to show that menu
    this.locationFilesLoadedSubject.next(val);
  }

  belongsToTreeChain(data): boolean {
    let belongsToTree: boolean = false;
    if (data?.obj?.parent) {
      const breadcrumbs = this.getBreadcrumbs();
      for (let i = 0; i < breadcrumbs.length; i++) {
        if (breadcrumbs[i].key === data.obj.key) {
          belongsToTree = true;
          break;
        }
      }
    } else {
      belongsToTree = true;
    }

    return belongsToTree;
  }
}
