import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { LanguageManagerService } from '../../../services/language-manager.service';
import { TrackingService } from '../../../services/tracking.service';
import { ILanguage } from '../../../models/lang.int';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'lang-static-menu',
  templateUrl: './lang-static-menu.component.html',
  styleUrls: ['./lang-static-menu.component.scss'],
})
export class LangStaticMenuComponent implements OnInit {
  @Output() closeMenuEvent = new EventEmitter();

  @Input() view: string = 'regular'; // regular and step-menu

  languages: ILanguage[] = [];
  currentLang: ILanguage;

  showStepMenu: boolean = false;

  translateRef: Subscription;
  constructor(
    private languageService: LanguageManagerService,
    private cdRef: ChangeDetectorRef,
    private trackingService: TrackingService
  ) {}

  ngOnInit(): void {
    this.languages = this.languageService.getAllLangs();
    this.currentLang = this.languageService.getCurrentLang();

    this.translateRef = this.languageService.finishLanguageChange.subscribe((event) => {
      this.currentLang = this.languageService.getCurrentLang();
      this.translateRef.unsubscribe();
    });
  }

  toggleMenu() {
    this.showStepMenu = !this.showStepMenu;
  }

  changeLanguage(lang) {
    this.trackingService.trackEvent('website.browse', 'lang-change', 'nav-bar.' + lang.code);
    this.languageService.setLanguage(lang.code);
    this.currentLang = this.languageService.getCurrentLang();
    this.cdRef.detectChanges();
    if (this.view === 'step-menu') {
      this.closeMenuEvent.emit(true);
    }
  }
}
