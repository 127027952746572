import { ErrorHandler, Injectable } from '@angular/core';
import * as StackTrace from 'stacktrace-js';

@Injectable()
export class AppGlobalErrorhandler implements ErrorHandler {
  async handleError(error) {
    const stackTrace = await StackTrace.fromError(error);
    this.trackEvent(error, stackTrace[0]);
  }

  trackEvent(error, errorReport) {
    const userAgent = {
      language: navigator.language,
      platform: navigator.platform,
      userAgent: navigator.userAgent,
      connectionDownlink: navigator['connection'] ? navigator['connection']['downlink'] : '',
      connectionEffectiveType: navigator['connection'] ? navigator['connection']['effectiveType'] : '',
      url: window.location.pathname,
    };

    const event = {
      event: 'GAEvent',
      eventCategory: 'TypeScript Error',
      eventAction: errorReport.toString(),
      eventLabel: error.name + ': ' + error.message,
    };

    const dataLayer = window['dataLayer'] || [];
    dataLayer.push(event);
  }
}
