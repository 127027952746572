import { Injectable, EventEmitter, Output } from '@angular/core';
import { Inject, Injector, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ServerDetectionService } from './server-detection.service';
import { languageMap } from '../configs/lang-configs';
import { ManageCookieService } from './manage-cookie.service';
import { first } from 'rxjs/operators';
import { ILanguage } from '../models/lang.int';
declare global {
  interface Window {
    cartApi: any;
  }
}
@Injectable()
export class LanguageManagerService {
  constructor(
    private injector: Injector,
    @Inject(PLATFORM_ID) private platformId: any,
    private translate: TranslateService,
    private serverDetection: ServerDetectionService,
    private manageCookieService: ManageCookieService
  ) {
    if (isPlatformServer(this.platformId)) {
      this.serverLangData = this.injector.get('langData');
    }
  }
  server: boolean = this.serverDetection.isServer();
  serverLangData: { lCode: string; cCode: string };
  textFlow: 'ltr' | 'rtl' = 'ltr';

  @Output() startLanguageChange: EventEmitter<any> = new EventEmitter();
  @Output() finishLanguageChange: EventEmitter<any> = new EventEmitter();

  getAllLangs() {
    return languageMap;
  }

  getLanguageByCountry(countryCode: string): ILanguage {
    let langData: ILanguage = {
      lang: 'English',
      label: 'English',
      code: 'en',
      countries: [],
    };

    languageMap.forEach((item) => {
      if (item.countries.indexOf(countryCode) !== -1) {
        langData = item;
      }
    });

    return langData;
  }

  getCurrentLang(): ILanguage {
    let langData: ILanguage = {
      lang: 'English',
      label: 'English',
      code: 'en',
      countries: [],
    };

    const savedLangCode = this.manageCookieService.getCookie('langaugeCode');

    if (!savedLangCode) {
      this.manageCookieService.setCookie('langaugeCode', 'en', { period: 'd', value: 365 });
      return langData;
    } else {
      languageMap.forEach((item) => {
        if (item.code === savedLangCode) {
          langData = item;
        }
      });

      return langData;
    }
  }

  getCountryKey(): string {
    const savedLangCode = this.manageCookieService.getCookie('langaugeCode') || 'en';
    let countries = [];
    languageMap.forEach((item) => {
      if (item.code === savedLangCode) {
        countries = item.countries;
      }
    });

    if (countries.indexOf(this.manageCookieService.getCookie('countryCode')) !== -1) {
      return this.manageCookieService.getCookie('countryCode');
    } else {
      return 'EN';
    }
  }

  getLangCode(): string {
    return this.manageCookieService.getCookie('langaugeCode');
  }

  setLanguage(langCode) {
    this.translate.onLangChange.pipe(first()).subscribe((event) => {
      this.setLangCodeClass(event.lang);
      this.finishLanguageChange.emit(true);
    });

    if (window.cartApi) {
      window.cartApi.changeLanguage(langCode);
    }

    const currentLangCode = this.manageCookieService.getCookie('langaugeCode');

    if (!currentLangCode || (currentLangCode && currentLangCode !== langCode)) {
      this.startLanguageChange.emit(true);
      this.manageCookieService.setCookie('langaugeCode', langCode, { period: 'd', value: 365 });

      this.translate.use(langCode);
    }
  }

  setLangCodeClass(langCode) {
    const html = document.querySelectorAll('html')[0];
    const oldLangClass = html.className.match(/lgcode-[a-z]{2}/g);

    if (oldLangClass) {
      html.classList.remove(oldLangClass[0]);
    }

    html.classList.remove('rtl');
    this.textFlow = 'ltr';

    html.classList.add(`lgcode-${langCode}`);
    if (langCode === 'ar' || langCode === 'he') {
      html.classList.add('rtl');
      this.textFlow = 'rtl';
    }
  }

  getLangCodeClass(): string {
    return this.textFlow;
  }

  initLanguage() {
    const langCode = this.server ? this.serverLangData.lCode : this.manageCookieService.getCookie('langaugeCode');
    const countryCode = this.server ? this.serverLangData.cCode : this.manageCookieService.getCookie('countryCode');
    if (langCode) {
      this.setLangCodeClass(langCode);
      this.translate.setDefaultLang(langCode);
    } else if (countryCode) {
      const lang = this.getLanguageByCountry(countryCode);
      this.setLanguage(lang.code);
    } else {
      this.translate.setDefaultLang('en');
      this.setLangCodeClass('en');
      this.manageCookieService.setCookie('langaugeCode', 'en', { period: 'd', value: 365 });
    }
  }
}
