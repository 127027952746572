<div class="profile-menu" [ngClass]="{ static: staticPage }">
  <div *ngIf="!userProfileData" class="sign-in-options">
    <a href="" (click)="signIn($event)" class="dd-menu-head">
      <span class="user-icon default">
        <ng-container *ngTemplateOutlet="defaultProfile"></ng-container>
      </span>
      <span class="user-name default">{{ 'header.navMenu.signin' | translate }}</span>
    </a>
  </div>

  <div class="ua-item dd-menu upgrade-wrapper" *ngIf="userProfileData && !staticPage && upgradeButton">
    <ng-container *ngIf="!upgradeButton.hideItem">
      <upgrade-button [upgradeButton]="upgradeButton"></upgrade-button>
      <div class="dd-link-header-separate"></div>
    </ng-container>
  </div>
  <div
    *ngIf="userProfileData"
    class="ua-item user-menu dd-menu"
    tabindex="0"
    [ngClass]="{ 'notify-dot': showNotifier }"
    [keyboardActions]="{ dropdown: true }"
    dropdown
  >
    <div class="dd-menu-head" dropdown-open>
      <span class="user-icon">{{ userProfileData.name | shortUserName }}</span>
      <span class="user-name">{{ userProfileData.name }}</span>
    </div>

    <ul class="dd-menu-hidden">
      <li *ngFor="let menuItem of userProfileMenu" class="{{ menuItem.className }}" [ngClass]="{ 'menu-separator': menuItem.separator }">
        <a
          class="dd-link"
          tabindex="-1"
          (click)="handleProfileMenu($event, menuItem.method, menuItem.url, menuItem.trackEvent)"
          target="{{ menuItem.target }}"
        >
          <span class="sub-menu-icon {{ menuItem.icon }}"></span>
          <ng-container *ngIf="menuItem.nameCode">
            {{ menuItem.nameCode | translate }}
          </ng-container>
          <ng-container *ngIf="!menuItem.nameCode">
            {{ menuItem.name }}
          </ng-container>
        </a>
      </li>
    </ul>
  </div>
</div>

<ng-template #defaultProfile>
  <svg
    width="31px"
    height="31px"
    viewBox="0 0 31 31"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Artboard-Copy-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
        d="M5.32907052e-15,31 L5.32907052e-15,28.42 C0.179695049,26.7469925 1.18085213,25.2733793 2.67,24.49 C4.12,23.77 8.19,21.73 11.36,20.14 L12.36,17.14 C10.9065877,16.2304452 9.93489672,14.7197408 9.71,13.02 L9.09,8 C8.58,3.86 11.41,0 15.48,0 C19.55,0 22.39,3.86 21.89,8 L21.28,13.02 C21.0671821,14.7267261 20.0976721,16.247094 18.64,17.16 L19.63,20.14 C22.79,21.72 26.85,23.75 28.37,24.52 C29.8909358,25.2446773 30.8981892,26.738323 31,28.42 L31,31 L5.32907052e-15,31 Z"
        id="Path"
        fill="#0BA0D3"
      ></path>
    </g>
  </svg>
</ng-template>
