import { Injectable, EventEmitter, Output, Directive } from '@angular/core';
import { SelectFilesService } from '../services/select-files.service';
import { ApplyActionService } from '../services/apply-action.service';

@Injectable()
export class DragService {
  constructor(private selectFilesService: SelectFilesService, private applyActionService: ApplyActionService) {}

  @Output() startDragEvent: EventEmitter<any> = new EventEmitter();
  @Output() endDragEvent: EventEmitter<any> = new EventEmitter();

  // Events for Breadcrumb menu on native Drag
  @Output() closeBreadcrumbMenu: EventEmitter<any> = new EventEmitter();

  dragSource: string = '';
  activeDrag: boolean = false;
  dragWrapper: any;
  selectionLength: number;
  classList = [];
  selectedFiles: any;
  timeouts: any[] = [];

  breadcrumbMenuDragLeave: boolean = false;

  startDrag(options) {
    // Hack to hide open menus if there is some
    document.getElementById('dragInfo').click();

    // Emit start drag Event
    this.startDragEvent.emit(true);
    this.activeDrag = true;

    // Get Selected items data
    this.selectedFiles = this.selectFilesService.getSelectedFiles();
    this.selectionLength = this.selectedFiles.length;

    // Prepare Selected items animation
    this.prepareDragAnimation(options.icon);

    // Drag End callback
    const _t = this;

    const endDragEvents = (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (e.which === 1 || e.type === 'mouseleave') {
        document.removeEventListener('mousemove', _t.dragMethod, true);
        document.removeEventListener('mouseup', endDragEvents, false);
        document.removeEventListener('contextmenu', endDragEvents, false);
        document.removeEventListener('mouseleave', endDragEvents, false);
        _t.endDrag();
      } else {
        return false;
      }
    };

    // Attach drag items events
    document.addEventListener('mousemove', _t.dragMethod, true);
    document.addEventListener('mouseup', endDragEvents, false);
    document.addEventListener('contextmenu', endDragEvents, false);
    document.addEventListener('mouseleave', endDragEvents, false);
  }

  prepareDragAnimation(iconName) {
    this.dragWrapper = document.getElementById('dragInfo');

    // Set drag items wrapper with counter
    this.dragWrapper.getElementsByClassName('counter')[0].innerText = '' + this.selectionLength;

    // Prepare the animation
    const itemsWrapper = document.getElementsByClassName('ft-body')[0];
    const items = itemsWrapper.getElementsByClassName('selected');

    this.dragWrapper.classList.add('active');

    for (let i = 0; i < items.length; i++) {
      this.classList.push(items[i].getElementsByClassName('menu-icon')[0].classList[1]);
      if (i === 2) {
        break;
      }
    }

    this.setDragIcons(iconName);
  }

  dragMethod(event) {
    event.preventDefault();
    const dw = document.getElementById('dragInfo');
    dw.style.left = event.clientX + 22 + 'px';
    dw.style.top = event.clientY + 27 + 'px';
  }

  setDragIcons(mainIcon) {
    const selectionContent = this.selectFilesService.getSelectionContent();
    if (this.selectionLength > 1) {
      if (selectionContent.content === 'mixed') {
        this.classList[2] = selectionContent.firstFileClass;
      }

      for (let i = 0; i < this.selectionLength; i++) {
        const div = document.createElement('div');
        div.setAttribute('class', `menu-icon layer-${i} ${this.classList[i]}`);
        this.dragWrapper.appendChild(div);
        if (i === 2) {
          break;
        }
      }
    } else {
      const div = document.createElement('div');
      div.setAttribute('class', `menu-icon iconame-ft-${mainIcon}`);
      this.dragWrapper.appendChild(div);
    }
  }

  endDrag() {
    // Reset classList
    this.classList = [];

    // Reset dragWrapper
    this.dragWrapper.classList.remove('active');
    this.dragWrapper.removeAttribute('style');

    const clonedIcons: any = this.dragWrapper.getElementsByClassName('menu-icon');
    let elemRef: any;
    while ((elemRef = clonedIcons[0])) {
      elemRef.parentNode.removeChild(elemRef);
    }

    // Handle End drag event
    if (this.activeDrag) {
      this.activeDrag = false;
      this.endDragEvent.emit(true);
    }
  }

  dragOverHandler(event) {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'none';

    if (this.breadcrumbMenuDragLeave) {
      if (!event.target.closest('.dd-menu')) {
        this.breadcrumbMenuDragLeave = false;
        this.closeBreadcrumbMenu.emit(true);
      }
    }
  }

  resetBreadcrumbHover(val: boolean) {
    this.breadcrumbMenuDragLeave = val;
  }

  moveItems(hostFolder) {
    this.activeDrag = false;
    this.applyActionService.moveAction(this.selectedFiles, hostFolder, '');
    this.endDragEvent.emit(true);
  }

  isActiveDrag() {
    return this.activeDrag;
  }
}
