import { Injectable } from '@angular/core';
import { IActionData } from './apply-action.service';
import { configs } from '../configs/drive-configs';

@Injectable()
export class CacheService {
  private recordCache = false;
  private cacheData: IActionData[] = [];
  private runningProcesses = 0;
  processLimit: number = configs.processLimit;

  constructor() {}

  /* Track and Report Running Processes
  ================================================== */
  updateProcessesStatus(type: string, count: number): void {
    if (type === 'start') {
      this.runningProcesses += count;
    } else if (type === 'end' && this.runningProcesses > 0) {
      this.runningProcesses -= count;
    }
  }

  getRunningProcesses(): number {
    return this.runningProcesses;
  }

  /* Record Cache
  ================================================== */
  startRecordCache(value: boolean): void {
    this.recordCache = value;
  }

  recoredCache(data: IActionData): void {
    if (!this.recordCache) {
      return;
    }
    this.cacheData.push(data);
  }

  getCacheData(): IActionData[] {
    return this.cacheData;
  }

  deleteCache(): void {
    this.cacheData = [];
  }
}
