export const configs = {
  uploadFilesLimit: 3, // originaly - 4 --- How many files could be uploaded at the same time
  processLimit: 2, // originally - 4 pros --- How many processes of a single action can run at same time - delete, move, new folder
  firstLoadItems: 25,
  loadStepItems: 20,
  sharelinkEmailLimit: 10,
  defaultListView: 'list-view',
  showPlansForFreeUser: 3,// Period in days for showing pricing-plans page Free users
  winAppFlag: 'winApp',
  officeFormats: {
    extensions: [
      'docx',
      'doc',
      'rtf',
      'odt',
      'txt',
      'docm',
      'dotx',
      'dot',
      'dotm',
      'ott',
      'pdf', // Word & PDF
      'xlsx',
      'xls',
      'csv',
      'ods',
      'xlsm',
      'xltx',
      'xlt',
      'xltm', // Excel
      'pptx',
      'ppt',
      'pptm',
      'ppsx',
      'ppsm',
      'potx',
      'pot',
      'potm',
      'pps',
      'odp', // PowerPoint
      'pages',
      'pages.zip',
      'numbers',
      'numbers.zip',
      'key', // Other
    ],
    mimeTypes: [],
  },
  officeFormatsAndroid: {
    extensions: [
      'xls',
      'xltx',
      'xlsx',
      'xlsm',
      'ods',
      'csv',
      'ots',
      'eml',
      'zip',
      'rar',
      'pdf',
      'ppt',
      'pps',
      'pptx',
      'potx',
      'ppsx',
      'pptm',
      'potm',
      'ppsm',
      'odp',
      'otp',
      'pot',
      '1st',
      'abc',
      'acgi',
      'ada',
      'aip',
      'asm',
      'asp',
      'aspx',
      'bat',
      'bsh',
      'c',
      'c#',
      'c++',
      'cc',
      'cfg',
      'com',
      'conf',
      'cpp',
      'cs',
      'csh',
      'css',
      'cxx',
      'def',
      'doc',
      'docm',
      'docx',
      'dot',
      'dotx',
      'etx',
      'f',
      'f77',
      'f90',
      'flx',
      'for',
      'g',
      'go',
      'h',
      'hh',
      'hlb',
      'htc',
      'htm',
      'html',
      'htmls',
      'htt',
      'htx',
      'idc',
      'inf',
      'ini',
      'jad',
      'jav',
      'java',
      'js',
      'json',
      'ksh',
      'list',
      'lng',
      'log',
      'lsp',
      'lst',
      'lsx',
      'm',
      'm3u8',
      'manifest',
      'mcf',
      'mst',
      'nfo',
      'odf',
      'odt',
      'opml',
      'ott',
      'p',
      'pas',
      'php',
      'pl',
      'pm',
      'prl',
      'prn',
      'py',
      'pyt',
      'rdf',
      'reg',
      'rep',
      'rexx',
      'rt',
      'rtf',
      'rtx',
      's',
      'scf',
      'scm',
      'sdml',
      'sgm',
      'sgml',
      'sh',
      'shtml',
      'spc',
      'sql',
      'src',
      'srt',
      'ssi',
      'sub',
      'swift',
      'talk',
      'tcl',
      'tcsh',
      'text',
      'tsv',
      'tt',
      'ttl',
      'txt',
      'uil',
      'uni',
      'unis',
      'uri',
      'uris',
      'uu',
      'uue',
      'vbs',
      'wml',
      'wmls',
      'wsc',
      'xml',
      'xsd',
      'xsl',
      'xslt',
      'yaml',
      'yml',
      'zsh',
      'properties',
      'pages',
      'pages.zip',
      'numbers',
      'numbers.zip',
      'key',
    ],
    mimeTypes: [
      'application/vnd.ms-excel',
      'application/msexcel',
      'application/x-msexcel',
      'application/x-ms-excel',
      'application/vnd.ms-excel',
      'application/x-excel',
      'application/x-dos_ms_excel',
      'application/xls',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel.sheet.macroEnabled.12',
      'application/vnd.ms-excel.sheet.macroenabled.12',
      'application/vnd.ms-excel.sheet.macroenabled',
      'application/x-xls',
      'text/csv',
      'application/csv',
      'text/comma-separated-values',
      'application/vnd.oasis.opendocument.spreadsheet',
      'application/vnd.oasis.opendocument.spreadsheet-template',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
      'message/rfc822',
      'application/zip',
      'application/x-rar-compressed',
      'application/x-zip',
      'application/x-zip-compressed',
      'application/rar',
      'application/x-rar',
      'application/x-compress',
      'application/x-compressed',
      'multipart/x-zip',
      'vnd.android.cursor.item/file',
      'application/pdf',
      'application/vnd.ms-powerpoint',
      'application/mspowerpoint',
      'application/ms-powerpoint',
      'application/mspowerpnt',
      'application/vnd-mspowerpoint',
      'application/powerpoint',
      'application/x-powerpoint',
      'application/x-mspowerpoint',
      'application/vnd.oasis.opendocument.presentation',
      'application/vnd.oasis.opendocument.presentation-template',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
      'application/vnd.openxmlformats-officedocument.presentationml.template',
      'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
      'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
      'application/vnd.ms-powerpoint.presentation.macroenabled.12',
      'application/vnd.ms-powerpoint.slideshow.macroenabled.12',
      'application/vnd.ms-powerpoint.presentation.macroenabled',
      'application/vnd.ms-powerpoint.slideshow.macroenabled',
      'application/msword',
      'application/doc',
      'application/vnd.msword',
      'application/vnd.ms-word',
      'application/winword',
      'application/word',
      'application/x-msw6',
      'application/x-msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
      'application/vnd.openxmlformats-officedocument.wordprocessing-template',
      'application/vnd.ms-word.document.macroEnabled.12',
      'application/vnd.ms-word.document.macroenabled.12',
      'application/vnd.ms-word.document.macroenabled',
      'application/vnd.oasis.opendocument.text',
      'application/vnd.oasis.opendocument.text-template',
      'appl/text',
      'application/rtf',
      'application/xml',
      'application/json',
      'application/x-csh',
      'application/x-pointplus',
      'application/x-javascript',
      'application/javascript',
      'application/ecmascript',
      'application/x-ksh',
      'application/x-lisp',
      'application/x-rtf',
      'application/x-lotusscreencam',
      'application/x-bsh',
      'application/x-sh',
      'application/x-shar',
      'application/x-pkcs7-certificates',
      'application/x-tcl',
      'application/plain',
      'application/vnd.apple.mpegurl',
      'image/vnd.dvb.subtitle',
      'application/rdf+xml',
      'text/*',
    ],
  },
  officeFormatsIOS: {
    extensions: [
      'docx',
      'dotx',
      'docm',
      'doc',
      'odt',
      'dot',
      'dotm',
      'pages',
      'txt',
      'rtf',
      'log',
      'pdf', // Word, Text, Pdf
      'xls',
      'xlsx',
      'xslm',
      'csv',
      'ods',
      'xlt',
      'xltx',
      'xltm',
      'numbers', // Excel
      'ppt',
      'pptx',
      'pps',
      'ppsx',
      'pptm',
      'ppsm',
      'odp',
      'pot',
      'potx',
      'potm',
      'key' // PowerPoint
    ],
    mimeTypes: [],
  },
  fileIconsMap: {
    imageIconMap: [
      'jpeg',
      'jpg',
      'jpe',
      'jpm',
      'jfif',
      'jif',
      'jp2',
      'jpx',
      'jpf',
      'j2k',
      'j2c',
      'fpx',
      'pcd',
      'gif',
      'bmp',
      'tiff',
      'tif',
      'png',
      'raw',
      'ico',
      'svg',
      'svgz',
      'webp',
      'mj2',
      'heif',
      'heic',
    ],
    videoIconMap: [
      'webm',
      'mkv',
      'flv',
      'vob',
      'ogv',
      'ogg',
      'drc',
      'gifv',
      'mng',
      'avi',
      'mov',
      'qt',
      'wmv',
      'rm',
      'rmvb',
      'amv',
      'mp4',
      'mpg',
      'mp2',
      'mpeg',
      'mpe',
      'mpv',
      '3gp',
      '3g2',
    ],
    audioIconMap: [
      '3gp',
      'aa',
      'aax',
      'act',
      'amr',
      'ape',
      'au',
      'awb',
      'dct',
      'dss',
      'dvf',
      'gsm',
      'm4a',
      'm4b',
      'm4p',
      'mmf',
      'mp3',
      'mpc',
      'msv',
      'nsf',
      'pcm',
      'wav',
      'aiff',
      'aac',
      'ogg',
      'wma',
      'flac',
      'alac',
    ],
    codeIconMap: [
      'css',
      'scss',
      'js',
      'jse',
      'asp',
      'hta',
      'aspx',
      'ts',
      'php',
      'c',
      'cc',
      'cpp',
      'cxx',
      'cs',
      'asmx',
      'm',
      'mm',
      'uc',
      'as',
      'mxml',
      'j',
      'jav',
      'java',
      'jsl',
      'd',
    ],
  },
};
