import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { TrackingService } from '../../../services/tracking.service';
import { IProfileMenuItem, UserProfileMenuService } from '../../../services/user-profile-menu.service';

@Component({
  selector: 'upgrade-button',
  templateUrl: './upgrade-button.component.html',
  styleUrls: ['./upgrade-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpgradeButtonComponent {
  @Input() upgradeButton: IProfileMenuItem = null;

  constructor(private trackingService: TrackingService, private userProfileMenuService: UserProfileMenuService) {}

  ngOnInit() {}

  handleProfileMenuItem(event: Event, methodName: string, url: string, trackEvent: { category: string; action: string; label: string }) {
    if (trackEvent) {
      this.trackingService.trackEvent(trackEvent.category, trackEvent.action, trackEvent.label);
    }

    if (methodName && url) {
      this.userProfileMenuService[methodName](event);
    } else if (methodName) {
      event.preventDefault();
      this.userProfileMenuService[methodName](event);
    }
  }
}
