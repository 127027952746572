import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'byteSize',
  pure: false,
})
export class ByteSizePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(bytes: number, precision: number = 2, displayTB: boolean = false): string {
    if (bytes == 0) {
      const loc: string = this.translate.instant('size.Bytes');
      return '0 ' + loc;
    }

    const k = 1024,
      units = ['Bytes', 'KB', 'MB', 'GB', 'TB'], // ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      unitCategory = Math.floor(Math.log(bytes) / Math.log(k)),
      catLimit = unitCategory > 3 ? 3 : unitCategory,
      val: number = parseFloat((bytes / Math.pow(k, catLimit)).toFixed(precision));

    if (displayTB && unitCategory > 3) {
      return val / 1000 + ' ' + this.translate.instant('size.' + units[unitCategory]);
    }
    return val + ' ' + this.translate.instant('size.' + units[catLimit]);
  }
}
