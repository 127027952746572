import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Observable, Subject } from 'rxjs';
import { last, map } from 'rxjs/operators';
import { configs } from '../../configs/drive-configs';
import { guideGenerator } from '../../helpers/guid-generator';
import {
  IFileUploadData,
  IMscFile,
  IUploadErrorReport,
  IUploadFilesStatus,
  IUploadProcessData,
  IUploadStatusType,
} from '../../models/files.int';
import { ApplyActionService } from '../apply-action.service';
import { AuthService } from '../auth.service';
import { LoginService } from '../login.service';
import { LogoutService } from '../logout.service';
import { ManageCookieService } from '../manage-cookie.service';
import { StorageStatusService } from '../storage-status.service';
import { UploadStatusService } from './upload-status.service';
@Injectable()
export class UploadManagerService {
  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private logoutService: LogoutService,
    private manageCookieService: ManageCookieService,
    private storageStatusService: StorageStatusService,
    private applyActionService: ApplyActionService,
    private deviceService: DeviceDetectorService,
    private loginService: LoginService,
    private ngxModal: NgxSmartModalService,
    private uploadStatusService: UploadStatusService
  ) {}

  private filesForUploadSubject = new Subject<IFileUploadData[]>();
  readonly filesForUpload = this.filesForUploadSubject.asObservable();

  private uploadCompleteSubject = new Subject<{ status: IMscFile; activeUploads: number }>();
  readonly uploadComplete = this.uploadCompleteSubject.asObservable();

  pendingUploads: IFileUploadData[] = [];
  activeUploads: IFileUploadData[] = [];
  errorUploads: IFileUploadData[] = [];

  uploadLimit: number = this.deviceService.isMobile() ? 2 : configs.uploadFilesLimit;
  trackTime: number;

  uploadFolderRef: any = null;

  /* Report Upload Status
  ================================================== */
  getUploadStatus(): number {
    return this.activeUploads.length + this.pendingUploads.length;
  }

  /* Upload Files
  ================================================== */
  uploadFiles(itemsForUpload: IFileUploadData[], fileFromFolder?: boolean): void {
    // If file not from folder add it in Upload list
    if (!fileFromFolder) {
      itemsForUpload.forEach((uplItem) => {
        uplItem.file.revisionId = guideGenerator();
      });
      this.filesForUploadSubject.next(itemsForUpload);

      this.uploadStatusService.prepareForUpload(itemsForUpload);
    }

    const fileUploadLimit = this.loginService.getProfileData().planFeatures.uploadFileSizeLimit;
    const storageSpaceLeft = this.storageStatusService.getAvailableSpace();
    if (storageSpaceLeft) {
      // If there is data for storage status check if there is enough space for upload
      for (let i = 0; i < itemsForUpload.length; i++) {
        if (
          itemsForUpload[i].file.size > storageSpaceLeft ||
          (fileUploadLimit && fileUploadLimit > 0 && itemsForUpload[i].file.size > fileUploadLimit)
        ) {
          const folderRelatedFile: boolean = itemsForUpload[i].file.folderRelated;
          const message = itemsForUpload[i].file.size > storageSpaceLeft ? 'faeOutOfStorage' : 'faeFileTooLarge';
          itemsForUpload[i].error = this.buildErrorReport('Error', {
            message: message,
          });

          const uploadFileEvent = {
            type: 'error',
            data: {
              file: itemsForUpload[i].file,
              error: itemsForUpload[i].error,
              findBy: folderRelatedFile ? 'rootKey' : 'revisionId',
              setProp: '',
              updateProgress: true,
              progressData: {
                folderRelated: false,
                createStream: false,
              },
            },
          };

          // Show fileSizeWarning modal for first appearance of the Error
          if (message === 'faeFileTooLarge' && !this.manageCookieService.getCookie('fileSizeLimitWarning')) {
            this.manageCookieService.setCookie('fileSizeLimitWarning', 'warningShown', { period: 'd', value: 1 });
            this.showUploadErrorModal(uploadFileEvent.data, 'file-size-error');
          }

          this.errorUploads.push(itemsForUpload[i]);
          this.uploadStatusService.uploadFileEvent(uploadFileEvent);
        } else {
          this.pendingUploads.push(itemsForUpload[i]);
        }
      }
    } else {
      // If no storage data add all to pending list
      this.pendingUploads = this.pendingUploads.concat(itemsForUpload);
    }

    for (let i = 0; i < itemsForUpload.length; i++) {
      if (this.activeUploads.length < this.uploadLimit) {
        this.manageUploadQueue();
      } else {
        break;
      }
    }
  }

  /* Upload Folders
  ================================================== */
  uploadFolders(folder: IFileUploadData): void {
    this.filesForUploadSubject.next([folder]);
    this.uploadStatusService.prepareForUpload([folder]);

    // Upload child folders
    if (!this.uploadFolderRef) {
      this.uploadFolderRef = this.applyActionService.uploadFolder.subscribe((folder) => {
        const itemFromList = this.uploadStatusService.findItemFromList(folder);
        if (folder.error) {
          this.uploadStatusService.errorFolderUpload(folder, itemFromList);
        } else if (itemFromList) {
          this.uploadStatusService.updateUploadStatus(folder, itemFromList);
          // If item is not removed and there is a content in it procced with upload
          if (!itemFromList.item.stopStream) {
            if (folder.folderObj.content && folder.folderObj.content.length > 0) {
              this.addSubFolder(folder.file, folder.folderObj);
            }
          }
        }
      });
    }

    this.createFolder(folder.parent, folder.file.folder.name + '/', folder.file);
  }

  createFolder(parent, folderName, folder): void {
    this.applyActionService.createFolderAction(parent, folderName, folder);
  }

  addSubFolder(parentFolder, folderData): void {
    const itemParent = parentFolder;
    folderData.content.forEach((folderItem) => {
      if (folderItem.file) {
        folderItem.file.parentRef = itemParent;
        folderItem.file.rootKey = parentFolder.rootKey;
        folderItem.file.folderRelated = true;
        const files = [{ file: folderItem.file, parent: itemParent }];
        this.uploadFiles(files, true);
      } else if (folderItem.folder) {
        folderItem.folder.parentRef = itemParent;
        folderItem.folder.rootKey = parentFolder.rootKey;
        const folder = { file: folderItem, parent: itemParent };
        this.createFolder(folder.parent, folder.file.folder.name + '/', folder.file);
      }
    });
  }

  /* Manage Upload process
  ================================================== */
  manageUploadQueue(index: number = 0): void {
    const uplItem: IFileUploadData = this.pendingUploads[index]; // Take first pending item
    if (!uplItem) {
      return;
    }

    // Check for duplicate name items and if yes try with next file
    if (this.hasDuplicateItem(uplItem)) {
      this.manageUploadQueue(index + 1);
      return;
    }

    this.pendingUploads.splice(index, 1); // Remove item from pending Array
    this.activeUploads.push(uplItem); // Add item in Active uploads
    this.streamCreate(uplItem.file, uplItem.parent); // Start Upload item
  }

  hasDuplicateItem(uplItem) {
    // Check for duplicate name
    const duplicateItem = this.activeUploads.filter((item) => {
      return item.file.name === uplItem.file.name;
    });

    if (duplicateItem.length) {
      return true;
    }

    return false;
  }

  updateProgress(multyStepsUpload, file, index, src, stream, uploadStep, closeTime?): void {
    // Attach file stream
    file.fileStream = stream;

    if (multyStepsUpload) {
      const percentDone = Math.round((index / uploadStep) * 100);
      this.handleUploadEvent({
        type: 'update',
        streamCommit: percentDone === 100,
        data: {
          file: file,
          findBy: 'revisionId',
          setProp: 'revisionId',
          updateProgress: true,
          progressData: {
            percentDone: percentDone,
            bytesLeft: src.chunk * index,
            timePart: this.trackingUploadTime(!closeTime),
          },
        },
      });
    } else {
      this.handleUploadEvent({
        type: 'update',
        data: {
          file: file,
          findBy: 'revisionId',
          setProp: 'revisionId',
          updateProgress: false,
        },
      });
    }
  }

  checkPendingUploads(fileId?: string): void {
    // Remove Uploaded file from Active uploads
    if (fileId) {
      const activeUplsLength: number = this.activeUploads.length;
      for (let i = 0; i < activeUplsLength; i++) {
        if (this.activeUploads[i].file.revisionId === fileId) {
          this.activeUploads.splice(i, 1);
          break;
        }
      }
    }

    // Check and handle pending uploads
    if (this.pendingUploads.length && this.activeUploads.length < this.uploadLimit) {
      this.manageUploadQueue();
    } else if (!this.pendingUploads.length && !this.activeUploads.length && this.errorUploads.length > 0) {
      // After uploads are ready check error items and Retry upload
      this.retryAfterError();
    }
  }

  trackingUploadTime(update: boolean = true): number {
    let timePart = 0;
    if (this.trackTime && update) {
      timePart = Math.round((new Date().getTime() / 1000 - this.trackTime) * 100) / 100;
    }
    this.trackTime = new Date().getTime() / 1000;
    return timePart;
  }

  /* Upload Actions
  ================================================== */
  removeUpload(item: IUploadFilesStatus): void {
    // Stop item(s) stream
    if (item.file.rootFolder) {
      item.stopStream = true;
      if (item.file.folderItemsStreams) {
        item.file.folderItemsStreams.forEach((refItem) => {
          if (refItem.fileStream) {
            refItem.fileStream.unsubscribe();
          }
        });
      }
    } else if (item.file.fileStream) {
      item.file.fileStream.unsubscribe();
    }

    // Remove from status list
    this.uploadStatusService.removeUploadStatus(item);

    // Remove from upload list
    if (item.file.rootKey) {
      this.removeFromList('pendingUploads', 'rootKey', item);
      this.removeFromList('activeUploads', 'rootKey', item);
    } else {
      this.removeFromList('pendingUploads', 'revisionId', item);
      this.removeFromList('activeUploads', 'revisionId', item);
    }
    this.checkPendingUploads();
  }

  removeAllUploads() {
    const filesAndFolders = this.uploadStatusService.obtainCurrentStatus().uploadFiles;
    let i = filesAndFolders.length;
    while (i--) {
      if (!filesAndFolders[i].file || filesAndFolders[i].curStatus !== 'complete') {
        // Remove folder items from folder creation pool
        if (filesAndFolders[i].file && filesAndFolders[i].file.rootFolder) {
          this.applyActionService.removePendingFolder(filesAndFolders[i].file.fid);
        }
        this.removeUpload(filesAndFolders[i]);
      }
    }
    this.cleanErrorUploads();
  }

  removeFromList(listArrayKey: string, findBy: string, item: IFileUploadData): void {
    let i = this[listArrayKey].length;
    while (i--) {
      if (this[listArrayKey][i].file[findBy] === item.file[findBy]) {
        this[listArrayKey].splice(i, 1);
      }
    }
  }

  retryUpload(item: IUploadFilesStatus): 'retry' | 'storageError' {
    item.error = null;

    // Check storage availability before retry
    const storageSpaceLeft = this.storageStatusService.getAvailableSpace();
    if (storageSpaceLeft && item.file.size > storageSpaceLeft) {
      item.error = this.buildErrorReport('Error', { message: 'faeOutOfStorage' });
      return 'storageError';
    }

    // Update status
    this.uploadStatusService.retryUploadStatus(item);

    for (let i = 0; i < this.errorUploads.length; i++) {
      if (this.errorUploads[i].file['revisionId'] === item.file['revisionId']) {
        this.pendingUploads.push(this.errorUploads[i]);
        this.errorUploads.splice(i, 1);
        break;
      }
    }
    this.checkPendingUploads();
    return 'retry';
  }

  retryFolderUpload(folder: IUploadFilesStatus): void {
    folder.error = null;
    folder.file.completedItems -= folder.file.subItemsErrors.length;
    folder.file.subItemsErrors = [];

    let uploadErrors = 1;
    if (folder.file.errorRetryItems.length === 0) {
      // Root folder Error
      uploadErrors++;
      this.createFolder(folder.parent, folder.file.folder.name + '/', folder.file);
    } else {
      // Nested folders items Error
      let i = folder.file.errorRetryItems.length;
      while (i--) {
        const retryItem = folder.file.errorRetryItems.splice(i, 1)[0];
        uploadErrors++;
        if (retryItem.file.dir) {
          this.createFolder(retryItem.file.parent, retryItem.file.name, retryItem.file.uploadF);
        } else {
          const files = [{ file: retryItem.file, parent: retryItem.file.parentRef }];
          this.uploadFiles(files, true);
        }
      }
    }

    // Update status
    this.uploadStatusService.retryUploadStatus(folder, uploadErrors);
  }

  retryAfterError(): void {
    let errorItem = null;
    for (let i = 0; i < this.errorUploads.length; i++) {
      if (this.errorUploads[i].error.retryAfter) {
        errorItem = this.errorUploads.splice(i, 1)[0];
        break;
      }
    }

    if (errorItem) {
      this.pendingUploads.push(errorItem);
      this.manageUploadQueue();
    }
  }

  /* Handle Upload events
  ================================================== */
  handleUploadEvent(result): void {
    // Report that file has been uploaded
    if (result.type === 'complete' || (result.type === 'update-folder' && result.stream === 'streamCommit')) {
      this.uploadCompleteSubject.next({
        status: result.file,
        activeUploads: this.activeUploads.length,
      });
      this.checkPendingUploads(result.file.headRevision);
    }

    // Handle File Error
    let retryAfter = false;
    if (result.type === 'error') {
      retryAfter = this.handleUploadError(result.data);
      this.checkPendingUploads(result.data.revisionId);
    }

    if (!retryAfter) {
      // File Update Report cases
      if (!result.data.file.folderRelated || result.type === 'update-folder' || result.type === 'error') {
        // For regular File upload report every event
        this.uploadStatusService.uploadFileEvent(result);
      }
    }
  }

  handleUploadError(data: IUploadProcessData): boolean {
    // On Upload Error move problematic item from activeUploads into errorUploads Array
    let retryAfter: any = false;
    for (let i = 0; i < this.activeUploads.length; i++) {
      // Check file Name as well because if data.findBy is set to 'RootKey'
      // the proper item may not be found and removed.
      // In future unique ID must be attached to every file item
      if (this.activeUploads[i].file[data.findBy] === data.file[data.findBy] && this.activeUploads[i].file.name === data.file.name) {
        retryAfter = data.error.retryAfter;

        if (!data.file.folderRelated || retryAfter) {
          this.activeUploads[i].error = data.error;
          this.errorUploads.push(this.activeUploads[i]);
        }
        this.activeUploads.splice(i, 1);
        break;
      }
    }

    return retryAfter;
  }

  createUploadError(file, error) {
    return {
      type: 'error',
      data: {
        file: file,
        findBy: file.folderRelated ? 'rootKey' : 'revisionId',
        error: this.buildErrorReport('serverError', error),
      },
    };
  }

  buildErrorReport(name: string, errorData: { [key: string]: any; message: string }): IUploadErrorReport {
    const errorStatus: IUploadStatusType = errorData.message === 'faeFileTooLarge' ? 'error-warning' : 'error';
    return {
      name: name,
      message: errorData.message,
      status: errorStatus,
      retryAfter: errorData.retryAfter ? errorData.retryAfter : null,
    };
  }

  cleanUplData() {
    this.pendingUploads = [];
    this.activeUploads = [];
    this.errorUploads = [];
    this.uploadStatusService.cleanUploadStatus();
  }

  cleanErrorUploads() {
    this.errorUploads = [];
  }

  showUploadErrorModal(errorsData: IUploadProcessData, modalView: 'error-report' | 'file-size-error') {
    // Open modal and show errors
    this.ngxModal.setModalData({ view: modalView, files: errorsData }, 'actionModal');
    this.ngxModal.resetModalData('actionModal');
    this.ngxModal.getModal('actionModal').open();
  }

  /* UPLOAD STREAM
  ================================================== */
  private streamCreate(file, parent) {
    const stream = this.authService
      .authRequest('files', 'stream-create-opt', {
        request: {
          type: 'file',
          id: null,
          parent: parent,
          name: file.name,
          contentType: file.type,
          allowIdxRename: true,
          strategy: 'duplicate',
          fileSize: file.size,
        },
      })
      .subscribe(
        (response) => {
          // Report for start Uploading
          if (!response) {
            this.logoutService.logout('rnd');
          } else {
            this.streamPartUpload(file, 0, response);
          }
        },
        (error) => {
          const uploadError = this.createUploadError(file, error);
          this.handleUploadEvent(uploadError);
        }
      );

    // Attach file stream
    file.fileStream = stream;
    if (!file.folderRelated) {
      // For regular file upload
      this.handleUploadEvent({
        type: 'update',
        data: {
          file: file,
          findBy: 'revisionId',
          setProp: '',
          updateProgress: false,
        },
      });
    } else {
      // For folder related files
      this.handleUploadEvent({
        type: 'update-folder',
        stream: 'streamCreate',
        data: {
          file: file,
          findBy: 'rootKey',
          setProp: '',
          updateProgress: true,
          progressData: {
            folderRelated: true,
            createStream: true,
          },
        },
      });
    }
  }

  private streamPartUpload(file, index, src) {
    const chunkLimit = src.chunk ? src.chunk : 4194304;
    const multyStepsUpload = file.size <= chunkLimit && index === 0 ? false : true; // Check file size to handle loader

    const chunk = src.chunk;
    const method = src.method;
    const url = src.url;
    const start = index * chunk;
    const end = start + chunk < file.size ? start + chunk : file.size;

    const last = end - 1;
    //let range = "bytes " + (end == file.size ? (start + "-" + last + "/" + file.size) : (start + "-" + last + "/*"));
    const range =
      file.size == 0 ? 'bytes */0' : 'bytes ' + (end == file.size ? start + '-' + last + '/' + file.size : start + '-' + last + '/*');
    const func = file.slice ? 'slice' : file['mozSlice'] ? 'mozSlice' : file['webkitSlice'] ? 'webkitSlice' : 'slice';
    const bytes = file[func](start, end);

    file.uploadStep = Math.ceil(file.size / chunkLimit);
    file.revisionId = src['revisionId'];

    const uploadStatusAction = (stream) => {
      if (index !== file.uploadStep - 1 && multyStepsUpload) {
        this.streamPartUpload(file, index + 1, src);
      } else {
        this.updateProgress(multyStepsUpload, file, index, src, stream, file.uploadStep - 1, 'closeTime');
        this.streamCommit(src, file);
      }
    };

    const stream = this.streamFileUpload(file, url, bytes, range, method, multyStepsUpload).subscribe(
      (result) => {
        uploadStatusAction(stream);
      },
      (error) => {
        // In Error the upload continues - Backend works like that!!!
        switch (error.status) {
          case 308: // Uploading File Chunks Here
            this.streamPartUpload(file, index + 1, src);
            break;
          case 0: // Upload is Finished and Start Commiting file in our Database
            uploadStatusAction(stream);
            break;
          case 200: // Upload is Finished and Start Commiting file in our Database
            uploadStatusAction(stream);
            break;
          default:
            const uploadError = this.createUploadError(file, error);
            this.handleUploadEvent(uploadError);
        }
      }
    );

    // Report for Upload-In-Progress and show 'LOADER BAR AND % UPLOADED' Message!
    this.updateProgress(multyStepsUpload, file, index, src, stream, file.uploadStep);
  }

  private streamCommit(src, file) {
    const stream = this.authService
      .authRequest('files', 'stream-commit-opt', {
        id: src.id,
        'stream-type': 'file',
        revision: src.revisionId,
      })
      .subscribe(
        (result) => {
          // Report when Upload Finish
          if (!file.folderRelated) {
            this.handleUploadEvent({
              type: 'complete',
              data: { file: file, findBy: 'revisionId' },
              file: result,
            });
          } else {
            this.handleUploadEvent({
              type: 'update-folder',
              stream: 'streamCommit',
              data: {
                file: file,
                findBy: 'rootKey',
                updateProgress: true,
                progressData: {
                  folderRelated: true,
                  completeUpload: true,
                },
              },
              file: result,
            });
          }
        },
        (error) => {
          const uploadError = this.createUploadError(file, error);
          this.handleUploadEvent(uploadError);
        }
      );

    // Attach file stream
    file.fileStream = stream;
    this.handleUploadEvent({
      type: 'update',
      file: src,
      data: {
        file: file,
        streamCommit: true,
        fileStream: stream,
        findBy: 'revisionId',
        setProp: '',
        updateProgress: false,
      },
    });
  }

  private streamFileUpload(file, url, bytes, range, method, multyStepsUpload): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Content-Range': range,
      }),
    };
    const req = new HttpRequest(method, url, bytes, {
      headers: header.headers,
      reportProgress: true,
    });

    return this.http.request(req).pipe(
      map((event) => {
        if (multyStepsUpload || file?.folderRelated) {
          return;
        }
        this.reportUploadEvent(event, file);
      }),
      last()
    );
  }

  /** Return distinct message for sent, upload progress, & response events */
  private reportUploadEvent(event: HttpEvent<any>, file?) {
    switch (event.type) {
      case HttpEventType.Sent:
        return;

      case HttpEventType.UploadProgress:
        const percentDone = Math.round((100 * event.loaded) / event.total); // Compute the % done:
        this.handleUploadEvent({
          type: 'update',
          data: {
            file: { name: file.name, revisionId: file['revisionId'] },
            findBy: 'revisionId',
            setProp: false,
            updateProgress: true,
            progressData: {
              percentDone: percentDone,
              bytesLeft: event.loaded,
              timePart: this.trackingUploadTime(),
            },
          },
        });
        return;

      case HttpEventType.Response:
        return;
    }
  }
}
