<div
  [ngClass]="{ 'static-page': currentPage.static, 'has-invite-banner': hasInviteBanner, 'has-exceed-banner': hasExceedBanner }"
  class="{{ 'main-wrapper ' + currentPage.customClass }}"
  dropPreventHandler
>
  <div id="debuggerEL"></div>
  <header id="header" class="header cfix" [class.logo-only]="currentPage.name === 'cloud-storage-plans'">
    <app-header [profileData]="profileData" [screen]="screenSize" [pageLocation]="currentPage" (openMobileMenu)="toggleMainMenu()">
    </app-header>
  </header>

  <div id="content" class="content">
    <ng-container *ngIf="currentPage.static || currentPage.hidePageHeader; then staticPage; else internalPage"> </ng-container>

    <ng-template #staticPage>
      <div id="pageWrapper" class="page-wrapper {{ currentPage.pageNameClass }}">
        <div class="page">
          <div class="file-list cfix">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #internalPage>
      <router-outlet></router-outlet>
    </ng-template>
  </div>

  <!-- Upload inputs -->
  <div class="upload-inputs">
    <input type="file" id="uplFile" multiple (change)="uploadEvent($event)" [accept]="" tabindex="-1" />
    <input
      type="file"
      id="uplFolder"
      multiple
      webkitdirectory
      mozdirectory
      directory
      allowdirs
      (change)="uploadEvent($event)"
      [accept]=""
      tabindex="-1"
    />
  </div>

  <!-- Action modals -->
  <ng-template #modalWrapper></ng-template>

  <!-- Drag Info Element -->
  <div id="dragInfo" class="drag-info">
    <div class="counter"></div>
  </div>

  <!-- Promotion Banner Mobile -->
  <app-promo-banner [pageLocation]="currentPage" *ngIf="!currentPage.static && screenSize.indexOf('mobile') !== -1"></app-promo-banner>

  <!-- Consent Cookie Banner -->
  <cookie-banner *ngIf="showConsentBanner"></cookie-banner>

  <!-- Change Language Loader -->
  <div class="change-language-loader" *ngIf="langChangeLoading" [@fadeRegular]>
    <div class="animation-block"></div>
  </div>
</div>
