export const searchInParents = (item, keyForSearch): boolean => {
  let findKey = false;

  const parentDigger = (item) => {
    if (item.parent === null) {
      return findKey;
    }

    if (item.parent.key === keyForSearch) {
      findKey = true;
    } else if (item.parent.parent) {
      parentDigger(item.parent);
    }
  };

  parentDigger(item);
  return findKey;
};
