import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ChangeDetectionManagerService {
  constructor() {}

  @Output() changeDetectionStrategy: EventEmitter<any> = new EventEmitter();

  detachChangeDetection(componentName?) {
    this.changeDetectionStrategy.emit({ detection: false, forComponent: componentName ? componentName : '' });
  }

  attachChangeDetection(componentName?) {
    this.changeDetectionStrategy.emit({ detection: true, forComponent: componentName ? componentName : '' });
  }
}
