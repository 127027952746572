export class BaseError extends Error {
  constructor(message, code) {
    super(message);
  }
}

export class AuthError extends Error {
  retryAfter: number;
  message: string;
  status: any;
  constructor(message, retryAfter = null, statusText = null) {
    super(message);
    this.retryAfter = retryAfter;
    this.message = message;
    this.status = statusText;
  }
}
