import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { ManageCookieService } from '../services/manage-cookie.service';

export type IExitParams = 'rnd' | 'logout' | 'error';

export interface ILoginPortalLink {
  exitParam: string;
  logoutUrl: string;
  app?: string;
  lang?: string;
  params?: { xchange?: string; aid?: string; activateWithKey?: number; storage?: number };
}

export interface IWindowLocation {
  host: string;
  pathname: string;
  search: string;
}

@Injectable({
  providedIn: 'root',
})
export class LogoutService {
  constructor(private manageCookieService: ManageCookieService, private router: Router) {}

  logout(exitParam: IExitParams, logoutUrl: string = ''): void {
    sessionStorage.removeItem('authToken');
    sessionStorage.removeItem('accountId');
    sessionStorage.removeItem('scode');
    this.manageCookieService.deleteCookie('scode');

    if (!this.manageCookieService.getCookie('userLoginTrc45New') && exitParam !== 'error') {
      this.router.navigate(['/']);
      return;
    }

    const logOutLink = this.buildLoginLink({ exitParam: exitParam, logoutUrl: logoutUrl });
    window.open(logOutLink, '_self', 'false');
  }

  buildLoginLink(data: ILoginPortalLink): string {
    const langCode: string = data.lang || this.manageCookieService.getCookie('langaugeCode');
    const wl: IWindowLocation = this.getWindowLocation();
    const app: string = data.app;
    let drivePath: string = `${environment.accountsLink}/${langCode === 'tr' ? 'en' : langCode}` + `?redirect=https%3A%2F%2F${wl.host}`;

    if (data?.exitParam !== 'logout') {
      drivePath += `&app=${app}`;
    }
    let redirectMDPath: string = '';
    if (data.exitParam !== 'logout' && wl.pathname !== '/') {
      redirectMDPath = data.logoutUrl ? data.logoutUrl : wl.pathname;
    }

    // When logout from Checkout return User to Pricing page
    if (redirectMDPath === '/checkout') {
      redirectMDPath = '/pricing';
    }

    if (!data.logoutUrl && wl.search) {
      redirectMDPath += wl.search;
    }

    let params = '';
    if (data.params) {
      for (const param in data.params) {
        params = `${params}&${JSON.stringify(param).replace(/"/g, '')}=${data.params[param]}`;
      }
    }
    return `${drivePath}${encodeURIComponent(redirectMDPath)}&${data.exitParam}=${Date.now()}${params}`;
  }

  getWindowLocation(): IWindowLocation {
    return {
      host: window.location.host,
      pathname: window.location.pathname,
      search: window.location.search,
    };
  }
}
