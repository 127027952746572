import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class FileValidationService {
  constructor(private deviceService: DeviceDetectorService) {}

  deviceData: any = this.deviceService.getDeviceInfo();

  /* File Validator
  ================================================== */
  validate(file) {
    const validFile: any = { status: true, reason: '' };

    // Check if File name is in correct format
    if (!this.fileNameIsAllowed(file)) {
      validFile.status = false;
      validFile.reason = 'fileNameError';
    }

    return validFile;
  }

  /* File Size Tester
  ================================================== */
  fileSizeTester(bytes): any {
    const k = 1024,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));

    return { sizeUnit: sizes[i], measureIndex: i };
  }

  /* File Name Tester
  ================================================== */
  fileNameIsAllowed(file) {
    const nameValidation = new RegExp('^(?!\\s*(?:\\.\\s*)+$)[^\\\\:*?"<>|/]+$');
    const isValid = nameValidation.test(file.name);
    return isValid;
  }

  /* IE11 Folder
  ================================================== */
  ie11FolderError(files) {
    const validationData: any = { error: false, reason: 'folderUploadErrorIE' };
    if (this.ie11Browser() && !files.length) {
      validationData.error = true;
    }

    return validationData;
  }

  ie11Browser() {
    const thisBrowser = this.deviceData.browser.toLowerCase();
    if (thisBrowser === 'ie' && this.deviceData.browser_version === '11.0') {
      return true;
    }
    return false;
  }
}
