import { Injectable, EventEmitter, Output, Directive } from '@angular/core';

@Injectable()
export class ScrollItemsService {
  @Output() scrollTargetEvent: EventEmitter<any> = new EventEmitter();
  @Output() scrollProgressPopupEvent: EventEmitter<any> = new EventEmitter();

  constructor() {}

  lockScroll: boolean = false;

  calculateScroll(element, gap = 450) {
    if (element === 'mobile') {
      const triggerPoint = window.innerHeight + window.scrollY >= document.body.scrollHeight - gap;
      if (triggerPoint && !this.lockScroll) {
        this.loadMoreResults();
      }
    } else {
      const scrollFromTop = element.scrollTop;
      const containerHeight = element.clientHeight;
      const scrollHeight = element.scrollHeight;
      const triggerBefore = gap;
      const triggerPoint = scrollFromTop + containerHeight + triggerBefore;
      if (triggerPoint >= scrollHeight && !this.lockScroll) {
        this.loadMoreResults();
      }
    }

    // Close context Menus on scroll
    if (document.querySelector('.ngx-contextmenu.open')) {
      document.dispatchEvent(new Event('click'));
    }

    // Debugg
    //var node = document.createElement("div");
    //let hit = triggerPoint >= scrollHeight;
    //node.innerHTML = 'tp ' + Math.round(triggerPoint) +'<br>'+
    //'scrollH ' + scrollHeight+'<br>'+ 'HIT: '+ hit + '<br>'+ '--------------------------';
    //document.getElementById('debuggerEL').appendChild(node);
  }

  loadMoreResults() {
    if (!this.lockScroll) {
      this.lockScroll = true;
      this.scrollTargetEvent.emit(true);
    }
  }

  unlockScroll(value) {
    this.lockScroll = value;
  }
}
