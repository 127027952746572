import { Injectable } from '@angular/core';
import { ManageCookieService } from './manage-cookie.service';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class ProductVersionService {
  driveVerFromCookie: string = '';
  driveVerCookie: string = 'Drive_Version';

  constructor(private manageCookieService: ManageCookieService) {}

  hasNewDriveVersion(): boolean {
    this.driveVerFromCookie = this.manageCookieService.getCookie(this.driveVerCookie);
    if (!this.driveVerFromCookie) {
      this.newVersionChecked();
      return false;
    } else {
      return this.checkVersionStatus();
    }
  }

  newVersionChecked() {
    this.manageCookieService.setCookie(this.driveVerCookie, `${environment.version}`, { period: 'd', value: 365 });
  }

  checkVersionStatus(): boolean {
    if (!this.driveVerFromCookie.includes('.')) {
      // Migration from old to new version format
      return true;
    } else {
      const envVer = `${environment.version}`.split('.');
      const cVer = this.driveVerFromCookie.split('.');
      if (envVer[0] != cVer[0] || envVer[1] != cVer[1]) {
        return true;
      } else {
        // Do Not show update notification for hotfixes and update version
        this.newVersionChecked();
        return false;
      }
    }
  }
}
