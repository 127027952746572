import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoginService } from './login.service';
import { ManageCookieService } from './manage-cookie.service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class HomePageGuardService implements CanActivate {
  constructor(private loginService: LoginService, private manageCookieService: ManageCookieService, private router: Router) {}

  loggedInRef: any;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>((obs) => {
      if (route.queryParams.vp || (route.queryParams.startdw && route.queryParams.startdw === '1')) {
        obs.next(true);
        return;
      }
      const logedUserCookie: string = this.manageCookieService.getCookie('userLoginTrc45New');
      const publicPageSeenCookie: string = this.manageCookieService.getCookie('publicPageSeen');
      if (logedUserCookie === '' && publicPageSeenCookie === '') {
        obs.next(true);
        return;
      }

      if (this.loginService.isLoggedIn()) {
        this.router.navigate(['/my-files']);
      } else {
        this.loggedInRef = this.loginService.loggedInSignal.subscribe((data) => {
          this.loggedInRef.unsubscribe();
          if (!data) {
            obs.next(true);
          } else {
            this.router.navigate(['/my-files']);
          }
        });
        this.loginService.checkSession();
      }
    });
  }
}
