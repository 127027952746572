import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { ManageCookieService } from '../services/manage-cookie.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export type IExitParams = 'rnd' | 'logout' | 'error';

export interface ILoginPortalLink {
  exitParam: string;
  logoutUrl: string;
  app?: string;
  lang?: string;
  params?: { xchange?: string; aid?: string; activateWithKey?: number; storage?: number };
}

export interface IWindowLocation {
  host: string;
  pathname: string;
  search: string;
}

@Injectable({
  providedIn: 'root',
})
export class LogoutService {
  constructor(private manageCookieService: ManageCookieService, private router: Router, private http: HttpClient) {}

  logout(exitParam: IExitParams, logoutUrl: string = ''): void {
    const scode = this.manageCookieService.getCookie('scode');
    // sign out here
    if (scode) {
      this.invalidateUserToken().subscribe();
    }

    sessionStorage.removeItem('authToken');
    sessionStorage.removeItem('accountId');
    sessionStorage.removeItem('scode');
    this.manageCookieService.deleteCookie('scode');

    if (!this.manageCookieService.getCookie('userLoginTrc45New') && exitParam !== 'error') {
      this.router.navigate(['/']);
      return;
    }

    const logOutLink = this.buildLoginLink({ exitParam: exitParam, logoutUrl: logoutUrl });
    window.open(logOutLink, '_self', 'false');
  }

  invalidateUserToken(): Observable<any> {
    const accId = sessionStorage.getItem('accountId');
    const authTkn = sessionStorage.getItem('token');
    const pushTkn: string = this.manageCookieService.getCookie('pushtkn2');

    const requestParams = `path=profile&command=sign-out`;

    return this.http
      .post(environment.apiUrl, requestParams, {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
          app: 'com.mobisystems.web.mobidrive',
          pushtkn: pushTkn,
          account: accId,
          tkn: authTkn,
        }),
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  buildLoginLink(data: ILoginPortalLink): string {
    const langCode: string = data.lang || this.manageCookieService.getCookie('langaugeCode');
    const wl: IWindowLocation = this.getWindowLocation();
    const app: string = data.app || 'com.mobisystems.web.mobidrive';
    const drivePath: string =
      `${environment.accountsLink}/${langCode === 'tr' ? 'en' : langCode}` + `?redirect=https%3A%2F%2F${wl.host + wl.pathname}`;

    let redirectLink: string;
    let redirectMDPath: string = '';

    if (data.exitParam !== 'logout' && wl.pathname !== '/') {
      redirectMDPath = data.logoutUrl ? data.logoutUrl : wl.pathname;
    }

    if (!data.logoutUrl && wl.search) {
      redirectMDPath += wl.search;
    }

    let params = '';
    if (data.params) {
      for (const param in data.params) {
        params = `${params}&${JSON.stringify(param).replace(/"/g, '')}=${data.params[param]}`;
      }
    }
    redirectLink = `${drivePath}${encodeURIComponent(redirectMDPath)}&${data.exitParam}=${Date.now()}${params}`;

    if (data?.exitParam !== 'logout') {
      redirectLink += `&app=${app}`;
    }

    return redirectLink;
  }

  getWindowLocation(): IWindowLocation {
    return {
      host: window.location.host,
      pathname: window.location.pathname,
      search: window.location.search,
    };
  }
}
