<!-- Move files header -->
<div class="move-files-header">
  <div *ngIf="loadingPath && !showForm" class="mfh-loader" [@fadeRegular]>
    <div class="back-link">
      <div class="back-button-wrap">
        <span class="l-block"></span>
      </div>
      <strong>
        <span class="l-block"></span>
      </strong>
    </div>

    <span class="add-folder-button">
      <span class="l-block"></span>
    </span>
  </div>

  <div *ngIf="!loadingPath && !showForm" class="mfh-controls cfix" [@fadeRegular]>
    <div class="back-link">
      <div class="back-button-wrap">
        <button
          *ngIf="folderLocation.key"
          (click)="openPreviousFolder()"
          [disabled]="loadingFolders"
          class="button col-menu"
          [@fadeRegular]
        >
          <span class="menu-icon iconame-arrow-backward"></span>
        </button>
      </div>

      <strong *ngIf="folderLocation.key">{{ folderLocation.name | folderName }}</strong>
      <strong *ngIf="!folderLocation.key">{{ 'pages.myFiles' | translate }}</strong>
    </div>

    <span class="button col-menu add-folder-button" [ngClass]="{ selected: showForm }" (click)="toggleForm()">
      <span class="menu-icon iconame-new-folder"></span>
    </span>
  </div>

  <form *ngIf="showForm" [formGroup]="newFolderForm" class="single-item-form" [@fadeRegular]>
    <div class="back-button-wrap">
      <button type="button" (click)="toggleForm(); (false)" class="button col-menu" [@fadeRegular]>
        <span class="menu-icon iconame-arrow-backward"></span>
      </button>
    </div>

    <div class="form-item cfix input-with-button">
      <div class="input-wrapper cfix">
        <input
          type="text"
          formControlName="folderName"
          #folderName
          class="input-style"
          [ngClass]="{
            'input-error': fc.folderName.errors && (fc.folderName.errors.pattern || fc.folderName.errors.maxlength)
          }"
        />
      </div>
      <button
        class="button col-blue small-size"
        [disabled]="newFolderForm.invalid || loading"
        (click)="createFolder(); (false)"
      >
        <span class="menu-icon iconame-checkmark-white"></span>
      </button>
    </div>
  </form>
</div>

<!-- New folder view -->
<div *ngIf="showForm" class="dir-wrapper" [@fadeRegular]>
  <div class="create-folder-stat">
    <div class="txt-box" *ngIf="!fc.folderName.errors || (fc.folderName.errors && fc.folderName.errors.required)">
      <p
        *ngIf="folderLocation.key"
        [innerHTML]="'modal.createNewFolderIn' | translate: { value: folderLocation.name | folderName }"
      ></p>
      <p
        *ngIf="!folderLocation.key"
        [innerHTML]="'modal.createNewFolderIn' | translate: { value: 'pages.myFiles' | translate }"
      ></p>
    </div>

    <div class="txt-box error-box" *ngIf="fc.folderName.errors">
      <div class="form-error" *ngIf="fc.folderName.errors && fc.folderName.errors.maxlength">
        {{ 'errorMessages.folderLongName' | translate }}
      </div>
      <div class="form-error" *ngIf="fc.folderName.errors && fc.folderName.errors.pattern">
        {{ 'validation.filenameRules' | translate }}
      </div>
    </div>
  </div>
</div>
