import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ApplyActionService } from '../../../../services/apply-action.service';
import { AuthService } from '../../../../services/auth.service';
import { LocationService } from '../../../../services/location.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-new-folder',
  templateUrl: './new-folder.component.html',
})
export class NewFolderComponent implements OnInit, AfterViewInit {
  @Input() filesData: any;
  @Input() menuSource: any;
  @Input() modal: any;

  @ViewChild('folderName') folderName: ElementRef;

  newFolderForm: FormGroup;
  fc: any;
  defaultName: string = 'New folder';

  loading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private applyActionService: ApplyActionService,
    private authService: AuthService,
    private translate: TranslateService,
    private locationService: LocationService
  ) {}

  ngOnInit() {
    this.translate.get('modal.newFolder').subscribe((data) => {
      this.defaultName = data;
    });

    this.newFolderForm = this.formBuilder.group({
      folderName: [
        this.defaultName,
        [Validators.required, Validators.maxLength(255), Validators.pattern('(?!\\s*(?:\\.\\s*)+$)[^\\\\:*?"<>|/]+')],
      ],
    });

    this.newFolderForm.valueChanges.subscribe((value) => {
      const startWithSpace = value.folderName.match(/^\s+/g);
      if (startWithSpace) {
        const trimmedValue = value.folderName.replace(/^\s+/g, '');
        this.newFolderForm.patchValue({ folderName: trimmedValue });
      }
    });

    this.fc = this.newFolderForm.controls;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.folderName.nativeElement.focus();
      this.folderName.nativeElement.select();
    }, 0);
  }

  onKeyPress(event): void {
    if (event.keyCode === 13 && !this.fc.folderName.errors) {
      this.submitForm();
    }
  }

  submitForm(): void {
    this.fc.folderName.disable(); // Disable reactive input on loading
    const authData = this.authService.getUserAuth();

    // Determine parent key for the new folder
    let parentKey = this.locationService.getParentKey();
    if (this.menuSource && this.menuSource === 'breadcrumb') {
      parentKey = this.filesData[0].key;
    }

    const nfData = {
      account: authData.accountId,
      key: parentKey,
      name: null,
      parent: null,
    };

    // Remove white space from the START and the END of the FileName
    const folderName = this.fc.folderName.value.trim();
    this.applyActionService.createFolderAction(nfData, folderName + '/');

    this.modal.close();
  }
}
