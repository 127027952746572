<h5 *ngIf="view === 'move'" class="modal-title">
  <span *ngIf="moveType === 'file'">{{ 'modal.moveFile' | translate }}</span>
  <span *ngIf="moveType === 'files'">{{ 'modal.moveFiles' | translate: { value: filesNumber } }}</span>
  <span *ngIf="moveType === 'folder'">{{ 'modal.moveFolder' | translate }}</span>
  <span *ngIf="moveType === 'folders'">{{ 'modal.moveFolders' | translate: { value: filesNumber } }}</span>
  <span *ngIf="moveType === 'items'">{{ 'modal.moveItems' | translate: { value: filesNumber } }}</span>
</h5>

<h5 *ngIf="view === 'copy'" class="modal-title">
  <span *ngIf="moveType === 'file'">{{ 'modal.copyFile' | translate }}</span>
  <span *ngIf="moveType === 'files'">{{ 'modal.copyFiles' | translate: { value: filesNumber } }}</span>
  <span *ngIf="moveType === 'folder'">{{ 'modal.copyFolder' | translate }}</span>
  <span *ngIf="moveType === 'folders'">{{ 'modal.copyFolders' | translate: { value: filesNumber } }}</span>
  <span *ngIf="moveType === 'items'">{{ 'modal.copyItems' | translate: { value: filesNumber } }}</span>
</h5>
