import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {IRefsMap} from '../models/common.int';
import {ManageCookieService} from './manage-cookie.service';
import {DeviceInfo} from 'ngx-device-detector';
import {DeviceDetectorService} from 'ngx-device-detector';
import {LanguageManagerService} from './language-manager.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceInfoService {
  private refs: IRefsMap = {};
  private isLoadDeviceInfo = false;
  private isLoadClientInstalled = false;
  private deviceData: DeviceInfo = this.deviceService.getDeviceInfo();

  constructor(private authService: AuthService,
              private manageCookieService: ManageCookieService,
              private languageService: LanguageManagerService,
              private deviceService: DeviceDetectorService) {}

  saveDeviceInfo() {
    const prevData = this.manageCookieService.getCookie('saveDeviceInfo');
    const callParams = JSON.stringify({
      os_version: 'web',
      browser: this.deviceData.browser.toLowerCase(),
      browser_version: this.deviceData.browser_version.toLowerCase(),
      timezone: this.manageCookieService.getCookie('timeZoneOffset'),
      language: this.languageService.getLangCode(),
      country: this.languageService.getCountryKey(),
    });
    if ((!prevData || prevData !== callParams) && !this.isLoadDeviceInfo) {
      this.isLoadDeviceInfo = true;
      this.refs.authServiceRef = this.authService.authRequest(
        'applications',
        'save-device-info',
        { nozip: true, payload: JSON.parse(callParams) }
      ).subscribe(
        () => {
          this.isLoadDeviceInfo = false;
          this.manageCookieService.setCookie('saveDeviceInfo', callParams, {value: 365 * 24 * 60 * 60 * 10000, period: 'd'});
          this.refs.authServiceRef.unsubscribe();
        }
      );
    }
  }

  clientInstalled() {
    const prevData = this.manageCookieService.getCookie('clientInstalled');
    if (!prevData && !this.isLoadClientInstalled) {
      this.isLoadClientInstalled = true;
      const callParams = {
        ab_test_group: this.manageCookieService.getCookie('abTestGroup'),
        referrer: this.manageCookieService.getCookie('referrerCode'),
        download_source: null,
        media_source: null,
        campaign: null,
        ga: this.manageCookieService.getCookie('_ga'),
        gclid: this.manageCookieService.getCookie('_gid'),
        app_version: this.manageCookieService.getCookie('Drive_Version'),
      };
      this.refs.authServiceRef = this.authService.authRequest(
        'applications',
        'client-installed',
        { payload: callParams },
        null,
        null,
        true
      ).subscribe(
        () => {
          this.isLoadClientInstalled = false;
          this.manageCookieService.setCookie('clientInstalled', 'true', {value: 365 * 24 * 60 * 60 * 10000, period: 'd'});
          this.refs.authServiceRef.unsubscribe();
        }
      );
    }
  }
}
