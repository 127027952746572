import {ILanguage} from '../models/lang.int';

export const languageMap: ILanguage[] = [
  {
    lang: 'German',
    label: 'Deutsch',
    code: 'de',
    countries: ['DE'],
  },
  {
    lang: 'English',
    label: 'English',
    code: 'en',
    countries: [],
  },
  {
    lang: 'Spanish',
    label: 'Español',
    code: 'es',
    countries: [
      'ES',
      'AR',
      'MX',
      'CO',
      'PE',
      'VE',
      'CL',
      'EC',
      'GT',
      'CU',
      'BO',
      'DO',
      'HN',
      'PY',
      'SV',
      'NI',
      'CR',
      'PR',
      'PA',
      'UY',
      'GQ',
    ],
  },
  {
    lang: 'French',
    label: 'Français',
    code: 'fr',
    countries: ['FR', 'CG', 'MG', 'CI'],
  },
  {
    lang: 'Italian',
    label: 'Italiano',
    code: 'it',
    countries: ['IT'],
  },
  {
    lang: 'Swedish',
    label: 'Svenska',
    code: 'sv',
    countries: ['SV'],
  },
  {
    lang: 'Turkish',
    label: 'Türk',
    code: 'tr',
    countries: ['TR'],
  },
  {
    lang: 'Bulgarian',
    label: 'Български',
    code: 'bg',
    countries: ['BG'],
  },
  {
    lang: 'Russian',
    label: 'Русский',
    code: 'ru',
    countries: ['RU'],
  },
  {
    lang: 'Hebrew',
    label: 'עִבְרִית‎',
    code: 'he',
    countries: ['HE'],
  },
  {
    lang: 'Hindi',
    label: 'हिन्दी',
    code: 'hi',
    countries: [],
  },
  {
    lang: 'Thai',
    label: 'ภาษาไทย',
    code: 'th',
    countries: ['TH'],
  },
  {
    lang: 'Arabic',
    label: 'العَرَبِيَّة‎',
    code: 'ar',
    countries: [
      'SA',
      'DZ',
      'BH',
      'KM',
      'DJ',
      'EG',
      'ER',
      'IQ',
      'JO',
      'KW',
      'LB',
      'LY',
      'MR',
      'MA',
      'OM',
      'PS',
      'QA',
      'SO',
      'SD',
      'SS',
      'SY',
      'TZ',
      'TN',
      'AE',
      'YE',
    ],
  },
  {
    lang: 'Chinese',
    label: '中国',
    code: 'cn',
    countries: ['CN', 'TW', 'SG'],
  },
  {
    lang: 'Japanese',
    label: '日本語',
    code: 'jp',
    countries: ['JP'],
  }
];
