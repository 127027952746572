import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ApplyActionService } from '../../../../services/apply-action.service';
import { IitemsSetType } from '../../../../models/common.int';

@Component({
  selector: 'app-remove-recent-files',
  templateUrl: './remove-recent-files.component.html',
})
export class RemoveRecentFilesComponent implements OnInit, AfterViewInit {
  @Input() filesData: any;
  @Input() menuSource: any;
  @Input() modal: any;

  @ViewChild('confirmRemove') confirmRemove: ElementRef;

  filesForDelete: number;
  fileName: string;
  itemsType: IitemsSetType = 'files';

  loading: boolean = false;

  constructor(private applyActionService: ApplyActionService) {}

  ngOnInit() {
    // Determine remove message
    this.filesForDelete = this.filesData.length;
    if (this.filesForDelete === 1) {
      this.fileName = `${this.filesData[0].name}`;
    } else {
      this.itemsType = 'items';
    }
  }

  ngAfterViewInit() {
    // Focus Remove Button
    setTimeout(() => {
      this.confirmRemove.nativeElement.focus();
    }, 1);
  }

  removeFiles() {
    this.applyActionService.removeRecentAction(this.filesData, this.menuSource);
    this.modal.close();
  }
}
