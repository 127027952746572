import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root',
})
export class AdvanceDeviceDetectionService {
  constructor(private deviceService: DeviceDetectorService) {}

  deviceData: any = this.deviceService.getDeviceInfo();

  isIOS() {
    //TODO move to helpers - same code in app.component.ts
    if (this.deviceData.os === 'iOS') {
      return true;
    }

    if (this.deviceData.device === 'iPad') {
      return true;
    }

    // Check for iPad 7th Gen
    if (navigator.userAgent.match(/Mac/g) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
      return true;
    }

    return false;
  }

  getOS() {
    return this.isIOS() ? 'iOS' : this.deviceData.os;
  }

  getDetectedOS() {
    return this.deviceService.getDeviceInfo().os;
  }

  getDeviceInfo() {
    this.deviceData.os = this.getOS();
    this.deviceData.osDetected = this.getDetectedOS();
    return this.deviceData;
  }
}
