import { Injectable } from '@angular/core';

import { ServerDetectionService } from './server-detection.service';
import { LoginService } from './login.service';
import { ManageCookieService } from './manage-cookie.service';

@Injectable()
export class TrackingService {
  private _isServer: boolean = this.serverDetection.isServer();

  constructor(
    private serverDetection: ServerDetectionService,
    private loginService: LoginService,
    private cookieService: ManageCookieService
  ) {
    if (!this._isServer) {
      const initialUserData = this.getInitialUserData();
      const dataLayer = window['dataLayer'] || [];

      dataLayer.push({
        event: 'userData',
        ...initialUserData,
      });

      this.loginService.loggedInData.subscribe((userData) => {
        if (userData && initialUserData.logged_in === false) {
          // catching the user state changing on the client-side
          dataLayer.push({
            event: 'userData',
            logged_in: true,
            user_id: userData.accountId,
          });
        }
      });
    }
  }

  trackEvent(category, action, label?, value?) {
    const event = {
      event: 'GAEvent',
      eventCategory: category,
      eventAction: action,
    };

    if (label) {
      event['eventLabel'] = label;
    }
    if (value) {
      event['eventValue'] = value;
    }

    const dataLayer = window['dataLayer'] || [];
    dataLayer.push(event);
  }

  private getInitialUserData() {
    const userId = window.sessionStorage.getItem('accountId');
    const loggedIn = userId !== null;
    const referrer = this.cookieService.getCookie('referrerCode');

    return {
      logged_in: loggedIn,
      referrer,
      ...(userId !== null && { user_id: userId }),
    };
  }
}
