import { Injectable, EventEmitter, Output, Directive } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { IRefsMap } from '../models/common.int';

export interface IStorageData {
  maximum: number;
  size: number;
}
export interface IStorageStatus extends IStorageData {
  storageUsedCriticalRound: number;
  storageUsedSafeRound: number;
  usedPercentage: number;
}

@Injectable()
export class StorageStatusService {
  constructor(private authService: AuthService) {}

  @Output() storageStatusUpdate: EventEmitter<any> = new EventEmitter();
  @Output() storageAlert: EventEmitter<any> = new EventEmitter();

  storageStatus: IStorageStatus;
  refs: IRefsMap = {};

  // Available Space UI Bar
  storageUsedSafe: number = 0;
  storageUsedCritical: number = 0;

  updateStorageStatus(): void {
    this.refs.authServiceRef = this.authService.authRequest('files', 'storage', {}).subscribe((result: IStorageData) => {
      this.storageStatus = this.buildStorageStatus(result);
      this.storageStatusUpdate.emit({ ...this.storageStatus });
    });
  }

  getStorageStatus() {
    return { ...this.storageStatus };
  }

  buildStorageStatus(storageData: IStorageData): IStorageStatus {
    const storageCritical: number = 0.8;
    const usedSpace = storageData.size / storageData.maximum;
    const storageStatus: IStorageStatus = {
      ...storageData,
      usedPercentage: usedSpace > 1 ? 1 : usedSpace,
      storageUsedSafeRound: 0,
      storageUsedCriticalRound: 0,
    };

    if (storageCritical > storageStatus.usedPercentage) {
      this.storageUsedSafe = storageStatus.usedPercentage;
      this.storageUsedCritical = 0;
    } else {
      this.storageUsedSafe = storageCritical;
      this.storageUsedCritical = storageStatus.usedPercentage - storageCritical;
    }

    storageStatus.storageUsedSafeRound = Math.round(this.storageUsedSafe * 100);
    storageStatus.storageUsedCriticalRound = Math.round(this.storageUsedCritical * 100);

    return storageStatus;
  }

  getAvailableSpace(): number | boolean {
    if (this.storageStatus) {
      return this.storageStatus.maximum - this.storageStatus.size;
    } else {
      return false;
    }
  }

  fullStorageAlert(fileName?) {
    this.storageAlert.emit({ fullStorage: true, fileName: fileName });
  }
}
