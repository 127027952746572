import { Injectable, EventEmitter, Output } from '@angular/core';
import { AuthService } from './auth.service';
import { LocationService } from './location.service';
import { DisabledItemsManagerService } from './disabled-items-manager.service';
import { configs } from '../configs/drive-configs';
import { IRefsMap } from '../models/common.int';
import { SharedViewModesE } from '../models/enums';

interface FilesQueryData {
  root?: any; // folder data
  dirsOrFiles?: string;
  dirPosition?: string;
  listType?: string;
  searchFilter?: any;
  sortData: any;
  cursor: string;
}

@Injectable()
export class GetFilesService {
  constructor(
    private authService: AuthService,
    private locationService: LocationService,
    private disabledFilesManager: DisabledItemsManagerService
  ) {}

  @Output() getFiles: EventEmitter<any> = new EventEmitter();
  @Output() showErrors: EventEmitter<any> = new EventEmitter();
  @Output() getRecentFiles: EventEmitter<any> = new EventEmitter();
  @Output() getSharedFiles: EventEmitter<any> = new EventEmitter();
  @Output() getBinFiles: EventEmitter<any> = new EventEmitter();
  @Output() getSearchSuggestions: EventEmitter<any> = new EventEmitter();
  @Output() getThumbnails: EventEmitter<any> = new EventEmitter();

  loadStepItems: number = configs.loadStepItems; // 20
  firstLoadItems: number = configs.firstLoadItems; // 25

  refs: IRefsMap = {};

  thumbnailsRefs: any[] = [];
  thumbCallsLimit: number = 2;
  thumbsWaitingPool: any[] = [];

  /* Get Files List
  ================================================== */
  getFilesRequest(fq: FilesQueryData) {
    const pageKey = typeof fq.root.key !== 'undefined' ? fq.root.key : this.locationService.getParentKey();

    // Folder root / folder to search in
    const rootFolder = {
      account: fq.root.accountId,
      key: pageKey,
    };

    // Listing/pagging options
    const listingOptions = {
      cursor: fq.cursor,
      size: fq.cursor ? this.loadStepItems : this.firstLoadItems,
    };

    const sortFilter = {
      sort: fq.sortData.filterBy,
      direction: fq.sortData.direction,
    };

    const callParams = {
      root: rootFolder,
      filter: fq.searchFilter ? fq.searchFilter : null,
      options: listingOptions,
      sortOrder: sortFilter,
      dirPosition: 'dirsOnTop', // dirsOnTop, dirsOnBottom, any
      type: fq.listType ? fq.listType : 'flat', // flat, recursive
      dirsOrFiles: fq.dirsOrFiles ? fq.dirsOrFiles : 'both', // dirsOnly, filesOnly, both
    };

    this.refs.authServiceRef = this.authService.authRequest('files', 'search-adv', { request: callParams }).subscribe(
      (result) => {
        const lastPage = result.items.length < listingOptions.size ? true : false;

        if (result.items.length > 0) {
          // Check for disable files data
          const disabledFiles = this.disabledFilesManager.getDisabledFiles(result.items[0].parent.key);
          const checkDisableFiles = disabledFiles && disabledFiles.length > 0 ? true : false;

          result.items.forEach((item) => {
            // Check for file commander hidden file
            if (item.dir && item.name === '.file_commander_files_do_not_delete/') {
              item.hiddenItem = true;
            }

            // Apply files disabled state
            if (checkDisableFiles && disabledFiles.indexOf(item.key) !== -1) {
              item.disabled = true;
            }
          });
        }
        this.getFiles.emit({
          status: result,
          lastPage: lastPage,
          extendList: fq.cursor !== null,
          dirsOrFiles: fq.dirsOrFiles ? fq.dirsOrFiles : 'both',
          searchData: fq.searchFilter ? fq.searchFilter : null,
        });

        // Show Search suggestions in header bar (5 items only)
        if (fq.searchFilter) {
          this.getSearchSuggestions.emit(result.items.splice(0, 5));
        }
      },
      (error) => {
        if (error['retryAfter']) {
          setTimeout(() => {
            this.getFilesRequest(fq);
          }, error['retryAfter']);
        } else {
          this.showErrors.emit({ status: 'error', action: 'files-list', errorData: error });
        }
      }
    );

    return this.refs.authServiceRef;
  }

  rejectFilesList() {
    // Clean refs
    for (const ref in this.refs) {
      if (this.refs[ref]) {
        this.refs[ref].unsubscribe();
      }
    }
  }

  /* Get Thumbnails
  ================================================== */
  requireThumbnails(item, bigThumb?) {
    if (this.thumbnailsRefs.length > this.thumbCallsLimit) {
      if (bigThumb) {
        this.thumbsWaitingPool.unshift({ item: item, bigThumb: bigThumb });
      } else {
        this.thumbsWaitingPool.push({ item: item, bigThumb: false });
      }

      return;
    }
    const thumb = bigThumb ? 'thumb1280' : 'thumb';

    const data = {
      id: item,
      revision: '',
      'thumb-type': thumb,
      type: 'thumb',
      expires: new Date().getTime() + 1000 * 60 * 5,
    };
    const itemThumb = {
      thumbType: thumb,
      key: item.key,
      name: item.name,
      status: '',
    };

    const thumbReqRef = this.authService.authRequest('files', 'get-thumb', data).subscribe(
      (thumbnail) => {
        if (bigThumb) {
          itemThumb['imageBigUrl'] = thumbnail.url;
          itemThumb['status'] = thumbnail.status;
        } else {
          itemThumb['imageUrl'] = thumbnail.url;
        }

        this.getThumbnails.emit(itemThumb);
        this.removeThumbRef(thumbReqRef);
      },
      (error) => {
        if (bigThumb) {
          itemThumb['imageBigUrl'] = 'error';
        } else {
          itemThumb['imageUrl'] = 'error';
        }
        this.getThumbnails.emit(itemThumb);
        this.removeThumbRef(thumbReqRef);
      }
    );

    this.thumbnailsRefs.push(thumbReqRef);
  }

  removeThumbRef(thumbRef) {
    const index = this.thumbnailsRefs.indexOf(thumbRef);
    this.thumbnailsRefs.splice(index, 1);

    if (this.thumbsWaitingPool.length) {
      const thumbCandidate = this.thumbsWaitingPool.shift();
      this.requireThumbnails(thumbCandidate.item, thumbCandidate.bigThumb);
    }
  }

  rejectThumbnailsRequests() {
    if (this.thumbnailsRefs && this.thumbnailsRefs.length) {
      const ln = this.thumbnailsRefs.length;
      for (let i = 0; i < ln; i++) {
        this.thumbnailsRefs[i].unsubscribe();
      }
      this.thumbnailsRefs = [];
      this.thumbsWaitingPool = [];
    }
  }

  /* Get Recent List
  ================================================== */
  getRecentFilesRequest(fq) {
    // Listing/pagging options
    const listingOptions = {
      cursor: fq.cursor,
      size: fq.cursor ? this.loadStepItems : this.firstLoadItems,
      order: null,
    };

    const callParams = {
      'last-update': null,
      category: null,
      options: listingOptions,
    };

    this.refs.recentFilesRef = this.authService.authRequest('files', 'list-recents-opt', callParams).subscribe(
      (result) => {
        const lastPage = result.items.length < listingOptions.size ? true : false;
        const formatedItems = result.items.map((item) => {
          return { ...item, ...item.result };
        });
        result.items = formatedItems;
        this.getRecentFiles.emit({
          status: result,
          lastPage: lastPage,
          extendList: fq.cursor !== null,
        });
      },
      (error) => {
        this.showErrors.emit({ status: 'error', action: 'files-list', errorData: error });
      }
    );

    this.refs.recentFilesRef;
  }

  /* Get Shared By/With Me files
  ================================================== */
  getSharedFilesRequest(fq: FilesQueryData, sharedMode: SharedViewModesE) {
    // Listing/pagging options
    const listOptions = {
      cursor: fq.cursor,
      size: fq.cursor ? this.loadStepItems : this.firstLoadItems,
      order: null,
    };
    const sortOrder = {
      sort: fq.sortData.filterBy,
      direction: fq.sortData.direction,
    };
    const callParams = {
      filter: null,
      sortOrder,
      listOptions,
      dirsOrFiles: 'both',
      dirPosition: 'dirsOnTop',
    };

    const commands = {
      [SharedViewModesE.SHARED_WITH_ME]: 'list-shared-with-me',
      [SharedViewModesE.SHARED_BY_ME]: 'list-shared-by-me',
    };

    this.refs.sharedFilesRef = this.authService.authRequest('files', commands[sharedMode], { request: callParams }).subscribe(
      (result) => {
        const lastPage = result.items.length < listOptions.size;

        if (result.items.length > 0) {
          // Check for disable files data
          const disabledFiles = this.disabledFilesManager.getDisabledFiles(result.items[0].parent?.key);
          const checkDisableFiles = disabledFiles && disabledFiles.length > 0;

          result.items.forEach((item) => {
            // Check for file commander hidden file
            if (item.dir && item.name === '.file_commander_files_do_not_delete/') {
              item.hiddenItem = true;
            }

            // Apply files disabled state
            if (checkDisableFiles && disabledFiles.indexOf(item.key) !== -1) {
              item.disabled = true;
            }
          });
        }
        this.getSharedFiles.emit({
          status: result,
          lastPage: lastPage,
          extendList: fq.cursor !== null,
          dirsOrFiles: fq.dirsOrFiles ? fq.dirsOrFiles : 'both',
          searchData: fq.searchFilter ? fq.searchFilter : null,
        });

        // Show Search suggestions in header bar (5 items only)
        if (fq.searchFilter) {
          this.getSearchSuggestions.emit(result.items.splice(0, 5));
        }
      },
      (error) => {
        if (error['retryAfter']) {
          setTimeout(() => {
            this.getSharedFilesRequest(fq, sharedMode);
          }, error['retryAfter']);
        } else {
          this.showErrors.emit({ status: 'error', action: 'files-list', errorData: error });
        }
      }
    );

    this.refs.sharedFilesRef;
  }

  /* Get Bin files
  ================================================== */
  getBinFilesRequest(fq) {
    // Listing/pagging options
    const listingOptions = {
      cursor: fq.cursor,
      size: fq.cursor ? this.loadStepItems : this.firstLoadItems,
    };

    const sortFilter = {
      sort: fq.sortData.filterBy,
      direction: fq.sortData.direction,
    };

    const callParams = {
      filter: fq.searchFilter ? fq.searchFilter : null,
      options: listingOptions,
      sortOrder: sortFilter,
      dirPosition: 'dirsOnTop', // dirsOnTop, dirsOnBottom, any
    };

    this.refs.binFilesRef = this.authService.authRequest('files', 'list-bin', { request: callParams }).subscribe(
      (result) => {
        const lastPage = result.items.length < listingOptions.size ? true : false;

        if (result.items.length > 0) {
          // Check for disable files data
          const disabledFiles = this.disabledFilesManager.getDisabledFiles(result.items[0].parent.key);
          const checkDisableFiles = disabledFiles && disabledFiles.length > 0 ? true : false;

          result.items.forEach((item) => {
            // Apply files disabled state
            if (checkDisableFiles && disabledFiles.indexOf(item.key) !== -1) {
              item.disabled = true;
            }
          });
        }

        this.getBinFiles.emit({
          status: result,
          lastPage: lastPage,
          extendList: fq.cursor !== null,
          dirsOrFiles: fq.dirsOrFiles ? fq.dirsOrFiles : 'both',
          searchData: fq.searchFilter ? fq.searchFilter : null,
        });
      },
      (error) => {
        this.getBinFiles.emit({ status: 'error', action: 'files-list', errorData: error });
      }
    );
  }
}
