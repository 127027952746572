<section class="modal-wrap error-report-modal">
  <h5 class="modal-title">
    <span *ngIf="errorsData.length === 1" [innerHTML]="'errorMessages.errorOccured' | translate : { value: errorsData.length }"></span>
    <span *ngIf="errorsData.length > 1" [innerHTML]="'errorMessages.errorsOccured' | translate : { value: errorsData.length }"></span>
  </h5>

  <div class="modal-body cfix">
    <div class="process-errors-wrap cfix" [perfectScrollbar] [disabled]="mobileDevice">
      <ul class="process-errors-list">
        <li *ngFor="let error of errorsData">
          <!-- Errors from download Action -->
          <div class="error-item">
            <ng-container *ngTemplateOutlet="errorFileIcon; context: { $implicit: error }"></ng-container>
            <section class="file-status-box">
              <ng-container *ngTemplateOutlet="errorFileName; context: { $implicit: error }"></ng-container>
              <ng-container *ngTemplateOutlet="errorStatusTemplate; context: { $implicit: error }"></ng-container>
            </section>

            <section class="file-action-box">
              <div class="file-size">
                <span *ngIf="!error.obj.dir">{{ error.obj.size | byteSize }}</span>
              </div>
              <span *ngIf="error.errorData.message === 'faeFileTooLarge'" class="upgrade-link" (click)="showFileLimitationModal(error)">
                {{ upgradeLinkLable | translate }}
              </span>

              <a *ngIf="error.errorData.message === 'faeOutOfStorage'" [href]="mobisystemsUrl + '/store'" class="upgrade-link">
                {{ upgradeLinkLable | translate }}
              </a>
            </section>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <footer class="modal-footer cfix">
    <button class="button col-blue medium-size" (click)="closeModal()">
      {{ 'modal.close' | translate }}
    </button>
  </footer>
</section>

<!-- File Icon template -->
<ng-template #errorFileIcon let-error>
  <div *ngIf="error.action === 'download'" class="file-icon-wrapper">
    <span class="menu-icon iconame-download-file"></span>
  </div>

  <div *ngIf="error.action !== 'download'" class="file-icon-wrapper">
    <span *ngIf="!error.obj.dir" class="menu-icon iconame-ft-{{ error.obj.name | fileExtension : 'noDot' }}"></span>
    <span *ngIf="error.obj.dir" class="menu-icon iconame-ft-folder"></span>
  </div>
</ng-template>

<!-- File Name template -->
<ng-template #errorFileName let-error>
  <ng-container *ngIf="error.action === 'download'; then nameForDownloadError; else nameForBaseError"></ng-container>

  <ng-template #nameForDownloadError>
    <h6 *ngIf="error.files.length === 1" class="filename-wrap">
      <ng-container *ngIf="error.files[0].dir">
        <span class="file-name">{{ error.files[0].name | folderName }}</span>
      </ng-container>
      <ng-container *ngIf="!error.files[0].dir">
        <span class="file-name">{{ error.files[0].name | fileName }}</span>
        <span class="file-extension">{{ error.files[0].name | fileExtension }}</span>
      </ng-container>
    </h6>

    <h6 *ngIf="error.files.length > 1" class="filename-wrap">
      {{ 'errorMessages.downloadErrorMulti' | translate : { value: error.files.length } }}
    </h6>

    <h6 *ngIf="!error.files.length" class="filename-wrap">
      {{ 'actionFailed.download' | translate }}
    </h6>
  </ng-template>

  <ng-template #nameForBaseError>
    <h6 *ngIf="!error.obj.dir" class="filename-wrap" title="{{ error.obj.name }}">
      <span class="file-name">{{ error.obj.name | fileName }}</span>
      <span class="file-extension">{{ error.obj.name | fileExtension }}</span>
    </h6>

    <h6 *ngIf="error.obj.dir" class="foldername-wrap" title="{{ error.obj.name | folderName }}">
      {{ error.obj.name | folderName }}
    </h6>
  </ng-template>
</ng-template>

<!-- Error messages template -->
<ng-template #errorStatusTemplate let-error>
  <div
    title="{{ 'actionFailed.' + error.action | translate }} - {{ 'serverErrors.' + createErrorTitle(error.errorData) | translate }}"
    (click)="isEllipsisActive(error)"
    class="error-process"
  >
    <strong>{{ 'actionFailed.' + error.action | translate }}</strong>
    <span class="sp-bullet"></span>
    <ng-container *ngIf="error.errorData.name === 'HttpErrorResponse'; then errorNameText; else errorMessageText"></ng-container>

    <ng-template #errorMessageText>
      <span *ngIf="errorValues[error.errorData.message]">{{ 'serverErrors.' + error.errorData.message | translate }}</span>
      <span *ngIf="!errorValues[error.errorData.message]">{{ 'serverErrors.' + 'Error' | translate }}</span>
    </ng-template>
    <ng-template #errorNameText>
      <span>{{ 'serverErrors.' + error.errorData.name | translate }}</span>
    </ng-template>
  </div>
</ng-template>
