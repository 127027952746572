import { Pipe, PipeTransform } from '@angular/core';
import { ServerDetectionService } from '../../../services/server-detection.service';
import * as moment from 'moment';
import 'moment/min/locales';

@Pipe({
  name: 'formatDate',
  pure: false,
})
export class FormatDatePipe implements PipeTransform {
  constructor(private serverDetection: ServerDetectionService) {}
  server: boolean = this.serverDetection.isServer();

  transform(value: any, format: any, langCode): any {
    if (this.server) {
      return;
    }

    let bLang = navigator.language || navigator.languages[0];
    if (langCode) {
      bLang = langCode;
    }
    // Japan code fix
    if (bLang === 'jp') {
      bLang = 'ja';
    }
    // China code fix
    if (bLang === 'cn') {
      bLang = 'zh-cn';
    }

    moment.locale(bLang);

    if (format === 'timeOnly') {
      // return `${moment.unix(value / 1000).format('HH:mm A')}`;
      return `${moment.unix(value / 1000).format('LT')}`;
    } else if (format === 'inDays') {
      return `${moment.duration(value).asDays()}`;
    } else {
      let dateString = `${moment.unix(value / 1000).format('lll')}`;
      // Fix for BG months
      if (bLang === 'bg') {
        dateString = dateString.replace(/\W([^\d]+)\W/gim, (match, grp) => {
          if (grp === 'мар') {
            return ' Март ';
          }
          return ` ${grp.charAt(0).toUpperCase() + grp.slice(1)} `;
        });
      }
      return dateString;
    }
  }
}
