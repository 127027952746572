import { animate, trigger, style, transition, keyframes, state, AnimationTriggerMetadata } from '@angular/animations';

export const fadeRegularAnimation = (enterSpeed = 250, exitSpeed = 0) => {
  return trigger('fadeRegular', [
    transition(':enter', [style({ opacity: '0' }), animate(enterSpeed + 'ms linear', style({ opacity: '1' }))]),
    transition(':leave', [style({ opacity: '1' }), animate(exitSpeed + 'ms linear', style({ opacity: '0' }))]),
  ]);
};

export const viewStateAnimation = (enterSpeed = 800, exitSpeed = 800) => {
  return trigger('viewState', [
    state(
      'visible',
      style({
        opacity: '1',
      })
    ),
    state(
      'hidden',
      style({
        opacity: '0',
      })
    ),
    transition('* => visible', [animate(enterSpeed + 'ms linear')]),
    transition('* => hidden', [animate(exitSpeed + 'ms linear')]),
  ]);
};
