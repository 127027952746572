import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable, of } from 'rxjs';
import { LanguageManagerService } from './language-manager.service';

@Injectable()
export class SendEmailService {
  constructor(private http: HttpClient, private authService: AuthService, private languageManagerService: LanguageManagerService) {}

  sendInvites(mailsData) {
    return this.http.post(window.location.origin + '/mdapi/invite-friends', mailsData).pipe(
      map((res: HttpResponse<any>) => {
        if (res) {
          return res;
        } else {
          this.authService.handleErrors(res);
        }
      })
    );
  }

  isEmailValid(email: string, source: 'Invite a friend' | 'Share file'): Observable<any> {
    const mailValidation = new RegExp(
      [
        '^(([^<>()[\\]\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\.,;:\\s@"]+)*)',
        '|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.',
        '[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+',
        '[a-zA-Z]{2,}))$',
      ].join('')
    );

    if (mailValidation.test(email)) {
      const lang = this.languageManagerService.getCurrentLang();
      return this.authService.validateEmail(email, lang.code, source).pipe(
        map((res) => {
          const result = JSON.parse(res.result);
          if (result.type === 'invalid') {
            return false;
          } else {
            return true;
          }
        })
      );
    } else {
      return of(false);
    }
  }
}
