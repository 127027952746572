import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'folderName',
})
export class FolderNamePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value === null) {
      return 'My Files';
    }

    return value.replace(/\/$/g, '');
  }
}
