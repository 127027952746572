import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  HostListener,
  NgZone,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as Hammer from 'hammerjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { fadeRegularAnimation } from './app-animations/app-animations';
import { DataLayerService } from './services/data-layer.service';
import { LanguageManagerService } from './services/language-manager.service';
import { ILocationData, LocationService } from './services/location.service';
import { IProfileData, LoginService } from './services/login.service';
import { IPageMapItem, NavigationHandlerService } from './services/navigation-handler.service';
import { PageHelpersService } from './services/page-helpers.service';
import { ReportPageEventService } from './services/report-page-event.service';
import { ResponsiveLayoutService } from './services/responsive-layout.service';
import { ScrollItemsService } from './services/scroll-items.service';
import { ServerDetectionService } from './services/server-detection.service';
import { StorageStatusService } from './services/storage-status.service';
import { UploadDataService } from './services/upload/upload-data.service';
import { UserSessionService } from './services/user-session.service';

const DEV_LOGIN = environment.devLogin;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [fadeRegularAnimation(200, 300)],
})
export class AppComponent implements OnInit, AfterViewInit {
  mobileDevice: boolean = false;
  server: boolean = this.serverDetection.isServer();

  screenSize: string = '';
  hasInviteBanner = false;
  hasExceedBanner = false;
  langChangeLoading: boolean = false;
  profileData: IProfileData;

  isiPad: boolean = false;

  currentPage: any = {};

  bodyElem = document.getElementsByTagName('body')[0];
  scrollInProgressBar: boolean = false;

  htmlWrap: Element;
  timeZone: string;
  showConsentBanner: boolean = true;

  @ViewChild('modalWrapper', { read: ViewContainerRef, static: true }) viewContainerRef: ViewContainerRef;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private reportPageEventService: ReportPageEventService,
    private cdRef: ChangeDetectorRef,
    private loginSessionService: UserSessionService,
    private uploadDataService: UploadDataService,
    private locationService: LocationService,
    private responsiveLayoutService: ResponsiveLayoutService,
    private languageService: LanguageManagerService,
    private scrollItemsService: ScrollItemsService,
    private serverDetection: ServerDetectionService,
    private navigationHandlerService: NavigationHandlerService,
    private pageHelpersService: PageHelpersService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private storageStatusService: StorageStatusService,
    private zone: NgZone,
    private dataLayerService: DataLayerService
  ) {
    this.getUrlParams();
    // Device Reporter
    const di = this.deviceService.getDeviceInfo();
    const htmlElem = document.getElementsByTagName('html')[0];
    const isMobile = () => {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    };

    // Check for iPad 7th Gen
    if (navigator.userAgent.match(/Mac/g) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
      this.isiPad = true;
    }

    // Set User language
    this.languageService.initLanguage();

    // Set Device type data
    if ((isMobile() || this.isiPad) && !environment.production) {
      htmlElem.classList.add('mobile-device');
      this.mobileDevice = true;
    }

    if (this.deviceService.isMobile() || this.isiPad) {
      htmlElem.classList.add('mobile-device');
      this.mobileDevice = true;
    } else {
      Hammer.defaults.cssProps.userDrag = 'element';
    }

    htmlElem.classList.add(di.browser.toLowerCase());
    htmlElem.classList.add(`v-${di.browser_version}`);

    if (this.isiPad) {
      htmlElem.classList.add(`os-iOS`);
    } else {
      htmlElem.classList.add(`os-${di.os}`);
    }

    // Fix text selection for IE11
    if (di.browser.toLowerCase() === 'ie') {
      document.onselectstart = function () {
        return false;
      };
    }

    // POC For online/offline Mode
    window.addEventListener('load', () => {
      window.addEventListener('online', (e) => {
        console.log('Connection online');
        this.reportPageEventService.reportEvent('', 'closeErrorPopup', 'yesInternet');
      });
      window.addEventListener('offline', (e) => {
        this.reportPageEventService.reportEvent('errorMessages.noInternet', 'error', 'noInternet', false);
      });
    });
  }

  ngOnInit() {
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.showConsentBanner = this.timeZone.indexOf('Europe') === -1;
    this.loadModals();
    // Check for Malformed URI
    try {
      decodeURI(window.location.href);
    } catch (error) {
      this.router.navigate(['']);
    }

    if (!this.server) {
      // Set proper Responsive Layout
      this.responsiveLayoutService.startResponsiveListener();
      this.screenSize = this.responsiveLayoutService.getScreenSize();
      this.responsiveLayoutService.screenChange.subscribe((screen) => {
        this.screenSize = screen;
        this.cdRef.detectChanges();
      });
    }

    // Listen and apply data for Login User
    this.loginService.loggedInData.subscribe((result) => {
      this.profileData = result;
      if (!DEV_LOGIN) {
        // Start Session Checkers
        this.loginSessionService.startSessionManager();
      }

      this.cdRef.detectChanges();
    });

    // Listener for Scroll Progress Popup
    this.scrollItemsService.scrollProgressPopupEvent.subscribe((event) => {
      this.handleMobileScroll(event);
    });

    // Language Change
    this.languageService.startLanguageChange.subscribe((event) => {
      this.langChangeLoading = true;
      this.cdRef.detectChanges();
    });
    this.languageService.finishLanguageChange.subscribe((event) => {
      setTimeout(() => {
        this.langChangeLoading = false;
        this.cdRef.detectChanges();
      }, 700);
    });

    // Navigation End Handlers
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      // TODO move more navigation related logic to this service
      // Handle Page not found
      this.navigationHandlerService.checkForPageError();

      // Obtain page data
      const cp: IPageMapItem = this.navigationHandlerService.getPageData(event);
      if (cp) {
        this.currentPage = cp;
      }

      // check page with banner
      this.hasInviteBanner =
        this.profileData?.planFeatures?.storageTier === 0 &&
        !this.hasExceedBanner &&
        ['my-files', 'recent-files', 'shared'].includes(this.navigationHandlerService.currentPage?.name);

      // Hide context menu
      document.dispatchEvent(new Event('click'));
      this.cdRef.detectChanges();
    });
    this.storageStatusService.storageStatusUpdate.subscribe((storageStatus) => {
      this.hasExceedBanner =
        storageStatus.size > storageStatus.maximum && this.loginService.getProfileData().planFeatures.canBuyPersonal1000;
      this.hasInviteBanner =
        this.profileData?.planFeatures?.storageTier === 0 &&
        !this.hasExceedBanner &&
        ['my-files', 'recent-files', 'shared'].includes(this.navigationHandlerService.currentPage.name);
      this.cdRef.detectChanges();
    });
  }

  ngAfterViewInit() {
    this.htmlWrap = document.getElementsByClassName('main-wrapper')[0];
    if (this.mobileDevice) {
      this.zone.runOutsideAngular(() => {
        this.htmlWrap.addEventListener('touchmove', this.checkMobileBodyScroll.bind(this));
      });
    }
  }

  /* Add all modals dynamically
  ================================================== */
  async loadModals() {
    this.viewContainerRef.clear();
    const { ModalComponent } = await import('./components/modal/modal.component');
    this.viewContainerRef.createComponent(this.componentFactoryResolver.resolveComponentFactory(ModalComponent));
  }

  /* Handle Upload data from Upload Inputs
  ================================================== */
  uploadEvent(event): void {
    const parent: ILocationData = this.locationService.getUploadParent();
    if (parent.key === 'BA__CK__UPS') {
      return;
    }
    this.uploadDataService.uploadFromInput(event, parent);
  }

  toggleMainMenu() {
    this.pageHelpersService.toggleMainMenuEvent.emit(true);
  }

  // Prevent scroll on Body for mobile, when scroll in upload bar
  checkMobileBodyScroll(e): void {
    if (this.bodyElem.classList.contains('no-scroll') && !this.scrollInProgressBar) {
      e.preventDefault();
    }
  }

  handleMobileScroll(data): void {
    this.scrollInProgressBar = data;
  }

  /* Scroll Logic for Mobile Devices
  ================================================== */
  @HostListener('window:scroll', [])
  onWindowScroll() {
    // Check Native scroll for mobile devices only
    if (!document.getElementsByTagName('html')[0].classList.contains('mobile-device')) {
      return;
    }

    if (document.querySelector('.lang-container.open')) {
      return;
    }

    document.dispatchEvent(new Event('click')); // Close context popups for mobile
    this.scrollItemsService.calculateScroll('mobile');
  }

  private getUrlParams(): void {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const fireEvent = urlParams.get('fireEvent');
    const loginType = urlParams.get('loginType');
    console.log('Fire event', fireEvent);
    console.log('Login type', loginType);
    if (fireEvent && loginType) {
      this.dataLayerService.createPixelEvent('sign_up_mobidrive', { signup_method: loginType });
    }
  }
}
