import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ApplyActionService } from '../../../../services/apply-action.service';
import { IitemsSetType } from '../../../../models/common.int';

@Component({
  selector: 'app-restore-files',
  templateUrl: './restore-files.component.html',
  styleUrls: ['./restore-files.component.scss'],
})
export class RestoreFilesComponent implements OnInit, AfterViewInit {
  @Input() filesData: any;
  @Input() menuSource: any;
  @Input() modal: any;

  @ViewChild('confirmRestore') confirmRestore: ElementRef;

  onKeyPress: any;

  filesForDelete: number;
  fileName: string;
  itemsType: IitemsSetType;
  deleteType: string = ''; // file, files, folder, folders, items

  isFolder: boolean = false;
  loading: boolean = false;

  constructor(private applyActionService: ApplyActionService) {}

  ngOnInit() {
    // Determine delete message
    this.itemsType = this.filesData[0].dir ? 'folders' : 'files';
    this.filesForDelete = this.filesData.length;

    if (this.filesForDelete === 1) {
      this.fileName = `${this.filesData[0].name}`;
      this.isFolder = this.filesData[0].dir ? true : false;
    } else {
      for (let i = 1; i < this.filesForDelete; i++) {
        if (this.filesData[i].dir !== this.filesData[i - 1].dir) {
          this.itemsType = 'items';
          break;
        }
      }
    }

    // Show separate labels in Popup for different move actions
    if (this.itemsType === 'files') {
      this.deleteType = 'files';
      if (this.filesForDelete === 1) {
        this.deleteType = 'file';
      }
    } else if (this.itemsType === 'folders') {
      this.deleteType = 'folders';
      if (this.filesForDelete === 1) {
        this.deleteType = 'folder';
      }
    } else {
      this.deleteType = 'items';
    }
  }

  ngAfterViewInit() {
    // Focus Delete Button
    setTimeout(() => {
      this.confirmRestore.nativeElement.focus();
    }, 1);
  }

  restoreFiles() {
    this.applyActionService.restoreAction(this.filesData);
    this.modal.close();
  }
}
