import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ApplyActionService } from '../../../../services/apply-action.service';
import { fadeRegularAnimation } from '../../../../app-animations/app-animations';
import { LanguageManagerService } from '../../../../services/language-manager.service';
import { AuthService } from '../../../../services/auth.service';
import { IRefsMap } from '../../../../models/common.int';

@Component({
  selector: 'app-file-versioning',
  templateUrl: './file-versioning.component.html',
  animations: [fadeRegularAnimation()],
})
export class FileVersioningComponent implements OnInit {
  @Input() filesData: any;
  @Input() menuSource: any;
  @Input() modal: any;
  @Input() activeModal: string;

  @ViewChild('versionsList') versionsList: ElementRef;

  refs: IRefsMap = {};

  langCode: string = this.languageManagerService.getLangCode() || 'en';
  loading: boolean = true;
  versionError: boolean = false;
  fileHistory: any[] = [];
  mobileDevice: boolean = document.getElementsByTagName('html')[0].classList.contains('mobile-device');

  constructor(
    private applyActionService: ApplyActionService,
    private authService: AuthService,
    private languageManagerService: LanguageManagerService
  ) {}

  ngOnInit() {
    this.requestRevision();
  }

  requestRevision() {
    this.refs.authServiceRef = this.authService
      .authRequest('files', 'revisions', {
        id: this.filesData[0],
      })
      .subscribe(
        (result) => {
          this.loading = false;
          this.versionError = false;
          this.fileHistory = result;
          this.fileHistory.sort((a, b) => {
            return this.sortArray(a, b, 'idx', true);
          });
          this.refs.authServiceRef.unsubscribe();
        },
        (error) => {
          console.error(error);
          this.versionError = true;
          this.loading = false;
          this.refs.authServiceRef.unsubscribe();
        }
      );
  }

  sortArray(a, b, property, reverse = false) {
    let a_win = 1;
    let b_win = -1;
    if (reverse) {
      a_win = -1;
      b_win = 1;
    }

    if (a[property] < b[property]) {
      return b_win;
    }
    if (a[property] > b[property]) {
      return a_win;
    }
    return 0;
  }

  refreshVersions(event) {
    if (event.target.classList.contains('action')) {
      this.loading = true;
      this.requestRevision();
    }
  }

  downloadVersion(revision) {
    this.applyActionService.downloadAction(this.filesData, false, revision.id);
    return;
  }
}
