/*
 ** constants and default values for <tag-input>
 */

export const PLACEHOLDER = '+ Tag';
export const SECONDARY_PLACEHOLDER = 'Enter a new tag';
export const KEYDOWN = 'keydown';
export const KEYUP = 'keyup';
export const FOCUS = 'focus';
export const MAX_ITEMS_WARNING = 'The number of items specified was greater than the property max-items.';

export const ACTIONS_KEYS = {
  DELETE: 'DELETE',
  SWITCH_PREV: 'SWITCH_PREV',
  SWITCH_NEXT: 'SWITCH_NEXT',
  TAB: 'TAB',
};

export const KEY_PRESS_ACTIONS = {
  8: ACTIONS_KEYS.DELETE,
  37: ACTIONS_KEYS.SWITCH_PREV,
  39: ACTIONS_KEYS.SWITCH_NEXT,
  9: ACTIONS_KEYS.TAB,
};

export const DRAG_AND_DROP_KEY = 'Text';
export const NEXT = 'NEXT';
export const PREV = 'PREV';
