import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileExtension',
})
export class FileExtensionPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const pos = value.lastIndexOf('.');
    if (args === 'noDot') {
      return value.slice(pos + 1).toLowerCase();
    } else {
      return pos !== -1 ? value.slice(pos) : '';
    }
  }
}
