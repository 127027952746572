import { Component, OnInit, Input } from '@angular/core';
import { LanguageManagerService } from '../../../../services/language-manager.service';
import { TrackingService } from '../../../../services/tracking.service';

@Component({
  selector: 'app-change-language',
  templateUrl: './change-language.component.html',
})
export class ChangeLanguageComponent implements OnInit {
  @Input() filesData: any;
  @Input() menuSource: any;
  @Input() modal: any;

  languages: {
    lang: string;
    label: string;
    code: string;
    countries: string[];
  }[] = [];

  constructor(private languageService: LanguageManagerService, private trackingService: TrackingService) {}

  ngOnInit() {
    this.languages = this.languageService.getAllLangs();
  }

  changeLanguage(lang) {
    this.trackingService.trackEvent('web-app.account-settings', 'change-lang', 'lang.' + lang.code);
    this.languageService.setLanguage(lang.code);
    this.modal.close();
  }
}
