export function loadRecaptchaScript(siteKey: string, nonce?: string, async = true, defer = true) {
  const script = document.createElement('script');

  script.innerHTML = '';
  script.src = `https://www.google.com/recaptcha/enterprise.js?render=${siteKey}`;
  if (nonce) {
    script.nonce = nonce;
  }

  script.async = async;
  script.defer = defer;
  document.head.appendChild(script);

  return script;
}
