<section class="modal-wrap language-modal">
  <h5 class="modal-title">{{ 'modal.chooseLanguage' | translate }}</h5>

  <div class="modal-body cfix">
    <ul class="lang-menu">
      <li *ngFor="let lang of languages">
        <span (click)="changeLanguage(lang)">{{ lang.label }}</span>
      </li>
    </ul>
  </div>

  <footer class="modal-footer cfix">
    <button class="button col-blue medium-size" (click)="modal.close()">
      {{ 'modal.close' | translate }}
    </button>
  </footer>
</section>
