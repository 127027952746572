<div class="image-page-box refresh-page">
  <div class="content-wrap" [@fadeRegular]>
    <strong>{{ 'body.refreshPageTitle' | translate }}</strong>
    <ng-container *ngIf="messageType === 'refreshPage'">
      <p>
        <span (click)="refreshPage($event)" [innerHTML]="'body.refreshPageInfo' | translate"></span>
      </p>
    </ng-container>
    <ng-container *ngIf="messageType === 'pageNotFound'">
      <p>{{ 'serverErrors.faeEntryNotFound' | translate }}</p>
    </ng-container>
  </div>
</div>
