import { Directive, HostListener, Input, ElementRef, ChangeDetectorRef } from '@angular/core';
import { DragService } from '../../../services/drag-service.service';
import { UploadDataService } from '../../../services/upload/upload-data.service';

@Directive({
  selector: '[dropArea]',
})
export class DropDirective {
  constructor(
    private uploadDataService: UploadDataService,
    private dragService: DragService,
    private el: ElementRef,
    private cdRef: ChangeDetectorRef
  ) {}

  @Input()
  set dropArea(options: any) {
    if (options) {
      this.options = options;
    }
  }

  private options: any = {};

  @HostListener('dragover', ['$event'])
  onDragOver(event) {
    // Disable dragover for disabled files and inside drop folder area
    if (this.options.disable || this.options.zone === 'inside-drop-only') {
      return;
    }

    this.cdRef.detach();

    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = 'copy';

    const elem = this.el.nativeElement;

    if (elem.classList.contains('folder-drop-zone-js') && !elem.parentNode.classList.contains('drop-over')) {
      this.el.nativeElement.parentNode.classList.add('drop-over');
    } else if (!elem.classList.contains('folder-drop-zone-js')) {
      this.el.nativeElement.classList.add('drop-over');
    }
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event) {
    this.cdRef.detach();

    if (this.el.nativeElement.classList.contains('folder-drop-zone-js')) {
      this.el.nativeElement.parentNode.classList.remove('drop-over');
    } else {
      this.el.nativeElement.classList.remove('drop-over');
    }
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    if (this.options.disable || this.options.zone === 'inside-drop-only') {
      return;
    }

    this.cdRef.reattach();
    event.stopPropagation();
    event.preventDefault();

    const elements = document.getElementsByClassName('drop-over');
    while (elements.length > 0) {
      elements[0].classList.remove('drop-over');
    }

    this.handleDropData(event);
  }

  @HostListener('mouseup', ['$event'])
  mouseup(event) {
    if (event.which !== 1) {
      return;
    }

    this.cdRef.reattach();
    if (this.dragService.isActiveDrag() && !this.options.selected && (this.options.zone === 'folder' || this.options.zone === 'folder-p')) {
      const hostData = Object.assign({}, this.options.hostData);
      hostData.parent = Object.assign({}, this.options.hostData.parent);

      this.dragService.moveItems(hostData);
    }
  }

  handleDropData(event: DragEvent) {
    const parentData = Object.assign({}, this.options.hostData);
    parentData.parent = Object.assign({}, this.options.hostData.parent);

    this.uploadDataService.uploadFromDrop(event, parentData);
    this.dragService.endDragEvent.emit(true);
  }
}
