import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { LoginService } from '../../../../services/login.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-error-report',
  templateUrl: './error-report.component.html',
})
export class ErrorReportComponent implements OnInit {
  @Input() errorsData: any;
  @Input() menuSource: any;
  @Input() modal: any;

  mobileDevice: boolean = document.getElementsByTagName('html')[0].classList.contains('mobile-device');
  errorValues: [];
  upgradeLinkLable: string;
  mobisystemsUrl = environment.mobisystemsURL + '/mobidrive';

  constructor(private translate: TranslateService, private ngxModal: NgxSmartModalService, private loginService: LoginService) {}

  ngOnInit() {
    const planFeatures = this.loginService.getProfileData().planFeatures;
    this.upgradeLinkLable = planFeatures.storageTier === 0 ? 'base.upgrade' : 'base.learnMore';
    this.handleErrorMessage();
  }

  closeModal() {
    this.modal.close();
  }

  showFileLimitationModal(errorItem) {
    // Open modal and show errors
    this.ngxModal.setModalData({ view: 'file-size-error', files: errorItem }, 'actionModal');
    this.ngxModal.resetModalData('actionModal');
    this.ngxModal.getModal('actionModal').open();
  }

  createErrorTitle(errorData): string {
    if (errorData.name === 'HttpErrorResponse') {
      return errorData.name;
    } else {
      return errorData.message;
    }
  }

  handleErrorMessage() {
    this.translate.get('serverErrors').subscribe((data) => {
      this.errorValues = data;
    });
  }
}
