import { EventEmitter, Injectable, Output } from '@angular/core';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class PageHelpersService {
  @Output() toggleMainMenuEvent: EventEmitter<any> = new EventEmitter();

  constructor() {}

  setItemsBreadcrumbs(items: any[]) {
    items.forEach((item) => {
      if (!item.groupManager) {
        item.fileBreadcrumbs = [];
        if (!item.owner) {
          this.getFolderLevels(item.fileBreadcrumbs, item.parent);
        } else {
          item.fileBreadcrumbs.push({ otherOwner: item.owner, sharedUrl: item.shareInfo.publicShareLink });
        }
      }
    });

    return items;
  }

  getFolderLevels(breadcrumb, parent) {
    parent.name = parent.key && parent.name ? parent.name.replace(/\//g, '') : 'My Files';
    const response = { dir: true, url: '', ...parent };
    if (parent['parent'] !== null && parent['key'] !== 'BA__CK__UPS') {
      this.getFolderLevels(breadcrumb, parent['parent']);
    }
    breadcrumb.push(response);
    response['url'] = this.buildFolderUrl(parent, breadcrumb);
  }

  buildFolderUrl(parent, breadcrumb): string {
    if (!parent.key) {
      return '/my-files';
    }
    if (parent.key === 'BA__CK__UPS' || (breadcrumb[0] && breadcrumb[0].url.startsWith('/backups'))) {
      return parent['key'] !== 'BA__CK__UPS' ? '/backups/' + parent.key : '/backups';
    } else if (parent.key !== 'BA__CK__UPS') {
      return '/my-files/folder/' + parent.key;
    }
  }
}
