import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileName',
})
export class FileNamePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const pos = value.lastIndexOf('.');
    if (pos !== -1) {
      return value.slice(0, pos);
    } else {
      return value;
    }
  }
}
