import { Injectable } from '@angular/core';
import { ServerDetectionService } from '../services/server-detection.service';
import { removeURLParameter } from '../helpers/remove-url-param';
import { Params } from '@angular/router';

@Injectable()
export class UrlHandlerService {
  constructor(private serverDetection: ServerDetectionService) {}

  server: boolean = this.serverDetection.isServer();

  /* Get Params from URL
  ================================================== */
  getUrlParam(url, parameter) {
    const reg = new RegExp('[?&]' + parameter + '=([^&#]*)', 'i');
    const string = reg.exec(url);
    return string ? string[1] : undefined;
  }

  /* Remove url params
  ================================================== */
  removeURLParameter(url, parameter) {
    return removeURLParameter(url, parameter);
  }

  queryParamsToObj(ignoreParams: string[], params?: string): { [key: string]: string } {
    const paramsString = params ? params : window.location.search.split('?')[1];
    const queryParams = {};
    if (paramsString) {
      paramsString.split('&').forEach((paramPair) => {
        const paramSplit = paramPair.split('=');
        if (!ignoreParams.includes(paramSplit[0])) {
          queryParams[paramSplit[0]] = paramSplit[1];
        }
      });
    }
    return queryParams;
  }

  preserveParams(): string {
    if (this.server) {
      return;
    }
    const preservePrmsList = ['gclid'];
    const paramsString = window.location.search.split('?')[1];
    let preservedPrms: string = '';

    if (paramsString) {
      paramsString.split('&').forEach((item) => {
        const paramKey = item.split('=')[0];
        if (preservePrmsList.includes(paramKey) || paramKey.startsWith('utm_')) {
          preservedPrms += `${item}&`;
        }
      });
    }

    if (preservedPrms.length > 0) {
      preservedPrms = '?' + preservedPrms.slice(0, -1);
    }

    return preservedPrms;
  }

  cleanUrl() {
    if (this.server) {
      return;
    }

    setTimeout(() => {
      // setTimeout fix Mozilla bug
      const path: string = `${window.location.pathname.replace(/\/$/g, '')}${this.preserveParams()}`;
      history.replaceState({}, null, path);
    }, 0);
  }

  extractParams(params: Params): string {
    const ignoreParams = ['xchange', 'aid'];
    let extractedParams: string = '';

    for (const prm in params) {
      if (!ignoreParams.includes(prm)) {
        extractedParams += `${prm}=${params[prm]}&`;
      }
    }

    return extractedParams.slice(0, -1);
  }

  groupTrackingParams(params: Params, trackingFormatMode: boolean): string {
    const tParams: string[] = ['source', 'referrer', 'url'];
    let trackingParams: string = '';
    let otherParams: string = '';

    for (const prm in params) {
      if (tParams.includes(prm)) {
        trackingParams += trackingFormatMode ? `tracking[${prm}]=${params[prm]}&` : `${prm}=${params[prm]}&`;
      } else {
        otherParams += `${prm}=${encodeURIComponent(params[prm])}&`;
      }
    }

    return `${trackingParams}${otherParams}`.slice(0, -1);
  }
}
