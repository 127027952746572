import { Injectable } from '@angular/core';

@Injectable()
export class DisabledItemsManagerService {
  constructor() {}

  disabledFiles: any = {};
  disabledFilesArr: any[] = [];

  addDisabledFiles(files) {
    files.forEach((item) => {
      const fileOwnerId = item.owner ? item.owner.id : item.ownerProfile ? item.ownerProfile.id : null;
      let parent = fileOwnerId ? fileOwnerId : item.parent.key;
      if (parent === null) {
        parent = 'myfiles';
      }

      if (!this.disabledFiles[parent]) {
        this.disabledFiles[parent] = [];
      }

      this.disabledFilesArr.push(item);
      this.disabledFiles[parent].push(item.key);
    });
  }

  removeDisableFile(file) {
    const fileOwnerId = file.owner ? file.owner.id : file.ownerProfile ? file.ownerProfile.id : null;
    let parent = fileOwnerId ? fileOwnerId : file.parent.key;
    if (parent === null) {
      parent = 'myfiles';
    }

    if (this.disabledFiles[parent]) {
      const fileIndex = this.disabledFiles[parent].indexOf(file.key);
      if (fileIndex !== -1) {
        this.disabledFiles[parent].splice(fileIndex, 1);
      }
      if (this.disabledFiles[parent].length === 0) {
        delete this.disabledFiles[parent];
      }
      this.disabledFilesArr = this.disabledFilesArr.filter((el) => el.key !== file.key);
    }
  }

  getDisabledFiles(parentKey) {
    let parent = parentKey;
    if (parentKey === null) {
      parent = 'myfiles';
    }

    return this.disabledFiles[parent];
  }
}
