export interface promoConfig {
  banner: string;
  data: {
    title: string;
    supportedOS: string[],
    notSupportedOS: string[]
  };
  cookie: {
    name: string;
    state: boolean;
    days: number;
  };
}

export const promoBannersConfig: promoConfig[]  = [
  {
    banner: 'MobiDrive_Promo',
    data: {
      title: 'MobiDrive',
      supportedOS: [],
      notSupportedOS: ['ios'],
    },
    cookie: {
      name: 'MobiDrive_Promo',
      state: true,
      days: 360,
    },
  },
  // Currently disable FileCommander Promo banner
  // {
  //   banner: 'FileCommander_Promo',
  //   data: {
  //     title: 'File Commander',
  //     supportedOS: [],
  //     notSupportedOS: ['ios'],
  //   },
  //   cookie: {
  //     name: 'FileCommander_Promo',
  //     state: true,
  //     days: 360,
  //   },
  // },
]