<section class="modal-wrap delete-modal">
  <div class="modal-body cfix">
    <div class="files-to-delete">
      <p>{{ 'modal.sorryPlan' | translate }}</p>
      <p>{{ 'modal.pleaseContact' | translate }}</p>
      <br />
    </div>
  </div>

  <footer class="modal-footer cfix">
    <button class="button col-blue medium-size" (click)="contactSupport()">
      {{ 'header.navMenu.contactSupport' | translate }}
    </button>

    <button class="button col-grey medium-size" (click)="modal.close()">
      {{ 'modal.close' | translate }}
    </button>
  </footer>
</section>
