import { Injectable, EventEmitter, Output } from '@angular/core';

export interface ISearchCatItem {
  readonly icon: string;
  readonly nameCode: string;
  readonly files: string[];
  readonly key: string;
}
export interface ISavedSearchData {
  searchText: string;
  searchSuggestions: any[];
}

export interface ISearchCategories {
  [key: string]: ISearchCatItem;
}

export type ICategoryKey = 'pdf' | 'document' | 'speadsheet' | 'presentation' | 'image' | 'video';

@Injectable()
export class SearchService {
  @Output() searchKey: EventEmitter<any> = new EventEmitter();

  constructor() {}
  lastSearchData: ISavedSearchData;
  searchCategories: ISearchCategories = {
    pdf: {
      key: 'pdf',
      icon: 'pdf.svg',
      nameCode: 'pdfFiles',
      files: ['pdf'],
    },
    document: {
      key: 'document',
      icon: 'docx.svg',
      nameCode: 'documents',
      files: ['docx', 'doc', 'txt', 'docm', 'dotx', 'dot', 'dotm', 'docb', 'rtf', 'html'],
    },
    speadsheet: {
      key: 'speadsheet',
      icon: 'xlsx.svg',
      nameCode: 'speadsheets',
      files: ['xlsx', 'xml', 'csv', 'xls', 'xltx', 'xlsm', 'ods', 'csv', 'ots'],
    },
    presentation: {
      key: 'presentation',
      icon: 'pptx.svg',
      nameCode: 'presentations',
      files: ['pptx', 'ppt', 'pot', 'pps', 'potx', 'ppsx', 'pptm', 'potm', 'ppsm', 'odp', 'otp'],
    },
    image: {
      key: 'image',
      icon: 'image.svg',
      nameCode: 'photosAndImages',
      files: [
        'jpeg',
        'jpg',
        'jpe',
        'png',
        'gif',
        'bmp',
        'cod',
        'ief',
        'jfif',
        'svg',
        'tif',
        'tiff',
        'ras',
        'cmx',
        'ico',
        'pnm',
        'pbm',
        'pgm',
        'ppm',
        'rgb',
        'xbm',
        'xpm',
        'xwd',
      ],
    },
    video: {
      key: 'video',
      icon: 'video.svg',
      nameCode: 'videos',
      files: ['mp4', 'mov', 'flv', 'm3u8', 'ts', '3gp', 'mov', 'avi', 'wmv', 'mpeg', 'mpg', 'mkv'],
    },
  };

  getCategoryFiles(categoryKey: ICategoryKey): string[] {
    return this.searchCategories[categoryKey].files;
  }

  getSearchParams(params) {
    let searchText: string = params.s;
    let categoryFiles: string[] = [];

    if (params.s.indexOf('type:') !== -1) {
      const searchRegEx = new RegExp(`type:(${Object.keys(this.searchCategories).join('|')})`, 'g');
      const trimType = params.s.replace(searchRegEx, '');
      categoryFiles = this.getCategoryFiles(params.s.replace('type:', '').split(' ')[0]);
      searchText = trimType.replace(/(^\s+|\s+$)/g, '');
    }

    searchText = decodeURIComponent(searchText);
    if (searchText || categoryFiles.length) {
      this.searchKey.emit(decodeURIComponent(params.s));
    }

    return {
      searchKey: searchText,
      categoryKeys: categoryFiles,
    };
  }

  getSearchCategories(): ISearchCatItem[] {
    return Object.values(this.searchCategories);
  }

  getLastSearchData(): ISavedSearchData {
    return this.lastSearchData;
  }

  recordLastSearchData(searchData: ISavedSearchData) {
    this.lastSearchData = searchData;
  }
}
