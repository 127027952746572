<section class="modal-wrap delete-modal">
  <h5 class="modal-title">
    <span *ngIf="deleteType === 'file'">{{ 'modal.restoreFile' | translate }}</span>
    <span *ngIf="deleteType === 'files'">{{ 'modal.restoreFiles' | translate: { value: filesForDelete } }}</span>
    <span *ngIf="deleteType === 'folder'">{{ 'modal.restoreFolder' | translate }}</span>
    <span *ngIf="deleteType === 'folders'">{{ 'modal.restoreFolders' | translate: { value: filesForDelete } }}</span>
    <span *ngIf="deleteType === 'items'">{{ 'modal.restoreItems' | translate: { value: filesForDelete } }}</span>
  </h5>

  <div class="modal-body cfix">
    <div class="files-to-delete">
      <strong *ngIf="!isFolder">{{ fileName }}</strong>
      <strong *ngIf="isFolder">{{ fileName | folderName }}</strong>
    </div>
  </div>

  <footer class="modal-footer cfix">
    <button class="button col-blue medium-size" #confirmRestore [disabled]="loading" (click)="restoreFiles()">
      {{ 'actionMenu.restore' | translate }}
    </button>

    <button class="button col-grey medium-size" [disabled]="loading" (click)="modal.close()">
      {{ 'modal.cancel' | translate }}
    </button>
  </footer>
</section>
