import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { ActionMenuService } from '../../../../services/action-menu.service';
import { AuthService } from '../../../../services/auth.service';
import { GetFilesService } from '../../../../services/get-files.service';
import { FilePreviewService } from '../../../../services/file-preview.service';
import { searchInParents } from '../../../../helpers/search-in-parents';
import { LanguageManagerService } from '../../../../services/language-manager.service';
import { LoginService } from '../../../../services/login.service';
import { IRefsMap } from '../../../../models/common.int';

@Component({
  selector: 'app-file-properties',
  templateUrl: './file-properties.component.html',
  animations: [
    trigger('fadeRegular', [
      transition(':enter', [style({ opacity: '0' }), animate('200ms linear', style({ opacity: '1' }))]),
      transition(':leave', [style({ opacity: '1' }), animate('0ms linear', style({ opacity: '0' }))]),
    ]),
  ],
})
export class FilePropertiesComponent implements OnInit, OnDestroy {
  loading: boolean = true;
  currentFile: any = {};
  currentFileParent: any = null;
  fileOwnerInfo: any = null;
  ldItems: any[] = new Array(7);
  langCode: string = this.languageManagerService.getLangCode() || 'en';
  mobileDevice: boolean = document.getElementsByTagName('html')[0].classList.contains('mobile-device');
  fileOwner: boolean = false;

  refs: IRefsMap = {};

  @Input() set filesData(val) {
    this.setCurrentFileData(val[0]);
  }

  @Input() modal: any;
  @Input() menuSource: any;
  @Input() activeModal: any;

  constructor(
    public actionMenuService: ActionMenuService,
    private authService: AuthService,
    private getFilesService: GetFilesService,
    private filePreviewService: FilePreviewService,
    private loginService: LoginService,
    private languageManagerService: LanguageManagerService
  ) {}

  ngOnInit() {
    this.refs.getThumbnailsRef = this.getFilesService.getThumbnails.subscribe((thumbData) => {
      if (this.currentFile.key === thumbData.key) {
        this.currentFile.thumbImage = thumbData.imageUrl;
      }
    });
  }

  ngOnDestroy() {
    this.currentFile = null;
    // Clean refs
    for (const ref in this.refs) {
      if (this.refs[ref]) {
        this.refs[ref].unsubscribe();
      }
    }
  }

  setCurrentFileData(item) {
    if (!item) {
      return;
    }

    this.currentFile = item;

    // Request file Thumbnail
    if (!this.currentFile.dir && !this.currentFile.thumbImage) {
      this.getFilesService.requireThumbnails(this.currentFile);
    }

    this.refs.authServiceRef = this.authService
      .authRequest('files', 'details', {
        id: {
          key: this.currentFile.key,
          account: this.currentFile.account,
          parent: this.currentFile.parent,
          name: this.currentFile.name,
        },
      })
      .subscribe(
        (result) => {
          this.currentFile.modified = result.modified;
          this.currentFile.created = result.created;
          this.currentFile.users = result.users;
          this.fileOwnerInfo = result.ownerProfile;
          this.currentFile.parent = result.parent;
          this.currentFile.publiclyShared = result.publiclyShared;
          if (this.loginService.isLoggedIn()) {
            this.fileOwner = this.loginService.getProfileData().accountId === result.ownerProfile.id;
          }

          this.getParentFolder(this.currentFile.parent);
          this.loading = false;
        },
        (error) => {
          console.error(error);
        }
      );
  }

  getParentFolder(fileParent) {
    const fp = { ...fileParent };

    fp.regularFolder = this.isRegularFolder(fp);
    fp.url = this.getUrl(fp);
    this.currentFileParent = fp;

    if (!this.fileOwner) {
      this.currentFileParent.noLink = true;
    }
  }

  isRegularFolder(fp) {
    if (!fp.key) {
      return false;
    } else if (fp.key === 'BA__CK__UPS') {
      return false;
    } else {
      return true;
    }
  }

  getUrl(fp) {
    if (searchInParents({ parent: fp }, 'BA__CK__UPS')) {
      return fp.key === 'BA__CK__UPS' ? '/backups/' : '/backups/' + fp.key;
    } else {
      return fp.key ? '/my-files/folder/' + fp.key : '/my-files';
    }
  }

  openContainFolder() {
    this.modal.close();
    this.filePreviewService.closeFilePreview();
  }
}
