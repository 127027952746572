import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ProductVersionService } from '../../services/product-version.service';
import { IRefsMap } from '../../models/common.int';
import { LoginService, IProfileData } from '../../services/login.service';
import { IProfileMenuItem, UserProfileMenuService } from '../../services/user-profile-menu.service';
import { DeviceInfo } from 'ngx-device-detector';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() profileData: IProfileData;
  @Input() screen: string;
  @Input() set pageLocation(page) {
    this.staticPage = page.static || (['sharelink', 'shared-folder'].includes(this.pageName) && !this.profileData);
    this.pageName = page.name;
  }
  @Output() openMobileMenu = new EventEmitter();

  pageName: string = '';
  staticPage: boolean = false;
  refs: IRefsMap = {};

  deviceData: DeviceInfo = this.deviceService.getDeviceInfo();
  showNotifier: boolean = false;
  upgradeButton: IProfileMenuItem;
  staticMenuState: boolean = false;
  loginStatusLoading: boolean = true;

  constructor(
    private deviceService: DeviceDetectorService,
    private cdRef: ChangeDetectorRef,
    private productVersionService: ProductVersionService,
    private userProfileMenuService: UserProfileMenuService,
    private loginService: LoginService
  ) {}

  ngOnInit() {
    this.showNotifier = this.productVersionService.hasNewDriveVersion();
    this.refs.loggedInSignalRef = this.loginService.loggedInSignal.subscribe((data) => {
      this.loginStatusLoading = false;
      this.cdRef.detectChanges();
    });

    // LoggedIn status
    this.refs.loggedInDataRef = this.loginService.loggedInData.subscribe((data) => {
      if (data) {
        this.upgradeButton = this.userProfileMenuService.getProfileMenu(this.loginService.getProfileData()).shift();
      }
    });
  }

  ngOnDestroy() {
    // Clean refs
    for (const ref in this.refs) {
      if (this.refs[ref]) {
        this.refs[ref].unsubscribe();
      }
    }
  }

  openMobileSidebarMenu() {
    if (!this.staticPage) {
      this.openMobileMenu.emit(false);
    } else {
      this.openHeaderStaticMenu();
    }
  }

  openHeaderStaticMenu() {
    this.staticMenuState = !this.staticMenuState;

    const bodyElem = document.getElementsByTagName('body')[0];
    if (this.staticMenuState) {
      bodyElem.classList.add('no-scroll');
    } else {
      bodyElem.classList.remove('no-scroll');
    }

    this.cdRef.detectChanges();
  }
}
