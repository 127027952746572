<section class="modal-wrap move-files-modal">
  <move-files-title [view]="activeModal" [filesData]="{ type: itemsType, count: filesData.length }"> </move-files-title>

  <div class="modal-body cfix">
    <!-- Create Folder and Navigate Section -->
    <new-folder-creator (toggleFolderFormEvent)="folderFormIsOpen = $event" [loadingFolders]="loadingFolders"></new-folder-creator>

    <folder-list [activeModal]="activeModal" [filesData]="filesData" (canMoveNotification)="canMoveFiles($event)" (navigationInProcess)="loadingFolders=$event">
    </folder-list>
  </div>

  <footer class="modal-footer cfix">
    <button *ngIf="selectedFolder" class="button col-blue medium-size" [disabled]="!canMoveHere" (click)="moveItems()">
      <ng-container *ngIf="activeModal === 'move'">{{ 'modal.move' | translate }}</ng-container>
      <ng-container *ngIf="activeModal === 'copy'">{{ 'modal.copy' | translate }}</ng-container>
    </button>
    <button
      *ngIf="!selectedFolder"
      class="button col-blue medium-size"
      [disabled]="!canMoveHere && !differentParents"
      (click)="moveItems()"
    >
      <ng-container *ngIf="activeModal === 'move'">{{ 'modal.moveHere' | translate }}</ng-container>
      <ng-container *ngIf="activeModal === 'copy'">{{ 'modal.copyHere' | translate }}</ng-container>
    </button>

    <button class="button col-grey medium-size" (click)="modal.close()">
      {{ 'modal.cancel' | translate }}
    </button>
  </footer>
</section>
