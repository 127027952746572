<section class="modal-wrap head-image-mode file-size-error-modal">
  <ng-container *ngIf="storage">
    <h5 class="modal-title">
      <img src="../../../../../assets/images/upgrade-st-cloud.svg" alt="" />
    </h5>
    <div class="modal-body cfix">
      <div class="process-errors-wrap cfix" [perfectScrollbar] [disabled]="mobileDevice">
        <h6>{{ 'modal.activationSuccess' | translate }}</h6>
        <div class="text">
          <p>{{ 'modal.congratulations' | translate }}</p>
          <p [innerHTML]="'modal.storageAvailable' | translate: { storage: storage | byteSize: '2' : true }"></p>
        </div>
        <button class="button col-blue medium-size" (click)="closeModal(true)">
          {{ 'modal.close' | translate }}
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!storage">
    <h5 class="modal-title">
      <img src="../../../../../assets/images/ic-infoIllustration-day.svg" alt="" />
    </h5>
    <div class="modal-body cfix">
      <div class="process-errors-wrap cfix" [perfectScrollbar] [disabled]="mobileDevice">
        <div class="text">
          <p class="help-text" [innerHTML]="'modal.wentWrong' | translate: { link: helpCenterUrl }"></p>
        </div>
        <button class="button col-blue medium-size" (click)="closeModal(true)">
          {{ 'modal.close' | translate }}
        </button>
      </div>
    </div>
  </ng-container>
</section>
