<section class="modal-wrap file-size-error-modal" [ngClass]="{'head-image-mode': offerPlanMode}">
  <ng-container *ngIf="!offerPlanMode">
    <h5 class="modal-title">
      {{ 'modal.fileTooLarge' | translate }}
    </h5>
    <div class="modal-body cfix">
      <div *ngIf="fileData" class="text">
        <p [innerHTML]="'modal.fileExceed' | translate: { fileName: fileData.name, sizeLimit: fileUploadLimit | byteSize }"></p>
      </div>
    </div>

    <footer class="modal-footer cfix">
      <button class="button col-grey medium-size" (click)="closeModal(false)">
        {{ 'modal.close' | translate }}
      </button>
    </footer>
  </ng-container>

  <ng-container *ngIf="offerPlanMode">
    <h5 class="modal-title">
      <img src="../../../../../assets/images/upgrade-st-cloud.svg" alt="" />
    </h5>

    <div class="modal-body cfix">
      <div class="process-errors-wrap cfix" [perfectScrollbar] [disabled]="mobileDevice">
        <h6>{{ 'modal.fileTooLarge' | translate }}</h6>

        <div *ngIf="fileData" class="text">
          <p [innerHTML]="'modal.fileExceed' | translate: { fileName: fileData.name, sizeLimit: fileUploadLimit | byteSize }"></p>
          <p>{{ 'modal.unlimitedUploads' | translate }}</p>
        </div>

        <button class="button col-blue medium-size" (click)="closeModal(true)">
          {{ 'base.upgrade' | translate }}
        </button>
      </div>
    </div>
  </ng-container>
</section>


