import { Injectable } from '@angular/core';
import { UploadManagerService } from '../upload/upload-manager.service';
import { FileValidationService } from '../file-validation.service';
import { ReportPageEventService } from '../report-page-event.service';
import { guideGenerator } from '../../helpers/guid-generator';
import { ILocationData } from '../location.service';
import { IFileUploadData } from '../../models/files.int';

declare global {
  interface Window {
    DataTransferItem: any;
  }
}

export interface IHTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

@Injectable()
export class UploadDataService {
  constructor(
    private uploadManagerService: UploadManagerService,
    private reportPageEventService: ReportPageEventService,
    private fileValidationService: FileValidationService
  ) {}

  uploadFromInput(event: IHTMLInputEvent, parentData: ILocationData): void {
    let isFolder: boolean = false;
    if (!this.fileValidationService.ie11Browser()) {
      isFolder = !!event.target.files[0]['webkitRelativePath'].length;
    }

    if (isFolder) {
      const folderContent: any = this.generateFolderStructure(event.target.files);
      this.folderUpload(folderContent, parentData);
    } else {
      const files: IFileUploadData[] = this.prepareFiles([...event.target.files], parentData);
      this.uploadManagerService.uploadFiles(files);
    }
  }

  uploadFromDrop(event: DragEvent, parentData: ILocationData): void {
    if (event.dataTransfer.items) {
      const items = event.dataTransfer.items;
      if (items.length === 0) {
        return;
      }

      for (let i = 0; i < items.length; i++) {
        const item = items[i].webkitGetAsEntry();
        if (item.isDirectory) {
          // If item is Folder analyze folder structure and send final data for upload
          this.readFolderStructure(item).then((folderContent: any) => {
            this.folderUpload(folderContent, parentData);
          });
        } else {
          const files: IFileUploadData[] = this.prepareFiles([items[i].getAsFile()], parentData);
          this.uploadManagerService.uploadFiles(files);
        }
      }
    } else {
      const filesData = event.dataTransfer ? event.dataTransfer.files : null;
      const files: IFileUploadData[] = this.prepareFiles([...filesData], parentData);
      this.uploadManagerService.uploadFiles(files);
    }
  }

  /* Prepare Files for Upload 
  ================================================== */
  prepareFiles(files: File[], parent: ILocationData): IFileUploadData[] {
    const filesForUpload: IFileUploadData[] = [];

    // IE 11 Folder Check
    const ieFolderError = this.fileValidationService.ie11FolderError(files);
    if (ieFolderError.error) {
      this.reportPageEventService.reportEvent('errorMessages.' + ieFolderError.reason, 'error');
      return filesForUpload;
    }

    for (const file of files) {
      if (this.validateFile(file)) {
        filesForUpload.push({ file: file, parent: parent });
      }
    }

    return filesForUpload;
  }

  validateFile(file): boolean {
    const isValid = this.fileValidationService.validate(file);
    if (isValid.status) {
      return true;
    } else {
      this.reportPageEventService.reportEvent('errorMessages.' + isValid.reason, 'error');
      return false;
    }
  }

  /* Manage Folder Upload 
  ================================================== */
  folderUpload(folderContent, parentData: ILocationData) {
    folderContent.rootFolder = true;
    folderContent.fid = guideGenerator(); // Attach uniq Id to the folder
    const folder = { file: folderContent, parent: parentData };
    this.uploadManagerService.uploadFolders(folder);
  }

  readFolderStructure(item): Promise<any> {
    // Collect Folder data
    const folderContent = {
      folder: item,
      content: [],
      totalSize: 0,
      uploadedItems: 0,
      numberOfItems: 1,
      completedItems: 0,
      originalName: item.name,
      subItemsErrors: [],
      errorRetryItems: [],
    };
    /* eslint-disable */
    const extractFolderContent = (directoryReader, entriesPromises, folderRef) => {
      directoryReader.readEntries((entries) => {
        if (entries.length > 0) {
          for (const entry of entries) {
            entriesPromises.push(traverseFolderTree(entry, entry.fullPath, folderRef));
          }
          extractFolderContent(directoryReader, entriesPromises, folderRef);
        }
      });
    };

    // Recursive function for folders
    const traverseFolderTree = (item, fullPath, folderRef) => {
      return new Promise((resolve) => {
        if (item.isDirectory) {
          item.dir = true;
          folderContent.numberOfItems += 1;
          const subFolderContent = { folder: item, content: [] };
          folderRef.content.push(subFolderContent);

          const directoryReader = item.createReader();
          const entriesPromises2 = [];
          extractFolderContent(directoryReader, entriesPromises2, subFolderContent);
          resolve(Promise.all(entriesPromises2));
        } else {
          item.file((file) => {
            this.addFileInFolder(file, fullPath, folderContent, folderRef);
            resolve(file);
          });
        }
      });
    };

    // Return a Promise which will be resolved when all Folder data is collected
    return new Promise((resolve, reject) => {
      const entriesPromises = [];
      const directoryReader = item.createReader();

      extractFolderContent(directoryReader, entriesPromises, folderContent);

      // When went threw all the folders content resolve that data
      Promise.all(entriesPromises).then((entries) => {
        resolve(folderContent);
      });
    });

    /* eslint-enable */
  }

  generateFolderStructure(data) {
    const folderContent = [
      {
        folder: {},
        content: [],
        totalSize: 0,
        uploadedItems: 0,
        numberOfItems: 1,
        completedItems: 0,
        originalName: '',
        subItemsErrors: [],
        errorRetryItems: [],
      },
    ];

    for (const file of data) {
      const pathDataArray = file.webkitRelativePath.split('/');
      pathDataArray.pop();
      this.populateFolder(folderContent[0], folderContent, pathDataArray, file);
    }

    return folderContent[0];
  }

  populateFolder(rootFolder, hostFolder, path, file): void {
    hostFolder.map((folder, index) => {
      if (folder.folder && folder.folder.name === path[0]) {
        folder.folder.dir = true;

        path.shift();
        this.createSubFolder(path, file, rootFolder, folder);
      } else if (folder.folder && !folder.folder.name) {
        folder.folder = {
          name: path[0],
        };
        folder.originalName = path[0];

        path.shift();
        this.createSubFolder(path, file, rootFolder, folder);
      }
    });
  }

  createSubFolder(path, file, rootFolder, folder) {
    // Add file to the folder
    if (path.length === 0) {
      this.addFileInFolder(file, file.webkitRelativePath, rootFolder, folder);
    }

    if (path.length > 0) {
      // Check if next folder exist
      const checkNextFolderLevel = folder.content.filter((item) => {
        return item.folder && item.folder.name === path[0];
      });

      if (checkNextFolderLevel.length) {
        this.populateFolder(rootFolder, folder.content, path, file);
      } else {
        const subFolderContent = { folder: { name: path[0] }, content: [] };
        folder.content.push(subFolderContent);
        rootFolder.numberOfItems += 1;
        this.populateFolder(rootFolder, folder.content, path, file);
      }
    }
  }

  addFileInFolder(file, filePath, rootFolder, hostFolder): void {
    const isValid = this.fileValidationService.validate(file);
    file.path = filePath;
    file.fromFolder = true;

    if (isValid.status) {
      hostFolder.content.push({ file: file });
      rootFolder.totalSize += file.size;
      rootFolder.numberOfItems += 1;
    } else {
      this.reportPageEventService.reportEvent('errorMessages.' + isValid.reason, 'error');
    }
  }

  canUploadFolder(): boolean {
    if (
      window.DataTransferItem &&
      window.DataTransferItem.prototype.webkitGetAsEntry &&
      !document.getElementsByTagName('html')[0].classList.contains('mobile-device')
    ) {
      return true;
    } else {
      return false;
    }
  }

  cleanInput(): void {
    if (this.fileValidationService.ie11Browser()) {
      (<HTMLInputElement>document.getElementById('uplFile')).type = 'text';
      (<HTMLInputElement>document.getElementById('uplFile')).type = 'file';
    } else {
      (<HTMLInputElement>document.getElementById('uplFile')).value = '';
      (<HTMLInputElement>document.getElementById('uplFolder')).value = '';
    }
  }
}
