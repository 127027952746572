import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { fadeRegularAnimation } from '../../../../../../app-animations/app-animations';
import { promoConfig } from '../../../../../../configs/promo-banner-configs';
import { TrackingService } from '../../../../../../services/tracking.service';

@Component({
  selector: 'app-mobi-drive',
  templateUrl: './mobi-drive.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeRegularAnimation(350, 250)],
})
export class MobiDriveComponent {
  @Output() bannerClosed = new EventEmitter();
  @Input() bannerData: promoConfig;
  @Input() bannerUIClass: string = '';

  showPromo: boolean = true;

  constructor(private trackingService: TrackingService) {}

  promoClose() {
    this.trackingService.trackEvent('MobiDrive Banner', 'Close Banner');
    this.showPromo = false;
    this.bannerClosed.emit(this.bannerData?.cookie);
  }

  getApp(e, platform: string) {
    e.preventDefault();
    let pageUrl: string = window.location.href;
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);

    if (urlParams.has('originalUrl')) {
      const origin = window.location.origin;
      pageUrl = origin + urlParams.get('originalUrl');
    }

    this.openStoreLink(pageUrl, platform);
  }

  openStoreLink(pageUrl: string, platform: string) {
    const mobiDriveStoreLink =
      //eslint-disable-next-line max-len
      'https://play.google.com/store/apps/details?id=com.mobisystems.mobidrive&referrer=' +
      encodeURIComponent(
        'utm_source=MyFilesInstallBanner&utm_campaign=InstallLinksOct2023' + (this.isOnSharePage(pageUrl) ? '&utm_content=' + pageUrl : '')
      );
    this.trackingService.trackEvent('website.download', platform, 'content.button');
    window.open(mobiDriveStoreLink, '_blank');
  }

  isOnSharePage(pageUrl: string) {
    return pageUrl.indexOf('sharelink') !== -1 || pageUrl.indexOf('shared-folder') !== -1;
  }
}
