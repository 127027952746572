<!-- REGULAR VIEW
============================================================== -->
<div class="regular-view cfix" *ngIf="!screen.includes('mobile')">
  <logo-area [view]="'regular'" [staticPage]="staticPage" [pageName]="pageName" [hasProfileData]="!!profileData"></logo-area>
  <header-static-menu
    *ngIf="staticPage && pageName !== 'cloud-storage-plans'"
    [screen]="screen"
    [pageName]="pageName"
    [profileData]="profileData"
  ></header-static-menu>
  <search-bar *ngIf="profileData && !staticPage" [pageName]="pageName" [mobileView]="screen.includes('mobile')"></search-bar>

  <div *ngIf="pageName !== 'cloud-storage-plans'" class="user-area-menus" [ngClass]="{ loading: loginStatusLoading }">
    <span class="loading-layer"></span>
    <div *ngIf="!profileData" class="lang-menu">
      <lang-static-menu></lang-static-menu>
    </div>
    <profile-menu [profileData]="profileData" [showNotifier]="showNotifier" [staticPage]="staticPage"></profile-menu>
  </div>
</div>

<!-- MOBILE VIEW
  ============================================================== -->
<div class="mobile-view cfix" *ngIf="screen.includes('mobile')">
  <logo-area [view]="'mobile'" [staticPage]="staticPage" [pageName]="pageName" [hasProfileData]="!!profileData"></logo-area>
  <header-static-menu
    *ngIf="staticPage && pageName !== 'cloud-storage-plans'"
    [screen]="screen"
    [pageName]="pageName"
    [openMenu]="staticMenuState"
    (closeMenuEvent)="openHeaderStaticMenu()"
  ></header-static-menu>
  <div *ngIf="pageName !== 'cloud-storage-plans'" class="ham-button" [ngClass]="{ hidden: !profileData }">
    <span
      class="button col-menu open-sidebar-button"
      [ngClass]="{ 'notify-dot': showNotifier }"
      (click)="openMobileSidebarMenu()"
      [keyboardActions]
      tabindex="0"
    >
      <span *ngIf="staticMenuState" class="menu-icon iconame-close"></span>
      <span *ngIf="!staticMenuState" class="menu-icon iconame-hamburger-menu"></span>
    </span>
  </div>

  <div
    *ngIf="pageName !== 'cloud-storage-plans'"
    class="user-area-menus"
    [ngClass]="{ loading: loginStatusLoading, 'share-align': !profileData }"
  >
    <span class="loading-layer"></span>
    <div *ngIf="!profileData" class="lang-menu">
      <lang-static-menu></lang-static-menu>
    </div>
    <profile-menu
      *ngIf="staticPage || !profileData"
      [profileData]="profileData"
      [showNotifier]="showNotifier"
      [staticPage]="staticPage"
    ></profile-menu>
  </div>
  <div class="ua-item dd-menu" *ngIf="profileData && !staticPage && upgradeButton">
    <upgrade-button [upgradeButton]="upgradeButton"></upgrade-button>
  </div>
  <search-bar *ngIf="profileData && !staticPage" [pageName]="pageName" [mobileView]="screen.includes('mobile')"></search-bar>

  <div class="upload-button" *ngIf="profileData && !staticPage">
    {{ pageName !== 'shared-folder' }}
    <upload-button view="mobile-view"></upload-button>
  </div>
</div>
