import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {TrackingService} from '../../../../services/tracking.service';
import {LoginService} from '../../../../services/login.service';
import {StorageStatusService} from '../../../../services/storage-status.service';
import {IRefsMap} from '../../../../models/common.int';
import {LanguageManagerService} from '../../../../services/language-manager.service';
import {ServerDetectionService} from '../../../../services/server-detection.service';

@Component({
  selector: 'app-exceeded-banner',
  templateUrl: './exceeded-banner.component.html',
  styleUrls: ['./exceeded-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ExceededBannerComponent implements OnInit, OnDestroy {
  @Input() hasExtraMenu: boolean = false;
  @Input() isStatic: boolean = false;
  public isShowBanner: boolean = false;
  private refs: IRefsMap = {};

  constructor(private router: Router,
              private trackingService: TrackingService,
              private loginService: LoginService,
              private cdRef: ChangeDetectorRef,
              private languageManagerService: LanguageManagerService,
              private serverDetectionService: ServerDetectionService,
              private storageStatusService: StorageStatusService) {}

  ngOnInit(): void {
    if (!this.serverDetectionService.isServer()) {
      this.refs.loggedInData = this.loginService.loggedInData.subscribe((result) => {
        const hasUpgradePlan = this.loginService.getProfileData()?.planFeatures.canBuyPersonal1000;
        if (hasUpgradePlan) {
          if (Object.keys(this.storageStatusService.getStorageStatus()).length) {
            const storageStatus = this.storageStatusService.getStorageStatus();
            this.isShowBanner = (storageStatus.size > storageStatus.maximum) && hasUpgradePlan;
            if (this.isShowBanner) {
              this.trackingService.trackEvent(
                'web-app.widget.conv-window',
                'premium-banner.storage-exceeded',
                'premium-banner.storage-exceeded.view'
              );
            }
            this.cdRef.detectChanges();
          } else {
            this.refs.storageStatusUpdateRef = this.storageStatusService.storageStatusUpdate.subscribe((storageStatus) => {
              this.isShowBanner = (storageStatus.size > storageStatus.maximum) && hasUpgradePlan;
              if (this.isShowBanner) {
                this.trackingService.trackEvent(
                  'web-app.widget.conv-window',
                  'premium-banner.storage-exceeded',
                  'premium-banner.storage-exceeded.view'
                );
              }
              this.cdRef.detectChanges();
            });
          }
        }
      });
    }

    this.refs.finishLanguageChange = this.languageManagerService.finishLanguageChange.subscribe(() => {
      this.cdRef.detectChanges();
    });
  }

  upgradeStorage(event): void {
    if (event.target.classList.contains('upgrade')) {
      if (this.isShowBanner) {
        this.trackingService.trackEvent(
          'web-app.widget.conv-window',
          'premium-banner.storage-exceeded',
          'premium-banner.storage-exceeded.click'
        );
      }
      this.router.navigate(['/pricing']);
    }
  }

  ngOnDestroy() {
    // Clean refs
    for (const ref in this.refs) {
      if (this.refs[ref]) {
        this.refs[ref].unsubscribe();
      }
    }
  }
}
