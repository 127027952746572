import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import { fadeRegularAnimation } from '../../../../../app-animations/app-animations';
import { GetFilesService } from '../../../../../services/get-files.service';
import { AuthService } from '../../../../../services/auth.service';
import { LocationService } from '../../../../../services/location.service';
import { MoveFilesService } from '../../../services/move-files.service';
import { IRefsMap, IitemsSetType } from '../../../../../models/common.int';

export interface ICanMoveData {
  canMove: boolean;
  selectedFolder: boolean;
}
@Component({
  selector: 'folder-list',
  templateUrl: './folder-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeRegularAnimation(250, 0)],
})
export class FolderListComponent implements OnInit, OnDestroy {
  @ViewChild('foldersList') foldersList: ElementRef;

  @Input() filesData: any;
  @Input() activeModal: string;

  @Output() canMoveNotification = new EventEmitter<ICanMoveData>();
  @Output() navigationInProcess = new EventEmitter<boolean>();

  filesParent: any;

  loadingFolders: boolean = true;

  folderList: any[] = [];
  folderLocation: any;
  destination: any;

  lockScroll: boolean = false;
  loadScroll: boolean = false;

  pageCursor: any = null;
  refs: IRefsMap = {};

  canMoveHere: boolean = false;
  selectedFolder: boolean = false;

  itemsType: IitemsSetType;
  differentParents: boolean = false;
  sharedResource: boolean = false;

  mobileDevice: boolean = document.getElementsByTagName('html')[0].classList.contains('mobile-device');

  constructor(
    private getFilesService: GetFilesService,
    private authService: AuthService,
    private cdRef: ChangeDetectorRef,
    private locationService: LocationService,
    private moveFilesService: MoveFilesService
  ) {}

  ngOnInit(): void {
    this.refs.getFilesServiceRef = this.getFilesService.getFiles.subscribe((data) => {
      // When new files are added to the fileList
      if (data.dirsOrFiles === 'dirsOnly') {
        this.pageCursor = data.status.cursor;
        this.updateFolderList(data);
      }
    });

    this.refs.navigationRequestEvent = this.moveFilesService.openFolder.subscribe((navData) => {
      this.navigateToFolder(navData.folder, navData.key);
    });

    this.refs.folderCreationEvent = this.moveFilesService.startFolderCreation.subscribe(() => {
      this.loadingFolders = true;
      this.folderList = [];
      this.canMoveNotification.emit({ canMove: false, selectedFolder: this.selectedFolder });
      this.navigationInProcess.emit(true);
      this.cdRef.detectChanges();
    });

    this.filesParent = this.filesData[0].parent;
    this.folderLocation = this.moveFilesService.getCurrentLocation();

    const moveProps = this.moveFilesService.getMoveProps();
    this.itemsType = moveProps.itemsType;
    this.differentParents = moveProps.differentParents;
    this.sharedResource = moveProps.sharedResource;

    const entryFolder = moveProps.sharedResource ? null : this.folderLocation.key;
    this.requestFoldersList(entryFolder, true);
    this.canMoveChecker();
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    // Clean refs
    for (const ref in this.refs) {
      if (this.refs[ref]) {
        this.refs[ref].unsubscribe();
      }
    }
  }

  requestFoldersList(parentKey: string | null = '', newRequest: boolean = false) {
    // If new page request
    if (newRequest) {
      this.pageCursor = null;
    }

    this.getFilesService.getFilesRequest({
      root: {
        accountId: this.authService.getUserAuth().accountId,
        key: parentKey,
      },
      sortData: {
        filterBy: 'name',
        direction: 'asc',
      },
      dirsOrFiles: 'dirsOnly',
      cursor: this.pageCursor,
    });
  }

  updateFolderList(data) {
    this.lockScroll = data.lastPage ? true : false;
    this.loadScroll = false;

    this.folderList = this.folderList.concat(data.status.items);
    if (this.folderList.length) {
      this.checkSameLevelFolders();
    }

    this.loadingFolders = false;
    this.canMoveChecker();
    this.cdRef.detectChanges();
  }

  openFolder(folder) {
    if (folder.disabled) {
      return;
    }

    const folderLocationData = {
      account: folder.account,
      name: folder.name,
      key: folder.key,
      parent: folder.parent,
    };

    this.navigateToFolder(folderLocationData, folder.key);
  }

  navigateToFolder(folderData, loadContentKey: string | null = '') {
    this.folderList = [];
    this.selectedFolder = false;
    this.loadingFolders = true;

    this.folderLocation = folderData;
    this.destination = folderData;
    this.moveFilesService.updateFolderLocation(this.folderLocation);

    if (loadContentKey !== '') {
      // Load folder content for existing folders
      this.requestFoldersList(loadContentKey, true);
    } else {
      // For new folder set previous parent
      this.loadingFolders = false;
    }

    this.canMoveNotification.emit({ canMove: false, selectedFolder: this.selectedFolder });
    this.navigationInProcess.emit(true);
  }

  checkSameLevelFolders() {
    if (this.itemsType !== 'files') {
      for (let i = 0; i < this.filesData.length; i++) {
        if (this.filesData[i].dir) {
          for (let j = 0; j < this.folderList.length; j++) {
            if (this.folderList[j].key === this.filesData[i].key) {
              this.folderList[j].disabled = true;
            }
          }
        }
      }
    }
  }

  selectFolder(e, key) {
    this.destination = this.locationService.getCurrentParent();
    this.selectedFolder = false;

    this.folderList.forEach((folder) => {
      if (folder.key === key && !folder.selected) {
        folder.selected = true;
        this.selectedFolder = true;
        this.destination = folder;
      } else {
        folder.selected = false;
      }

      this.moveFilesService.setDestination(this.destination);
      this.canMoveChecker();
    });
  }

  canMoveChecker() {
    if (this.selectedFolder) {
      this.canMoveHere = true;
    } else {
      this.canMoveHere = true;
      if (this.activeModal === 'move') {
        this.canMoveHere = this.folderLocation.key === this.filesParent.key ? false : true;
      }
    }

    this.canMoveNotification.emit({ canMove: this.canMoveHere, selectedFolder: this.selectedFolder });
    this.navigationInProcess.emit(false);
  }

  onListScroll() {
    const scrollFromTop = this.foldersList.nativeElement.scrollTop;
    let containerHeight = this.foldersList.nativeElement.clientHeight;
    const scrollHeight = this.foldersList.nativeElement.scrollHeight;

    if (this.mobileDevice) {
      containerHeight = this.foldersList.nativeElement.offsetHeight;
    }

    const triggerBefore = 250;
    const triggerPoint = scrollFromTop + containerHeight + triggerBefore;

    if (triggerPoint >= scrollHeight && !this.lockScroll) {
      this.requestFoldersList(this.folderLocation.key, false);

      this.lockScroll = true;
      this.loadScroll = true;
    }
    this.cdRef.detectChanges();
  }
}
