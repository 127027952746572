import { Injectable, EventEmitter, Output, Directive } from '@angular/core';

@Injectable()
export class LastSyncedTimeService {
  constructor() {}

  @Output() syncEvent: EventEmitter<any> = new EventEmitter();

  setSyncTime() {
    const date = new Date();
    this.syncEvent.emit(date.getTime());
  }
}
