//Documentation for Deep links
//https://firebase.google.com/docs/dynamic-links/create-manually

export const applicationConfigs = {
  android: {
    openWithOffice: {
      domain: 'https://officesuite.com/',
      page: 'mobisystems.page.link',
      apn: 'com.mobisystems.office',
      amvOpenFolder: '',
      amvOpenFile: 27800,
      amvSharelink: 14562,
    },
    openWithPdfExtra: {
      domain: 'https://pdfextra.com/',
      page: 'mobiscanner.page.link',
      apn: 'com.mobisystems.mobiscanner',
      amvOpenFolder: '',
      amvOpenFile: '',
      amvSharelink: '',
    },
    openWithMobiDrive: {
      domain: 'https://mobidrive.com/',
      page: 'mobidrivems.page.link',
      apn: 'com.mobisystems.mobidrive',
      amvOpenFolder: '',
      amvOpenFile: '',
      amvSharelink: '',
    },
    openWithFileCommander: {
      domain: 'https://mobisystems.com/',
      page: 'filecommander.page.link',
      apn: 'com.mobisystems.fileman',
      amvOpenFolder: 30013,
      amvOpenFile: '',
      amvSharelink: 22750,
    },
  },
  ios: {
    openWithOffice: {
      domain: 'https://officesuite.com/',
      page: 'mobisystems.page.link',
      ibi: 'com.mobisystems.OfficeSuiteFree2',
      isi: 924005506, // ISI for ios only
      amvOpenFolder: '',
      amvOpenFile: '',
      amvSharelink: '',
    },
    openWithPdfExtra: {
      domain: 'https://pdfextra.com/',
      page: 'pdfextra.page.link',
      ibi: 'com.mobisystems.PDFExtra',
      isi: 1527395366, // ISI for ios only
      amvOpenFolder: '',
      amvOpenFile: '',
      amvSharelink: '',
    },
    openWithMobiDrive: {
      domain: 'https://mobidrive.com/',
      page: 'mobidrivems.page.link',
      ibi: 'com.mobisystems.MobiDrive',
      isi: 1558848373, // ISI for ios only
      amvOpenFolder: '',
      amvOpenFile: '',
      amvSharelink: '',
    },
  },
}
