import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { LoginService, IPlanFeatures } from './login.service';
import { ActionMenuService } from './action-menu.service';
import { GetFilesService } from './get-files.service';
import { LocationService, ILocationData } from './location.service';
import { SelectFilesService } from './select-files.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ReportPageEventService } from './report-page-event.service';
import { Router } from '@angular/router';
import { searchInParents } from '../helpers/search-in-parents';
import { ManageCookieService } from './manage-cookie.service';
import { NavigationHandlerService } from './navigation-handler.service';
import { IPageSettingsOptions, IFilePageFilter } from '../models/pages.int';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
export interface IPageLocationData {
  routeKey?: string; // Parent Folder Key
  pageData?: any;
}

export interface IPageInitProperties {
  currentFilter: IFilePageFilter;
}

@Injectable({
  providedIn: 'root',
})
export class PageInitService {
  private authServiceRef: Subscription;

  constructor(
    private authService: AuthService,
    private loginService: LoginService,
    private actionMenuService: ActionMenuService,
    private getFilesService: GetFilesService,
    private locationService: LocationService,
    private selectFilesService: SelectFilesService,
    private deviceService: DeviceDetectorService,
    private reportPageEventService: ReportPageEventService,
    private router: Router,
    private manageCookieService: ManageCookieService,
    private navigationHandlerService: NavigationHandlerService
  ) {}

  defaultFilter: IFilePageFilter = {
    order: 'asc',
    filterBy: 'name',
  };

  startPageInit(pageSettings: IPageSettingsOptions) {
    // Collect data from Page Initialization

    // Show MobiDrive
    if (!pageSettings.preventShowDrive) {
      this.canShowDrive(pageSettings.scrollDirectiveRef);
    }

    // Remove items from action menu
    this.actionMenuService.setMenuConfig(pageSettings.menuConfig);

    // Cancel current/previous file list request
    this.getFilesService.rejectFilesList();

    // Cancel aff thumbnails requests if have some
    this.getFilesService.rejectThumbnailsRequests();

    // Clean selected files for every new view, which will clean Top Menu
    this.selectFilesService.removeSelectedFiles(true);

    // Check for user filters
    const pageInitProps: IPageInitProperties = {
      currentFilter: this.checkFilters(),
    };

    // Check Folder Location
    if (this.loginService.isLoggedIn() && !this.showPricingPlans()) {
      this.setPageLocation(pageSettings);
    } else {
      this.loginService.loggedInSignal.pipe(first()).subscribe((data) => {
        if (pageSettings.publicPage) {
          this.setPageLocation(pageSettings);
        } else if (data && !this.showPricingPlans()) {
          this.setPageLocation(pageSettings);
        }
      });

      if (pageSettings.publicPage) {
        // Set Location key of shared folder in order to load proper files list
        this.locationService.setParentKey(pageSettings.routeKey);
        // Check user Login status
        this.loginService.checkSession();
      }
    }

    // Handle IE Support Message for all non public pages
    this.stopIESupport(pageSettings);

    return pageInitProps;
  }

  checkFilters(): IFilePageFilter {
    if (!localStorage.getItem('userFilters')) {
      // If no filters, set default ones
      localStorage.setItem('userFilters', JSON.stringify(this.defaultFilter));
      return this.defaultFilter;
    } else {
      const oldFiltersMap = {
        fileName: 'name',
        modificationDate: 'modified',
      };

      const currentFilters = JSON.parse(localStorage.getItem('userFilters'));
      if (typeof oldFiltersMap[currentFilters.filterBy] !== 'undefined') {
        // If user has old filters rewrite his localstorage
        localStorage.setItem(
          'userFilters',
          JSON.stringify({
            order: currentFilters.order,
            filterBy: oldFiltersMap[currentFilters.filterBy],
          })
        );
      }

      return JSON.parse(localStorage.getItem('userFilters'));
    }
  }

  setPageLocation(pageLocation: IPageLocationData): void {
    const { routeKey, pageData } = pageLocation;
    this.locationService.setParentKey(routeKey);
    if (this.authServiceRef) {
      this.authServiceRef.unsubscribe();
    }

    if (routeKey) {
      if (pageData?.name === 'sharedFolder') {
        const shareKey = pageData.shareRootKey.split('/');
        const readShareLinkRef = this.authService
          .authRequest('files', 'read-shared-link', { link: shareKey[shareKey.length - 1] })
          .subscribe(
            (data) => {
              // If folder belongs to same user abort to set Location
              if (this.authService.getUserAuth().accountId === data.account) {
                this.redirectSharedFolder(data);
                return;
              }

              if (routeKey === data.key) {
                this.locationService.setCurrentParent({
                  account: data.account,
                  key: data.key,
                  name: data.name,
                  parent: null,
                  publiclyShared: data.publiclyShared,
                  sharedRoot: pageData.shareRootKey,
                  sharedRootKey: data.key,
                });
                this.locationService.locationDataReady(true);
              } else {
                this.authServiceRef = this.authService.authRequest('files', 'find-by-key', { key: routeKey }).subscribe((result) => {
                  this.findByKeySetter({
                    result: result,
                    sharedFolder: {
                      sharedRoot: pageData.shareRootKey,
                      sharedRootKey: data.key,
                    },
                  });
                });
              }
              readShareLinkRef.unsubscribe();
            },
            (error) => {
              readShareLinkRef.unsubscribe();
              this.locationService.setLocationSharedFolderErrorData(error);
            }
          );
      } else if (pageData?.name === 'backups') {
        this.authServiceRef = this.authService
          .authRequest('files', 'details', { id: { account: this.authService.getUserAuth().accountId, key: routeKey } })
          .subscribe((result) => this.findByKeySetter({ result }));
      } else {
        this.authServiceRef = this.authService
          .authRequest('files', 'find-by-key', { key: routeKey })
          .subscribe((result) => this.findByKeySetter({ result }));
      }
    } else {
      this.locationService.setCurrentParent(this.locationService.getRootParent());
      // Enable selected items menu, since page data is ready
      this.locationService.locationDataReady(true);
    }
  }

  findByKeySetter(data) {
    if (this.authServiceRef) {
      this.authServiceRef.unsubscribe();
    }
    if (!data.result || searchInParents(data.result, 'RECYCLED')) {
      this.router.navigate(['/not-found']);
      return;
    }

    const parentData: ILocationData = {
      account: data.result.account,
      key: data.result.key,
      name: data.result.name,
      parent: data.result.parent,
      publiclyShared: data.result.publiclyShared,
    };

    if (data.sharedFolder) {
      parentData.sharedRoot = data.sharedFolder.sharedRoot;
      parentData.sharedRootKey = data.sharedFolder.sharedRootKey;
    }

    this.locationService.setCurrentParent(parentData);
    // Enable selected items menu, since page data is ready
    this.locationService.locationDataReady(true);
  }

  stopIESupport(pageSettings) {
    if (!pageSettings.publicPage && sessionStorage && !sessionStorage.getItem('ieWarning')) {
      sessionStorage.setItem('ieWarning', 'true');
      setTimeout(() => {
        const di = this.deviceService.getDeviceInfo();
        if (di.browser.toLowerCase() === 'ie') {
          this.reportPageEventService.reportEvent('errorMessages.browserNotSupported', 'warning', 'browserSupport', false);
        }
      }, 7000);
    }
  }

  redirectSharedFolder(data) {
    if (searchInParents(data, 'BA__CK__UPS')) {
      this.router.navigate(['/backups/' + data.key]);
    } else {
      this.router.navigate(['/my-files/folder/' + data.key]);
    }
  }

  canShowDrive(scrollRefUpdate?) {
    const bodyEl = document.getElementsByTagName('body')[0];
    if (!bodyEl.classList.contains('show-drive')) {
      // Prevent page elements flickering by adding timeout
      setTimeout(() => {
        bodyEl.classList.add('show-drive');
        if (scrollRefUpdate) {
          scrollRefUpdate.update();
        }
      }, 30);
    }
  }

  showPricingPlans(): boolean {
    const pageName: string = this.navigationHandlerService.getCurrentPageData()?.name;
    const nonRelevantPages: string[] = [
      'pricing',
      'pricing-plans',
      'checkout',
      'purchase-success',
      'order-error',
      'file-version',
      'survey',
      'privacy-policy',
      'terms-of-use',
      'activate',
    ];

    if (nonRelevantPages.includes(pageName)) {
      return false;
    }

    const plansPageSeen: string = this.manageCookieService.getCookie('plansPageSeen');
    const planFeatures: IPlanFeatures = this.loginService.getProfileData().planFeatures;
    const canExtendPLan: boolean = planFeatures.storageTier === 0;

    if (!plansPageSeen && canExtendPLan) {
      this.router.navigate(['/pricing-plans']);
      return true;
    }

    return false;
  }
}
