import { Injectable, EventEmitter, Output, Directive } from '@angular/core';

@Injectable()
export class BulkOperationsService {
  constructor() {}

  @Output() bulkActionComplete: EventEmitter<any> = new EventEmitter();

  bulkStorage: any = {};

  addBulkAction(bulkId, actionData) {
    this.bulkStorage[bulkId] = JSON.parse(JSON.stringify(actionData));
    const noRefitemsArray = actionData.file.map((item) => {
      return Object.assign({}, item);
    });

    this.bulkStorage[bulkId].undoData = {
      timestamp: new Date().getTime(),
      action: actionData.action,
      menuSource: actionData.menuSource,
      bulkFiles: noRefitemsArray,
      actionFromUndo: actionData.actionFromUndo,
    };
  }

  removeItemFromBulk(item, result) {
    if (!this.bulkStorage[item.bulkId]) {
      return;
    }

    const bulkFilesArray = this.bulkStorage[item.bulkId].file;
    let i = bulkFilesArray.length;

    while (i--) {
      const executetAction: boolean = bulkFilesArray[i].key === item.key;
      if (executetAction) {
        bulkFilesArray.splice(i, 1);
        break;
      }
    }

    // If error remove item from LastBulkAction, since action failed for this item
    if (result === 'error') {
      let j = this.bulkStorage[item.bulkId].undoData.bulkFiles.length;
      while (j--) {
        const targetToRemove: boolean = this.bulkStorage[item.bulkId].undoData.bulkFiles[j].key === item.key;
        if (targetToRemove) {
          this.bulkStorage[item.bulkId].undoData.bulkFiles.splice(i, 1);
          break;
        }
      }
    }

    if (bulkFilesArray.length === 0) {
      this.bulkActionComplete.emit({ data: Object.assign({}, this.bulkStorage[item.bulkId].undoData) });
      delete this.bulkStorage[item.bulkId];
    }
  }

  updateUndoData(updateData) {
    const bulkId = updateData.source.bulkId;
    if (!this.bulkStorage[bulkId]) {
      return;
    }
    // Replace source object with new object - used for copy undo action
    const lnt = this.bulkStorage[bulkId]['undoData']['bulkFiles'].length;
    for (let i = 0; i < lnt; i++) {
      if (this.bulkStorage[bulkId]['undoData']['bulkFiles'][i].key === updateData.source.key) {
        this.bulkStorage[bulkId]['undoData']['bulkFiles'].splice(i, 1, updateData.newData);
        break;
      }
    }
  }
}
