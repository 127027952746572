<section class="modal-wrap delete-modal">
  <ng-container *ngIf="emptyBin">
    <h5 class="modal-title">
      <span [innerHTML]="'body.deleteForeverText' | translate"></span>
    </h5>
    <div class="modal-body cfix"></div>
  </ng-container>

  <ng-container *ngIf="!emptyBin">
    <h5 class="modal-title">
      <span *ngIf="deleteType === 'file'">{{ 'modal.deleteFile' | translate }}</span>
      <span *ngIf="deleteType === 'files'">{{ 'modal.deleteFiles' | translate: { value: filesForDelete } }}</span>
      <span *ngIf="deleteType === 'folder'">{{ 'modal.deleteFolder' | translate }}</span>
      <span *ngIf="deleteType === 'folders'">{{ 'modal.deleteFolders' | translate: { value: filesForDelete } }}</span>
      <span *ngIf="deleteType === 'items'">{{ 'modal.deleteItems' | translate: { value: filesForDelete } }}</span>
    </h5>

    <div class="modal-body cfix">
      <div class="files-to-delete">
        <strong *ngIf="!isFolder">{{ fileName }}</strong>
        <strong *ngIf="isFolder">{{ fileName | folderName }}</strong>
      </div>
    </div>
  </ng-container>

  <footer class="modal-footer cfix">
    <button
      *ngIf="emptyBin"
      class="button col-blue medium-size"
      #confirmDelete
      [disabled]="loading"
      (click)="deleteFiles()"
    >
      {{ 'modal.emptyBin' | translate }}
    </button>
    <button
      *ngIf="!emptyBin"
      class="button col-blue medium-size"
      #confirmDelete
      [disabled]="loading"
      (click)="deleteFiles()"
    >
      {{ 'modal.delete' | translate }}
    </button>

    <button class="button col-grey medium-size" [disabled]="loading" (click)="modal.close()">
      {{ 'modal.cancel' | translate }}
    </button>
  </footer>
</section>
