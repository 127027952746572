import { Component, Input } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-plan-message',
  templateUrl: './plan-message.component.html',
  styleUrls: ['./plan-message.component.scss'],
})
export class PlanMessageComponent {
  @Input() modal: any;

  constructor() {}

  contactSupport() {
    if (window) {
      window.open(environment.helpCenterURL, '_blank', 'false');
    }
    this.modal.close();
  }
}
