import { Injectable } from '@angular/core';
import { WindowReferenceService } from './window-reference.service';

@Injectable({
  providedIn: 'root',
})
export class DataLayerService {
  private window;

  constructor(private _windowRef: WindowReferenceService) {
    this.window = _windowRef.nativeWindow;
  }

  createPixelEvent(event: string, props: any): void {
    if (typeof this.window !== 'undefined') {
      this.window.dataLayer = this.window.dataLayer || [];
      this.window.dataLayer.push({ event: event, ...props });
    }
  }
}
