import { Injectable } from '@angular/core';
import { GetFilesService } from '../services/get-files.service';
import { configs } from '../configs/drive-configs';
import { ManageCookieService } from '../services/manage-cookie.service';
import { Subject } from 'rxjs';

@Injectable()
export class ListViewSwitcherService {
  private listViewChangedSubject = new Subject<string>();
  readonly listViewChanged = this.listViewChangedSubject.asObservable();

  listView: string;

  constructor(private getFilesService: GetFilesService, private manageCookieService: ManageCookieService) {
    this.listView = this.setListView();
  }

  changeListView() {
    if (this.listView === 'list-view') {
      this.listView = 'grid-view';
    } else {
      this.listView = 'list-view';
    }

    this.manageCookieService.setCookie('listViewMode', this.listView, { period: 'd', value: 1000 });
    this.listViewChangedSubject.next(this.listView);
  }

  setListView(): string {
    let view: string = configs.defaultListView;
    const viewModes: string[] = ['grid-view', 'list-view'];
    const savedCookieView: string = this.manageCookieService.getCookie('listViewMode');

    if (viewModes.includes(savedCookieView)) {
      view = savedCookieView;
    } else {
      this.manageCookieService.setCookie('listViewMode', view, { period: 'd', value: 1000 });
    }

    return view;
  }

  getListView(): string {
    return this.listView;
  }

  requestThumbnails(files) {
    if (this.listView === 'grid-view') {
      files.forEach((item) => {
        if (!item.dir && !item.thumbImage) {
          this.getFilesService.requireThumbnails(item);
        }
      });
    }
  }
}
