import { Component, OnInit, Input, HostListener, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { ApplyActionService } from '../../../../services/apply-action.service';
import { SelectFilesService } from '../../../../services/select-files.service';
import { MoveFilesService } from '../../services/move-files.service';
import { fadeRegularAnimation } from '../../../../app-animations/app-animations';
import { IRefsMap } from '../../../../models/common.int';
import { IitemsSetType } from '../../../../models/common.int';

@Component({
  selector: 'app-move-files',
  templateUrl: './move-files.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeRegularAnimation(250, 0)],
})
export class MoveFilesComponent implements OnInit {
  @Input() filesData: any;
  @Input() menuSource: any;
  @Input() modal: any;
  @Input() activeModal: string;

  folderFormIsOpen: boolean = false;
  itemsType: IitemsSetType;
  selectedFolder: boolean = false;
  canMoveHere: boolean = false;
  loadingFolders: boolean = true;
  refs: IRefsMap = {};

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event) {
    if (event.keyCode === 13) {
      if (this.canMoveHere && !this.folderFormIsOpen) {
        this.moveItems();
      }
    }
  }

  constructor(
    private selectFilesService: SelectFilesService,
    private applyActionService: ApplyActionService,
    private moveFilesService: MoveFilesService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.moveFilesService.setMoveProps(this.filesData);
    this.moveFilesService.setCurrentLocation(this.filesData, this.menuSource);
    this.itemsType = this.moveFilesService.getMoveProps().itemsType;
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    // Clean refs
    for (const ref in this.refs) {
      if (this.refs[ref]) {
        this.refs[ref].unsubscribe();
      }
    }
  }

  canMoveFiles(data) {
    this.canMoveHere = data.canMove;
    this.selectedFolder = data.selectedFolder;
    this.cdRef.detectChanges();
  }

  moveItems(): void {
    const action = `${this.activeModal}Action`;
    const { differentParents, destination } = this.moveFilesService.getMoveData();

    if (differentParents) {
      const filesForTransfer = [];
      const filesInSameParent = [];

      this.filesData.forEach((item) => {
        if (item.parent.key !== destination.key) {
          filesForTransfer.push(item);
        } else {
          filesInSameParent.push(item);
        }
      });

      // Deselect items in same parent, since they will not be moved
      if (filesInSameParent.length) {
        filesInSameParent.forEach((item) => {
          this.selectFilesService.removeSelectedFile(item);
        });
      }

      this.applyActionService[action](filesForTransfer, destination, this.menuSource);
    } else {
      this.applyActionService[action](this.filesData, destination, this.menuSource);
    }

    this.modal.close();
  }
}
