import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { TrackingService } from '../../../services/tracking.service';

@Component({
  selector: 'logo-area',
  templateUrl: './logo-area.component.html',
  styleUrls: ['./logo-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoAreaComponent {
  @Input() view: string;
  @Input() staticPage: boolean;
  @Input() pageName: string = '';
  @Input() hasProfileData: boolean = false;

  constructor(private trackingService: TrackingService) {}

  noMSlogo: string[] = ['pricing-plans', 'cloud-storage-plans'];

  trackEvent() {
    this.trackingService.trackEvent('website.outbound-links', 'mobisystems', 'nav-bar.logo');
  }
}
