import { Component, OnInit, Input } from '@angular/core';
import { LoginService } from '../../../../services/login.service';
import { TrackingService } from '../../../../services/tracking.service';

@Component({
  selector: 'file-size-error',
  templateUrl: './file-size-error.component.html',
})
export class FileSizeErrorComponent implements OnInit {
  @Input() set errorsData(data) {
    if (data.obj) {
      this.fileData = data.obj;
    } else {
      this.fileData = data.file;
    }
  }
  @Input() menuSource: string;
  @Input() modal: any;

  mobileDevice: boolean = document.getElementsByTagName('html')[0].classList.contains('mobile-device');
  fileData: File = null;
  offerPlanMode: boolean = true;
  fileUploadLimit: number = null;
  constructor(private loginService: LoginService, private trackingService: TrackingService) {}

  ngOnInit() {
    const planFeatures = this.loginService.getProfileData().planFeatures;
    this.fileUploadLimit = planFeatures.uploadFileSizeLimit;
    this.offerPlanMode = planFeatures.storageTier === 0;
    this.trackingService.trackEvent('web-app.conv-window', 'file-size-limit', 'file-size-limit.view');
  }

  closeModal(redirectToPricing: boolean) {
    this.modal.close();
    if (redirectToPricing) {
      this.trackingService.trackEvent('web-app.conv-window', 'file-size-limit', 'file-size-limit.upgrade');
      window.open(`${window.location.origin}/pricing?source=file-size-limit.upgrade.pop-up`, '_blank');
    }
  }
}
