import { Injectable, EventEmitter, Output, Directive } from '@angular/core';

@Injectable()
export class ReportPageEventService {
  constructor() {}

  @Output() pageEventReporter: EventEmitter<any> = new EventEmitter();

  pageError: boolean = false;

  reportEvent(errorMessageCode: string, popupType: string, errorType: string = '', autoHide: boolean = true) {
    this.pageEventReporter.emit({
      message: errorMessageCode,
      popupType: popupType,
      errorType: errorType,
      autoHide: autoHide,
    });
  }

  isPageError() {
    return this.pageError;
  }

  setPageError(state: boolean) {
    this.pageError = state;
  }
}
