<ngx-smart-modal
  #actionModal
  identifier="actionModal"
  [closable]="closeModal"
  [dismissable]="false"
  [escapable]="escapeModal"
  [hideDelay]="100"
  [customClass]="'nsm-dialog-animation-fade ' + customModalClass"
  (onDataAdded)="setActionModal(actionModal)"
  (onAnyCloseEvent)="closeActionModal(actionModal)"
  (onOpen)="checkModalLayout()"
>
  <app-os-last-version
    *ngIf="activeModal === 'OSLastVersion'"
    [modal]="actionModal"
    [menuSource]="menuSource"
  ></app-os-last-version>
  <app-new-item
    *ngIf="activeModal === 'newItem'"
    [filesData]="activeModalData"
    [modal]="actionModal"
    [menuSource]="menuSource"
  ></app-new-item>
  <app-promo-os-trial
    *ngIf="activeModal === 'promoOSTrial'"
    [modal]="actionModal"
  ></app-promo-os-trial>
  <app-rename-file
    *ngIf="activeModal === 'rename'"
    [filesData]="activeModalData"
    [menuSource]="menuSource"
    [modal]="actionModal"
  ></app-rename-file>
  <app-delete-files
    *ngIf="activeModal === 'delete-permanent'"
    [filesData]="activeModalData"
    [menuSource]="menuSource"
    [modal]="actionModal"
  ></app-delete-files>
  <app-restore-files
    *ngIf="activeModal === 'restore'"
    [filesData]="activeModalData"
    [menuSource]="menuSource"
    [modal]="actionModal"
  ></app-restore-files>
  <app-remove-recent-files
    *ngIf="activeModal === 'remove-recent'"
    [filesData]="activeModalData"
    [menuSource]="menuSource"
    [modal]="actionModal"
  ></app-remove-recent-files>
  <app-new-folder
    *ngIf="activeModal === 'create-folder'"
    [filesData]="activeModalData"
    [menuSource]="menuSource"
    [modal]="actionModal"
  ></app-new-folder>
  <app-move-files
    *ngIf="activeModal === 'move' || activeModal === 'copy'"
    [activeModal]="activeModal"
    [filesData]="activeModalData"
    [menuSource]="menuSource"
    [modal]="actionModal"
  ></app-move-files>
  <app-share-link
    *ngIf="activeModal === 'share'"
    [activeModal]="activeModal"
    [filesData]="activeModalData"
    [menuSource]="menuSource"
    [modal]="actionModal"
  ></app-share-link>
  <app-invite
    *ngIf="activeModal === 'invite'"
    [modal]="actionModal"
    [menuSource]="menuSource"
  ></app-invite>
  <app-file-versioning
    *ngIf="activeModal === 'version-history'"
    [activeModal]="activeModal"
    [filesData]="activeModalData"
    [menuSource]="menuSource"
    [modal]="actionModal"
  ></app-file-versioning>
  <app-install-office
    *ngIf="activeModal === 'installOffice'"
    [filesData]="activeModalData"
    [menuSource]="menuSource"
    [modal]="actionModal"
  ></app-install-office>
  <app-change-language
    *ngIf="activeModal === 'change-language'"
    [filesData]="activeModalData"
    [menuSource]="menuSource"
    [modal]="actionModal"
  ></app-change-language>
  <app-error-report
    *ngIf="activeModal === 'error-report'"
    [errorsData]="activeModalData"
    [menuSource]="menuSource"
    [modal]="actionModal"
  ></app-error-report>
  <file-size-error
    *ngIf="activeModal === 'file-size-error'"
    [errorsData]="activeModalData"
    [menuSource]="menuSource"
    [modal]="actionModal"
  ></file-size-error>
  <activation-success
    *ngIf="activeModal === 'activation-success'"
    [storage]="activeModalData"
    [menuSource]="menuSource"
    [modal]="actionModal"
  ></activation-success>
  <app-file-properties
    *ngIf="activeModal === 'file-properties'"
    [activeModal]="activeModal"
    [filesData]="activeModalData"
    [menuSource]="menuSource"
    [modal]="actionModal"
  ></app-file-properties>
  <app-plan-message
    *ngIf="activeModal === 'plan-message'"
    [modal]="actionModal"
  ></app-plan-message>
</ngx-smart-modal>
