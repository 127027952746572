import { environment } from '../../environments/environment';

    
    //ShareFolder test
    //https://web-1931-search-dot-mobisystems-drive-test.appspot.com/sharelink/5dONxkwXwDq0sP1I5j5A403vDRSukzsYYBTxHOZkFxMb
    //Sharelink test
    //https://mobisystems-drive-test.appspot.com/sharelink/4w79Sno3om3EPP1T7GKuOy3VIQGSsOQAszPiLOQ7tAAQ
    //https://test-new-login-dot-mobisystems-drive-test.appspot.com?redirect=https%3A%2F%2Ftest-new-login-dot-mobisystems-drive-test.appspot.com%2F
    

    let devProfile: any = null; // eslint-disable-line

    // EXP Environment users
    //=====================================================================
    //devProfile = {email: 'verytest_test_exp@mobisystems.com', pass: '123456'};
    //devProfile = {email: 'tintirimintiri@msc.com', pass: 'а'};
    //devProfile = {email: 'dimitar.stoev@mobisystems.com', pass: 'dimitar45801'};
    // devProfile = {email: 'checkout8@msc.com', pass: 'a'};

    // TEST Environment users
    //=====================================================================
    //devProfile = {email: 'kalo_test123@abv.bg', pass: '123456'};
    //devProfile = {email: 'milena.yanovska@gmail.com', pass: 'Mnogo.09'};
    //devProfile = {email: 'verytest_test@mobisystems.com', pass: '123456'}; 
    //devProfile = {email: 'kaloyan.mobisys+x1@gmail.com', pass: '123456'}; //Free account
    const devProfilePDF = {email: 'qamitko+pdf50@gmail.com', pass: 'a'};
    //devProfile = {email: 'mobiwin@abv.bg', pass: 'mobiwin'};

    //devProfile = {email: 'key.mobisystems@gmail.com', pass: 'Mnogo.09'};
    //devProfile = {email: 'km_test123@tmobisystems.com', pass: '123456'}; 
    //devProfile = {email: 'kalo_test_space@abm.bg', pass: '123456'};

    //Backup Test Accounts
    //=====================================================================
    //devProfile = {email: 'backup@msc.com', pass: 'а'};
    //devProfile = {email: 'backup2@msc.com', pass: 'а'};
    //devProfile = {email: 'backup3@msc.com', pass: 'а'};

    export const getDevAccount = () => {
      if(devProfile) {
        return devProfile;
      }

      // EXP default user
      if(environment.apiUrl === 'https://exp.connect.mobisystems.com/api') {
        return {email: 'verytest_test_exp@mobisystems.com', pass: '123456'};
      }
      // TEST default user
      if(environment.apiUrl === 'https://test.connect.mobisystems.com/api') {
        return {email: 'qamitko+multipleTrial@gmail.com', pass: 'a'};
      }
      // PRDOD default user
      if(environment.apiUrl === 'https://connect.mobisystems.com/api') {
        return {email: 'stage.mobisystems@gmail.com', pass: 'Mnogo.09'};
      }
    }