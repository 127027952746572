import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Observable, forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';
import { inAppsPlans } from '../configs/inapp-map-config';
import { configs } from '../configs/drive-configs';
@Injectable({
  providedIn: 'root',
})
export class UserPlansDataService {
  constructor(private authService: AuthService) {}

  canOfferTrial({ userFeatures, trialInApps }: { userFeatures: any; trialInApps?: string[] }): Observable<any> {
    let inAppToCheck = [];
    if (trialInApps) {
      inAppToCheck = trialInApps;
    }

    const plansMap = window?.sessionStorage.getItem(configs.winAppFlag) ? inAppsPlans['plansMapWindows'] : inAppsPlans['plansMapWeb'];
    plansMap.listPlans.forEach((plan) => {
      if (plan.trialPlan) {
        inAppToCheck.push(plansMap.additional[plan.trialPlan]);
      }
    });

    return new Observable<any>((obs) => {
      if (!userFeatures) {
        obs.next(true);
        obs.complete();
      } else if (inAppToCheck.length) {
        const checkTrials = {};
        inAppToCheck.forEach((inApp) => {
          checkTrials[inApp] = this.authService.authRequest('subscriptions', 'is-trial-consumed', { inapp: inApp }).pipe(first());
        });

        forkJoin(checkTrials).subscribe((trialData: any) => {
          for (const inAppKey in trialData) {
            if (!trialData[inAppKey] && userFeatures.storageTier < 3) {
              trialData[inAppKey] = true;
            } else {
              trialData[inAppKey] = false;
            }
          }
          obs.next(trialData);
          obs.complete();
        });
      } else {
        obs.next(false);
        obs.complete();
      }
    });
  }

  getMDSubscriptions(): Observable<{ inTrial: boolean }> {
    return new Observable<any>((obs) => {
      this.authService
        .authRequest('subscriptions', 'get-subscriptions', { product: 'MOBIDRIVE' })
        .pipe(first())
        .subscribe((res) => {
          let inTrial: boolean = false;
          if (res && res.length) {
            res.forEach((md) => {
              const d = new Date();
              const utcDate = d.getTime() + d.getTimezoneOffset() * 60000;
              if (md.validTo > utcDate && md.inTrial) {
                inTrial = true;
              }
            });
          }

          obs.next({ inTrial: inTrial });
          obs.complete();
        });
    });
  }
}
