import { Injectable } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { LanguageManagerService } from './language-manager.service';
import { LogoutService } from './logout.service';
import { ProductVersionService } from './product-version.service';

export interface IProfileMenuItem {
  nameCode: string;
  name: string;
  className: string;
  icon: string;
  url: string;
  method: string;
  target: string;
  separator?: boolean;
  trackEvent?: {
    category: string;
    action: string;
    label: string;
  };
  hideItem?: boolean;
}

@Injectable()
export class UserProfileMenuService {
  constructor(
    private logoutService: LogoutService,
    private ngxModal: NgxSmartModalService,
    private languageService: LanguageManagerService,
    private productVersionService: ProductVersionService,
    private authService: AuthService
  ) {}

  readonly profileMenu: IProfileMenuItem[] = [
    {
      nameCode: 'header.navMenu.upgradeStorage',
      name: 'Upgrade Storage',
      className: 'pim-upgrade-storage',
      icon: 'iconame-upgrade-storage',
      url: window.location.origin + '/pricing?source=profile-menu.upgrade-storage',
      method: '',
      target: '_blank',
      trackEvent: {
        category: 'web-app.engage',
        action: 'pricing',
        label: 'profile-menu.upgrade-storage',
      },
    },
    {
      nameCode: 'header.navMenu.activate',
      name: 'Activate',
      className: 'pim-activate',
      icon: 'iconame-activate',
      url: window.location.origin + '/activate',
      method: '',
      target: '_blank',
      trackEvent: {
        category: 'web-app.engage',
        action: 'activate-mobidrive',
        label: 'profile-menu.activate',
      },
    },
    {
      nameCode: 'header.navMenu.manageAccount',
      name: 'Manage Account',
      className: 'pim-manage-account',
      icon: 'iconame-account-setting',
      url: '',
      method: 'manageAccount',
      target: '',
      separator: true,
      trackEvent: {
        category: 'web-app.account-settings',
        action: 'manage-accounts',
        label: 'manage-accounts',
      },
    },
    {
      nameCode: 'header.navMenu.productUpdates',
      name: 'Product Updates',
      className: 'pim-product-updates',
      icon: 'iconame-product-updates',
      url: window.location.origin + '/updates',
      method: 'hideUserUpdates',
      target: '_self',
      trackEvent: {
        category: 'web-app.account-settings',
        action: 'product-updates',
        label: 'product-updates',
      },
    },
    {
      nameCode: 'header.navMenu.helpCenter',
      name: 'Help Center',
      className: 'pim-help-center',
      icon: 'iconame-help',
      url: environment.helpCenterURL,
      method: '',
      target: '_self',
      trackEvent: {
        category: 'web-app.browse',
        action: 'help-center',
        label: 'profile-menu',
      },
    },
    {
      nameCode: '',
      name: '',
      className: 'pim-change-language',
      icon: 'iconame-langs',
      url: '',
      method: 'changeLanguage',
      target: '_self',
    },
    {
      nameCode: 'header.navMenu.signout',
      name: 'Sign Out',
      className: 'pim-sign-out',
      icon: 'iconame-sign-out',
      url: '',
      method: 'signOut',
      target: '_self',
      trackEvent: {
        category: 'web-app.account-settings',
        action: 'sign-out',
        label: 'profile-menu',
      },
    },
    {
      nameCode: 'header.navMenu.downloadForDesktop',
      name: 'Download MobiDrive for desktop',
      className: 'pim-download-desktop',
      icon: 'iconame-win-dw',
      url: window.location.origin + '/win-download?referrer=mdweb-mddownload-btn-accmenu',
      method: '',
      target: '_self',
      separator: true,
      trackEvent: {
        category: 'web-app.download',
        action: 'windows',
        label: 'profile-menu',
      },
    },
    {
      nameCode: '',
      name: 'mobioffice.com',
      className: 'pim-officesuite',
      icon: 'iconame-officesuite',
      url: 'https://www.mobioffice.com/',
      method: '',
      target: '_self',
      separator: true,
      trackEvent: {
        category: 'web-app.outbound-links',
        action: 'account-menu',
        label: 'mobioffice.com',
      },
    },
    {
      nameCode: '',
      name: 'mobipdf.com',
      className: 'pim-pdf-extra',
      icon: 'iconame-pdf-extra',
      url: 'https://mobipdf.com/',
      method: '',
      target: '_self',
      trackEvent: {
        category: 'web-app.outbound-links',
        action: 'account-menu',
        label: 'mobipdf.com',
      },
    },
    {
      nameCode: '',
      name: 'mobisystems.com',
      className: 'pim-mobisystems',
      icon: 'iconame-mobisystems',
      url: 'https://www.mobisystems.com/',
      method: '',
      target: '_self',
      trackEvent: {
        category: 'web-app.outbound-links',
        action: 'account-menu',
        label: 'mobiystems.com',
      },
    },
  ];

  getProfileMenu(profileData, download_page?: string) {
    // Set current active language before serve the menu
    let canOfferTrial: boolean = profileData ? false : true;

    if (profileData?.planFeatures.canBuyPersonal1000 && profileData?.planFeatures.storageTier < 3) {
      for (const inAppTrial in profileData.planFeatures.canOfferTrial) {
        if (profileData.planFeatures.canOfferTrial[inAppTrial]) {
          canOfferTrial = true;
          break;
        }
      }
    }

    const profileMenu: IProfileMenuItem[] = JSON.parse(JSON.stringify(this.profileMenu));
    profileMenu.forEach((item) => {
      if (item.name === 'Upgrade Storage' && canOfferTrial) {
        item.nameCode = 'pricingPage.startTrial';
      }
      if (item.method === 'changeLanguage') {
        const lang = this.languageService.getCurrentLang();
        item.name = lang.label;
      }

      if (download_page && item.url.indexOf('win-download') !== -1) {
        item.url += `&${encodeURIComponent(download_page)}`;
      }
    });

    return profileMenu;
  }

  signOut(event) {
    event.preventDefault();
    this.logoutService.logout('logout');
  }

  changeLanguage(event) {
    event.preventDefault();
    this.ngxModal.setModalData({ view: 'change-language', files: [], menuSource: '' }, 'actionModal');
    this.ngxModal.resetModalData('actionModal');
    this.ngxModal.getModal('actionModal').open();
  }

  hideUserUpdates() {
    this.productVersionService.newVersionChecked();
  }

  manageAccount() {
    const authData = this.authService.getUserAuth();
    const windowRef = window.open(`${window.location.origin}/redirecting-to-account`);
    this.authService.getXcode(authData.scode).subscribe(
      (data) => {
        const langCode = this.languageService.getCurrentLang().code;
        windowRef.location.replace(`${environment.accountsLink}/${langCode}/my-account/?xcode=${data.xcode}`);
      },
      (error) => {
        this.logoutService.logout('logout');
      }
    );
  }
}
