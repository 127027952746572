import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { getDevAccount } from '../configs/dev-accounts';
import { AuthService } from './auth.service';
import { DeviceInfoService } from './device-info.service';
import { LogoutService } from './logout.service';
import { ManageCookieService } from './manage-cookie.service';
import { ServerDetectionService } from './server-detection.service';
import { UserPlansDataService } from './user-plans-data.service';
export interface IUUserAlias {
  alias: string;
  bounced: boolean;
  removable: boolean;
  status: string;
  type: string;
}

export interface IPlanFeatures {
  storageTier: number;
  purgePeriod: number;
  uploadFileSizeLimit?: number;
  canBuyPersonal1000: boolean;
  canOfferTrial: { [key: string]: boolean };
}

export interface ILoginData {
  accountId: string;
  aliases: IUUserAlias[] | null;
  canBuyPersonal1000: boolean;
  email: string;
  expire: number;
  features: IPlanFeatures;
  id: string;
  name: string;
  picture: any;
  sid: string;
  timeLimit: number;
  token: string;
}

export interface IProfileData {
  accountId: string;
  aliases?: any[];
  connectType: number;
  currentAlias: string;
  customProfile: boolean;
  email: string;
  hasCustomIdentity: boolean;
  hasEmail: boolean;
  hasPhoneNumber: boolean;
  identityId: any;
  metadata: any;
  name: string;
  phoneNumber: string;
  planFeatures: IPlanFeatures;
  profileCoverPic: any;
  profilePic: any;
  verified: boolean;
}

@Injectable()
export class LoginService {
  private loggedInSignalSubject = new Subject<boolean>();
  readonly loggedInSignal = this.loggedInSignalSubject.asObservable();

  private loggedInDataSubject = new BehaviorSubject<IProfileData>(null);
  readonly loggedInData = this.loggedInDataSubject.asObservable().pipe(filter((data) => !!data));

  loggedIn: boolean = false;
  profileData: IProfileData = null;
  hasSessionId: boolean;
  devTestLogin: boolean = environment.devLogin;

  constructor(
    private serverDetection: ServerDetectionService,
    private manageCookieService: ManageCookieService,
    private deviceInfoService: DeviceInfoService,
    private http: HttpClient,
    private authService: AuthService,
    private logoutService: LogoutService,
    private userPlansData: UserPlansDataService
  ) {}

  server: boolean = this.serverDetection.isServer();

  /* Check/Start User Session
  ================================================== */
  checkSession(sid: string = '', logoutUrl: string = ''): void {
    // Not allow express js to check session
    if (this.server) {
      return;
    }

    // Obtain sessionId
    const sessionId: string = sid ? sid : this.manageCookieService.getCookie('scode');

    // Expose scode for test purposes
    if (!environment.production) {
      console.log('%c ! scode : ' + sessionId, ' color: red; font-size: 16px');
    }

    this.hasSessionId = true;
    if (this.devTestLogin) {
      this.devLogin('checkSession');
    } else if (sessionId) {
      this.loginCheck(sessionId, false);
    } else if (logoutUrl) {
      // Redirect to Login portal if try to access protected page
      // and have no sessionId
      this.logoutService.logout('rnd', logoutUrl);
    } else {
      this.hasSessionId = false;
      this.notifyLogin(false);
    }
  }

  /* User Login
  ================================================== */
  loginCheck(sessionId: string, noLogout: boolean = false): void {
    this.loginRequest(sessionId).subscribe(
      (result) => {
        if (!result) {
          if (!noLogout) {
            this.logoutService.logout('rnd');
          } else {
            this.notifyLogin(false);
          }
        } else {
          // If scode is valid set UserAuth data and proceed with login
          this.loggedIn = true;
          this.loadUserProfile({ ...result.features, canBuyPersonal1000: result.canBuyPersonal1000 });
        }
      },
      (error) => {
        // Redirect to Login portal
        if (!noLogout) {
          this.logoutService.logout('rnd');
        } else {
          this.notifyLogin(false);
        }
      }
    );
  }

  loginRequest(sessionId: string): Observable<ILoginData> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    // TODO - api/extend-session should not provide any features
    return this.http
      .post<ILoginData>(environment.accountsLink + '/api/extend-session', { sessionId: sessionId, app: 'mobidrive' }, header)
      .pipe(
        switchMap((result) => {
          if (result) {
            this.authService.setUserAuth(result, sessionId);
            const accId = sessionStorage.getItem('accountId');
            const authTkn = sessionStorage.getItem('token');

            return this.authService.authRequest('profile', 'issue-xchange-code', { account: accId, tkn: authTkn }).pipe(
              switchMap((xchangeCode) =>
                this.authService.authRequest('auth', 'sign-in-by-xchange-code', { account: accId, tkn: authTkn, xchangeCode }).pipe(
                  map((res) => {
                    const newResult = { ...result, token: res.token };
                    this.authService.setUserAuth(newResult, sessionId);
                    this.deviceInfoService.saveDeviceInfo();
                    this.deviceInfoService.clientInstalled();

                    return newResult;
                  })
                )
              )
            );
          } else {
            this.authService.handleErrors(result);
          }
        }),
        switchMap((result) =>
          this.userPlansData.canOfferTrial({ userFeatures: result.features }).pipe(
            map((canOfferTrial) => {
              result.features.canOfferTrial = canOfferTrial;

              return result;
            })
          )
        )
      );
  }

  notifyLogin(status: boolean = true): void {
    // Emit Login result
    this.loggedInSignalSubject.next(status);
    this.loggedIn = status;

    const bodyEl = document.getElementsByTagName('body')[0];
    const loginMarkClass = status ? 'logged-in' : 'not-logged';
    bodyEl.classList.add(loginMarkClass);
  }

  signIn(logoutUrl?: string): void {
    window.open(
      this.logoutService.buildLoginLink({ exitParam: 'rnd', logoutUrl: logoutUrl ? logoutUrl : '', app: 'com.mobisystems.web.mobidrive' }),
      '_self',
      'false'
    );
  }

  /* Load User Profile after login
  ================================================== */
  loadUserProfile(planFeatures: IPlanFeatures): void {
    const accId = !this.server && sessionStorage.getItem('accountId');
    const authTkn = !this.server && sessionStorage.getItem('authToken');

    this.authService.authRequest('profile', 'load-user-profile', { account: accId, tkn: authTkn }).subscribe(
      (result) => {
        this.profileData = { ...result, planFeatures: planFeatures, accountId: accId };
        this.loggedInDataSubject.next(this.profileData);
        this.notifyLogin();
        this.manageCookieService.setCookie('userLoginTrc45New', 'true', { period: 'd', value: 45 });
      },
      (error) => {
        // Redirect to Login portal
        this.logoutService.logout('rnd');
      }
    );
  }

  updateProfileData() {
    return new Observable((subscriber) => {
      this.loginRequest(this.manageCookieService.getCookie('scode')).subscribe(
        (result) => {
          if (!result) {
            this.logoutService.logout('rnd');
          } else {
            this.profileData.planFeatures = { ...result.features, canBuyPersonal1000: result.canBuyPersonal1000 };
            this.loggedInDataSubject.next(this.profileData);
            subscriber.next(true);
            subscriber.complete();
          }
        },
        (error) => {
          this.logoutService.logout('rnd');
        }
      );
    });
  }

  getProfileData(): IProfileData {
    return this.profileData;
  }

  isLoggedIn(): boolean {
    return this.loggedIn;
  }

  hasSessionKey(): boolean {
    return this.hasSessionId;
  }

  getLoggedResult(): Promise<{ loggedUser: boolean }> {
    return new Promise<{ loggedUser: boolean }>((resolve, reject) => {
      if (this.isLoggedIn()) {
        resolve({ loggedUser: true });
      } else {
        if (!this.hasSessionKey()) {
          resolve({ loggedUser: false });
        } else {
          // Check loggedIn status first and then request shared file data
          const lgSignal = this.loggedInSignal.subscribe(
            (data) => {
              resolve({ loggedUser: data });
              lgSignal.unsubscribe();
            },
            (error) => {
              reject(error);
            }
          );
        }
      }
    });
  }

  /* DEV User Login
  ================================================== */
  devLogin(method: string): void {
    const logStyle = 'background: #000000; color:#fff; font-size: 16px';
    console.log('%c ☠ DEV Login Enabled ☠ ', logStyle);
    console.log('%c Logged from: ' + method, logStyle);

    const user: any = getDevAccount();
    const header = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
      }),
    };
    this.http
      .post(environment.accountsLink + '/api/login-dev', { accountId: user.email, password: user.pass }, header)
      .pipe(
        map((res: HttpResponse<any>) => {
          if (res) {
            return res;
          } else {
            this.authService.handleErrors(res);
          }
        })
      )
      .subscribe((data: any) => {
        console.log('%c ! scode : ' + data.sessionId, ' color: red; font-size: 16px');
        this.loginCheck(data.sessionId);
        this.manageCookieService.setCookie('scode', data.sessionId, { period: 'd', value: 1 });
      });
  }
}
