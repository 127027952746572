import { Injectable, EventEmitter, Output } from '@angular/core';
import { LoginService } from '../../../services/login.service';
import { LocationService, ILocationData } from '../../../services/location.service';
import { searchInParents } from '../../../helpers/search-in-parents';
import { IitemsSetType } from '../../../models/common.int';

@Injectable()
export class MoveFilesService {
  @Output() currentLocationReady: EventEmitter<boolean> = new EventEmitter();
  @Output() openFolder: EventEmitter<any> = new EventEmitter();
  @Output() locationChanged: EventEmitter<any> = new EventEmitter();
  @Output() startFolderCreation: EventEmitter<any> = new EventEmitter();

  constructor(private loginService: LoginService, private locationService: LocationService) {}

  folderLocation: ILocationData;
  destination: ILocationData;
  itemsType: IitemsSetType;
  sharedResource: boolean;
  differentParents: boolean = false;

  setMoveProps(filesData: any[]) {
    this.itemsType = this.checkItemType(filesData);
    this.sharedResource = this.checkForSharedResource(filesData);
    this.differentParents = this.checkParents(filesData);
  }

  getMoveProps() {
    return {
      itemsType: this.itemsType,
      sharedResource: this.sharedResource,
      differentParents: this.differentParents,
    };
  }

  setCurrentLocation(filesData: any[], menuSource) {
    this.folderLocation = this.locationService.getCurrentParent();
    if (
      this.sharedResource ||
      searchInParents({ parent: this.folderLocation }, 'BA__CK__UPS') ||
      searchInParents({ parent: filesData[0].parent }, 'BA__CK__UPS')
    ) {
      this.folderLocation = this.locationService.getRootParent();
    } else if (menuSource || !this.differentParents) {
      // When move item from breadcrumb
      this.folderLocation = filesData[0].parent;
    }

    this.setDestination({ ...this.folderLocation });
    this.currentLocationReady.emit(true);
  }

  getCurrentLocation() {
    return this.folderLocation;
  }

  updateFolderLocation(location) {
    this.folderLocation = location;
    this.destination = location;
    this.locationChanged.emit(location);
  }

  checkForSharedResource(filesData): boolean {
    let sharedRes = false;
    const ln = filesData.length;

    if (ln === 1) {
      sharedRes = filesData[0].account !== this.loginService.getProfileData().accountId;
    } else {
      for (let i = 0; i < ln; i++) {
        if (filesData[i].account !== this.loginService.getProfileData().accountId) {
          sharedRes = true;
          break;
        }
      }
    }
    return sharedRes;
  }

  checkParents(filesData) {
    const ln = filesData.length;
    for (let i = 0; i < ln; i++) {
      const currentItem = filesData[i];
      const nextItem = filesData[i + 1];
      if (nextItem && currentItem.parent.key !== nextItem.parent.key) {
        return true;
      }
    }

    return false;
  }

  checkItemType(filesData): IitemsSetType {
    // Set Popup Title
    let itemsType: IitemsSetType = filesData[0].dir ? 'folders' : 'files';
    for (let i = 1; i < filesData.length; i++) {
      if (filesData[i].dir !== filesData[i - 1].dir) {
        itemsType = 'items';
        break;
      }
    }

    return itemsType;
  }

  setDestination(destinationData: ILocationData) {
    this.destination = destinationData;
  }

  getMoveData() {
    return {
      differentParents: this.differentParents,
      destination: this.destination,
    };
  }

  navigateToFolder(folderData, loadContentKey: string | null = '') {
    this.openFolder.emit({ folder: folderData, key: loadContentKey });
  }

  createFolderNotification() {
    this.startFolderCreation.emit();
  }
}
