import { Injectable, EventEmitter, Output } from '@angular/core';
import { AuthService } from './auth.service';
import { ReportPageEventService } from './report-page-event.service';
import { LoginService } from './login.service';

@Injectable()
export class DownloadManagerService {
  constructor(private authService: AuthService,
              private reportPageEventService: ReportPageEventService,
              private loginService: LoginService) {}

  @Output() processDownload: EventEmitter<any> = new EventEmitter();

  updateTimeout: any;
  lastId: any;
  downloadMap: any = {};
  readyForDownload: boolean = true;
  localDownload: boolean = false;
  processCheckLimit: number = 100;

  startDownload(items, revisionId: string = '') {
    this.processCheckLimit = 100;
    // Do not allow more than 1 multidownload process
    if (items.length > 1 || items[0].dir) {
      if (!this.readyForDownload) {
        this.downloadRefuse();
        return;
      } else {
        this.readyForDownload = false;
      }
    }

    const processId = new Date().getTime(); // Timestamp ID
    this.downloadMap[processId] = {
      completed: false,
      taskId: '',
      processId: processId,
      singleFile: false,
      fileKey: '',
    };
    this.processDownload.emit({
      status: 'start',
      pId: processId,
      multiDownload: items.length > 1 || items[0].dir,
      files: items,
    });

    // Download single file
    if (revisionId || (items.length === 1 && !items[0].dir)) {
      items[0].activeSingleDownload = true;

      const data = {
        id: items[0],
        revision: revisionId,
        type: 'file',
        expires: new Date().getTime() + 1000 * 60 * 5,
      };

      this.downloadMap[processId].singleFile = true;
      this.downloadMap[processId].fileKey = items[0].key;
      this.downloadMap[processId].file = items[0];

      this.authService.authRequest('files', 'url', data).subscribe(
        (downloadLink) => {
          // If no downloadLink do nothing and let Authservice Error handler solve the case
          // It is because protocolCheck native dialog block Authservice error handler
          if (!downloadLink) {
            return;
          }

          // If item is canceled there is more record in downloadMap
          if (this.downloadMap[processId] && this.downloadMap[processId].canceled) {
            delete this.downloadMap[processId];
            return;
          }

          // Mark as local onBeforeUnload event and do not show exit alert!
          this.localDownload = true;

          window.location.assign(downloadLink);
          delete this.downloadMap[processId];

          // Hack for protocol check bug that enter error callback when Download native modal is shown
          // Timeout prevent calling protocol check before native DW modal is initiated
          setTimeout(() => {
            items[0].activeSingleDownload = false;
          }, 300);

          this.processDownload.emit({ status: 'complete', data: downloadLink, pId: processId, files: items });
        },
        (error) => {
          this.processDownload.emit({
            status: 'error',
            action: 'download',
            errorData: error,
            pId: processId,
            files: [{ ...items[0] }],
          });
        }
      );
    } else {
      // Download Bulk of files
      this.downloadFiles(items, processId);
    }
  }

  /* Download Files
  ================================================== */
  downloadFiles(items, processId) {
    this.authService.downloadRequest('createCompressionTask', 'text', items).subscribe(
      (response) => {
        if (!this.downloadMap[processId]) {
          this.cancelTaskRequest(response);
          return;
        }

        this.downloadMap[processId] = { completed: false, taskId: response, processId: processId };
        this.updateDownloadStatus(!this.loginService.loggedIn ? response : '');
      },
      (error) => {
        if (this.downloadMap[processId]) {
          delete this.downloadMap[processId];
        }
        this.readyForDownload = true;
        this.processDownload.emit({
          status: 'error',
          action: 'download',
          errorData: error,
          pId: processId,
          files: items.map((item) => {
            const newItem = { ...item };
            return newItem;
          }),
        });
      }
    );
  }

  updateDownloadStatus(taskId?) {
    this.authService.downloadRequest('queryCompressionTasks', 'json', '', taskId).subscribe(
      (response) => {
        let allFinished = false;

        // If response is empty array limit to 10 checks
        this.processCheckLimit--;

        if (this.processCheckLimit === 0) {
          this.reportGenericError({ name: 'timeoutError' });
          allFinished = true;
        }

        if (response.length) {
          response.forEach((item) => {
            // Get Procees ID
            const pId = this.getProcessIdBytaskId(item.taskId);

            if (item.progress !== 1 && this.downloadMap[pId]) {
              allFinished = false;
              this.processCheckLimit = 100;
            }

            // Report Error
            if (response.error) {
              this.processDownload.emit({
                status: 'error',
                action: 'download',
                errorData: response,
                pId: pId,
                files: Object.assign({}, item),
              });
              this.processCheckLimit = 100;
              this.readyForDownload = true;
            }

            if (item.error) {
              if (this.downloadMap[pId] && !this.downloadMap[pId].completed) {
                this.processDownload.emit({
                  status: 'error',
                  action: 'download',
                  errorData: { message: item.error },
                  pId: pId,
                  files: Object.assign({}, item),
                });
                allFinished = true;
                this.readyForDownload = true;
                this.processCheckLimit = 100;
                delete this.downloadMap[pId];
              }
            }

            // If completed init item download
            if (this.downloadMap[pId] && !this.downloadMap[pId].completed) {
              this.processDownload.emit({ status: 'update', pId: pId, progress: item.progress });
              if (item.progress === 1) {
                this.getCompressedFile(item, pId);
                this.downloadMap[pId].completed = true;
                allFinished = true;
              }

              this.processCheckLimit = 100;
            } else if (this.downloadMap[pId] && this.downloadMap[pId].completed) {
              // Remove completed items from downloadMap
              delete this.downloadMap[pId];
            }
          });
        }

        // Stop if there are any issues with download
        if (this.readyForDownload) {
          allFinished = true;
        }

        if (!allFinished && this.processCheckLimit > 0) {
          this.updateTimeout = setTimeout(() => {
            this.updateDownloadStatus(taskId);
          }, 2500);
        }
      },
      (error) => {
        this.reportGenericError(error);
      }
    );
  }

  getCompressedFile(task, processId) {
    this.authService.downloadRequest('getCompressedFile', 'text', '', task.taskId).subscribe(
      (response) => {
        // Mark as local onBeforeUnload event and do not show exit alert!
        this.localDownload = true;
        this.processDownload.emit({ status: 'complete', data: response, pId: processId });
        this.readyForDownload = true;

        // Stop if download is canceled
        if (this.downloadMap[processId].canceled) {
          return;
        }

        window.location.assign(response);
      },
      (error) => {
        this.processDownload.emit({
          status: 'error',
          action: 'download',
          errorData: error,
          pId: processId,
          files: this.downloadMap[processId].files,
        });
      }
    );
  }

  cancelDownload() {
    for (const key in this.downloadMap) {
      this.downloadMap[key].canceled = true;
      if (this.downloadMap[key].file) {
        this.downloadMap[key].file.activeSingleDownload = false;
      }
      if (this.downloadMap[key].taskId) {
        this.cancelTaskRequest(this.downloadMap[key].taskId);
      } else if (!this.downloadMap[key].singleFile) {
        delete this.downloadMap[key];
      }
    }

    this.readyForDownload = true;
    clearInterval(this.updateTimeout);
  }

  cancelTaskRequest(taskId) {
    this.authService.downloadRequest('cancelCompressionTask', 'text', '', taskId).subscribe(
      (response) => {
        // Remove completed items from downloadMap
        const pId = this.getProcessIdBytaskId(taskId);
        if (this.downloadMap[pId]) {
          delete this.downloadMap[pId];
        }
      },
      (error) => {
        console.log('ErrorCancel', error);
      }
    );
  }

  getProcessIdBytaskId(taskId) {
    let pId = '';
    for (const key in this.downloadMap) {
      if (this.downloadMap[key].taskId === taskId) {
        pId = key;
        break;
      }
    }

    return pId;
  }

  isLocalDownload() {
    if (this.localDownload) {
      this.localDownload = false;
      return true;
    } else {
      return false;
    }
  }

  downloadRefuse() {
    this.reportPageEventService.reportEvent('errorMessages.downloadIsRunning', 'error');
  }

  reportGenericError(error?) {
    let pid = '';
    const errorData = error ? error : { name: 'Error' };

    for (const key in this.downloadMap) {
      if (this.downloadMap[key].taskId && !this.downloadMap[key].singleFile) {
        pid = this.downloadMap[key].processId;
      }
    }
    this.processDownload.emit({ status: 'error', action: 'download', errorData: errorData, pId: pid, files: '' });

    this.processCheckLimit = 100;
    this.readyForDownload = true;
    delete this.downloadMap[pid];
  }

  getDownloadMap() {
    return this.downloadMap;
  }
}
