import { ChangeDetectorRef, Component, NgModule, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSmartModalModule, NgxSmartModalService } from 'ngx-smart-modal';
import { filter } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ChangeDetectionManagerService } from '../../services/change-detection-manager.service';
import { LocationService } from '../../services/location.service';
import { IProfileData, LoginService } from '../../services/login.service';
import { OfficeSuiteSupportService } from '../../services/office-suite-support.service';
import { ResponsiveLayoutService } from '../../services/responsive-layout.service';
import { ServerDetectionService } from '../../services/server-detection.service';
import { UserSessionService } from '../../services/user-session.service';

// for separated Module
import { ReactiveFormsModule } from '@angular/forms';
import { IRefsMap } from '../../models/common.int';
import { SharedModule } from '../../modules/_shared/shared.module';
import { ActivationSuccessComponent } from './modals/activation-success/activation-success.component';
import { ChangeLanguageComponent } from './modals/change-language/change-language.component';
import { DeleteFilesComponent } from './modals/delete-files/delete-files.component';
import { ErrorReportComponent } from './modals/error-report/error-report.component';
import { FilePropertiesComponent } from './modals/file-properties/file-properties.component';
import { FileSizeErrorComponent } from './modals/file-size-error/file-size-error.component';
import { FileVersioningComponent } from './modals/file-versioning/file-versioning.component';
import { InstallOfficeComponent } from './modals/install-office/install-office.component';
import { InviteComponent } from './modals/invite/invite.component';
import { FolderListComponent } from './modals/move-files/folder-list/folder-list.component';
import { MoveFilesTitleComponent } from './modals/move-files/move-files-title/move-files-title.component';
import { MoveFilesComponent } from './modals/move-files/move-files.component';
import { NewFolderCreatorComponent } from './modals/move-files/new-folder-creator/new-folder-creator.component';
import { NewFolderComponent } from './modals/new-folder/new-folder.component';
import { NewItemComponent } from './modals/new-item/new-item.component';
import { OSLastVersionComponent } from './modals/os-last-version/os-last-version.component';
import { PlanMessageComponent } from './modals/plan-message/plan-message.component';
import { PromoOsTrialComponent } from './modals/promo-os-trial/promo-os-trial.component';
import { RemoveRecentFilesComponent } from './modals/remove-recent-files/remove-recent-files.component';
import { RenameFileComponent } from './modals/rename-file/rename-file.component';
import { RestoreFilesComponent } from './modals/restore-files/restore-files.component';
import { ShareLinkComponent } from './modals/share-link/share-link.component';
import { TagInputModule } from './ngx-chips';
import { DragProvider } from './ngx-chips/core';
import { MoveFilesService } from './services/move-files.service';

const DEV_LOGIN = environment.devLogin;

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  providers: [MoveFilesService, DragProvider],
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements OnInit, OnDestroy {
  private server: boolean = this.serverDetection.isServer();
  mobileDevice: boolean = false;
  profileData: IProfileData;
  screenSize: string = '';
  activeModal: string = '';
  activeModalData: any;
  menuSource: string = '';
  closeModal: boolean = true;
  escapeModal: boolean = true;
  customModalClass: string = '';
  scrollTopFix: any;
  openModal: boolean = false;
  htmlWrap: Element;
  private refs: IRefsMap = {};

  constructor(
    private loginService: LoginService,
    private cdRef: ChangeDetectorRef,
    private serverDetection: ServerDetectionService,
    private router: Router,
    private locationService: LocationService,
    private ngxModal: NgxSmartModalService,
    private officeSuiteSupportService: OfficeSuiteSupportService,
    private responsiveLayoutService: ResponsiveLayoutService,
    private changeDetectionManager: ChangeDetectionManagerService,
    private deviceService: DeviceDetectorService,
    private loginSessionService: UserSessionService
  ) {
    let isiPad;
    const isMobile = () => {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    };
    // Check for iPad 7th Gen
    if (navigator.userAgent.match(/Mac/g) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
      isiPad = true;
    }
    // Set Device type data
    if ((isMobile() || isiPad) && !environment.production) {
      this.mobileDevice = true;
    }
    if (this.deviceService.isMobile() || isiPad) {
      this.mobileDevice = true;
    }
  }

  ngOnInit(): void {
    if (!this.server) {
      this.htmlWrap = document.getElementsByClassName('main-wrapper')[0];
      // Set proper Responsive Layout
      this.responsiveLayoutService.startResponsiveListener();
      this.screenSize = this.responsiveLayoutService.getScreenSize();
      this.refs.screenChange = this.responsiveLayoutService.screenChange.subscribe((screen) => {
        this.screenSize = screen;
        this.cdRef.detectChanges();
      });
    }

    // Listen and apply data for Login User
    this.refs.loggedInData = this.loginService.loggedInData.subscribe((result) => {
      this.profileData = result;
      if (!DEV_LOGIN) {
        // Start Session Checkers
        this.loginSessionService.startSessionManager();
      }

      this.cdRef.detectChanges();
    });

    // Trigger Action Modal for Shared Link
    this.officeSuiteSupportService.showSharedDialog.subscribe((data) => {
      this.openActionModal(data.view, data.files, data.menuSource);
    });

    // Navigation End Handlers
    this.refs.router = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      // Close Modal on Route change
      if (!this.server) {
        this.ngxModal.getModal('actionModal')?.close();
      }
    });
  }

  openActionModal(actionName: string, selectedFiles: any[], menuSource?: string): void {
    this.ngxModal.setModalData({ view: actionName, files: selectedFiles, menuSource: menuSource }, 'actionModal');
    this.ngxModal.resetModalData('actionModal');
    this.ngxModal.getModal('actionModal').open();
  }

  setActionModal(modal): void {
    const modalData = this.ngxModal.getModalData(modal.identifier);
    this.customModalClass = 'view-' + modalData.view;

    this.activeModal = modalData.view;
    this.activeModalData = modalData.files;
    this.menuSource = modalData.menuSource ? modalData.menuSource : '';
  }

  checkModalLayout(): void {
    this.openModal = true;
    this.locationService.setModalState(this.openModal);

    // Register overlay close listener (fix for chrome mouseup close bug)
    this.htmlWrap.addEventListener('mousedown', this.closeModalByOverlayClick);

    const getBodyScrollTop = () => {
      const el = document.scrollingElement || document.documentElement;
      return el.scrollTop;
    };

    if (this.mobileDevice) {
      this.scrollTopFix = getBodyScrollTop();
      document.getElementsByTagName('html')[0].classList.add('mobile-modal-view');

      if (document.getElementsByClassName('ft-body-list')[0]) {
        document.getElementsByClassName('ft-body-list')[0].setAttribute('style', 'top:' + -this.scrollTopFix + 'px');
      }
    }
    // Disable Change detection for Routing component when modal opens
    this.changeDetectionManager.detachChangeDetection();
  }

  private closeModalByOverlayClick = (e) => {
    if (e.target.classList.contains('overlay') && e.target.classList.contains('nsm-overlay-open')) {
      this.htmlWrap.removeEventListener('mousedown', this.closeModalByOverlayClick);
      this.ngxModal.getModal('actionModal').close();
    }
  };

  closeActionModal(e): void {
    this.htmlWrap.removeEventListener('mousedown', this.closeModalByOverlayClick);

    this.openModal = false;
    this.locationService.setModalState(this.openModal);
    this.customModalClass = '';

    if (this.mobileDevice) {
      document.getElementsByTagName('html')[0].classList.remove('mobile-modal-view');

      if (document.getElementsByClassName('ft-body-list')[0]) {
        document.getElementsByClassName('ft-body-list')[0].setAttribute('style', '');
        document.getElementsByTagName('html')[0].scrollTop = this.scrollTopFix;
        window.scrollTo(this.scrollTopFix, this.scrollTopFix);
      }
    }
    // Enable Change detection for Routing component when modal close
    this.changeDetectionManager.attachChangeDetection();

    this.activeModal = '';
    this.activeModalData = '';
  }

  ngOnDestroy() {
    // Clean refs
    for (const ref in this.refs) {
      if (this.refs[ref]) {
        this.refs[ref].unsubscribe();
      }
    }
  }
}

@NgModule({
  declarations: [
    ModalComponent,
    RenameFileComponent,
    MoveFilesComponent,
    MoveFilesTitleComponent,
    NewFolderCreatorComponent,
    FolderListComponent,
    DeleteFilesComponent,
    RestoreFilesComponent,
    NewFolderComponent,
    ShareLinkComponent,
    InviteComponent,
    NewItemComponent,
    OSLastVersionComponent,
    PromoOsTrialComponent,
    ErrorReportComponent,
    FileSizeErrorComponent,
    ActivationSuccessComponent,
    InstallOfficeComponent,
    RemoveRecentFilesComponent,
    FileVersioningComponent,
    ChangeLanguageComponent,
    FilePropertiesComponent,
    PlanMessageComponent,
  ],
  imports: [SharedModule, ReactiveFormsModule, NgxSmartModalModule, TagInputModule, RouterModule],
})
export class ModalModule {}
