<section class="modal-wrap">
  <div class="modal-body cfix">
    <div class="view-box cfix new-item">
      <div class="new-item-header">
        <div class="new-item-circle"></div>
        <img
          *ngIf="!filesData?.file?.[0].thumbImage"
          class="new-item-image"
          src="assets/images/modules/{{
            menuSource || (filesData.appCode === 'openWithPdfExtra' ? 'pdf-extra-logo' : 'OfficeSuiteIcon')
          }}.svg"
          alt="module"
        />
        <ng-container *ngIf="filesData?.file?.[0].thumbImage">
          <div class="new-item-wrapper">
            <span
              *ngIf="filesData.file[0].thumbImage !== 'error'"
              class="img-thumb"
              [ngStyle]="{ 'background-image': 'url(' + filesData.file[0].thumbImage + ')' }"
            ></span>
          </div>
        </ng-container>
        <h5 class="new-item-title" data-qa="im-title">
          {{ (!filesData.file ? 'modal.' + menuSource : filesData.file[0].name) | translate }}
        </h5>
        <p class="new-item-size" *ngIf="filesData.file">{{ filesData.file[0].size | byteSize }}</p>
      </div>
      <button type="button" class="button col-blue medium-size sub-modal-button new-item-button" (click)="download()">
        {{
          (!filesData.file
            ? 'modal.createWithOS'
            : filesData.appCode === 'openWithPdfExtra'
            ? 'actionMenu.openWithPdfExtra'
            : 'actionMenu.openWithOffice'
          ) | translate
        }}
      </button>
      <div class="new-item-separate"></div>
      <div class="new-item-footer">
        <div class="new-item-get-free">
          <img
            class="new-item-get-free-icon"
            src="assets/images/logos/{{ filesData.appCode === 'openWithPdfExtra' ? 'MobiPDF-Primary-icon' : 'OfficeSuiteIcon' }}.svg"
            alt=""
          />
          <p class="new-item-get-free-text">
            {{ (filesData.appCode === 'openWithPdfExtra' ? 'modal.noPDFExtra' : 'modal.noOfficeSuite') | translate }}
            <a class="install-office-links free" [attr.href]="downloadLink" target="_blank" (click)="modal.close()">
              {{ 'modal.getItFree' | translate }}
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
