<div class="logo-area {{ view + '-view' }}" [ngClass]="{ static: staticPage }">
  <ng-container *ngIf="view === 'regular' || staticPage">
    <ng-container *ngIf="staticPage">
      <div class="mobisystems-logo" *ngIf="!noMSlogo.includes(pageName)">
        <a target="_blank" href="https://www.mobisystems.com/" tabindex="0" (click)="trackEvent()">
          <ng-container *ngTemplateOutlet="mobisystemsLogoImg"></ng-container>
        </a>
      </div>
      <a *ngIf="!(pageName === 'pricing-plans' && hasProfileData)" [routerLink]="['/']" [queryParams]="{ vp: 'true' }" tabindex="0">
        <ng-container *ngTemplateOutlet="mobidriveLogoImg"></ng-container>
      </a>
      <a *ngIf="pageName === 'pricing-plans' && hasProfileData" [routerLink]="['/my-files']" tabindex="0">
        <ng-container *ngTemplateOutlet="mobidriveLogoImg"></ng-container>
      </a>
    </ng-container>

    <a *ngIf="!staticPage" [routerLink]="['/my-files']" tabindex="0">
      <ng-container *ngTemplateOutlet="mobidriveLogoImg"></ng-container>
    </a>
  </ng-container>

  <ng-container *ngIf="view === 'mobile' && !staticPage">
    <a [routerLink]="['/my-files']" tabindex="0">
      <img src="../../../../assets/images/logos/MobiDrive-Icon.svg" alt="MobiDrive Logo" />
    </a>
  </ng-container>
</div>

<ng-template #mobisystemsLogoImg>
  <img src="../../../../assets/images/logos/MobiSystems-icon.svg" alt="MobiSystems Logo" />
</ng-template>

<ng-template #mobidriveLogoImg>
  <img src="../../../../assets/images/logos/MobiDrive-Primary.svg" alt="MobiDrive Logo" />
</ng-template>
