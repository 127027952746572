// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  GA_production: false,
  devLogin: false,
  version: "1.01.1",
  envName: 'Experimental',

  apiUrl: 'https://exp.connect.mobisystems.com/api',
  accountsLink: 'https://exp.accounts.mobisystems.com',
  officesuiteLink: 'https://exp.officesuite.com',
  downloadUrl: 'https://us-central1-mobisystems-drive-exp.cloudfunctions.net',
  mDomain: 'exp.mobidrive.com',
  mobisystemsURL: 'https://exp.mobisystems.com',
  helpCenterURL: 'https://exp.mobisystems.com/help-center/support/?prod=mobidrive&platform=web',
  storeApi: 'https://exp.checkout.mobisystems.com',

  // google recaptcha enterprise options
  recaptchaSiteKey: '6LeSGCsmAAAAAHEmxfouUSCjac1ZFE6mOaXtws88',
  recaptchaProjectId: 'mobisystems-drive-exp',
  recaptchaMinimumScore: 0.3
};
