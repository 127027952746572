import { Component, OnDestroy, OnInit } from '@angular/core';
import { ManageCookieService } from '../../services/manage-cookie.service';
import { LoginService } from '../../services/login.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { IRefsMap } from '../../models/common.int';
import { AdvanceDeviceDetectionService } from '../../services/advance-device-detection.service';

@Component({
  selector: 'cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss'],
})
export class CookieBannerComponent implements OnInit, OnDestroy {
  constructor(private manageCookieService: ManageCookieService,
              private router: Router,
              private deviceService: AdvanceDeviceDetectionService,
              private loginService: LoginService) {}
  private refs: IRefsMap = {};
  showCB: boolean = false;
  isUpPosition: boolean = false;
  isMobile: boolean;

  ngOnInit(): void {
    this.showCB = this.manageCookieService.getCookie('cookieConsent') ? false : true;
    this.isMobile = document.getElementsByTagName('html')[0].classList.contains('mobile-device');
    this.refs.routerEvents = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event.urlAfterRedirects.startsWith('/sharelink') || event.urlAfterRedirects.startsWith('/shared-folder')) {
          this.loginService.getLoggedResult().then(({ loggedUser }) => {
            if (!(this.isMobile && this.deviceService.getDeviceInfo().os === 'Android')) {
              this.isUpPosition = !loggedUser;
            } else {
              this.isUpPosition = false;
            }
          });
        } else {
          this.isUpPosition = false;
        }
      });
  }

  closeBanner() {
    this.showCB = false;
    this.manageCookieService.setCookie('cookieConsent', 'true', { period: 'd', value: 365 });
  }

  ngOnDestroy(): void {
    for (const ref in this.refs) {
      if (this.refs[ref]) {
        this.refs[ref].unsubscribe();
      }
    }
  }
}
