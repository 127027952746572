import {
  Component,
  OnInit,
  Output,
  Input,
  EventEmitter,
  ViewChild,
  ElementRef,
  HostListener,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ApplyActionService } from '../../../../../services/apply-action.service';
import { AuthService } from '../../../../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { MoveFilesService } from '../../../services/move-files.service';
import { fadeRegularAnimation } from '../../../../../app-animations/app-animations';
import { IRefsMap } from '../../../../../models/common.int';

@Component({
  selector: 'new-folder-creator',
  templateUrl: './new-folder-creator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeRegularAnimation(250, 0)],
})
export class NewFolderCreatorComponent implements OnInit {
  @ViewChild('folderName') folderName: ElementRef;

  @Output() toggleFolderFormEvent = new EventEmitter();
  @Input() loadingFolders: boolean = true;

  newFolderForm: FormGroup;
  fc: any;
  defaultName: string = 'New folder';
  showForm: boolean = false;
  folderLocation: any;
  loadingPath: boolean = true;

  refs: IRefsMap = {};

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event) {
    if (event.keyCode === 13) {
      if (this.showForm && !this.fc.folderName.errors) {
        this.createFolder();
      }
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private applyActionService: ApplyActionService,
    private authService: AuthService,
    private translate: TranslateService,
    private cdRef: ChangeDetectorRef,
    private moveFilesService: MoveFilesService
  ) {}

  ngOnInit(): void {
    // Translate folderName when create new folder
    this.translate.get('modal.newFolder').subscribe((data) => {
      this.defaultName = data;
      this.cdRef.detectChanges();
    });

    // Listen for Create Folder, Move
    this.refs.actionServiceRef = this.applyActionService.actionComplete.subscribe((data) => {
      if (data.action === 'create-folder') {
        this.fc.folderName.enable();
        this.fc.folderName.value = this.defaultName;
        const folderLocationData = {
          account: data.status.account,
          name: data.status.name,
          key: data.status.key,
          parent: data.status.parent,
        };

        this.moveFilesService.navigateToFolder(folderLocationData, folderLocationData.key);
      }
    });

    // Listen for Create Folder, Move
    this.refs.locationChanged = this.moveFilesService.locationChanged.subscribe((data) => {
      this.folderLocation = data;
      this.loadingPath = false;
      this.cdRef.detectChanges();
    });

    // Init Create Folder Form
    this.newFolderForm = this.formBuilder.group({
      folderName: [
        this.defaultName,
        [Validators.required, Validators.maxLength(255), Validators.pattern('(?!\\s*(?:\\.\\s*)+$)[^\\\\:*?"<>|/]+')],
      ],
    });
    this.newFolderForm.valueChanges.subscribe((value) => {
      const startWithSpace = value.folderName.match(/^\s+/g);
      if (startWithSpace) {
        const trimmedValue = value.folderName.replace(/^\s+/g, '');
        this.newFolderForm.patchValue({ folderName: trimmedValue });
      }
    });
    this.fc = this.newFolderForm.controls;

    this.folderLocation = this.moveFilesService.getCurrentLocation();
    this.loadingPath = false;

    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    // Clean refs
    for (const ref in this.refs) {
      if (this.refs[ref]) {
        this.refs[ref].unsubscribe();
      }
    }
  }

  toggleForm(): void {
    this.showForm = !this.showForm;
    this.toggleFolderFormEvent.emit(this.showForm);
    this.cdRef.detectChanges();
    if (this.showForm) {
      this.folderName.nativeElement.focus();
      this.folderName.nativeElement.select();
    }
  }

  createFolder(): void {
    this.fc.folderName.disable();
    const authData = this.authService.getUserAuth();
    const nfData = {
      account: authData.accountId,
      key: this.folderLocation.key,
      name: null,
      parent: null,
    };

    // Remove white space from the START and the END of the FileName
    const folderName = this.fc.folderName.value.trim();
    this.applyActionService.createFolderAction(nfData, folderName + '/');
    this.toggleForm();

    this.moveFilesService.createFolderNotification();
  }

  openPreviousFolder() {
    this.moveFilesService.navigateToFolder(this.folderLocation.parent, this.folderLocation.parent.key);
  }
}
