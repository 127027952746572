import { Component, OnInit, Input, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { LocationService } from '../../../../services/location.service';
import { UploadDataService } from '../../../../services/upload/upload-data.service';
import { NavigationHandlerService } from '../../../../services/navigation-handler.service';
import { OfficeSuiteSupportService } from '../../../../services/office-suite-support.service';
import { TrackingService } from '../../../../services/tracking.service';
import { ActionMenuService } from '../../../../services/action-menu.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ManageCookieService } from '../../../../services/manage-cookie.service';

@Component({
  selector: 'upload-button',
  templateUrl: './upload-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadButtonComponent implements OnInit, OnDestroy {
  @Input() view: string = 'regular-view'; // regular-view, mobile-view
  @Input() set disableButton(netError) {
    this.buttonStateToggle(!netError);
  }

  hasLocationData: boolean = false;
  canUploadFolders: boolean = false;
  parentLocationSignalRef: any;
  pageData: any = null;

  active: boolean = true;
  disableForPages = ['backups'];

  constructor(
    private locationService: LocationService,
    private ngxModal: NgxSmartModalService,
    private manageCookieService: ManageCookieService,
    private uploadDataService: UploadDataService,
    private actionMenuService: ActionMenuService,
    private officeSuiteSupportService: OfficeSuiteSupportService,
    private navigationHandlerService: NavigationHandlerService,
    private trackingService: TrackingService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.hasLocationData = this.locationService.hasParentLocation();
    // Track if location is set for showing Upload button
    this.parentLocationSignalRef = this.locationService.parentLocationSetSignal$.subscribe((parent) => {
      if (parent) {
        this.hasLocationData = true;
        this.pageData = this.navigationHandlerService.getCurrentPageData();
        // Handle Upload Button State according to the location
        if (this.disableForPages.indexOf(this.pageData?.name) === -1) {
          this.buttonStateToggle(true);
        } else {
          this.buttonStateToggle(false);
        }

        this.canUploadFolders = this.uploadDataService.canUploadFolder();
        this.cdRef.detectChanges();
      }
    });

    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    if (this.parentLocationSignalRef) {
      this.parentLocationSignalRef.unsubscribe();
    }
  }

  cleanInput() {
    this.uploadDataService.cleanInput();
  }

  triggerUpload(uplType) {
    if (uplType === 'uplFile') {
      this.trackingService.trackEvent('web-app.usage', 'upload-button.side-bar', 'upload-files');
      // document.getElementById('uplFile').focus();
      document.getElementById('uplFile').click();
    } else {
      this.trackingService.trackEvent('web-app.usage', 'upload-button.side-bar', 'upload-folders');
      // document.getElementById('uplFolder').focus();
      document.getElementById('uplFolder').click();
    }
  }

  createNewItem(type: string) {
    const shouldShowOSLastVersionBanner = !this.manageCookieService.getCookie('is_show_OfficeSuite_last_version_banner_' + type);
    if (shouldShowOSLastVersionBanner) {
      this.openActionModal('OSLastVersion', [], type);
    } else {
      const appCode = this.officeSuiteSupportService.isOfficeSupported({name: '.doc'}, true);
      if (appCode) {
        this.officeSuiteSupportService.openNewModuleWithApp(type, appCode);
        this.trackingService.trackEvent(
          'web-app.usage',
          'upload-button.side-bar',
          'create-new-' + (type === 'presentation' ? 'slide' : type)
        );
      }
    }
  }

  createNewFolder() {
    this.trackingService.trackEvent('web-app.usage', 'upload-button.side-bar', 'create-new-folder');
    this.actionMenuService.performAction('create-folder', []);
  }

  buttonStateToggle(enable: boolean) {
    this.active = enable;
  }

  openActionModal(actionName: string, selectedFiles: any[], menuSource?: string): void {
    this.ngxModal.setModalData({ view: actionName, files: selectedFiles, menuSource: menuSource }, 'actionModal');
    this.ngxModal.resetModalData('actionModal');
    this.ngxModal.getModal('actionModal').open();
  }
}
