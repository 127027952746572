import { InAppsParamMap, InAppsPlans } from '../modules/_plans/models/in-apps-plans.model';

export const inAppsParamMap: InAppsParamMap = {
  p500weby: 'mobistore.mobidrive-personal-500.web.yearly.49.99.3d.trial',
  p500webm: 'mobistore.mobidrive-personal-500.web.monthly.4.99.notrial',
  p500web7dm: '',
  p2000weby: 'mobistore.mobidrive-personal-mobioffice-2000.web.yearly.99.99.3d.trial',
  p2000webm: 'mobistore.mobidrive-personal-mobioffice-2000.web.monthly.9.99.notrial',
  p2000web7dm: 'com.mobisystems.web.md.personal-plus-2000.9.99.7trial.monthly',
  p500winy: 'com.mobisystems.mobidrive.windows.personal-500.49.99.notrial.yearly',
  p500winm: 'com.mobisystems.mobidrive.windows.personal-500.4.99.notrial.monthly',
  p500win7dm: 'com.mobisystems.mobidrive.windows.personal-500.4.99.7trial.monthly',
  p2000winy: 'com.mobisystems.mobidrive.windows.personal-plus-2000.99.99.notrial.yearly',
  p2000winm: 'com.mobisystems.mobidrive.windows.personal-plus-2000.9.99.notrial.monthly',
  p2000win7dm: 'com.mobisystems.mobidrive.windows.personal-plus-2000.9.99.7trial.monthly'
};

export const inAppsPlans: InAppsPlans = {
  plansMapWeb: {
    listPlans: [
      { inAppId: 'default.MOBIDRIVE', highlightedByDefault: false },
      {
        inAppId: 'mobistore.mobidrive-personal-500.web.monthly.4.99.notrial',
        highlightedByDefault: false,
        monthlyPlan: 'monthlyPlanInAppId500',
        trialPlan: 'trial7dayMonthly500'
      },
      {
        inAppId: 'mobistore.mobidrive-personal-mobioffice-2000.web.monthly.9.99.notrial',
        highlightedByDefault: true,
        superSaver: true,
        monthlyPlan: 'monthlyPlanInAppId2000',
        trialPlan: 'trial7dayMonthly2000',
      },
    ],
    additional: {
      officePlanInAppId: 'com.mobisystems.web.osn.personal-no-pdf-extra.39.99.7trial.yearly',
      monthlyPlanInAppId500: 'mobistore.mobidrive-personal-500.web.yearly.49.99.3d.trial',
      monthlyPlanInAppId2000: 'mobistore.mobidrive-personal-mobioffice-2000.web.yearly.99.99.3d.trial',
      trial7dayMonthly500:'mobistore.mobidrive-personal-500.web.yearly.49.99.3d.trial',
      trial7dayMonthly2000: 'mobistore.mobidrive-personal-mobioffice-2000.web.yearly.99.99.3d.trial',
    },
  },
  plansMapWindows: {
    listPlans: [
      { inAppId: 'default.MOBIDRIVE', highlightedByDefault: false },
      {
        inAppId: 'com.mobisystems.mobidrive.windows.personal-500.49.99.notrial.yearly',
        highlightedByDefault: false,
        monthlyPlan: 'monthlyPlanInAppId500',
        trialPlan: 'trial7dayMonthly500'
      },
      {
        inAppId: 'com.mobisystems.mobidrive.windows.personal-plus-2000.99.99.notrial.yearly',
        highlightedByDefault: true,
        superSaver: true,
        monthlyPlan: 'monthlyPlanInAppId2000',
        trialPlan: 'trial7dayMonthly2000',
      },
    ],
    additional: {
      officePlanInAppId: 'com.mobisystems.windows.osn.personal-no-pdf-extra.39.99.7trial.yearly',
      monthlyPlanInAppId500: 'com.mobisystems.mobidrive.windows.personal-500.4.99.notrial.monthly',
      monthlyPlanInAppId2000: 'com.mobisystems.mobidrive.windows.personal-plus-2000.9.99.notrial.monthly',
      trial7dayMonthly500:'com.mobisystems.mobidrive.windows.personal-500.4.99.7trial.monthly',
      trial7dayMonthly2000: 'com.mobisystems.mobidrive.windows.personal-plus-2000.9.99.7trial.monthly',
    },
  }
}
