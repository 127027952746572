import { Injectable, Directive } from '@angular/core';
import { IFileUploadData, IUploadFilesStatus, IUploadProcessData, IUploadFileEvent } from '../../models/files.int';
import { BehaviorSubject, Subject } from 'rxjs';

export interface IUploadCountStatus {
  uploadsInProgress: number;
  uploadErrors: number;
  totalUploads: number;
}
export interface IUploadStatus {
  uploadFiles: IUploadFilesStatus[];
  countStatus: IUploadCountStatus;
}
export interface IUploadListItem {
  item: IUploadFilesStatus;
  listIndex: number;
}

@Injectable()
export class UploadStatusService {
  constructor() {}

  uploadsInProgress: number = 0;
  uploadErrors: number = 0;
  totalUploads: number = 0;
  uploadFiles: IUploadFilesStatus[] = [];
  completeFiles: IUploadFilesStatus[] = [];

  /* Emitters
  ================================================== */
  private currentUploadStatusSubject = new BehaviorSubject<IUploadStatus>({
    uploadFiles: this.uploadFiles,
    countStatus: {
      uploadsInProgress: this.uploadsInProgress,
      uploadErrors: this.uploadErrors,
      totalUploads: this.totalUploads,
    },
  });
  readonly currentUploadStatus = this.currentUploadStatusSubject.asObservable();

  private addNewUploadsSubject = new Subject<{ newFiles: IUploadFilesStatus[]; countStatus: IUploadCountStatus }>();
  readonly filesForUpload = this.addNewUploadsSubject.asObservable();

  private uploadEventSubject = new Subject<{ itemFromList: IUploadListItem; countStatus: IUploadCountStatus }>();
  readonly uploadEvent = this.uploadEventSubject.asObservable();

  /* Upload Reportes
  ================================================== */
  obtainCurrentStatus(): IUploadStatus {
    return {
      uploadFiles: this.uploadFiles,
      countStatus: {
        uploadsInProgress: this.uploadsInProgress,
        uploadErrors: this.uploadErrors,
        totalUploads: this.totalUploads,
      },
    };
  }

  emitCurrentUploadStatus() {
    const currentStatus = this.obtainCurrentStatus();
    this.currentUploadStatusSubject.next(currentStatus);
  }

  emitUploadEvent(itemFromList: IUploadListItem) {
    this.uploadEventSubject.next({
      itemFromList: itemFromList,
      countStatus: {
        uploadsInProgress: this.uploadsInProgress,
        uploadErrors: this.uploadErrors,
        totalUploads: this.totalUploads,
      },
    });
  }

  prepareForUpload(filesUploadList: IFileUploadData[]) {
    const newUploadItems = [];
    filesUploadList.forEach((uploadItem: IFileUploadData) => {
      const uplItem: IUploadFilesStatus = {
        ...uploadItem,
        curStatus: 'in-progress',
        animate: 'no',
        progressStatus: 0,
      };
      newUploadItems.push(uplItem);
    });

    this.uploadsInProgress += newUploadItems.length;
    this.uploadFiles.push(...newUploadItems);

    this.addNewUploadsSubject.next({
      newFiles: newUploadItems,
      countStatus: {
        uploadsInProgress: this.uploadsInProgress,
        uploadErrors: this.uploadErrors,
        totalUploads: this.totalUploads,
      },
    });
  }

  /* Update upload status
  ================================================== */
  uploadFileEvent(uploadFileEvent: IUploadFileEvent) {
    const uploadFileData: IUploadProcessData = uploadFileEvent.data;
    const newOriginalItemKey = uploadFileEvent.file?.key;
    const itemFromList: IUploadListItem = this.findItemFromList(uploadFileData);

    if (itemFromList) {
      if (uploadFileEvent.type === 'error') {
        this.errorUpload(itemFromList, uploadFileData);
      } else if (uploadFileEvent.type === 'complete') {
        this.completeUpload(itemFromList, newOriginalItemKey);
      } else {
        // update and update-folder
        this.updateUploadStatus(uploadFileData, itemFromList);
      }

      this.emitUploadEvent(itemFromList);
    }
  }

  updateUploadStatus(uplData: IUploadProcessData, listItem: IUploadListItem) {
    // Set Property if has data
    if (uplData.setProp) {
      this.propKeySetter(uplData.setProp, listItem.item.file, uplData.file);
    }
    // Update progress if has data
    if (uplData.updateProgress) {
      this.updateProgressStatus(listItem, uplData.progressData, uplData.file);
    }
    // Update streamCommit file property
    if (uplData.streamCommit) {
      listItem.item.curStatus = 'commited';
    }
  }

  updateProgressStatus(listItem: IUploadListItem, progressData, fileRef) {
    // Update Files only
    const item = listItem.item;
    if (!progressData.folderRelated) {
      item.progressStatus = progressData.percentDone;
      item.file.bytesLeft = progressData.bytesLeft;
      item.file.timePart = progressData.timePart ? progressData.timePart : '0.1';
    } else {
      // Create refference pool
      if (!item.file.folderItemsStreams) {
        item.file.folderItemsStreams = [];
      }

      if (fileRef.dir) {
        item.file.uploadedItems++;
        item.file.completedItems += 1;
      } else {
        if (progressData.createStream) {
          item.file.folderItemsStreams.push(fileRef);
        }
        if (progressData.completeUpload) {
          item.file.uploadedItems++;
          item.file.completedItems += 1;
        }
      }

      this.checkFolderUpload(listItem);
    }
  }

  checkFolderUpload(listItem: IUploadListItem) {
    // Check if all items from folder are uploaded
    const item = listItem.item;
    const processedItems = item.file.uploadedItems + item.file.subItemsErrors.length;
    if (item.file.numberOfItems === processedItems) {
      item.curStatus = 'complete';

      if (item.file.subItemsErrors.length) {
        const regularErrors = item.file.subItemsErrors.filter((errorItem) => {
          return errorItem.errorData.status !== 'error-warning';
        });
        if (!regularErrors.length) {
          item.curStatus = 'error-warning';
        }
      }
      this.completeUpload(listItem);
    }
  }

  completeUpload(completedFile: IUploadListItem, newOriginalKey?: string) {
    this.totalUploads++;
    const listItemFile = completedFile.item;
    // Handle files
    if (!listItemFile.file.rootFolder) {
      const lightFile = {
        animate: 'no' as const,
        curStatus: 'complete' as const,
        progressStatus: 100,
        file: {
          folder: listItemFile.file.folder,
          curStatus: 'complete',
          name: listItemFile.file.name,
          size: listItemFile.file.size,
          key: newOriginalKey,
        },
        parent: listItemFile.parent,
      };
      this.uploadFiles[completedFile.listIndex] = { ...lightFile };
    }
    this.updateUploadIndicator();
  }

  /* Error status 
  ================================================== */
  errorUpload(itemFromList: IUploadListItem, fileErrorData: IUploadProcessData): void {
    const listItem: IUploadFilesStatus = itemFromList.item;
    if (listItem.file.rootFolder) {
      this.errorFolderUpload(fileErrorData, itemFromList);
    } else {
      listItem.error = fileErrorData.error;
      listItem.curStatus = fileErrorData.error.status;
      listItem.progressStatus = 0;
      listItem.file.bytesLeft = 0;
      listItem.file.streamCommit = false;
      this.uploadErrors += 1;
      this.updateUploadIndicator();
    }
  }

  errorFolderUpload(errorItem: any, itemFromList: IUploadListItem) {
    const listItem: IUploadFilesStatus = itemFromList.item;
    // Error for Root Folder
    if (errorItem.findBy === 'fid') {
      listItem.file.error = errorItem.error;
      listItem.curStatus = 'error';
      this.uploadErrors += 1;
    } else {
      // Error for sub Folder items
      const errorReport = {
        action: errorItem.folderObj ? 'upload-folder' : 'upload-file',
        errorData: errorItem.error,
        obj: errorItem.file,
      };

      listItem.file.completedItems += 1;
      if (errorItem.folderObj) {
        const affectedItems = this.getAllFolderErrors(errorItem.folderObj);
        this.uploadErrors += affectedItems.length;
        listItem.file.completedItems += affectedItems.length;
        affectedItems.unshift(errorReport);
        listItem.file.subItemsErrors = listItem.file.subItemsErrors.concat(affectedItems);
      } else {
        errorItem.notUploaded = true;
        this.uploadErrors++;
        listItem.file.subItemsErrors.push(errorReport);
      }

      // Collect retry upload data
      listItem.file.errorRetryItems.push(errorItem);

      // Check folder status
      this.checkFolderUpload(itemFromList);
    }
  }

  getAllFolderErrors(folder) {
    const errors = [];
    const checkLevel = (folderItem) => {
      folderItem.content.map((item) => {
        const localError = {
          action: item.folder ? 'upload-folder' : 'upload-file',
          errorData: { name: 'parentFolderError' },
          obj: item.folder ? item.folder : item.file,
        };
        errors.push(localError);

        if (item.folder) {
          checkLevel(item);
        }
      });
    };
    checkLevel(folder);
    return errors;
  }

  /* Action handlers
  ================================================== */
  retryUploadStatus(item: IUploadFilesStatus, uploadErrors = 1) {
    item.curStatus = 'in-progress';
    this.uploadsInProgress++;
    this.uploadErrors -= uploadErrors;
    if (item.file.rootFolder) {
      this.totalUploads--;
    }

    this.emitUploadEvent({ item: item, listIndex: this.uploadFiles.indexOf(item) });
  }

  removeUploadStatus(item: IUploadFilesStatus) {
    this.uploadsInProgress--;
    const index = this.uploadFiles.indexOf(item);
    const removed = this.uploadFiles.splice(index, 1);
    this.emitUploadEvent({ item: removed[0], listIndex: index });
  }

  /* Utils Methods
  ================================================== */
  findItemFromList(data: IUploadProcessData): IUploadListItem {
    let findItem: IUploadListItem = null;
    for (let i = 0, ln = this.uploadFiles.length; i < ln; i++) {
      if (this.uploadFiles[i].curStatus && this.uploadFiles[i].file[data.findBy] === data.file[data.findBy]) {
        findItem = { item: this.uploadFiles[i], listIndex: i };
        break;
      }
    }
    return findItem;
  }

  propKeySetter(keys, hostObj, sourceObj) {
    const isArray = Array.isArray(keys);
    if (isArray) {
      keys.forEach((key) => {
        hostObj[key] = sourceObj[key];
      });
    } else {
      hostObj[keys] = sourceObj[keys];
    }
  }

  cleanUploadStatus() {
    this.uploadsInProgress = 0;
    this.uploadErrors = 0;
    this.totalUploads = 0;
    this.uploadFiles = [];
    this.emitCurrentUploadStatus();
  }

  updateUploadIndicator() {
    this.uploadsInProgress--;
    if (this.uploadsInProgress <= 0) {
      this.uploadsInProgress = 0;
    }
  }
}
