<section class="modal-wrap rename-modal">
  <h5 *ngIf="!isFolder" class="modal-title">{{ 'modal.newFileName' | translate }}</h5>
  <h5 *ngIf="isFolder" class="modal-title">{{ 'modal.newFolderName' | translate }}</h5>

  <div class="modal-body cfix">
    <form [formGroup]="renameForm" id="renameForm" class="single-item-form" *ngIf="!warningMessage">
      <div class="form-item cfix">
        <div class="input-wrapper cfix">
          <input
            type="text"
            formControlName="editName"
            #editName
            class="input-style"
            [ngClass]="{
              'input-error': fc.editName.errors && (fc.editName.errors.pattern || fc.editName.errors.maxlength)
            }"
          />
        </div>
      </div>
      <div class="error-box">
        <div class="form-error" *ngIf="fc.editName.errors && fc.editName.errors.maxlength">
          <span *ngIf="isFolder">{{ 'errorMessages.folderLongName' | translate }}</span>
          <span *ngIf="!isFolder">{{ 'errorMessages.fileLongName' | translate }}</span>
        </div>
        <div class="form-error" *ngIf="fc.editName.errors && fc.editName.errors.pattern">
          {{ 'validation.filenameRules' | translate }}
        </div>
      </div>
    </form>

    <div class="warning-message" *ngIf="warningMessage">
      <p>
        {{ 'modal.unusableWarning' | translate }}
      </p>
      <p>
        {{ 'modal.confirmChange' | translate }}
      </p>
    </div>
  </div>

  <footer class="modal-footer cfix">
    <button class="button col-blue medium-size" [disabled]="renameForm.invalid || loading" (click)="submitForm()">
      {{ 'modal.rename' | translate }}
    </button>
    <button class="button col-grey medium-size" [disabled]="loading" (click)="cancelAction()">
      {{ 'modal.cancel' | translate }}
    </button>
  </footer>
</section>
