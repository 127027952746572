import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IRefsMap } from '../../../models/common.int';
import { LanguageManagerService } from '../../../services/language-manager.service';
import { IProfileData, LoginService } from '../../../services/login.service';
import { TrackingService } from '../../../services/tracking.service';
import { IProfileMenuItem, UserProfileMenuService } from '../../../services/user-profile-menu.service';
@Component({
  selector: 'profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileMenuComponent implements OnInit, OnDestroy {
  @Input() set profileData(profileData: IProfileData) {
    this.userProfileData = profileData;
    if (profileData) {
      this.userProfileMenu = this.userProfileMenuService.getProfileMenu(profileData);
      this.upgradeButton = this.userProfileMenu.shift();
    }
  }
  @Input() showNotifier: boolean = false;
  @Input() staticPage: boolean;

  userProfileData: IProfileData;
  userProfileMenu: IProfileMenuItem[];
  upgradeButton: IProfileMenuItem = null;
  refs: IRefsMap = {};
  download_page: string;

  constructor(
    private loginService: LoginService,
    private userProfileMenuService: UserProfileMenuService,
    private languageManagerService: LanguageManagerService,
    private cdRef: ChangeDetectorRef,
    private trackingService: TrackingService
  ) {}

  ngOnInit(): void {
    this.refs.translateRef = this.languageManagerService.finishLanguageChange.subscribe(() => {
      if (this.userProfileData) {
        this.userProfileMenu = this.userProfileMenuService.getProfileMenu(this.userProfileData, this.download_page);
        if (!this.staticPage) {
          this.upgradeButton = this.userProfileMenu.shift();
        }
      }
      this.cdRef.detectChanges();
    });
  }

  ngOnDestroy() {
    // Clean refs
    for (const ref in this.refs) {
      if (this.refs[ref]) {
        this.refs[ref].unsubscribe();
      }
    }
  }

  handleProfileMenu(event: Event, methodName: string, url: string, trackEvent: { category: string; action: string; label: string }) {
    if (trackEvent) {
      this.trackingService.trackEvent(trackEvent.category, trackEvent.action, trackEvent.label);
    }
    this.download_page = btoa(window.location.protocol + '//' + window.location.host + window.location.pathname);
    if (methodName && url) {
      this.userProfileMenuService[methodName](event);
    } else if (methodName) {
      event.preventDefault();
      this.userProfileMenuService[methodName](event);
    }
  }

  signIn(e: Event) {
    this.trackingService.trackEvent('website.engage', 'login', 'nav-bar.button');
    e.preventDefault();
    this.loginService.signIn();
  }
}
