import { Component, ChangeDetectionStrategy } from '@angular/core';
import { OfficeSuiteSupportService } from '../../../../services/office-suite-support.service';
import { TrackingService } from '../../../../services/tracking.service';

@Component({
  selector: 'store-buttons',
  templateUrl: './store-buttons.component.html',
  styleUrls: ['./store-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoreButtonsComponent {
  constructor(private officeSuiteSupportService: OfficeSuiteSupportService, private trackingService: TrackingService) {}

  getApp(e, platform) {
    e.preventDefault();
    const mobiDriveStoreLink = this.officeSuiteSupportService.getMobileStoreLink('openWithMobiDrive', {
      store: platform,
    });
    this.trackingService.trackEvent('website.download', platform, 'content.button');
    window.open(mobiDriveStoreLink, '_blank');
  }
}
