import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { DownloadManagerService } from './download-manager.service';
import { DisabledItemsManagerService } from './disabled-items-manager.service';
import { BulkOperationsService } from './bulk-operations.service';
import { guideGenerator } from '../helpers/guid-generator';
import { OfficeSuiteSupportService } from './office-suite-support.service';
import { CacheService } from './cache.service';
import { Subject } from 'rxjs';

declare global {
  interface Window {
    protocolCheck: any;
  }
}

export interface IActionData {
  status?: any;
  action?: string;
  file?: any[];
  files?: any[];
  obj?: any;
  processComplete?: boolean;
  errorData?: any;
  shared?: boolean;
  menuSource?: string;
  extraParams?: any;
  noCache?: boolean;
  hasUndo?: boolean;
  history?: any;
  bulkProcess?: boolean;
  actionFromUndo?: boolean;
}

interface IActionInfo {
  [key: string]: {
    items: any[];
    active: number;
  };
}

@Injectable()
export class ApplyActionService {
  private actionCompleteSubject = new Subject<IActionData>();
  readonly actionComplete = this.actionCompleteSubject.asObservable();
  private uploadFolderSubject = new Subject<any>();
  readonly uploadFolder = this.uploadFolderSubject.asObservable();
  private startActionSubject = new Subject<IActionData>();
  readonly startAction = this.startActionSubject.asObservable();

  actionInfo: IActionInfo = {};

  constructor(
    private authService: AuthService,
    private cacheService: CacheService,
    private disabledFilesManager: DisabledItemsManagerService,
    private bulkOperationsService: BulkOperationsService,
    private officeSuiteSupportService: OfficeSuiteSupportService,
    private downloadManagerService: DownloadManagerService
  ) {
    // Create actionInfo object
    ['rename', 'delete', 'remove_recent', 'delete_permanent', 'move', 'copy', 'share', 'restore', 'create_folder'].forEach((el) => {
      this.actionInfo[el] = {
        items: [],
        active: 0,
      };
    });
  }

  /* Rename Files
  ================================================== */
  renameAction(files, newName: string, menuSource: string = null): void {
    this.reportActionStart({
      action: 'rename',
      files: files,
      menuSource: menuSource,
      hasUndo: true,
      extraParams: { newName: newName },
    });
  }

  process_rename(item): void {
    this.authService.authRequest('files', 'rename-adv', { id: item, 'new-name': item.newName, strategy: 'duplicate' }).subscribe(
      (result) => {
        this.reportActionComplete({
          status: result,
          action: 'rename',
          obj: item,
          processComplete: true,
          menuSource: item.menuSource,
          noCache: true,
        });
      },
      (error) => {
        this.reportActionComplete({
          status: 'error',
          action: 'rename',
          errorData: error,
          obj: item,
          processComplete: true,
          menuSource: item.menuSource,
          noCache: true,
        });
      }
    );
  }

  /* Create Folder
  ================================================== */
  createFolderAction(parentData, newName: string, uploadFolder?): void {
    // Report for starting process and determine action name
    let actionName = 'upload-folder';
    if (!uploadFolder) {
      actionName = 'create-folder';
      this.startActionSubject.next({ action: actionName });
    }

    this.cacheService.updateProcessesStatus('start', 1);
    this.actionInfo.create_folder.items.push({
      parent: parentData,
      name: newName,
      uploadF: uploadFolder,
      action: actionName,
      dir: true,
    });

    // Handle process que
    this.checkPendingProcess('create_folder', 'process_folder');
  }

  process_folder(folder): void {
    this.authService.authRequest('files', 'mkdir-adv', { parent: folder.parent, name: folder.name, strategy: 'duplicate' }).subscribe(
      (result) => {
        this.actionInfo.create_folder.active--;

        const emitData: IActionData = { status: result, action: folder.action, obj: folder, processComplete: true };
        this.cacheService.recoredCache(emitData);
        if (folder.action === 'upload-folder') {
          // this.uploadManagerService.addFolder(emitData, uploadFolder.content);
          // Create Process-Manager-Service and move runningProcesses and recordCache Logic !!!
          this.uploadFolderHandler(folder, emitData, 'success');
        }

        this.actionCompleteSubject.next(emitData);
        this.cacheService.updateProcessesStatus('end', 1);
        this.checkPendingProcess('create_folder', 'process_folder');
      },
      (error) => {
        this.actionInfo.create_folder.active--;
        const emitData: IActionData = {
          status: 'error',
          action: folder.action,
          errorData: error,
          obj: folder,
          processComplete: true,
        };
        this.cacheService.recoredCache(emitData);
        if (folder.action === 'upload-folder') {
          this.uploadFolderHandler(folder, emitData, 'error');
        }

        this.actionCompleteSubject.next(emitData);
        this.cacheService.updateProcessesStatus('end', 1);
        this.checkPendingProcess('create_folder', 'process_folder');
      }
    );
  }

  uploadFolderHandler(folder, emitData: IActionData, respondCase: string): void {
    const rootObject = respondCase === 'success' ? emitData.status : emitData.obj;

    if (folder.uploadF.rootFolder) {
      rootObject.originalName = folder.uploadF.folder.name;
      rootObject.fid = folder.uploadF.fid;
      rootObject.rootKey = respondCase === 'success' ? emitData.status.key : emitData.errorData.key;
    } else {
      rootObject.rootKey = folder.uploadF.folder ? folder.uploadF.folder.rootKey : folder.uploadF.rootKey;
    }

    this.uploadFolderSubject.next({
      file: rootObject,
      error: emitData.errorData,
      findBy: folder.uploadF.rootFolder ? 'fid' : 'rootKey',
      setProp: folder.uploadF.rootFolder ? ['key', 'rootKey'] : false, // Set both key and root key
      updateProgress: true,
      progressData: {
        folderRelated: true,
        addFolder: true,
      },
      folderObj: folder.uploadF,
    });

    this.cacheService.recoredCache(emitData);
  }

  removePendingFolder(fid): void {
    this.actionInfo.create_folder.items = this.actionInfo.create_folder.items.filter((folderObj) => folderObj.uploadF.fid !== fid);
  }

  /* Delete Files
  ================================================== */
  deleteAction(files, menuSource: string = null): void {
    this.reportActionStart({
      action: 'delete',
      files: files,
      menuSource: menuSource,
      hasUndo: true,
      extraParams: null,
    });
  }

  process_delete(item): void {
    this.authService.authRequest('files', 'delete-to-bin', { id: item }).subscribe(
      (result) => {
        this.reportActionComplete({
          status: result,
          action: 'delete',
          obj: item,
          processComplete: !this.actionInfo.delete.items.length && !this.actionInfo.delete.active,
          menuSource: item.menuSource,
        });
      },
      (error) => {
        this.reportActionComplete({
          status: 'error',
          action: 'delete',
          errorData: error,
          obj: item,
          processComplete: !this.actionInfo.delete.items.length && !this.actionInfo.delete.active,
          menuSource: item.menuSource,
        });
      }
    );
  }

  /* Permanent Delete Files
  ================================================== */
  deletePermanentAction(files, menuSource: string = null, reportUndo: boolean = false): void {
    this.reportActionStart({
      action: 'delete-permanent',
      files: files,
      menuSource: menuSource,
      hasUndo: reportUndo,
      extraParams: null,
    });
  }

  process_delete_permanent(item): void {
    this.authService.authRequest('files', 'delete-permanent', { id: item }).subscribe(
      (result) => {
        this.reportActionComplete({
          status: result,
          action: 'delete-permanent',
          obj: item,
          processComplete: !this.actionInfo.delete_permanent.items.length && !this.actionInfo.delete_permanent.active,
          menuSource: null, // TODO pass menuSource data
        });
      },
      (error) => {
        this.reportActionComplete({
          status: 'error',
          action: 'delete-permanent',
          errorData: error,
          obj: item,
          processComplete: !this.actionInfo.delete_permanent.items.length && !this.actionInfo.delete_permanent.active,
          menuSource: null, // TODO pass menuSource data
        });
      }
    );
  }

  /* Empty Bin
  ================================================== */
  emptyBinAction(): void {
    // Prepare action event data
    const actionData: IActionData = { action: 'empty-bin', file: null, menuSource: null };
    // Report for starting process
    this.startActionSubject.next(actionData);

    this.authService.authRequest('files', 'bin-purge', {}).subscribe(
      (result) => {
        this.actionCompleteSubject.next({
          status: result,
          action: 'empty-bin',
          processComplete: true,
        });
      },
      (error) => {
        this.actionCompleteSubject.next({
          status: 'error',
          action: 'empty-bin',
          errorData: error,
          processComplete: true,
        });
      }
    );
  }

  /* Move Files
  ================================================== */
  moveAction(files, destination, menuSource: string = null): void {
    this.reportActionStart({
      action: 'move',
      files: files,
      menuSource: menuSource,
      hasUndo: true,
      extraParams: { destination: destination },
    });
  }

  process_move(item): void {
    this.authService.authRequest('files', 'move-to-adv', { src: item, dst: item.destination, strategy: 'duplicate' }).subscribe(
      (result) => {
        this.reportActionComplete({
          status: result,
          action: 'move',
          obj: item, // TODO it was 'result' investigate
          processComplete: !this.actionInfo.move.items.length && !this.actionInfo.move.active,
          menuSource: item.menuSource,
        });
      },
      (error) => {
        this.reportActionComplete({
          status: 'error',
          action: 'move',
          errorData: error,
          obj: item,
          processComplete: !this.actionInfo.move.items.length && !this.actionInfo.move.active,
          menuSource: item.menuSource,
        });
      }
    );
  }

  /* Move Files
  ================================================== */
  copyAction(files, destination, menuSource: string = null): void {
    this.reportActionStart({
      action: 'copy',
      files: files,
      menuSource: menuSource,
      hasUndo: true,
      extraParams: { destination: destination },
    });
  }

  process_copy(item): void {
    const copyMethod = item.dir ? 'files-copy-adv' : 'files-copy-now';

    this.authService.authRequest('files', copyMethod, { src: item, dst: item.destination, strategy: 'duplicate' }).subscribe(
      (result) => {
        if (copyMethod === 'files-copy-now') {
          this.reportActionComplete({
            status: result,
            action: 'copy',
            obj: item,
            processComplete: !this.actionInfo.move.items.length && !this.actionInfo.move.active,
            menuSource: item.menuSource,
          });
        } else {
          const checkProgress = () => {
            this.authService.authRequest('files', 'files-op-progress', { operation: result }).subscribe(
              (response) => {
                if (response.errorCode) {
                  this.reportActionComplete({
                    status: 'error',
                    action: 'copy',
                    errorData: { code: response.errorCode },
                    obj: item,
                    processComplete: !this.actionInfo.move.items.length && !this.actionInfo.move.active,
                    menuSource: item.menuSource,
                  });
                  return;
                }

                if (response.completed) {
                  this.reportActionComplete({
                    status: { ...response.rootResult, modified: response.completed },
                    action: 'copy',
                    obj: item,
                    processComplete: !this.actionInfo.move.items.length && !this.actionInfo.move.active,
                    menuSource: item.menuSource,
                  });
                  return;
                }

                setTimeout(() => {
                  checkProgress();
                }, 3000);
              },
              (error) => {
                this.reportActionComplete({
                  status: 'error',
                  action: 'copy',
                  errorData: error,
                  obj: item,
                  processComplete: !this.actionInfo.move.items.length && !this.actionInfo.move.active,
                  menuSource: item.menuSource,
                });
              }
            );
          };

          checkProgress();
        }
      },
      (error) => {
        this.reportActionComplete({
          status: 'error',
          action: 'copy',
          errorData: error,
          obj: item,
          processComplete: !this.actionInfo.move.items.length && !this.actionInfo.move.active,
          menuSource: item.menuSource,
        });
      }
    );
  }

  /* Remove Recent Files
  ================================================== */
  removeRecentAction(files, menuSource?: string): void {
    this.reportActionStart({
      action: 'remove-recent',
      files: files,
      menuSource: menuSource,
      hasUndo: false,
      extraParams: null,
    });
  }

  process_remove_recent(item): void {
    this.authService.authRequest('files', 'remove-recent-opt', { id: item }).subscribe(
      (result) => {
        this.reportActionComplete({
          status: result,
          action: 'remove-recent',
          obj: item,
          processComplete: !this.actionInfo.remove_recent.items.length && !this.actionInfo.remove_recent.active,
          menuSource: null,
        });
      },
      (error) => {
        this.reportActionComplete({
          status: 'error',
          action: 'remove-recent',
          errorData: error,
          obj: item,
          processComplete: !this.actionInfo.remove_recent.items.length && !this.actionInfo.remove_recent.active,
          menuSource: null,
        });
      }
    );
  }

  /* Download Files
  ================================================== */
  downloadAction(items, openWithAppCode: any = false, revisionId: string = ''): void {
    if (openWithAppCode && openWithAppCode !== 'notSupported') {
      this.officeSuiteSupportService.openWithApp(items[0], openWithAppCode);
    } else {
      this.downloadManagerService.startDownload(items, revisionId);
    }
  }

  /* Generate sharable link
  ================================================== */
  manageSharableLink(files, share: boolean, menuSource: string = null): void {
    this.reportActionStart({
      action: 'share',
      files: files,
      menuSource: menuSource,
      hasUndo: false,
      extraParams: { shareThisFile: share },
    });
  }

  process_share(item): void {
    // Start Process
    (item.owner // get shared link from 'details' if item from 'Shared with Me' section
      ? this.authService.authRequest('files', 'details', {
          id: { key: item.key, account: item.account, parent: item.parent, name: item.name },
        })
      : this.authService.authRequest('files', 'share-publicly', { id: item, share: item.shareThisFile })
    ).subscribe(
      (result) => {
        item.shareLink = typeof result === 'string' ? result : '';
        // Action that Does not record Cache
        this.reportActionComplete({
          status: item,
          action: 'share',
          obj: item,
          processComplete: true,
          shared: item.shareThisFile,
          noCache: true,
        });
      },
      (error) => {
        // Action that Does not record Cache
        this.reportActionComplete({
          status: 'error',
          action: 'share',
          errorData: error,
          obj: item,
          processComplete: true,
          shared: item.shareThisFile,
          noCache: true,
        });
      }
    );
  }

  /* Restore files from Bin
  ================================================== */
  restoreAction(files): void {
    this.reportActionStart({
      action: 'restore',
      files: files,
      menuSource: null,
      hasUndo: true,
      extraParams: null,
    });
  }

  process_restore(item): void {
    this.authService.authRequest('files', 'restore', { id: item }).subscribe(
      (result) => {
        this.reportActionComplete({
          status: result,
          action: 'restore',
          obj: item,
          processComplete: !this.actionInfo.restore.items.length && !this.actionInfo.restore.active,
          menuSource: null, // TODO pass menuSource
        });
      },
      (error) => {
        this.reportActionComplete({
          status: 'error',
          action: 'restore',
          errorData: error,
          obj: item,
          processComplete: !this.actionInfo.restore.items.length && !this.actionInfo.restore.active,
          menuSource: null, // TODO pass menuSource
        });
      }
    );
  }

  /* Versioning
  ================================================== */
  getFileVersions(file, menuSource: string): void {
    this.startActionSubject.next({ action: 'version-history', file: file, menuSource: menuSource });
    // Start Process
    this.authService.authRequest('files', 'revisions', { id: file }).subscribe(
      (result) => {
        this.actionCompleteSubject.next({ status: file, action: 'version-history', processComplete: true, history: result });
      },
      (error) => {
        this.actionCompleteSubject.next({
          status: 'error',
          action: 'version-history',
          errorData: error,
          obj: file,
          processComplete: true,
        });
      }
    );
  }

  /* Manage Pending Process
  ================================================== */
  checkPendingProcess(processNameCode, callback): void {
    let itemsLength = this.actionInfo[processNameCode].items.length;
    while (itemsLength--) {
      if (this.actionInfo[processNameCode].active < this.cacheService.processLimit) {
        const item = this.actionInfo[processNameCode].items.splice(0, 1)[0];
        if (!item) {
          return;
        }

        this.actionInfo[processNameCode].active++;
        this[callback](item);
      } else {
        break;
      }
    }
  }

  /* Report for Starting Action
  ================================================== */
  reportActionStart(actionData: IActionData): void {
    // TODO Create interface for actionData
    const actionCode = actionData.action.replace('-', '_');
    const filesCount = actionData.files.length;

    let bulkId = null;
    let actionFromUndo = null;

    // Handle Undo data/process
    if (actionData.hasUndo) {
      bulkId = guideGenerator();
      actionFromUndo = !!actionData.files[0].undoApplied;
    }

    // Add items extra data
    actionData.files.map((item) => {
      item.menuSource = actionData.menuSource;
      item.selected = false;

      if (actionData.extraParams) {
        for (const ext in actionData.extraParams) {
          if (actionData.extraParams.hasOwnProperty(ext)) {
            item[ext] = actionData.extraParams[ext];
          }
        }
      }

      // Handle Undo data/process
      if (actionData.hasUndo) {
        if (!actionFromUndo) {
          item.bulkId = bulkId;
        } else {
          item.undoApplied = false;
        }
      }
    });

    // Update all items from given action
    this.actionInfo[actionCode].items = this.actionInfo[actionCode].items.concat(actionData.files);

    // Add to disabled files list
    this.disabledFilesManager.addDisabledFiles(actionData.files);

    // Prepare action event data
    const actionStartData: IActionData = {
      action: actionData.action,
      file: actionData.files,
      menuSource: actionData.menuSource,
      actionFromUndo: actionFromUndo,
    };

    // Register bulk process
    if (actionData.hasUndo && !actionFromUndo) {
      this.bulkOperationsService.addBulkAction(bulkId, actionStartData);
    }

    // Update starting process
    this.cacheService.updateProcessesStatus('start', filesCount);
    this.startActionSubject.next(actionStartData);

    // Handle process queu
    this.checkPendingProcess(actionCode, `process_${actionCode}`);
  }

  /* Report Action Complete and handle dependencies
  ================================================== */
  reportActionComplete(actionData: IActionData): void {
    const actionCode = actionData.action.replace('-', '_');
    const processWithCounter = typeof this.actionInfo[actionCode].active !== 'undefined';

    if (actionData.errorData && actionData.errorData.retryAfter && processWithCounter) {
      actionData.errorData = null;
      this.actionInfo[actionCode].active--;
      this.actionInfo[actionCode].items.push(actionData.obj);
      this.checkPendingProcess(actionCode, `process_${actionCode}`);
      return;
    }

    this.disabledFilesManager.removeDisableFile(actionData.obj);
    if (!actionData.noCache) {
      this.cacheService.recoredCache(actionData);
    }

    this.actionCompleteSubject.next(actionData);
    this.cacheService.updateProcessesStatus('end', 1);

    // Update process counter
    if (processWithCounter) {
      this.actionInfo[actionCode].active--;
      this.checkPendingProcess(actionCode, `process_${actionCode}`);
    }

    // Handle Undo action
    if (actionData.obj.bulkId) {
      if (actionCode === 'copy' && actionData.status !== 'error') {
        this.bulkOperationsService.updateUndoData({
          source: actionData.obj,
          newData: Object.assign({}, { ...actionData.status, destination: actionData.obj.destination }),
        });
      }
      this.bulkOperationsService.removeItemFromBulk(actionData.obj, actionData.status);
    }
  }
}
