import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { configs } from '../../../../configs/drive-configs';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ReportPageEventService } from '../../../../services/report-page-event.service';
import { LoginService } from '../../../../services/login.service';
import { TagModel } from '../../ngx-chips/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageStatusService } from '../../../../services/storage-status.service';
import { SendEmailService } from '../../../../services/send-email.service';
import { TrackingService } from '../../../../services/tracking.service';
import { RecaptchaEnterpriseService } from '../../../../services/recaptcha-enterprise.service';
import { IRefsMap } from '../../../../models/common.int';
import { ByteSizePipe } from '../../../../modules/_shared/pipes/byte-size.pipe';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  providers: [ByteSizePipe],
})
export class InviteComponent implements OnInit, OnDestroy {
  @Input() modal: any;
  @Input() menuSource: string = 'mail';

  @ViewChild('mailMessage') mailMessage: ElementRef;
  @ViewChild('emailEntryContainer') emailEntryContainer: ElementRef;
  @ViewChild(PerfectScrollbarDirective) customScrollDirectiveRef?: PerfectScrollbarDirective;

  mobileDevice: boolean = document.getElementsByTagName('html')[0].classList.contains('mobile-device');
  private refs: IRefsMap = {};
  storage: string;
  sendEmailForm: FormGroup;
  preventBlur: boolean = false;

  mailBoxFocused: boolean = false;
  emailsList: any[] = [];
  invalidEmail: boolean = false;
  sendingMail: boolean = false;
  emailsLimit: number = 30;
  emailTextLimit: number = 500;
  emailFc: { [key: string]: AbstractControl };
  blurCandidat: string = '';
  prevTagValue: string = '';
  heightLimit: boolean = true;
  emailLimit: number = configs.sharelinkEmailLimit;

  constructor(
    private formBuilder: FormBuilder,
    private ngxModalService: NgxSmartModalService,
    private loginService: LoginService,
    private reportPageEventService: ReportPageEventService,
    private translateService: TranslateService,
    private storageStatusService: StorageStatusService,
    private sendEmailService: SendEmailService,
    private trackingService: TrackingService,
    private recaptchaService: RecaptchaEnterpriseService,
    private byteSizePipe: ByteSizePipe,
    private cdRef: ChangeDetectorRef
  ) {}

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEventDown(event: KeyboardEvent) {
    if (event.ctrlKey && event.keyCode === 65) {
      event.preventDefault();
      this.mailMessage.nativeElement.focus();
      this.mailMessage.nativeElement.select();
    }
  }

  ngOnInit() {
    this.storage = this.byteSizePipe.transform(this.storageStatusService.getStorageStatus().maximum);
    this.sendEmailForm = this.formBuilder.group({
      mailMessage: [this.translateService.instant('modal.inviteMessage', { value: this.storage })],
    });
    this.emailFc = this.sendEmailForm.controls;
    this.cdRef.detectChanges();
    this.ngxModalService.getModal('actionModal').onAnyCloseEvent.subscribe(() => {
      this.preventBlur = true;
    });
  }

  ngOnDestroy() {
    // Clean refs
    for (const ref in this.refs) {
      if (this.refs[ref]) {
        this.refs[ref].unsubscribe();
      }
    }
  }

  setMailBoxState(isFocused) {
    this.mailBoxFocused = isFocused;
  }

  onBlurEmailInput(isFocused, event) {
    this.mailBoxFocused = isFocused;
    // blurCandidat is email that is typed, but not yet added on 'SendMail' button press
    this.blurCandidat = event;
  }

  onAdding(tag: TagModel) {
    const EMAILS_C = /[\,]/;
    const EMAIL_S = /[\;]/;
    tag.error = false;

    // Check if email on blur event has been added and unblock Send Email button
    if (tag.display === this.blurCandidat.trim()) {
      this.blurCandidat = '';
    }

    // Check for multiple mails and validate
    let emails = [];
    const trimed = tag.value.replace(/\s/g, '');

    if (EMAILS_C.test(tag.value) || EMAIL_S.test(tag.value)) {
      emails = trimed.split(/;|,/);
    } else if (trimed.length) {
      // Check single mail
      if (!this.isValidMail(tag.value)) {
        tag.error = true;
        this.invalidEmail = true;
      }
    }

    if (emails.length) {
      let allowedEmails = [];

      if (emails.length + this.emailsList.length <= this.emailsLimit) {
        allowedEmails = emails;
      } else {
        const emailsLeft = this.emailsLimit - (this.emailsList.length - 1);
        allowedEmails = emails.slice(0, emailsLeft);
      }

      const model = allowedEmails.map((email) => {
        return { display: email, value: email, error: !this.isValidMail(email) };
      });

      if (model.length) {
        this.emailsList.pop();
        this.emailsList = this.emailsList.concat(model);
      }
    }

    // Update Custom Scroll
    if (this.customScrollDirectiveRef) {
      setTimeout(() => {
        if (this.emailEntryContainer.nativeElement.clientHeight > 83) {
          this.heightLimit = false;
          this.customScrollDirectiveRef.update();
          this.customScrollDirectiveRef.scrollToBottom();
        } else {
          this.heightLimit = true;
        }
      }, 7);
    }
  }

  onRemove(tag) {
    // Loop updated model to check for errors
    this.invalidEmail = false;
    this.emailsList.forEach((item) => {
      if (tag.value !== item.value) {
        if (item.error) {
          this.invalidEmail = true;
        }
      }
    });
  }

  isValidMail(mail) {
    const mailValidation = new RegExp(
      [
        '^(([^<>()[\\]\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\.,;:\\s@"]+)*)',
        '|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.',
        '[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+',
        '[a-zA-Z]{2,}))$',
      ].join('')
    );

    return mailValidation.test(mail);
  }

  onTagSelect(tag) {
    this.prevTagValue = tag.display;
  }

  onTagEdited(tag, emailsList) {
    if (!this.isValidMail(tag.display)) {
      tag.error = true;
      this.invalidEmail = true;
    } else {
      tag.error = false;
      this.invalidEmail = false;
    }

    this.emailsList = emailsList.map((item) => {
      if (item.display !== this.prevTagValue) {
        return item;
      } else {
        return tag;
      }
    });

    // Loop updated model to check for errors
    this.emailsList.forEach((item) => {
      if (item.error) {
        this.invalidEmail = true;
      }
    });
  }

  async onSendInvite() {
    // Check if there is not added email
    if (this.blurCandidat) {
      return;
    }

    // Check for errors before send emails
    let hasError = false;
    this.emailsList.forEach((item) => {
      if (item.error) {
        hasError = true;
      }
    });

    if (hasError) {
      return;
    }

    this.sendingMail = true;
    this.emailFc.mailMessage.disable();

    // getting recaptcha token
    const token = await this.recaptchaService.execute('invite_friends');

    const emails = this.emailsList.map((item) => item.display);
    const mailsData = {
      token,
      subject: this.translateService.instant('modal.inviteSubject', { name: this.getUserName() }),
      mails: emails,
      message: this.emailFc.mailMessage.value,
      category: 'Invite a Friend',
    };

    if (!mailsData.message) {
      delete mailsData.message;
    } else {
      mailsData.message = mailsData.message
        .replace(/\n/g, '<br>')
        .replace(new RegExp(this.storage, 'g'), `<strong>${this.storage}</strong>`)
        .replace(
          new RegExp('https://www.mobidrive.com/install', 'g'),
          `<a href="https://www.mobidrive.com/install">https://www.mobidrive.com/install</a>`
        );
    }

    this.cdRef.detectChanges();

    this.trackingService.trackEvent('web-app.usage', 'invite-friends', 'invite-send');
    this.sendEmailService.sendInvites(mailsData).subscribe(
      (result) => {
        if (result.status === 200) {
          this.sendEmailForm.patchValue({ mailMessage: '' });
          this.emailsList = [];
          this.modal.close();
          this.reportPageEventService.reportEvent('modal.sendInviteLink', 'confirm');
        } else {
          this.modal.close();
          this.reportPageEventService.reportEvent('modal.sendInviteLinkError', 'error');
        }
      },
      (error) => {
        this.modal.close();
        this.reportPageEventService.reportEvent('modal.sendInviteLinkError', 'error');
      }
    );
  }

  showMailView(val) {
    this.menuSource = val ? 'mail' : 'breadcrumb';
    this.customScrollDirectiveRef.update();

    if (val) {
      document.getElementById('emailsListInput').focus();
      this.mailBoxFocused = true;
      this.trackingService.trackEvent('web-app.usage', 'invite-friends', 'pop-up.invite');
    }
  }

  getUserName() {
    const profile = this.loginService.getProfileData();
    return profile.name || profile.email;
  }

  get isMailView(): boolean {
    return this.menuSource !== 'breadcrumb';
  }
}
