<section class="modal-wrap new-folder-modal">
  <h5 class="modal-title">{{ 'modal.createNewFolder' | translate }}</h5>

  <div class="modal-body cfix">
    <form [formGroup]="newFolderForm" (keydown)="onKeyPress($event)" class="single-item-form">
      <div class="form-item cfix">
        <div class="input-wrapper cfix">
          <input
            type="text"
            formControlName="folderName"
            #folderName
            class="input-style"
            [ngClass]="{
              'input-error': fc.folderName.errors && (fc.folderName.errors.pattern || fc.folderName.errors.maxlength)
            }"
          />
        </div>
      </div>

      <div class="error-box">
        <div class="form-error" *ngIf="fc.folderName.errors && fc.folderName.errors.maxlength">
          {{ 'errorMessages.folderLongName' | translate }}
        </div>
        <div class="form-error" *ngIf="fc.folderName.errors && fc.folderName.errors.pattern">
          {{ 'validation.filenameRules' | translate }}
        </div>
      </div>
    </form>
  </div>

  <footer class="modal-footer cfix">
    <button class="button col-blue medium-size" [disabled]="newFolderForm.invalid || loading" (click)="submitForm()">
      {{ 'modal.create' | translate }}
    </button>
    <button class="button col-grey medium-size" [disabled]="loading" (click)="modal.close()">
      {{ 'modal.cancel' | translate }}
    </button>
  </footer>
</section>
