import { Directive, HostListener, Input, ElementRef } from '@angular/core';
import { ListViewSwitcherService } from '../../../services/list-view-switcher.service';

export interface keyboardOptions {
  dropdown?: boolean;
}

@Directive({
  selector: '[keyboardActions]',
})
export class KeyboardActionsDirective {
  constructor(private elRef: ElementRef, private listViewSwitcherService: ListViewSwitcherService) {}

  @Input()
  set keyboardActions(options: keyboardOptions) {
    if (options) {
      this.options = options;
    }
  }

  private options: keyboardOptions = {
    dropdown: false,
  };

  @HostListener('keydown', ['$event'])
  onKeyPress(event) {
    const elem = event.target;
    const htmlElem: any = document.getElementsByTagName('html')[0];

    // ENTER KEY Press Action
    if (event.keyCode === 13) {
      this.handleEnterKey(elem, htmlElem);
      return;
    }

    // ESCAPE KEY Press Action
    if (event.keyCode === 27) {
      this.handleEscapeKey(elem, htmlElem);
      return;
    }

    // DropDown Menus Navigation
    if (this.options.dropdown && [9, 38, 40].indexOf(event.keyCode) !== -1) {
      // Handle Tab, Up, Down Keys
      this.dropDownMenuNavigation(event, elem, htmlElem);
      return;
    }

    // Breadcrumb Navigation
    if (elem.classList.contains('bc-link') && [39, 37].indexOf(event.keyCode) !== -1) {
      // Handle Right, Left Keys
      this.breadCrumbNavigation(event, elem);
      return;
    }

    // File list Navigation
    if (event.target.classList.contains('item-wrapper') && [40, 38, 39, 37].indexOf(event.keyCode) !== -1) {
      // Handle Down, Up, Right, Left Keys
      this.fileListNavigation(event, elem);
      return;
    }

    // Mobile Sidebar Menu Tab Navigation
    if (htmlElem.classList.contains('open-mob-menu') && [9, 38, 40].indexOf(event.keyCode) !== -1) {
      // Handle Tab, Up, Down Keys
      this.sidebarNavigation(event, elem);
      return;
    }

    // When reach last Tab item focus the first
    if (elem.classList.contains('tab-cycle-end') && !event.shiftKey) {
      setTimeout(() => {
        htmlElem.querySelector('.logo-area a').focus();
      }, 20);
    }
  }

  handleEnterKey(elem, htmlElem) {
    // Handle dropdown
    if (elem.children[0] && elem.children[0].classList.contains('dd-menu-head')) {
      elem.children[0].click();
    } else if (elem.classList.contains('group-item')) {
      // Handel Recent page Group Item
      elem.querySelector('.checkbox-item').click();
    } else if (elem.classList.contains('bc-link')) {
      // Handel Breadcrumb item
      elem.click();
    } else if (elem.classList.contains('item-wrapper')) {
      // Handel Files list item
      const dblevent = new MouseEvent('dblclick', {
        view: window,
        bubbles: true,
        cancelable: true,
      });

      elem.dispatchEvent(dblevent);
    } else if (elem.tagName.toLowerCase() !== 'a' && elem.tagName.toLowerCase() !== 'button') {
      // Handel all other cases
      elem.click();
    }

    // Wehn open mobile menu from keyboard key focus first element
    if (elem.classList.contains('open-sidebar-button')) {
      // Handle TAB button functionality
      setTimeout(() => {
        htmlElem.querySelector('.mobile-scroll-container .user-menu').focus();
      }, 50);
    }

    // Mobile Sidebar Menu navigations
    if (elem.classList.contains('user-menu') && elem.classList.contains('first-tab-item-js')) {
      setTimeout(() => {
        htmlElem.querySelector('.first-tab-item-js').focus();
      }, 50);
    }

    if (elem.classList.contains('first-tab-item-js')) {
      setTimeout(() => {
        htmlElem.querySelector('.mobile-scroll-container .user-menu').focus();
      }, 50);
    }

    if (elem.classList.contains('changeLanguage')) {
      setTimeout(() => {
        htmlElem.querySelector('.second-tab-item-js').focus();
      }, 50);
    }

    if (elem.classList.contains('second-tab-item-js')) {
      setTimeout(() => {
        htmlElem.querySelector('.first-tab-item-js').focus();
      }, 50);
    }
  }

  handleEscapeKey(elem, htmlElem) {
    // Mobile sidebar open
    if (htmlElem.classList.contains('open-mob-menu')) {
      htmlElem.querySelector('.mobile-menu-curtain').click();
      setTimeout(() => {
        htmlElem.querySelector('.open-sidebar-button').focus();
      }, 50);
    } else {
      htmlElem.click();
      if (elem && elem.closest('.dd-menu')) {
        elem.closest('.dd-menu').focus();
      }
    }
  }

  // DropDown Menus Navigation
  dropDownMenuNavigation(event, elem, htmlElem) {
    if (event.keyCode === 9 && this.elRef.nativeElement.classList.contains('open')) {
      htmlElem.querySelector('.dd-menu.open').click();
    }

    // On Down or Up Keypress
    if (event.keyCode === 40 || event.keyCode === 38) {
      if (elem.classList.contains('dd-menu')) {
        const trg = elem.querySelector('li .dd-link');
        if (trg) {
          trg.focus();
        }
      } else {
        const parentElem = elem.closest('li');
        const trg =
          event.keyCode === 40
            ? this.getNextSibling(parentElem, ':not(.filter-category)', 'next')
            : this.getNextSibling(parentElem, ':not(.filter-category)', 'prev');
        if (trg) {
          trg.querySelector('.dd-link').focus();
        }
      }
    }
  }

  breadCrumbNavigation(event, elem) {
    let focusElem: any = false;
    let allowFocus = true;

    // On Right Keypress
    if (event.keyCode === 39) {
      focusElem = this.findNextTabStop(elem);
      if (!elem.closest('li').nextElementSibling) {
        allowFocus = false;
      }
    }

    // On Left Keypress
    if (event.keyCode === 37) {
      focusElem = this.findNextTabStop(elem, true);
      if (!elem.closest('li').previousElementSibling) {
        allowFocus = false;
      }
    }

    if (focusElem && allowFocus) {
      focusElem.focus();
    }
  }

  fileListNavigation(event, elem) {
    event.stopPropagation();

    let focusElem: any = false;
    let allowFocus = true;
    const listView = this.listViewSwitcherService.getListView();

    if (listView === 'list-view') {
      // LIST VIEW

      // On Down Keypress
      if (event.keyCode === 40) {
        focusElem = this.findNextTabStop(elem);

        if (!elem.closest('li').nextElementSibling) {
          allowFocus = false;
        }
      }

      // On Up Keypress
      if (event.keyCode === 38) {
        focusElem = this.findNextTabStop(elem, true);
        if (!elem.closest('li').previousElementSibling) {
          allowFocus = false;
        }
      }
    } else {
      // GRID VIEW

      // On Down Keypress
      if (event.keyCode === 40) {
        focusElem = this.findElemeByOffset(elem, 'down');
      }

      // On Up Keypress
      if (event.keyCode === 38) {
        focusElem = this.findElemeByOffset(elem, 'up');
      }

      // On Right Keypress
      if (event.keyCode === 39) {
        focusElem = this.findNextTabStop(elem);
        if (!elem.closest('li').nextElementSibling) {
          allowFocus = false;
        }
      }

      // On Left Keypress
      if (event.keyCode === 37) {
        focusElem = this.findNextTabStop(elem, true);
        if (!elem.closest('li').previousElementSibling || elem.closest('li').previousElementSibling.classList.contains('grid-item-cat')) {
          allowFocus = false;
        }
      }
    }

    if (focusElem && allowFocus) {
      focusElem.focus();
    }
  }

  sidebarNavigation(event, elem) {
    let focusElem: any = false;
    let allowFocus = true;

    // On Down Keypress
    if (event.keyCode === 40) {
      focusElem = this.findNextTabStop(elem);
      if (elem.closest('li') && !elem.closest('li').nextElementSibling) {
        allowFocus = false;
      }
    }

    // On Up Keypress
    if (event.keyCode === 38) {
      focusElem = this.findNextTabStop(elem, true);
    }

    // On Tab Keypress
    if (event.shiftKey && event.keyCode === 9) {
      event.preventDefault();
      focusElem = this.findNextTabStop(elem, true);
    } else if (event.keyCode === 9) {
      event.preventDefault();
      focusElem = this.findNextTabStop(elem);
    }

    if (focusElem && allowFocus) {
      focusElem.focus();
    }
  }

  findNextTabStop(el, prevTab?) {
    const universe = document.querySelectorAll('[tabindex]');
    const list = Array.prototype.filter.call(universe, function (item) {
      return item.tabIndex >= '0';
    });
    const index = list.indexOf(el);

    if (prevTab) {
      return list[index - 1] || list[0];
    } else {
      return list[index + 1] || list[0];
    }
  }

  findCurrentFocusedElement(el) {
    const universe = document.querySelectorAll('[tabindex]');
    const list = Array.prototype.filter.call(universe, function (item) {
      return item.tabIndex >= '0';
    });
    const index = list.indexOf(el);
    return list[index];
  }

  findElemeByOffset(el, location: string) {
    const startElement = el.closest('li');
    const xCord = startElement.getBoundingClientRect().x;
    let target = null;

    const findElement = (element) => {
      const nextEl = location === 'down' ? element.nextElementSibling : element.previousElementSibling;

      if (nextEl) {
        if (nextEl.getBoundingClientRect().x === xCord && !nextEl.classList.contains('grid-item-cat')) {
          target = nextEl;
        } else {
          findElement(nextEl);
        }
      } else {
        target = null;
      }
    };

    findElement(startElement);

    return target ? target.querySelectorAll('[tabindex]')[0] : target;
  }

  isVisible(ele) {
    const style = window.getComputedStyle(ele);
    return (
      style.width !== '0' && style.height !== '0' && style.opacity !== '0' && style.display !== 'none' && style.visibility !== 'hidden'
    );
  }

  getNextSibling(elem, selector, direction = 'next') {
    // Get the next sibling element
    let sibling = elem.nextElementSibling;

    if (direction === 'prev') {
      sibling = elem.previousElementSibling;
    }

    // If there's no selector, return the first sibling
    if (!selector) return sibling;

    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
      if (sibling.matches(selector)) return sibling;
      if (direction === 'next') {
        sibling = sibling.nextElementSibling;
      } else if (direction === 'prev') {
        sibling = sibling.previousElementSibling;
      }
    }
  }
}
