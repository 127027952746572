<nav id="headerStaticMenu" class="header-static-menu" [ngClass]="{ 'mobile-menu': showMobMenu }">
  <ul *ngIf="!noMenuForPages.includes(pageName)" class="hsm-list">
    <li>
      <a
        data-qa="sp-homep"
        (click)="onCloseMenuAfterClickLink('website.browse', 'why-mobi-drive', 'nav-bar.link')"
        [routerLink]="['/']"
        [queryParams]="{ vp: 'true' }"
        [ngClass]="{ selected: pageName === 'home' }"
      >
        {{ 'header.staticMenu.whyMobiDrive' | translate }}
      </a>
    </li>
    <li>
      <a
        data-qa="sp-pricingp"
        (click)="onCloseMenuAfterClickLink('website.engage', 'pricing', 'nav-bar.button')"
        [routerLink]="['/pricing']"
        [queryParams]="{ source: 'pricing' }"
        [ngClass]="{ selected: pageName === 'pricing' }"
      >
        {{ 'header.staticMenu.pricing' | translate }}
      </a>
    </li>
    <li>
      <a *ngIf="profileData" data-qa="sp-gotomd" (click)="goToMobiDrive($event)" class="button col-blue medium-size">
        {{ 'header.staticMenu.goToMobiDrive' | translate }}
      </a>
      <a *ngIf="!profileData" data-qa="sp-gotomd" (click)="goToMobiDrive($event)" class="button col-blue medium-size">
        {{ 'publicPage.signUpFor20Free' | translate }}
      </a>
    </li>
  </ul>

  <div *ngIf="showMobMenu && view === 'mobile'" class="lang-menu-area">
    <lang-static-menu [view]="'step-menu'" (closeMenuEvent)="closeMenu()"></lang-static-menu>
  </div>
</nav>
