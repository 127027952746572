<div *ngIf="upgradeButton" class="{{ upgradeButton.className }}">
  <a
    href="{{ upgradeButton.url }}"
    class="dd-link-header"
    tabindex="-1"
    (click)="handleProfileMenuItem($event, upgradeButton.method, upgradeButton.url, upgradeButton.trackEvent)"
    target="{{ upgradeButton.target }}"
  >
    <span class="sub-menu-icon {{ upgradeButton.icon }}"></span>
    <ng-container *ngIf="upgradeButton.nameCode">
      {{ upgradeButton.nameCode | translate }}
    </ng-container>
    <ng-container *ngIf="!upgradeButton.nameCode">
      {{ upgradeButton.name }}
    </ng-container>
  </a>
</div>
