import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'move-files-title',
  templateUrl: './move-files-title.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoveFilesTitleComponent {
  moveType: string = ''; // file, files, folder, folders, items
  filesNumber: number;

  @Input() view: string = ''; // move, copy
  @Input() set filesData(fd) {
    // Show separate labels in Popup for different move actions
    this.filesNumber = fd.count;
    if (fd.type === 'files') {
      this.moveType = 'files';
      if (fd.count === 1) {
        this.moveType = 'file';
      }
    } else if (fd.type === 'folders') {
      this.moveType = 'folders';
      if (fd.count === 1) {
        this.moveType = 'folder';
      }
    } else {
      this.moveType = 'items';
    }
  }

  constructor() {}
}
