import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// MODULES
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from '../ngx-dropdown/ngx-dropdown.module';
// COMPONENTS
import { StoreButtonsComponent } from './components/store-buttons/store-buttons.component';
import { UploadButtonComponent } from './components/upload-button/upload-button.component';
import { PromoBannerComponent } from './components/promo-banner/promo-banner.component';
import { MobiDriveComponent } from './components/promo-banner/banners/mobi-drive/mobi-drive.component';
import { LoaderGridComponent } from '../internal/common/components/loader-grid/loader-grid.component';
import { ExceededBannerComponent } from './components/exceeded-banner/exceeded-banner.component';
// PIPES
import { ByteSizePipe } from './pipes/byte-size.pipe';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { FileExtensionPipe } from './pipes/file-extension.pipe';
import { FolderNamePipe } from './pipes/folder-name.pipe';
import { FileNamePipe } from './pipes/file-name.pipe';
import { ShortUserNamePipe } from './pipes/short-user-name.pipe';
// DIRECTIVES
import { KeyboardActionsDirective } from './directives/keyboard-actions.directive';
import { DropPreventDirective } from './pipes/drop-prevent.directive';
import { ToggleFullscreenDirective } from './directives/fullscreen.directive';
import { DropDirective } from './pipes/drop.directive';

@NgModule({
  declarations: [
    StoreButtonsComponent,
    UploadButtonComponent,
    PromoBannerComponent,
    MobiDriveComponent,
    LoaderGridComponent,
    ExceededBannerComponent,
    ByteSizePipe,
    FormatDatePipe,
    FileExtensionPipe,
    FileNamePipe,
    FolderNamePipe,
    ShortUserNamePipe,
    KeyboardActionsDirective,
    DropPreventDirective,
    ToggleFullscreenDirective,
    DropDirective,
  ],
  imports: [CommonModule, TranslateModule.forChild(), PerfectScrollbarModule, DropdownModule, FormsModule],
  exports: [
    TranslateModule,
    PerfectScrollbarModule,
    DropdownModule,
    FormsModule,
    StoreButtonsComponent,
    UploadButtonComponent,
    PromoBannerComponent,
    MobiDriveComponent,
    LoaderGridComponent,
    ExceededBannerComponent,
    ByteSizePipe,
    FormatDatePipe,
    FileExtensionPipe,
    FileNamePipe,
    FolderNamePipe,
    ShortUserNamePipe,
    KeyboardActionsDirective,
    DropPreventDirective,
    ToggleFullscreenDirective,
    DropDirective,
  ],
})
export class SharedModule {}
