import { Injectable } from '@angular/core';

import { loadRecaptchaScript } from '../helpers/load-script';
import { environment } from '../../environments/environment';
import { RecaptchaActions } from '../models/common.int';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

interface RecaptchaEnterpriseType {
  ready: (callback: () => void) => void;
  execute: (site_key: string, { action }) => Promise<string>;
}

declare global {
  interface Window {
    grecaptcha: {
      enterprise: RecaptchaEnterpriseType;
    };
  }
}

@Injectable()
export class RecaptchaEnterpriseService {
  private script: HTMLScriptElement;

  constructor(private httpClient: HttpClient, private authService: AuthService) {
    this.init();
  }

  public execute(action: RecaptchaActions) {
    return new Promise<string>((resolve, reject) => {
      window.grecaptcha.enterprise.ready(() => {
        window.grecaptcha.enterprise
          .execute(environment.recaptchaSiteKey, { action })
          .then((token) => resolve(token))
          .catch(reject);
      });
    });
  }

  verify(token: string) {
    return this.httpClient.post(window.location.origin + '/mdapi/validate-recaptcha-sharelink', { token }).pipe(
      map((res) => {
        if (res) {
          return res;
        } else {
          this.authService.handleErrors(res);
        }
      })
    );
  }

  private init() {
    // loading js script
    this.script = loadRecaptchaScript(environment.recaptchaSiteKey);
  }
}
