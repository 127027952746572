import { Injectable } from '@angular/core';
import { configs } from '../configs/drive-configs';

@Injectable()
export class FileSortingService {
  constructor() {}

  fitInCurrentList(obj, sourceList, currentFilter, originalFilesListLength, lastPage): boolean {
    const sortConf = this.getSortingConfigs(obj.dir, currentFilter.filterBy, currentFilter.order);
    let fitInList = false;
    const splitLists = this.splitFilesFromFolders(sourceList);
    const selectedList = obj.dir ? splitLists.folders : splitLists.files;

    // If current files are less than pagging number or Last Page
    if (originalFilesListLength < configs.firstLoadItems || lastPage) {
      return true;
    }

    // If upload file and there are only folders and not the last page
    if (!obj.dir && !lastPage && sourceList[sourceList.length - 1].dir) {
      return false;
    }

    // If folder, and folders are less than pagging number
    if (obj.dir && splitLists.folders.length < sourceList.length) {
      return true;
    }

    // Check via compare methods
    if (sortConf.sortingOrder === 'asc') {
      // Ascending case check
      fitInList = this.ascCompare(sortConf.compareProp, selectedList, selectedList.length - 1, obj);
    } else {
      // Descend case check
      fitInList = this.descCompare(sortConf.compareProp, selectedList, selectedList.length - 1, obj);
    }

    return fitInList;
  }

  addInCurrentList(obj, sourceList, currentFilter) {
    const sortConf = this.getSortingConfigs(obj.dir, currentFilter.filterBy, currentFilter.order);
    const splitLists = this.splitFilesFromFolders(sourceList); // Separate Folders from Files lists
    const selectedList = obj.dir ? splitLists.folders : splitLists.files; // Compare in list that object belongs
    let position: any = selectedList.length; // Take Default Object position

    // Get new file index position
    for (let i = 0; i < selectedList.length; i++) {
      const index = this.compareEngine(sortConf.sortingOrder, sortConf.compareProp, selectedList, i, obj);
      if (index !== false) {
        position = index;
        break;
      }
    }

    if (!obj.dir) {
      position = splitLists.folders.length + position;
    }

    sourceList.splice(position, 0, obj);
  }

  compareEngine(compareOrder, compareProp, selectedList, i, obj) {
    let itemIndex = false;

    if (compareOrder === 'asc') {
      itemIndex = this.ascCompare(compareProp, selectedList, i, obj);
    } else {
      itemIndex = this.descCompare(compareProp, selectedList, i, obj);
    }

    return itemIndex;
  }

  getSortingConfigs(folder, compareProp, sortingOrder) {
    const sortingConfigs = {
      compareProp: compareProp,
      sortingOrder: sortingOrder,
    };

    // Cover Folder sorting cases
    if (folder) {
      // If not Modified filter compare Folder by name
      if (compareProp !== 'modified') {
        sortingConfigs.compareProp = 'name';
      }
      // If size filter sort Folders ascending
      if (compareProp === 'size') {
        sortingConfigs.sortingOrder = 'asc';
      }
    }

    return sortingConfigs;
  }

  ascCompare(compareProp, selectedList, i, obj) {
    if (compareProp === 'name') {
      if (obj.dir) {
        if (
          selectedList[i]['name'].split('/')[0].localeCompare(obj['name'].split('/')[0], 'en-US', {
            caseFirst: 'lower',
            ignorePunctuation: false,
            numeric: true,
          }) === 1
        ) {
          return i;
        }
      } else {
        const result = this.fileNameCompare(selectedList, i, obj, 'asc');
        if (result !== false) {
          return result;
        }
      }
    } else {
      if (selectedList[i][compareProp] >= obj[compareProp]) {
        return i;
      }
    }

    return false;
  }

  descCompare(compareProp, selectedList, i, obj) {
    if (compareProp === 'name') {
      if (obj.dir) {
        if (
          obj['name'].split('/')[0].localeCompare(selectedList[i]['name'].split('/')[0], 'en-US', {
            caseFirst: 'lower',
            ignorePunctuation: false,
            numeric: true,
          }) === 1
        ) {
          return i;
        }
      } else {
        const result = this.fileNameCompare(selectedList, i, obj, 'desc');
        if (result !== false) {
          return result;
        }
      }
    } else {
      if (selectedList[i][compareProp] <= obj[compareProp]) {
        return i;
      }
    }

    return false;
  }

  fileNameCompare(selectedList, i, obj, filter) {
    let matchCase = 0;
    const listItemName = selectedList[i]['name'].split('.').slice(0, -1).join('.');
    const objName = obj['name'].split('.').slice(0, -1).join('.');
    let compareResult = 0;

    if (filter === 'asc') {
      compareResult = listItemName.localeCompare(objName, 'en-US', {
        caseFirst: 'lower',
        ignorePunctuation: false,
        numeric: true,
      });
    } else {
      compareResult = objName.localeCompare(listItemName, 'en-US', {
        caseFirst: 'lower',
        ignorePunctuation: false,
        numeric: true,
      });
    }

    if (compareResult === 1) {
      return i;
    } else if (compareResult === 0) {
      const fileExt = obj['name'].split('.').pop();
      matchCase = this.fileExtChecker(fileExt, selectedList, i, objName, filter);
      return matchCase;
    }

    return false;
  }

  fileExtChecker(fileExt, list, i, objName, filter) {
    if (list[i]) {
      // If next item is with different name
      if (objName !== list[i].name.split('.').slice(0, -1).join('.')) {
        return i;
      }

      if (filter === 'asc') {
        // asc case
        if (fileExt > list[i]['name'].split('.').pop()) {
          return this.fileExtChecker(fileExt, list, i + 1, objName, filter);
        } else {
          return i;
        }
      } else {
        // desc case
        if (fileExt < list[i]['name'].split('.').pop()) {
          return this.fileExtChecker(fileExt, list, i + 1, objName, filter);
        } else {
          return i;
        }
      }
    } else {
      return i;
    }
  }

  splitFilesFromFolders(sourceList) {
    const itemsLength = sourceList.length;
    const result = {
      folders: [],
      files: [],
    };

    for (let i = 0; i < itemsLength; i++) {
      if (!sourceList[i].dir) {
        result.files = sourceList.slice(i, itemsLength);
        break;
      } else {
        result.folders.push(sourceList[i]);
      }
    }

    return result;
  }
}
