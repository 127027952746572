import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortUserName',
})
export class ShortUserNamePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const nameParts: string[] = value ? value.split(' ') : [];
    let shortName: string = '';

    if (nameParts.length !== 0) {
      if (nameParts.length > 1) {
        shortName = nameParts[0].charAt(0) + nameParts[nameParts.length - 1].charAt(0);
      } else if (nameParts.length === 1) {
        shortName = nameParts[0].charAt(0) + nameParts[0].charAt(1);
      }
    }

    return shortName.toUpperCase();
  }
}
