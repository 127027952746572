import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrackingService } from '../../../../services/tracking.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-promo-os-trial',
  templateUrl: './promo-os-trial.component.html',
  styleUrls: ['./promo-os-trial.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromoOsTrialComponent {
  @Input() modal: any;

  constructor(private trackingService: TrackingService) {
    this.trackingService.trackEvent(
      'web-app.widget.conv-window',
      'premium-popup.try-mobidrive-officesuite',
      'premium-popup.try-mobidrive-officesuite.view'
    );
  }

  startTrial() {
    this.trackingService.trackEvent(
      'web-app.widget.conv-window',
      'premium-popup.try-mobidrive-officesuite',
      'premium-popup.try-mobidrive-officesuite.click'
    );
    window.open(`${environment.mobisystemsURL}/mobidrive/store?source=TryFree-popup-on-open-or-create`, '_blank');
  }
}
