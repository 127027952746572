export const capitalizeFirstLetter = (string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export function flattenArray(arr: any[]) {
  const result = [];
  const flatten = (subArr: any[]) => {
    subArr.forEach((item) => {
      if (Array.isArray(item)) {
        flatten(item);
      } else {
        result.push(item);
      }
    });
  };
  flatten(arr);
  return result;
}

export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function isBase64(str: string): boolean {
  // Check if the length is a multiple of 4
  if (str.length % 4 !== 0) {
    return false;
  }
  // Check if the string contains only valid base64 characters
  const base64Regex = /^[A-Za-z0-9+/]+={0,2}$/;
  return base64Regex.test(str);
}

export function checkSupportedFileFormats(fileName: string): boolean {
  const supportedFileformats = ['.docx', '.doc', '.rtf', '.odt', '.txt'];
  return supportedFileformats.some((fileFormat) => fileName.endsWith(fileFormat));
}
