<div class="search-area" [ngClass]="{ hidden: !showSearch && mobileView, 'mobile': mobileView, 'regular': !mobileView }">
  <button *ngIf="mobileView" class="close-search" (click)="toggleSearch()">
    <img src="../../../../assets/images/back.svg" alt="Back Button" />
  </button>
  <form id="searchFiles" class="search-box">
    <div class="input-wrap" [ngClass]="{ focused: dropdownShow || inputIsFocused }">
      <input
        #searchInput
        data-qa="searchInput"
        id="searchInput"
        tabindex="0"
        type="text"
        autocomplete="off"
        spellcheck="false"
        class="search-input search-element"
        placeholder="{{ 'header.searchMenu.searchPhlder' | translate }}"
        [value]="searchValue"
        (blur)="manageFocus(false)"
        (focus)="manageFocus(true)"
        (input)="onSearchChange($event)"
      />

      <div class="clear-btn-wrap">
        <button
          *ngIf="showClearBtn"
          tabindex="-1"
          class="button clear-search"
          (click)="clearSearch(false)"
        >
          <img src="../../../../assets/images/close.svg" alt="Clear Button" />
        </button>
      </div>

      <div *ngIf="dropdownShow" class="search-dropdown-menu">
        <div *ngIf="suggestionShow">
          <button
            *ngFor="let suggestion of suggestionsItems"
            tabindex="0"
            class="button dropdown-item"
            (click)="selectSearchCategory(suggestion.key)"
          >
            <span class="menu-icon cat-icon iconame-ft-{{ suggestion.icon }}"></span>
            <span class="cat-title search-element" title="{{ suggestion.key }}">{{ suggestion.key }}</span>
          </button>
        </div>

        <div *ngIf="!suggestionShow">
          <button
            *ngFor="let category of categories"
            tabindex="0"
            class="button dropdown-item"
            (click)="selectSearchCategory(category.key, true)"
            attr.data-qa="{{ 'srch-cat-opt-' + category.nameCode }}"
          >
            <img class="cat-icon" src="../../../assets/images/file-icons/{{ category.icon }}" alt="Category Icon" />
            <span class="cat-title search-element">{{ 'header.searchCats.'+category.nameCode | translate }}</span>
          </button>
        </div>
      </div>

      <button *ngIf="!mobileView" tabindex="-1" (click)="search()" data-qa="searchBtn" class="button search-btn col-blue small-size">
        <span class="menu-icon iconame-search-white-btn"></span>
      </button>
    </div>
  </form>
</div>

<div class="search-wrap" *ngIf="mobileView">
  <button class="show-search" [keyboardActions] tabindex="0" (click)="toggleSearch()">
    <span class="menu-icon search-button-dark"></span>
  </button>
</div>
