<div class="loader-view" #ldWrap [ngClass]="{ blink: ldBlink }" [@fadeRegular]>
  <!-- File-list loader -->
  <div *ngIf="loaderTemplate === 'file-list' || loaderTemplate === 'file-list-shared'" class="ft-body" #ldBody>
    <ul class="ft-list ft-body-list cfix">
      <li *ngFor="let item of ldItems" class="ld-item">
        <div class="item-wrapper">
          <div class="cell-group" [class.compact]="loaderTemplate === 'file-list-shared'">
            <div class="cell-selection"></div>
            <div class="cell-icon">
              <span class="l-block"></span>
            </div>
            <div class="cell-name"><span class="l-block"></span></div>
          </div>
          <div class="cell-date" [class.compact]="loaderTemplate === 'file-list-shared'"><span class="l-block"></span></div>
          <div class="cell-size"><span class="l-block"></span></div>
          <div class="cell-date compact shared" *ngIf="loaderTemplate === 'file-list-shared'"><span class="l-block"></span></div>
        </div>
      </li>
    </ul>
  </div>

  <!-- Modal loaders -->
  <div *ngIf="loaderTemplate === 'file-version-modal'" class="ft-body">
    <ul class="ft-list ft-body-list cfix">
      <li *ngFor="let item of ldItems" class="ld-item">
        <div class="item-wrapper">
          <div class="cell-icon">
            <span class="l-block"></span>
          </div>
          <div class="cell-name"><span class="l-block"></span></div>
          <div class="cell-selection"><span class="l-block"></span></div>
        </div>
      </li>
    </ul>
  </div>

  <div *ngIf="loaderTemplate === 'move-files-modal'" class="ft-body">
    <ul class="ft-list ft-body-list cfix">
      <li *ngFor="let item of ldItems" class="ld-item isFixRow">
        <div class="item-wrapper">
          <div class="cell-selection"></div>
          <div class="cell-icon">
            <span class="l-block"></span>
          </div>
          <div class="cell-name"><span class="l-block"></span></div>
        </div>
      </li>
    </ul>
  </div>

  <ng-container *ngIf="loaderTemplate === 'file-properties-modal'">
    <div class="file-info-row ld-item" *ngFor="let item of ldItems">
      <div class="file-info-label">
        <div class="loading-item"></div>
      </div>
      <div class="file-info-value">
        <div class="loading-item"></div>
      </div>
    </div>
  </ng-container>
</div>
