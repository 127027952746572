import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RefreshPageComponent } from '../modules/internal/common/components/refresh-page/refresh-page.component';
import { LoginComponent } from '../components/login/login.component';
import { ErrorPageComponent } from '../modules/internal/common/components/error-page/error-page.component';

export const appRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'sharelink', loadChildren: () => import('src/app/modules/shared-link/share-link.module').then((m) => m.ShareLinkModule) },
  {
    path: 'shared-folder',
    loadChildren: () => import('src/app/modules/shared-folder/shared-folder.module').then((m) => m.SharedFolderModule),
  },
  { path: 'survey', loadChildren: () => import('src/app/modules/survey/survey.module').then((m) => m.SurveyModule) },
  { path: 'refresh-page', component: RefreshPageComponent }, // TODO when directly access refresh page nothing happen
  { path: 'error', component: ErrorPageComponent },
  {
    path: '',
    loadChildren: () => import('src/app/modules/internal/wrapper/wrapper.module').then((m) => m.WrapperModule),
  }, // Do not put anything below that statement, wild card route (**) inside wrapper module
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      //  { enableTracing: true } // <-- debugging purposes only! - Start router tracing and print every event in the console.
      { relativeLinkResolution: 'legacy' }
    ),
  ],
  exports: [RouterModule],
})
export class RoutingModule {}
