<ng2-dropdown [dynamicUpdate]="dynamicUpdate">
  <ng2-dropdown-menu
    [focusFirstElement]="focusFirstElement"
    [zIndex]="zIndex"
    [appendToBody]="appendToBody"
    [offset]="offset"
  >
    <ng2-menu-item
      *ngFor="let item of items; let index = index; let last = last"
      [value]="item"
      [ngSwitch]="!!templates.length"
    >
      <span *ngSwitchCase="false" [innerHTML]="item[displayBy] | highlight : tagInput.inputForm.value.value"> </span>

      <ng-template
        *ngSwitchDefault
        [ngTemplateOutlet]="templates.first"
        [ngTemplateOutletContext]="{ item: item, index: index, last: last }"
      >
      </ng-template>
    </ng2-menu-item>
  </ng2-dropdown-menu>
</ng2-dropdown>
