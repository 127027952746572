import { Injectable, EventEmitter, Output } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DeviceInfo } from 'ngx-device-detector';

@Injectable()
export class ResponsiveLayoutService {
  constructor(private deviceService: DeviceDetectorService) {}

  @Output() screenChange: EventEmitter<string> = new EventEmitter();

  deviceData: DeviceInfo = this.deviceService.getDeviceInfo();
  screenSize: string = '';
  breakPoints = {
    bp1: window.matchMedia('screen and (min-width: 1501px)'),
    bp2: window.matchMedia('screen and (max-width: 1500px) and (min-width: 1321px)'),
    bp3: window.matchMedia('screen and (max-width: 1320px) and (min-width: 1025px)'),
    bp4: window.matchMedia('screen and (max-width: 1024px) and (min-width: 769px)'),
    bp5: window.matchMedia('screen and (max-width: 768px) and (min-width: 601px)'),
    bp6: window.matchMedia('screen and (max-width: 600px) and (min-width: 421px)'),
    bp7: window.matchMedia('screen and (max-width: 420px)'),
  };

  responsiveListener(breakPoint) {
    const startPoint = breakPoint.media.indexOf('(');
    let media = breakPoint.media.slice(startPoint, breakPoint.media.length);

    const thisBrowser = this.deviceData.browser.toLowerCase();
    if (thisBrowser === 'ie' || thisBrowser === 'ms-edge') {
      media = media.replace(/\:/g, ': ');
    }

    if (breakPoint.matches) {
      // If media query matches
      if (media === '(min-width: 1501px)') {
        this.screenSize = 'b'; // Big screen resolution
        // console.log('screen size --> min-width: 1501px');
      }
      if (media === '(max-width: 1500px) and (min-width: 1321px)') {
        this.screenSize = 'm'; // Medium screen resolution
        // console.log('screen size --> 1321px - 1500px');
      }
      if (media === '(max-width: 1320px) and (min-width: 1025px)') {
        this.screenSize = 's'; // Small screen resolution
        // console.log('screen size --> 1025px - 1320px');
      }

      if (media === '(max-width: 1024px) and (min-width: 769px)') {
        this.screenSize = 'mobile-1024'; // Small screen resolution
        // console.log('MOBILE size !!! 1024 - 769');
      }
      if (media === '(max-width: 768px) and (min-width: 601px)') {
        this.screenSize = 'mobile-768'; // Small screen resolution
        // console.log('MOBILE size !!! - 768');
      }
      if (media === '(max-width: 600px) and (min-width: 421px)') {
        this.screenSize = 'mobile-600'; // Small screen resolution
        // console.log('MOBILE size !!! - 600-421');
      }
      if (media === '(max-width: 420px)') {
        this.screenSize = 'mobile-600'; // Small screen resolution
        // console.log('MOBILE size !!! - 420');
      }

      this.screenChange.emit(this.screenSize);
    }
  }

  startResponsiveListener() {
    for (const bp in this.breakPoints) {
      this.responsiveListener(this.breakPoints[bp]);
      this.breakPoints[bp].addListener(
        function (layoutBreakPoint) {
          this.responsiveListener(layoutBreakPoint);
        }.bind(this)
      );
    }
  }

  getScreenSize() {
    return this.screenSize;
  }
}
