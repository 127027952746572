import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { UrlHandlerService } from '../../services/url-handler.service';
import { LanguageManagerService } from '../../services/language-manager.service';
import { ManageCookieService } from '../../services/manage-cookie.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  constructor(
    private loginService: LoginService,
    private router: Router,
    private languageService: LanguageManagerService,
    private manageCookieService: ManageCookieService,
    private activatedRoute: ActivatedRoute,
    private urlHandlerService: UrlHandlerService
  ) {}

  sessionId: string;
  searchKey: string;
  countryKey: string = '';

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      const isRootPath = params.prevPath === '/' || params.prevPath.startsWith('/?');

      let redirectPage = params.prevPath && !isRootPath ? params.prevPath : '/my-files';
      this.sessionId = this.manageCookieService.getCookie('scode');

      if (params.prevPath) {
        // Check for search string
        this.searchKey = decodeURI(this.urlHandlerService.getUrlParam(redirectPage, 's'));
        redirectPage = this.urlHandlerService.removeURLParameter(redirectPage, 's');

        // Check for user language
        this.countryKey = decodeURI(this.urlHandlerService.getUrlParam(redirectPage, 'country'));
        redirectPage = this.urlHandlerService.removeURLParameter(redirectPage, 'country');
      } else {
        // Clean Scode from url
        this.urlHandlerService.cleanUrl();
      }

      this.loginUser(redirectPage);
    });
  }

  loginUser(redirectPage) {
    // Set User language
    const langCode = this.manageCookieService.getCookie('langaugeCode');
    if (!langCode && this.countryKey && this.countryKey !== 'undefined') {
      const lang = this.languageService.getLanguageByCountry(this.countryKey);
      this.languageService.setLanguage(lang.code);
    }

    // If user is loggedin redirect it and directly access Login page
    if (this.loginService.isLoggedIn()) {
      this.redirectUrl(redirectPage);
    }

    // Else Start Loging Process and pass redirect URL cords in case that login fails
    this.loginService.checkSession(this.sessionId, redirectPage);
    this.loginService.loggedInData.subscribe((data) => {
      if (data) {
        this.redirectUrl(redirectPage);
      }
    });
  }

  redirectUrl(redirectPage) {
    if (this.searchKey && this.searchKey !== 'undefined') {
      this.router.navigate([redirectPage.split('?')[0]], { queryParams: { s: this.searchKey } });
    } else {
      const redirectUrl = redirectPage.split('?');
      const queryParams = this.urlHandlerService.queryParamsToObj(['prevPath'], redirectUrl[1]);
      this.router.navigate([redirectUrl[0]], { queryParams: queryParams });
    }
  }
}
