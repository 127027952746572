import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IProfileData } from 'src/app/services/login.service';
import { OfficeSuiteSupportService } from '../../../services/office-suite-support.service';
import { TrackingService } from '../../../services/tracking.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'header-static-menu',
  templateUrl: './header-static-menu.component.html',
  styleUrls: ['./header-static-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderStaticMenuComponent {
  @Output() closeMenuEvent = new EventEmitter();

  @Input() set screen(res) {
    const resData = res.split('-');
    if (resData[1] && resData[1] < 1025) {
      this.view = 'mobile';
    } else {
      this.view = 'regular';
    }
  }

  @Input() set openMenu(state: boolean) {
    this.showMobMenu = state;
  }

  @Input() pageName = '';
  @Input() profileData: IProfileData;

  showMobMenu: boolean = false;
  view: string = 'regular';
  mobisystemsUrl = environment.mobisystemsURL + '/mobidrive';

  noMenuForPages: string[] = ['checkout', 'pricing-plans'];

  constructor(private officeSuiteSupportService: OfficeSuiteSupportService, private trackingService: TrackingService) {}

  onCloseMenuAfterClickLink(category: string, action: string, label: string) {
    this.trackingService.trackEvent(category, action, label);
    this.closeMenu();
  }

  closeMenu() {
    this.closeMenuEvent.emit(true);
    if (window) {
      window.scrollTo(0, 0);
    }
  }

  goToMobiDrive(e) {
    e.preventDefault();
    this.closeMenu();
    this.trackingService.trackEvent('website.browse', 'go-to-mobi-drive', 'nav-bar.button');
    this.officeSuiteSupportService.goToMobiDrive();
  }
}
