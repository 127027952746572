import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import 'hammerjs';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  const excludeTestHosts = ['localhost', 'mobidrive.local'];
  Sentry.init({
    dsn: 'https://71538bf899354f8fb9150c085e95d94e@sentry.mobisystems.com/5',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    environment: environment.envName,
    beforeSend(event) {
      if (excludeTestHosts.includes(window.location.hostname)) return null;
      return event;
    },
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule, { preserveWhitespaces: false })
    .catch((err) => console.log(err));
});
