<div
  *ngIf="view === 'regular'"
  class="lang-container dd-menu"
  tabindex="0"
  [keyboardActions]="{ dropdown: true }"
  dropdown
>
  <div class="lang-selector" dropdown-open>
    <span class="planet-lang">
      <ng-container *ngTemplateOutlet="planetLangIcon"></ng-container>
    </span>
    <span class="current-lang">{{ currentLang?.label }}
      <ng-container *ngTemplateOutlet="arrowDownIcon"></ng-container>
    </span>
  </div>
  <ng-container *ngTemplateOutlet="langList"></ng-container>
</div>

<div *ngIf="view === 'step-menu'" class="lang-container step-menu dd-menu" [ngClass]="{ open: showStepMenu }">
  <div class="lang-selector" (click)="toggleMenu()">
    <span class="planet-lang">
      <ng-container *ngTemplateOutlet="planetLangIcon"></ng-container>
    </span>
    <span class="current-lang">{{ currentLang?.label }}</span>
  </div>
  <ng-container *ngTemplateOutlet="langList"></ng-container>
</div>

<ng-template #langList>
  <ul class="lang-list dd-menu-hidden">
    <li *ngFor="let lang of languages">
      <span
        tabindex="0"
        [ngClass]="{ bold: lang.code === currentLang?.code }"
        [keyboardActions]
        (click)="changeLanguage(lang)"
      >
        <span>{{ lang.label }}</span>
      </span>
    </li>

    <li class="close-step-menu">
      <span (click)="toggleMenu()">{{ 'base.back' | translate }}</span>
    </li>
  </ul>
</ng-template>

<ng-template #planetLangIcon>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 35 35" style="enable-background:new 0 0 35 35;" xml:space="preserve">
    <g>
      <path style="fill:#546065;" d="M17.5,31.9c-8,0-14.4-6.4-14.4-14.4S9.5,3.1,17.5,3.1c8,0,14.4,6.4,14.4,14.4c0,6.7-4.6,12.5-11.1,14
        C19.7,31.8,18.6,31.9,17.5,31.9z M17.5,4.8c-1,0-2,0.1-2.9,0.4l0,0C7.8,6.8,3.7,13.6,5.3,20.4s8.4,10.9,15.2,9.3
        c6.8-1.6,10.9-8.4,9.3-15.2C28.4,8.8,23.4,4.8,17.5,4.8z"/>
      <path style="fill:#546065;" d="M8.6,27.8l-1.2-1.2c1.4-1.4,3.1-2.5,5-3.2c5.2-2,11.2-0.7,15.2,3.2l-1.2,1.2c-1.2-1.2-2.7-2.2-4.4-2.8
        C17.3,23.2,12.1,24.3,8.6,27.8L8.6,27.8z"/>
      <path style="fill:#546065;" d="M17.5,12.6c-1.8,0-3.5-0.3-5.1-1c-1.9-0.7-3.6-1.8-5-3.2l1.2-1.2c1.2,1.2,2.7,2.2,4.4,2.8c2.9,1.1,6.1,1.1,9,0
        c1.6-0.6,3.1-1.6,4.4-2.8l1.2,1.2c-1.4,1.4-3.1,2.5-5,3.2C21,12.3,19.3,12.6,17.5,12.6z"/>
      <path style="fill:#546065;" d="M17.5,31.9c-3.6,0-6.4-6.2-6.4-14.4s2.7-14.4,6.4-14.4s6.4,6.2,6.4,14.4S21.2,31.9,17.5,31.9z M17.5,4.8
        c-2.2,0-4.7,5.3-4.7,12.7s2.5,12.7,4.7,12.7s4.7-5.3,4.7-12.7S19.7,4.8,17.5,4.8z"/>
      <rect x="4" y="16.7" style="fill:#546065;" width="27.1" height="1.7"/>
    </g>
</svg>
</ng-template>

<ng-template #arrowDownIcon>
  <span>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 16 9" style="enable-background:new 0 0 16 9;" xml:space="preserve">
    <g>
      <g>
        <path style="fill-rule:evenodd;clip-rule:evenodd;fill:#949494;" d="M8.7,8.7C8.7,8.7,8.7,8.8,8.7,8.7l7-7c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0L8,6.6L1.7,0.3
          c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l7,7C7.7,9.1,8.3,9.1,8.7,8.7z"/>
      </g>
    </g>
    </svg>
  </span>
</ng-template>
