import { Component, Input } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'activation-success',
  templateUrl: './activation-success.component.html',
})
export class ActivationSuccessComponent {
  @Input() storage: number;
  @Input() menuSource: string;
  @Input() modal: any;

  mobileDevice: boolean = document.getElementsByTagName('html')[0].classList.contains('mobile-device');
  fileData: File = null;
  helpCenterUrl: string = environment.helpCenterURL;

  constructor() {}

  closeModal() {
    this.modal.close();
  }
}
