import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TrackingService} from '../../../../services/tracking.service';
import {GetFilesService} from '../../../../services/get-files.service';
import {IRefsMap} from '../../../../models/common.int';

@Component({
  selector: 'app-new-item',
  templateUrl: './new-item.component.html',
  styleUrls: ['./new-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewItemComponent implements OnInit, OnDestroy {
  @Input() modal: any;
  @Input() menuSource: string = '';
  @Input() filesData: any;
  refs: IRefsMap = {};
  downloadLink: string = '';

  constructor(private trackingService: TrackingService,
              private cdRef: ChangeDetectorRef,
              private getFilesService: GetFilesService) {}

  ngOnInit() {
    if (this.filesData.appCode === 'openWithPdfExtra') {
      this.downloadLink = 'https://pdfextra.com/?referrer=opennewMDWEB';
    } else {
      this.downloadLink = `https://www.officesuite.com/free-download/?referrer=${this.filesData.file ? 'open' : 'create'}newMDWEB`;
    }
    if (this.filesData.file?.length) {
      this.getFilesService.requireThumbnails(this.filesData.file[0]);
      this.refs.getThumbnails = this.getFilesService.getThumbnails.subscribe((thumbData) => {
        this.filesData.file[0].thumbImage = thumbData.imageUrl;
        this.cdRef.detectChanges();
      });
    }
  }

  download() {
    window.open(this.downloadLink, '_blank');
  }

  ngOnDestroy() {
    // Clean refs
    for (const ref in this.refs) {
      if (this.refs[ref]) {
        this.refs[ref].unsubscribe();
      }
    }
  }
}
