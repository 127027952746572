import { Component, OnInit, Input } from '@angular/core';
import { ApplyActionService } from '../../../../services/apply-action.service';

@Component({
  selector: 'app-install-office',
  templateUrl: './install-office.component.html',
})
export class InstallOfficeComponent implements OnInit {
  @Input() filesData: any;
  @Input() menuSource: any;
  @Input() modal: any;

  downloadLink: string = '';

  constructor(private applyActionService: ApplyActionService) {}

  ngOnInit() {
    if (this.filesData.appCode === 'openWithOffice') {
      this.downloadLink = 'https://www.officesuite.com';
    } else {
      this.downloadLink = 'https://pdfextra.com';
    }
  }

  downloadFile() {
    this.applyActionService.downloadAction(this.filesData.file);
    this.modal.close();
  }
}
