import { Injectable } from '@angular/core';

const getWindow = (): any => {
  return window;
};

@Injectable({
  providedIn: 'root',
})
export class WindowReferenceService {
  get nativeWindow() {
    return getWindow();
  }
}
