<div *ngIf="showCB" class="cookie-consent-wrap" [class.up]="isUpPosition">
  <div class="cookie-consent">
    <span class="close-button" (click)="closeBanner()"></span>
    <div class="text-area">
      <span>{{ 'base.cookieConsent' | translate }}</span>
      <a [routerLink]="['/privacy-policy']" target="_blank">{{ 'base.learnMore' | translate }}</a>
    </div>
    <div class="button big-size col-blue" (click)="closeBanner()">{{ 'base.iAgree' | translate }}</div>
  </div>
</div>
