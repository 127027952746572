<section class="modal-wrap install-office-modal">
  <div class="cfix container modal-body custom-wrap" [ngClass]="filesData.appCode">
    <div class="file-name">
      <div class="cell-icon">
        <span class="menu-icon iconame-ft-{{ filesData.file[0].name | fileExtension: 'noDot' }}"></span>
      </div>
      <div class="cell-name">
        {{ filesData.file[0].name }}
      </div>
    </div>
    <div class="file-size">
      {{ filesData.file[0].size | byteSize }}
    </div>
    <div class="action-box">
      <div class="officesuite-links">
        <span *ngIf="filesData.appCode === 'openWithOffice'">{{ 'modal.noOfficeSuite' | translate }}</span>
        <span *ngIf="filesData.appCode === 'openWithPdfExtra'">{{ 'modal.noPDFExtra' | translate }}</span>
        <a class="install-office-links free" [attr.href]="downloadLink" target="_blank" (click)="modal.close()">
          {{ 'modal.getItFree' | translate }}
        </a>
      </div>

      <div class="hr-box">
        <a class="install-office-links" (click)="downloadFile()">
          {{ 'modal.downloadFile' | translate }}
        </a>
      </div>
    </div>
  </div>
</section>
