export enum SharedViewModesE {
  SHARED_WITH_ME = 'list-shared-with-me',
  SHARED_BY_ME = 'list-shared-by-me',
}

export enum NewItemModulesE {
  doc = 'Documents',
  sheet = 'Sheets',
  presentation = 'Slides',
}

export enum OsArchitecture {
  x64 = '64',
  x86 = '32',
}

export enum StaticAppLinks {
  // eslint-disable-next-line max-len
  homePageAndroid = `https://play.google.com/store/apps/details?id=com.mobisystems.mobidrive&referrer=utm_source=WebDriveAppsAvailable&utm_campaign=InstallLinksOct2023`,
  homePageIos = 'https://apps.apple.com/app/apple-store/id1558848373?pt=37682&ct=WebDriveAppsAvailabIeOct2023&mt=8',
  // eslint-disable-next-line max-len
  android = `https://play.google.com/store/apps/details?id=com.mobisystems.mobidrive&referrer=utm_source=WebDriveGetMobileExclusiveFeatures&utm_campaign=InstallLinksOct2023`,
  ios = `https://apps.apple.com/app/apple-store/id1558848373?pt=37682&ct=MobileExclusiveFeaturesOct2023&mt=8`,
  // eslint-disable-next-line max-len
  footerHomeAndroid = `https://play.google.com/store/apps/details?id=com.mobisystems.mobidrive&referrer=utm_source=HomePageFooter&utm_campaign=InstallLinksOct2023`,
  footerHomeIos = `https://apps.apple.com/app/apple-store/id1558848373?pt=37682&ct=HomePageFooterOct2023&mt=8`,
  // eslint-disable-next-line max-len
  footerPricingAndroid = `https://play.google.com/store/apps/details?id=com.mobisystems.mobidrive&referrer=utm_source=PricingPlanPageFooter&utm_campaign=InstallLinksOct2023`,
  footerPricingIos = `https://apps.apple.com/app/apple-store/id1558848373?pt=37682&ct=PricingPlanPageFooterOct2023&mt=8`,
  // eslint-disable-next-line max-len
  footerLandingAndroid = `https://play.google.com/store/apps/details?id=com.mobisystems.mobidrive&referrer=utm_source=LandingPageFooter&utm_campaign=InstallLinksOct2023`,
  footerLandingIos = `https://apps.apple.com/app/apple-store/id1558848373?pt=37682&ct=LandingPageFooterOct2023&mt=8 `,
}
