import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DropdownNotClosableZoneDirective } from './directives/dropdown-not-closable-zone.directive';
import { DropdownDirective } from './directives/dropdown.directive';
import { DropdownOpenDirective } from './directives/dropdown-open.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    DropdownNotClosableZoneDirective,
    DropdownDirective,
    DropdownOpenDirective
  ],
  exports: [
    DropdownNotClosableZoneDirective,
    DropdownDirective,
    DropdownOpenDirective
  ]
})
export class DropdownModule {}
