import { Injectable, EventEmitter, Output, Directive } from '@angular/core';

@Injectable()
export class FilePreviewService {
  @Output() openPreview: EventEmitter<any> = new EventEmitter();
  @Output() closePreview: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  openFilePreview(data) {
    this.openPreview.emit(data);
  }

  closeFilePreview() {
    this.closePreview.emit(true);
  }
}
