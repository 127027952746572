<section class="modal-wrap file-properties-modal">
  <div class="thumb-wrap">
    <ng-container *ngIf="!loading && currentFile.thumbImage">
      <div
        *ngIf="currentFile.thumbImage !== 'error'"
        class="file-thumb"
        [ngStyle]="{ 'background-image': 'url(' + currentFile.thumbImage + ')' }"
      ></div>
      <div
        *ngIf="currentFile.thumbImage === 'error'"
        class="file-thumb backup-img iconame-ft-{{ currentFile.name | fileExtension: 'noDot' }}"
      ></div>
    </ng-container>

    <div *ngIf="!currentFile.thumbImage && !currentFile.dir" class="file-thumb">
      <div class="animation-block"></div>
    </div>
    <div *ngIf="!currentFile.thumbImage && currentFile.dir" class="file-thumb backup-img iconame-ft-folder"></div>
  </div>

  <div class="modal-body cfix">
    <div *ngIf="!currentFile.dir" class="sub-modal-title">
      <span>{{ currentFile.name }}</span>
    </div>
    <div *ngIf="currentFile.dir" class="sub-modal-title">
      <span>{{ currentFile.name | folderName }}</span>
    </div>

    <!-- Loader view -->
    <div *ngIf="loading" class="dir-wrapper loading" [@fadeRegular]>
      <app-loader-grid [loaderTemplate]="'file-properties-modal'" [loadItems]="7"></app-loader-grid>
    </div>

    <!-- File properties view -->
    <div
      *ngIf="!loading"
      #fileProperties
      [perfectScrollbar]
      [disabled]="mobileDevice"
      [@fadeRegular]
      class="dir-wrapper"
    >
      <div *ngIf="fileOwner" class="file-info-row">
        <div class="file-info-label">{{ 'modal.locationPath' | translate }}</div>
        <div class="file-info-value">
          <a
            class="folder-link"
            *ngIf="currentFileParent && !currentFileParent.noLink"
            [routerLink]="[currentFileParent.url]"
            (click)="openContainFolder()"
          >
            <span class="menu-icon iconame-ft-folder"></span>
            <span class="parent-folder-name" title="{{ currentFileParent.name | folderName }}">
              <ng-container *ngIf="currentFileParent.regularFolder">
                {{ currentFileParent.name | folderName }}
              </ng-container>
              <ng-container *ngIf="currentFileParent.key === 'BA__CK__UPS'">
                {{ 'pages.backups' | translate }}
              </ng-container>
              <ng-container *ngIf="!currentFileParent.key">
                {{ 'pages.myFiles' | translate }}
              </ng-container>
            </span>
          </a>
          <span class="folder-link" *ngIf="currentFileParent && currentFileParent.noLink">
            <span class="menu-icon iconame-ft-folder"></span>
            <span class="parent-folder-name" title="{{ currentFileParent.name | folderName }}">{{
              currentFileParent.name | folderName
            }}</span>
          </span>
        </div>
      </div>
      <div class="file-info-row">
        <div class="file-info-label">{{ 'modal.fileType' | translate }}</div>
        <div class="file-info-value">
          <span *ngIf="!currentFile.dir" class="file-extension">{{ currentFile.name | fileExtension: 'noDot' }}</span>
          <span *ngIf="currentFile.dir">{{ 'base.folder' | translate }}</span>
        </div>
      </div>
      <div *ngIf="!currentFile.dir" class="file-info-row">
        <div class="file-info-label">{{ 'table.size' | translate }}</div>
        <div class="file-info-value">{{ currentFile.size | byteSize }}</div>
      </div>
      <div class="file-info-row">
        <div class="file-info-label">{{ 'modal.dateModified' | translate }}</div>
        <div class="file-info-value">{{ currentFile.modified | formatDate: 'fullDate':langCode }}</div>
      </div>
      <div class="file-info-row">
        <div class="file-info-label">{{ 'modal.dateCreated' | translate }}</div>
        <div class="file-info-value">{{ currentFile.created | formatDate: 'fullDate':langCode }}</div>
      </div>
      <div *ngIf="fileOwner && !currentFile.dir" class="file-info-row">
        <div class="file-info-label"></div>
        <div class="file-info-value">
          <a class="file-action-link" (click)="actionMenuService.performAction('version-history', [currentFile])">
            <span class="menu-icon iconame-version-history"></span>
            <span>{{ 'modal.versionHistory' | translate }}</span>
          </a>
        </div>
      </div>
      <div class="file-info-row">
        <div class="file-info-label">{{ 'modal.owner' | translate }}:</div>
        <div class="file-info-value">
          <div *ngIf="fileOwnerInfo" class="ua-item user-menu">
            <div class="dd-menu-head">
              <span class="user-icon">{{ fileOwnerInfo.name | shortUserName }}</span>
              <span class="user-name">{{ fileOwnerInfo.name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="fileOwner && currentFileParent.key !== 'BA__CK__UPS'" class="file-info-row">
        <div class="file-info-label">{{ 'modal.shareStatus' | translate }}:</div>
        <div class="file-info-value">
          <ng-container *ngIf="!currentFile.publiclyShared">
            <span
              >{{ 'modal.linkShareOff' | translate }}<span class="dot-separator">&#9679;</span
              ><a class="file-action-link" (click)="actionMenuService.performAction('share', [currentFile])">{{
                'modal.share' | translate
              }}</a></span
            >
          </ng-container>

          <ng-container *ngIf="currentFile.publiclyShared">
            <span
              >{{ 'modal.anyoneCanView' | translate }}<span class="dot-separator">&#9679;</span
              ><a class="file-action-link" (click)="actionMenuService.performAction('unshare', [currentFile])">{{
                'modal.stopSharing' | translate
              }}</a></span
            >
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <footer class="modal-footer cfix">
    <button class="button col-blue medium-size" [disabled]="loading" (click)="modal.close()">
      {{ 'modal.close' | translate }}
    </button>
  </footer>
</section>
