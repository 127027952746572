import { Directive, OnDestroy, HostBinding, Input, HostListener } from '@angular/core';
import { DragService } from '../../../services/drag-service.service';

@Directive({
  selector: '[dropPreventHandler]',
  host: {
    '(dragover)': 'dragOverHandler($event)',
    '(dragenter)': 'dragOverHandler($event)',
    '(drop)': 'preventDropExit($event)',
  },
})
export class DropPreventDirective implements OnDestroy {
  constructor(private dragService: DragService) {}

  ngOnDestroy() {}

  dragOverHandler(event) {
    this.dragService.dragOverHandler(event);
  }

  preventDropExit(event) {
    event.preventDefault();
    event.stopPropagation();
  }
}
