<section class="modal-wrap version-history-modal">
  <h5 class="modal-title">
    <span class="light">{{ 'modal.versionHistory' | translate }}</span>
  </h5>

  <div class="modal-body cfix">
    <div class="sub-modal-title">
      <span>{{ filesData[0].name }}</span>
    </div>
    <!-- Loader view -->
    <div *ngIf="loading" class="dir-wrapper loading" [@fadeRegular]>
      <app-loader-grid [loaderTemplate]="'file-version-modal'"></app-loader-grid>
    </div>

    <!-- Versions list view -->
    <div
      *ngIf="!loading"
      class="dir-wrapper"
      #versionsList
      [perfectScrollbar]
      [disabled]="mobileDevice"
      [@fadeRegular]
    >
      <ng-container *ngIf="versionError">
        <div class="version-error">
          <strong>{{ 'body.refreshPageTitle' | translate }}</strong>
          <p>
            <span (click)="refreshVersions($event)" [innerHTML]="'body.pleaseTryAgain' | translate"></span>
          </p>
        </div>
      </ng-container>

      <div *ngIf="!versionError" class="folders-list">
        <ul *ngIf="fileHistory.length" class="ft-list ft-body-list cfix">
          <li *ngFor="let file of fileHistory; let i = index">
            <div class="item-wrapper file" [ngClass]="{ selected: file.selected }">
              <div class="cell-icon">
                <span class="menu-icon iconame-ft-{{ filesData[0].name | fileExtension: 'noDot' }}"></span>
              </div>

              <div class="cell-name">
                <strong *ngIf="i === 0">{{ 'modal.latestVersion' | translate }}</strong>
                <strong *ngIf="i !== 0">{{ 'modal.version' | translate }} {{ file.idx + 1 }}</strong>
                <span class="version-details">
                  {{ file.modified | formatDate: 'fullDate':langCode }} -
                  <span class="v-file-size">{{ file.size | byteSize }}</span>
                  <span
                    *ngIf="file.metadata.device.userFriendlyName"
                    title="{{ file.metadata.device.userFriendlyName }}"
                  >
                    - {{ file.metadata.device.userFriendlyName }}</span
                  >
                </span>
              </div>

              <span
                class="button col-menu"
                title="{{ 'actionMenu.download' | translate }}"
                (click)="downloadVersion(file)"
              >
                <span class="menu-icon iconame-download-file"></span>
              </span>
            </div>
          </li>
          <li class="load-files-flag" *ngIf="loadScroll" [@fadeRegular]>
            <span class="files-loader iconame-loading"></span>
          </li>
        </ul>

        <div class="txt-box" *ngIf="!fileHistory.length">
          <p>
            {{ 'modal.noVersionHistory' | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <footer class="modal-footer cfix">
    <button class="button col-blue medium-size" [disabled]="loading" (click)="modal.close()">
      {{ 'modal.close' | translate }}
    </button>
  </footer>
</section>
