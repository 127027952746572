import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { applicationConfigs as appParams } from '../configs/app-confis';
import { configs } from '../configs/drive-configs';
import { NewItemModulesE } from '../models/enums';
import { AdvanceDeviceDetectionService } from './advance-device-detection.service';
import { AuthService } from './auth.service';
import { DownloadManagerService } from './download-manager.service';
import { LocationService } from './location.service';
import { LoginService } from './login.service';
import { ManageCookieService } from './manage-cookie.service';
import { ServerDetectionService } from './server-detection.service';
import { TrackingService } from './tracking.service';
import { LanguageManagerService } from './language-manager.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable()
export class OfficeSuiteSupportService {
  constructor(
    private authService: AuthService,
    private loginService: LoginService,
    private deviceService: AdvanceDeviceDetectionService,
    private locationService: LocationService,
    private trackingService: TrackingService,
    private downloadManagerService: DownloadManagerService,
    private router: Router,
    private serverDetection: ServerDetectionService,
    private manageCookieService: ManageCookieService,
    private languageManagerService: LanguageManagerService
  ) {}

  @Output() showSharedDialog: EventEmitter<any> = new EventEmitter();

  server: boolean = this.serverDetection.isServer();
  deviceData: any = this.deviceService.getDeviceInfo();
  windowRef: any;

  osConfigMap: any = {
    Android: 'officeFormatsAndroid',
    Windows: 'officeFormats',
    iOS: 'officeFormatsIOS',
  };

  openWithApp(file, appCode) {
    if (this.isFileBusy(file.key)) {
      return;
    }

    // Fix for iOS window.open
    if (this.deviceData.os === 'iOS') {
      this.windowRef = window.open();
    }
    if (this.loginService.isLoggedIn()) {
      // If user is logged In
      const accId = sessionStorage.getItem('accountId');
      const authTkn = sessionStorage.getItem('token');
      this.authService.authRequest('profile', 'issue-xchange-code', { account: accId, tkn: authTkn }).subscribe(
        (data: any) => {
          this.openFileManager(file, appCode, data);
        },
        (error) => {
          console.log('error', error);
        }
      );
    } else {
      this.openFileManager(file, appCode, '');
    }
  }

  openNewModuleWithApp(module, appCode) {
    const accId = sessionStorage.getItem('accountId');
    const authTkn = sessionStorage.getItem('token');
    this.authService.authRequest('profile', 'issue-xchange-code', { account: accId, tkn: authTkn }).subscribe(
      (data: any) => {
        this.openNewModuleWindows(module, appCode, data);
      },
      (error) => {
        console.log('error', error);
      }
    );
  }

  openFileManager(file, appCode, xchangeCode) {
    if (this.deviceData.os === 'Windows') {
      this.openFileWindows(file, appCode, xchangeCode);
    }

    if (this.deviceData.os === 'Android' || this.deviceData.os === 'iOS') {
      const officeUrl: any = this.getMobileOpenFileLink(file, appCode, xchangeCode);
      if (this.deviceData.os === 'iOS') {
        this.windowRef.location = officeUrl;
      } else {
        window.open(officeUrl, '_blank');
      }
    }
  }

  openFileWindows(file, appCode, xchangeCode) {
    const officeUrl = this.getWindowsAppUrl(file, appCode, xchangeCode);
    const sharedDialog = this.showSharedDialog;
    const dms = this.downloadManagerService;
    const trackName =
      (this.isFileFormatPdf(file) ? 'pdf' : 'doc') + (appCode === 'openWithOffice' ? '-open-with-officesuite' : '-open-with-pdf-extra');

    window.protocolCheck(
      officeUrl,
      () => {
        // If user do not have officesuite installed
        sharedDialog.emit({ view: 'newItem', files: { file: [file], appCode: appCode }, menuSource: '' });
        this.trackingService.trackEvent('web-app.usage', trackName, trackName + '-fail');
      },
      (e) => {
        // on success
        const shouldShowBannerCookies = !this.manageCookieService.getCookie('OfficeSuite_Promo_Trial_Popup');
        if (shouldShowBannerCookies && this.loginService.getProfileData()?.planFeatures?.storageTier === 0) {
          sharedDialog.emit({ view: 'promoOSTrial', files: [] });
          this.manageCookieService.setCookie('OfficeSuite_Promo_Trial_Popup', `${true}`, { period: 'd', value: 3 });
        }
        this.trackingService.trackEvent('web-app.usage', trackName, trackName + '-success');
      },
      (e) => {
        // If Unsupported browser do download
        dms.startDownload([file]);
      }
    );
  }

  openNewModuleWindows(module, appCode, xchangeCode) {
    const officeUrl = this.getWindowsAppNewUrl(module, appCode, xchangeCode);
    const sharedDialog = this.showSharedDialog;
    const trackName = (module === 'presentation' ? 'slide' : module) + '-create-with-officesuite';
    window.protocolCheck(
      officeUrl,
      () => {
        // If user do not have officesuite installed
        console.log('do not have officesuite installed');
        sharedDialog.emit({ view: 'newItem', files: [], menuSource: module });
        this.trackingService.trackEvent('web-app.usage', trackName, trackName + '-fail');
      },
      (e) => {
        // on success
        console.log('officesuite success');
        const shouldShowBannerCookies = !this.manageCookieService.getCookie('OfficeSuite_Promo_Trial_Popup');
        if (shouldShowBannerCookies && this.loginService.getProfileData()?.planFeatures?.storageTier === 0) {
          sharedDialog.emit({ view: 'promoOSTrial', files: [] });
          this.manageCookieService.setCookie('OfficeSuite_Promo_Trial_Popup', `${true}`, { period: 'd', value: 3 });
        }
        this.trackingService.trackEvent('web-app.usage', trackName, trackName + '-success');
      },
      (e) => {
        // If Unsupported browser do download
        console.log('Unsupported browser do download');
      }
    );
  }

  getWindowsAppNewUrl(module, appCode, xchangeCode) {
    const protocolName = `OfficeSuiteProtocol`;
    const userAccount = xchangeCode ? encodeURIComponent(sessionStorage.getItem('accountId')) : '';
    const code = encodeURIComponent(xchangeCode);
    let officeUrl = `${protocolName}:open-module?module=${NewItemModulesE[module]}&account=${userAccount}`;
    if (xchangeCode) {
      officeUrl += `&xchange=${code}`;
    }
    officeUrl += `&email=${encodeURIComponent(this.loginService.profileData.email)}`;
    return officeUrl;
  }

  getWindowsAppUrl(item, appCode, xchangeCode) {
    const protocolName = appCode === 'openWithOffice' ? 'OfficeSuiteProtocol' : 'PdfExtraProtocol';
    let filePath = 'MobiSystems Drive';
    this.locationService.getBreadcrumbs().forEach((itemBC) => {
      if (itemBC.key) {
        filePath = filePath + ' > ' + itemBC.name.replace(/\/$/g, '');
      }
    });
    filePath = encodeURIComponent(`${filePath} > ${item.name}`);

    const userAccount = xchangeCode ? encodeURIComponent(sessionStorage.getItem('accountId')) : '';
    const fileAccount = encodeURIComponent(item.account);
    const key = encodeURIComponent(item.key);
    const code = encodeURIComponent(xchangeCode);
    const name = encodeURIComponent(item.name);
    let officeUrl = '';

    if (item.publiclyShared) {
      officeUrl = `${protocolName}:open-shared-link?file-account=${fileAccount}&file-key=${key}&file-name=${name}`;
    } else {
      officeUrl =
        `${protocolName}:open-drive-file?account=` +
        `${userAccount}&file-key=${key}&file-account=${fileAccount}&file-path=${filePath}&file-name=${name}`;
    }

    if (xchangeCode) {
      officeUrl += `&xchange=${code}`;
    }
    const profileData = this.loginService.getProfileData();
    if (profileData) {
      officeUrl += `&email=${encodeURIComponent(profileData.email)}`;
    }
    return officeUrl;
  }

  getMobileOpenFileLink(item, appCode, xchangeCode) {
    const dlData = {
      userAccount: xchangeCode ? encodeURIComponent(sessionStorage.getItem('accountId')) : '',
      fileAccount: encodeURIComponent(item.account),
      key: encodeURIComponent(item.key),
      code: encodeURIComponent(xchangeCode),
    };

    return this.buildDeepLink('openfile', appCode, dlData);
  }

  getMobileShareLink(appCode, shareData) {
    if (this.server) {
      return '';
    }
    return this.buildDeepLink('sharelink', appCode, shareData);
  }

  getMobileStoreLink(appCode, appData) {
    return this.buildDeepLink('storelink', appCode, appData);
  }

  isOfficeSupported(file, filePreviewException?): any {
    const fileExt: string = file['name'].split('.').pop().toLowerCase();
    const officePlatform: any = this.osConfigMap[this.deviceData.os];

    // Disable File preview for Document files for Unsupported OS
    if (!officePlatform && filePreviewException) {
      const officePlatformFallback: string = 'officeFormatsAndroid';
      if (configs[officePlatformFallback].extensions.indexOf(fileExt) !== -1) {
        return 'notSupported';
      } else if (configs[officePlatformFallback].mimeTypes.indexOf(file.type) !== -1) {
        return 'notSupported';
      }
    }

    // Throw False for unknown OS
    if (officePlatform) {
      if (configs[officePlatform].extensions.indexOf(fileExt) !== -1 && fileExt !== 'pdf') {
        return 'openWithOffice';
      } else if (configs[officePlatform].extensions.indexOf(fileExt) !== -1 && fileExt === 'pdf') {
        return 'openWithPdfExtra';
      } else if (configs[officePlatform].mimeTypes.indexOf(file.type) !== -1) {
        return 'openWithOffice';
      }
    }

    return false;
  }

  isFileFormatPdf(file): boolean {
    const fileExt: string = file['name'].split('.').pop().toLowerCase();
    return fileExt === 'pdf';
  }

  isFileBusy(fileKey) {
    // Prevent from click flood
    let downloadProcess = false;
    const dwMap = this.downloadManagerService.getDownloadMap();
    for (const key in dwMap) {
      if (dwMap[key].fileKey === fileKey) {
        downloadProcess = true;
      }
    }

    return downloadProcess;
  }

  buildDeepLink(linkType: string, appCode, linkData?) {
    let deepLink: any = null;
    const getHost = environment.mDomain ? environment.mDomain : window.location.host;
    const os = this.deviceData.os.toLowerCase();

    let app: any;
    if (linkType === 'storelink' && linkData) {
      app = appParams[linkData.store][appCode];
    } else {
      // Handle MobiDrive banner case
      if (os === 'windows' && appCode === 'openWithMobiDrive') {
        app = appParams['android'][appCode];
      } else {
        app = appParams[os][appCode];
      }
    }

    // Link Application data
    let appLink = `https://${app.page}/`;

    // Handle banner links from mobidrive banner
    if (linkType === 'storelink') {
      appLink =
        linkData.store === 'android'
          ? `https://play.google.com/store/apps/details?id=${app.apn}`
          : `https://apps.apple.com/app/id${app.isi}`;
    } else {
      // Set apn for iOS only
      if (app.apn) {
        appLink += `?apn=${app.apn}`;
      }

      // Set ibi for iOS only
      if (app.ibi) {
        appLink += `?ibi=${app.ibi}`;
      }
    }

    // Sharelink
    let startParams = `https://${getHost}`;
    if (linkType === 'sharelink') {
      if (linkData.shareType) {
        startParams += `/sharelink/${linkData.shareType}/${linkData.shareKey}?sharelink=${linkData.shareKey}`;
      } else {
        startParams += `/sharelink/${linkData.shareKey}?sharelink=${linkData.shareKey}`;
      }

      const amv = linkData.dir && app.amvOpenFolder ? app.amvOpenFolder : app.amvSharelink;

      let endLink = app.isi ? `&isi=${app.isi}` : '';
      endLink += `&utm_medium=sharelink&utm_source=webdrive&amv=${amv}`;
      deepLink = `${appLink}&link=${encodeURIComponent(startParams)}${endLink}`;
    }

    // Open file
    if (linkType === 'openfile') {
      startParams += `/openfile?fileaccount=${linkData.fileAccount}&filekey=${linkData.key}`;
      if (linkData.code) {
        startParams += `&account=${linkData.userAccount}&xchangecode=${linkData.code}`;
      }

      let endLink = app.isi ? `&isi=${app.isi}` : '';
      endLink += `&utm_medium=openfile&utm_source=webdrive&amv=${app.amvOpenFile}`;

      deepLink = `${appLink}&link=${encodeURIComponent(startParams)}${endLink}`;
    }

    // Open App in Store
    if (linkType === 'storelink') {
      if (linkData.store === 'ios') {
        deepLink = `${appLink}`;
      } else {
        const utm = linkData.utm_source ? linkData.utm_source : 'webdrive';
        deepLink = `${appLink}&link=${encodeURIComponent(app.domain)}&utm_source=${utm}`;
      }

      //https://your_subdomain.page.link/?link=your_deep_link&apn=package_name[&amv=minimum_version][&afl=fallback_link]
    }

    return deepLink;
  }

  navigateToOfficeSuiteWeb(payload, urlTarget: string): void {
    const lang = this.languageManagerService.getCurrentLang();
    const file = {
      fileId: payload,
    };
    this.authService
      .getOfficeSuiteOnlineLink(file, lang.code)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        if (res?.result) {
          const url = res.result.replace(/["']/g, '');
          window.open(url, urlTarget);
        }
      });
  }

  goToMobiDrive(path: string = '') {
    if (this.loginService.isLoggedIn()) {
      this.router.navigate(['/my-files']);
    } else {
      this.loginService.signIn(path);
    }
  }
}
