<!-- Loader view -->
<div *ngIf="loadingFolders" class="dir-wrapper loading" [@fadeRegular]>
  <app-loader-grid [loaderTemplate]="'move-files-modal'"></app-loader-grid>
</div>

<!-- Folder list view -->
<div
  *ngIf="!loadingFolders"
  class="dir-wrapper"
  #foldersList
  (scroll)="onListScroll()"
  [perfectScrollbar]
  [disabled]="mobileDevice"
  [@fadeRegular]
>
  <div class="folders-list">
    <ul *ngIf="folderList.length" class="ft-list ft-body-list cfix">
      <li *ngFor="let folder of folderList">
        <div
          *ngIf="!folder.hiddenItem"
          class="item-wrapper folder"
          [ngClass]="{ selected: folder.selected, disabled: folder.disabled }"
        >
          <div class="cell-selection">
            <span class="checkbox-item style-type-2">
              <input
                type="checkbox"
                [checked]="folder.selected"
                [id]="'folder-key' + folder.key"
                (change)="selectFolder($event, folder.key)"
              />
              <ng-container *ngIf="sharedResource">
                <label [for]="'folder-key' + folder.key"></label>
              </ng-container>
              <ng-container *ngIf="!sharedResource">
                <label
                  *ngIf="filesParent.key !== folder.key || differentParents"
                  [for]="'folder-key' + folder.key"
                ></label>
                <label *ngIf="filesParent.key === folder.key && !differentParents" class="disabled"></label>
              </ng-container>
            </span>
          </div>

          <div class="cell-icon">
            <span class="menu-icon iconame-ft-folder"></span>
          </div>

          <div class="cell-name" (click)="openFolder(folder)">
            <span title="{{ folder.name | folderName }}">{{ folder.name | folderName }}</span>
          </div>
        </div>
      </li>
      <li class="load-files-flag" *ngIf="loadScroll" [@fadeRegular]>
        <span class="files-loader iconame-loading"></span>
      </li>
    </ul>

    <div class="txt-box" *ngIf="!folderList.length">
      <p *ngIf="!canMoveHere && !differentParents">{{ 'modal.sameFolders' | translate }}</p>
      <p *ngIf="canMoveHere">
        {{ 'modal.noFolders' | translate }}
      </p>
    </div>
  </div>
</div>
