import {
  Component,
  OnInit,
  Input,
  NgZone,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { GetFilesService } from '../../../services/get-files.service';
import { SearchService, ISearchCatItem } from '../../../services/search.service';
import { Router, Event, ActivationStart } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TrackingService } from '../../../services/tracking.service';
import { fromEvent } from 'rxjs';
import { IRefsMap } from '../../../models/common.int';
import { DeviceInfo } from 'ngx-device-detector';

export interface ISuggestionItem {
  key: string;
  icon: string;
}

@Component({
  selector: 'search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBarComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() set pageName(pageName) {
    if (pageName !== 'search') {
      this.searchValue = '';
    } else {
      this.suggestionShow = this.searchValue ? true : false;
    }
  }

  @Input() mobileView: boolean;

  @ViewChild('searchInput') searchInput: ElementRef;

  refs: IRefsMap = {};

  suggestionsItems: ISuggestionItem[] = [];
  suggestionShow: boolean = false;
  currentCategory: string = '';
  searchValue: string = '';
  showClearBtn: boolean = false;
  showSearch: boolean = false;
  dropdownShow: boolean = false;
  inputIsFocused: boolean = false;
  deviceData: DeviceInfo = this.deviceService.getDeviceInfo();

  categories: ISearchCatItem[] = this.searchService.getSearchCategories();

  constructor(
    private getFilesService: GetFilesService,
    private cdRef: ChangeDetectorRef,
    private searchService: SearchService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private trackingService: TrackingService,
    private zone: NgZone
  ) {}

  ngOnInit(): void {
    this.getSavedSearchData();

    // Get search suggestions
    this.refs.showSearchSuggestionsRef = this.getFilesService.getSearchSuggestions.subscribe((items) => {
      this.suggestionsItems = [];
      items.forEach((item) => {
        const iconName = item.dir ? 'folder' : item.name.split('.').pop().toLowerCase();
        this.suggestionsItems.push({ key: item.name, icon: iconName });
      });
      this.suggestionShow = this.suggestionsItems.length !== 0;
    });

    // Handle search word
    this.refs.searchKeyRef = this.searchService.searchKey.subscribe((searchText) => {
      this.searchValue = searchText;
      this.showClearBtn = this.searchValue.length > 0;
      this.cdRef.detectChanges();
    });
  }

  ngAfterViewInit() {
    // Clear search upon page change
    this.router.events.subscribe((event: Event) => {
      if (event instanceof ActivationStart) {
        this.clearSearch(true);
        this.cdRef.detectChanges();
      }
    });

    // Hide Search Dropdown when user click elsewhere
    this.zone.runOutsideAngular(() => {
      this.refs.clicknEventRef = fromEvent(document, 'click').subscribe((event) => {
        this.handleOutsideClickEvent(event);
      });
    });
  }

  ngOnDestroy() {
    this.searchService.recordLastSearchData({
      searchText: this.searchValue,
      searchSuggestions: this.suggestionsItems,
    });

    // Clean refs
    for (const ref in this.refs) {
      if (this.refs[ref]) {
        this.refs[ref].unsubscribe();
      }
    }
  }

  getSavedSearchData() {
    const savedSearchData = this.searchService.getLastSearchData();
    if (savedSearchData) {
      this.searchValue = savedSearchData.searchText;
      this.showClearBtn = this.searchValue.length > 0;
      this.suggestionsItems = savedSearchData.searchSuggestions;
    }
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  search() {
    this.dropdownShow = false;
    if (this.searchValue) {
      this.trackingService.trackEvent('web-app.usage', 'nav-bar.search', 'type-search-text');
      this.router.navigate(['/search'], { queryParams: { s: this.searchValue }, queryParamsHandling: 'merge' });
      this.inputIsFocused = false;
    }
  }

  selectSearchCategory(value: string, state?: boolean) {
    if (state) {
      this.searchValue = 'type:' + value;
      this.trackingService.trackEvent('web-app.usage', 'nav-bar.search', 'filter.' + value);
    } else {
      this.searchValue = value;
    }

    this.router.navigate(['/search'], { queryParams: { s: this.searchValue } });
    this.inputIsFocused = false;
    this.showClearBtn = true;
    this.dropdownShow = false;
  }

  onSearchChange(event) {
    this.searchValue = event.target.value;
    const thisBrowser = this.deviceData.browser.toLowerCase();

    if (thisBrowser !== 'ie') {
      this.showClearBtn = true;
      this.dropdownShow = false;
    }

    if (this.searchValue === '' && event.inputType === 'deleteContentBackward') {
      this.clearSearch(false);
      this.inputIsFocused = true;
      this.dropdownShow = true;
    }
  }

  clearSearch(state) {
    this.suggestionShow = false;
    this.currentCategory = '';
    this.dropdownShow = false;
    this.showClearBtn = false;
    this.suggestionsItems = [];

    if (this.searchValue) {
      this.searchValue = '';
    }

    if (!state) {
      this.searchInput.nativeElement.focus();
    }
  }

  manageFocus(isFocused) {
    this.inputIsFocused = isFocused;

    if (isFocused) {
      this.dropdownShow = isFocused;
      this.showClearBtn = this.searchValue.length > 0;
      this.cdRef.detectChanges();

      this.zone.runOutsideAngular(() => {
        this.refs.keyDownEventRef = fromEvent(document, 'keydown').subscribe((event) => {
          this.handleKeyboardEventDown(event);
        });
      });
    } else {
      this.refs.keyDownEventRef.unsubscribe();
    }
  }

  handleKeyboardEventDown(event) {
    // Handle Ctrl+A
    if (event.ctrlKey && event.keyCode === 65) {
      event.preventDefault();
      this.zone.run(() => {
        this.searchInput.nativeElement.focus();
        this.searchInput.nativeElement.setSelectionRange(0, this.searchValue.length);
      });
    }

    // Handle Enter
    if (event.keyCode === 13 || event.key === 'Enter') {
      event.preventDefault();
      this.zone.run(() => {
        if (this.searchValue) {
          this.router.navigate(['/search'], { queryParams: { s: this.searchValue }, queryParamsHandling: 'merge' });
          this.dropdownShow = false;
          this.inputIsFocused = false;
          this.showClearBtn = true;
        } else {
          this.searchInput.nativeElement.blur();
          this.dropdownShow = false;
        }
      });
    }

    // Handle Esc
    if (event.keyCode === 27 || event.key === 'Escape') {
      this.zone.run(() => {
        this.toggleSearch();
      });
    }
  }

  handleOutsideClickEvent(event) {
    const eTarget = event['target'] as HTMLInputElement;
    if (!eTarget.classList) {
      return;
    }

    if (typeof eTarget.className === 'string') {
      const searchElement = eTarget.className.split(' ').indexOf('search-element');
      if (searchElement === -1) {
        this.zone.run(() => {
          this.dropdownShow = false;
          this.showClearBtn = this.searchValue.length > 0;
          this.cdRef.detectChanges();
        });
      }
    }
  }
}
