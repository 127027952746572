<section class="modal-wrap share-modal">
  <span class="back-to-view" [ngClass]="{ switch: mailView }" (click)="showMailView(false)">
    <span class="menu-icon iconame-back-arrow"></span>
  </span>

  <div class="modal-body cfix">
    <div class="view-box share-view cfix" [ngClass]="{ switch: mailView }">
      <h5 class="sub-modal-title" data-qa="shm-title">
        {{ 'modal.share' | translate }}
        <span *ngIf="itemType === 'file'" data-qa="shm-title-fname">"{{ filesData[0].name }}"</span>
        <span *ngIf="itemType === 'folder'" data-qa="shm-title-fname">"{{ filesData[0].name | folderName }}"</span>
      </h5>

      <form [formGroup]="shareForm" class="single-item-form">
        <div class="items-bar share-status-bar cfix" [ngClass]="{ loading: loading }" data-qa="shm-itmbar">
          <div class="status-icon">
            <span
              *ngIf="shareStatus && !loading"
              class="button col-blue"
              (click)="shareFile(false)"
              [@fadeRegular]
              data-qa="shm-itmbar-shfile-btn"
            >
              <span class="menu-icon iconame-active-shared-file"></span>
            </span>

            <span
              *ngIf="!shareStatus && !loading"
              class="button col-grey"
              (click)="shareFile(true)"
              [@fadeRegular]
              data-qa="shm-itmbar-shfile-btn"
            >
              <span class="menu-icon iconame-disabled-shared-file"></span>
            </span>

            <span *ngIf="loading" class="button col-grey no-hover" [@fadeRegular]>
              <span class="animation-block"></span>
            </span>
          </div>

          <div class="status-text" data-qa="shm-itmbar-status-text">
            <ng-container *ngIf="!shareError">
              <span *ngIf="shareStatus && !loading" [@fadeRegular]>{{ 'modal.anyoneCanView' | translate }}</span>
              <span *ngIf="!shareStatus && !loading" [@fadeRegular]>{{ 'modal.privateFile' | translate }}</span>
              <span *ngIf="!shareStatus && loading" [@fadeRegular]>{{ 'modal.generatingLink' | translate }}</span>
              <span *ngIf="shareStatus && loading" [@fadeRegular]>{{ 'base.working' | translate }}</span>
            </ng-container>

            <ng-container *ngIf="shareError">
              <span *ngIf="!shareStatus" class="error-text" [@fadeRegular]>{{ 'errorMessages.linkNotGenerated' | translate }}</span>
              <span *ngIf="shareStatus" class="error-text" [@fadeRegular]>{{ 'errorMessages.linkNotUnshared' | translate }}</span>
            </ng-container>
          </div>

          <div *ngIf="!loading" class="share-action-link" [@fadeRegular]>
            <ng-container *ngIf="!shareError">
              <span *ngIf="shareStatus && !hasOtherOwner" (click)="shareFile(false)" data-qa="shm-itmbar-shfile-txt">{{
                'modal.stopSharing' | translate
              }}</span>
              <span *ngIf="!shareStatus" (click)="shareFile(true)" data-qa="shm-itmbar-shfile-txt">{{
                'modal.getShareableLink' | translate
              }}</span>
            </ng-container>

            <span *ngIf="shareError" (click)="shareFile(!shareStatus)" data-qa="shm-itmbar-shfile-txt">{{
              'modal.retry' | translate
            }}</span>
          </div>
        </div>

        <div class="form-item cfix input-with-button">
          <div class="input-wrapper cfix">
            <input
              type="text"
              id="shLink"
              data-qa="shm-input"
              #shLink
              class="input-style"
              formControlName="shLink"
              (blur)="onBlurMethod($event)"
              placeholder="{{ 'modal.linkShareOff' | translate }}"
              readonly
            />
          </div>
          <label
            for="shLink"
            data-qa="shm-input-btn"
            class="button col-blue small-size"
            (click)="copyToClipboard()"
            [ngClass]="{ disabled: loading || shareError || !shareStatus }"
          >
            <span class="menu-icon iconame-copy-link"></span>
          </label>
        </div>

        <div class="bottom-share-section">
          <div class="items-bar send-email-bar" *ngIf="shareStatus && !loading">
            <div class="status-icon">
              <span class="button col-blue" (click)="showMailView(true)" [@fadeRegular] data-qa="shm-email-btn">
                <span class="menu-icon iconame-email"></span>
              </span>
            </div>
            <span class="send-email-text share-action-link" (click)="showMailView(true)" data-qa="shm-email-btn">
              {{ 'modal.sendEmail' | translate }}
            </span>
          </div>

          <span class="copied" [ngClass]="{ show: showCopiedText }">
            {{ 'modal.copiedToClipboard' | translate }}
          </span>
        </div>
      </form>
    </div>

    <div class="view-box email-view cfix" [ngClass]="{ switch: mailView }">
      <h5 class="sub-modal-title">
        <span *ngIf="itemType === 'file'" [outerHTML]="'modal.sendFileTo' | translate : { fileName: filesData[0].name }"> </span>
        <span *ngIf="itemType === 'folder'" [outerHTML]="'modal.sendFolderTo' | translate : { folderName: filesData[0].name | folderName }">
        </span>
      </h5>

      <div
        class="entry-mails"
        [ngClass]="{ focused: mailBoxFocused, locked: emailsList.length === emailLimit }"
        #emailEntryContainer
        [perfectScrollbar]
        [disabled]="mobileDevice || heightLimit"
      >
        <tag-input
          #tagInput
          [(ngModel)]="emailsList"
          [placeholder]="'modal.plusMail' | translate"
          [secondaryPlaceholder]="'modal.addEmails' | translate"
          [separatorKeyCodes]="[32, 186, 188]"
          [separatorKeys]="['32', '186', '188']"
          [addOnBlur]="true"
          [addOnPaste]="true"
          [editable]="true"
          [disable]="sendingMail"
          [inputId]="'emailsListInput'"
          [pasteSplitPattern]="null"
          [animationDuration]="{ enter: '0ms', leave: '0ms' }"
          (onFocus)="setMailBoxState(true)"
          (onBlur)="onBlurEmailInput(false, $event)"
          (onAdd)="onAdding($event)"
          (onRemove)="onRemove($event)"
          (onSelect)="onTagSelect($event)"
          (onTagEdited)="onTagEdited($event, emailsList)"
        >
        </tag-input>
      </div>

      <div class="error-box" *ngIf="emailsList.length >= emailLimit">
        <div class="form-error">
          {{ 'modal.emailLimit' | translate : { emailLimit: emailLimit } }}
        </div>
      </div>

      <form [formGroup]="sendEmailForm" class="single-item-form message-form">
        <div class="form-item">
          <textarea
            class="textarea-style input-style"
            #mailMessage
            id="mailMessage"
            formControlName="mailMessage"
            placeholder="{{ 'modal.enterMessageShareLink' | translate }}"
          >
          </textarea>
        </div>
        <div
          class="text-limit"
          [ngClass]="{ error: emailFc.mailMessage.value.length > emailTextLimit }"
          *ngIf="emailFc.mailMessage.value && emailFc.mailMessage.value.length > 249"
        >
          {{ sendEmailForm.controls.mailMessage.value.length + '/' + emailTextLimit }}
        </div>
        <p class="recaptcha-disclaimer" [innerHTML]="'modal.recaptchaDisclaimer' | translate"></p>
      </form>
    </div>
  </div>

  <footer class="modal-footer cfix">
    <button *ngIf="!mailView" class="button col-blue medium-size" [disabled]="loading" (click)="modal.close()" data-qa="done-share-btn">
      {{ 'modal.done' | translate }}
    </button>

    <button
      *ngIf="mailView"
      class="button col-blue medium-size"
      [disabled]="
        emailValidationLoading ||
        loading ||
        !emailsList.length ||
        sendingMail ||
        invalidEmail ||
        (emailFc.mailMessage.value && emailFc.mailMessage.value.length > emailTextLimit)
      "
      title="{{ invalidEmail ? ('modal.sendLinkButtonError' | translate) : '' }}"
      (click)="sendEmail()"
    >
      <span *ngIf="!sendingMail">{{ 'modal.sendEmail' | translate }}</span>
      <span *ngIf="sendingMail">{{ 'modal.sendingShareLink' | translate }}</span>
    </button>
  </footer>
</section>
