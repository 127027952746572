<section class="modal-wrap delete-modal">
  <h5 class="modal-title">
    <span *ngIf="filesForDelete === 1">{{ 'modal.singleRemoveConfirm' | translate }}</span>
    <span
      *ngIf="filesForDelete > 1"
      [innerHTML]="'modal.multiRemoveConfirm' | translate: { value: filesForDelete }"
    ></span>
  </h5>

  <div class="modal-body cfix">
    <div class="files-to-delete">
      <strong>{{ fileName }}</strong>
    </div>
  </div>

  <footer class="modal-footer cfix">
    <button class="button col-blue medium-size" #confirmRemove [disabled]="loading" (click)="removeFiles()">
      {{ 'modal.remove' | translate }}
    </button>
    <button class="button col-grey medium-size" [disabled]="loading" (click)="modal.close()">
      {{ 'modal.cancel' | translate }}
    </button>
  </footer>
</section>
